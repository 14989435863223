import {Button, Divider} from '@mui/material'
import {asSubmitData} from 'pages/install/dataConfig/submitData'
import markerImage from 'pages/install/images/maker.png'
import AsInstallModalContainer from 'pages/install/modules/asModalComponents/AsInstallModalContainer'
import {useRef, useState} from 'react'
import {Map, MapMarker, MapTypeControl} from 'react-kakao-maps-sdk'
import {useSelector} from 'react-redux'

const AsInstall = (props: any) => {
  const {countCall} = props
  const position = useSelector((state: any) => state?.currentPosition.position)
  const userInfo = useSelector((state: any) => state?.userInfo?.userInfo)
  const [asInstallSubmit, setAsInstallSubmit] = useState<any>(asSubmitData)
  const mapRef = useRef<any>(null)
  const [renderStep, setRenderStep] = useState<number>(0)
  const clear = () => {
    setRenderStep(0)
    setAsInstallSubmit(asSubmitData)
  }

  const [modalStatus, setModalStatus] = useState<boolean>(false)
  const modalOpen = () => {
    setAsInstallSubmit((prev: any) => ({
      ...prev,
      latitude: position?.latitude,
      longitude: position?.longitude,
      updateId: userInfo?.userId,
    }))

    setModalStatus(true)
  }
  const modalClose = () => setModalStatus(false)

  return (
    <div className={'container-fluid w-100 px-0 mb-5'}>
      <div className={'card card-body '}>
        <span className={'fw-bolder fs-3'}>현재위치</span>
        <Map
          id='map'
          center={{
            lat: position?.latitude,
            lng: position?.longitude,
          }}
          onCreate={(map: any) => (mapRef.current = map)}
          isPanto={false}
          style={{
            width: '100%',
            height: '80vw',
          }}
          level={3}
          mapTypeId={kakao.maps.MapTypeId.ROADMAP}
          className={' mt-2 rounded'}
        >
          <MapTypeControl position={kakao.maps.ControlPosition.TOPRIGHT} />
          <MapMarker
            position={{
              lat: position?.latitude,

              lng: position?.longitude,
            }}
            draggable={false}
            image={{src: markerImage, size: {width: 30, height: 45}}}
          />
        </Map>
        <Divider className={'my-3 '} color={'#919090'} />
        <Button variant='contained' color='warning' className={'float-end'} onClick={modalOpen}>
          A/S 진행하기
        </Button>
      </div>
      <AsInstallModalContainer
        open={modalStatus}
        onClose={modalClose}
        name={'as-modal-container'}
        renderStep={renderStep}
        set={setAsInstallSubmit}
        clear={clear}
        countCall={countCall}
        asSubmit={asInstallSubmit}
        setRenderStep={setRenderStep}
      />
    </div>
  )
}

export default AsInstall
