import React, {useEffect, useState} from 'react'
import {Button, TextField, Tooltip} from '@mui/material'
import {envBackHost} from '../../../GLOBAL/envVariables'
import {axiosWithAuth} from '../../../helper/axiosWithAuth'
import {normalAlert, resultAlert} from '../../../utils/CustomAlert/Alert'

const CustomerAddModal = (props: any) => {
  const {modalType, toast, setDetailInfoFlag, detailInfoFlag, selectedOfficeInfo, flag, setFlag} =
    props
  const [updateCustomerType, setUpdateCustomerType] = useState<any>({
    customerTypeName: '',
  })
  const [updateCustomerWorkType, setUpdateCustomerWorkType] = useState<any>({
    customerWorkTypeName: '',
  })
  const resetHandler = () => {
    setUpdateCustomerType('')
    setUpdateCustomerWorkType('')
  }

  const buttonHandler = async () => {
    if (modalType === '대상') {
      if (updateCustomerType === '') {
        await normalAlert(`추가할 ${modalType} 명을 입력해주세요.`)
        return
      }
      await axiosWithAuth()
        .post(
          `${envBackHost}/office/${selectedOfficeInfo?.officeId}/customertype`,
          updateCustomerType
        )
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            setFlag(!flag)
            document.getElementById('addCustomerModal')?.click()
            setDetailInfoFlag(!detailInfoFlag)
            resetHandler()
            return
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    } else if (modalType === '업종') {
      if (updateCustomerWorkType === '') {
        await normalAlert(`추가할 ${modalType} 명을 입력해주세요.`)
        return
      }
      await axiosWithAuth()
        .post(
          `${envBackHost}/office/${selectedOfficeInfo?.officeId}/customerworktype`,
          updateCustomerWorkType
        )
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            setFlag(!flag)
            document.getElementById('addCustomerModal')?.click()
            setDetailInfoFlag(!detailInfoFlag)
            resetHandler()
            return
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    }
  }
  return (
    <div
      className='modal fade'
      id='customerAddModal'
      tabIndex={-1}
      aria-labelledby='customerAddModalLabel'
      aria-hidden='true'
      data-bs-backdrop={'true'}
    >
      <div className='modal-dialog modal-dialog-centered modal-lg'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='customerAddModalLabel'>
              수용가 {modalType} 추가
            </h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              id={'exitButton'}
              onClick={() => resetHandler()}
            ></button>
          </div>
          {/*hi*/}
          <div className='modal-body'>
            <TextField
              variant={'outlined'}
              label={`수용가 ${modalType} 명`}
              defaultValue={
                modalType === '대상'
                  ? updateCustomerType.customerTypeName
                  : updateCustomerWorkType.customerWorkTypeName
              }
              // defaultValue={
              //   modalType === '업종'
              //     ? updateCustomerType.customerWorkType
              //     : updateCustomerWorkType.customerWorkType
              // }
              placeholder={`수용가 ${modalType} 명을 입력해주세요.`}
              onChange={(e) =>
                modalType === '대상'
                  ? setUpdateCustomerType({
                      customerTypeName: e.target.value,
                    })
                  : setUpdateCustomerWorkType({
                      customerWorkTypeName: e.target.value,
                    })
              }
              fullWidth
            />
          </div>

          <div className='modal-footer d-flex gap-4'>
            <Button variant={'outlined'} size={'large'} onClick={() => buttonHandler()}>
              추가
            </Button>
            <Tooltip
              title={<span className={'fs-5 fw-bolder'}>바깥화면을 클릭해도 창이 닫힙니다.</span>}
              placement={'right'}
              arrow={true}
            >
              <Button
                variant={'contained'}
                id={'addCustomerModal'}
                color={'error'}
                size={'large'}
                data-bs-dismiss='modal'
                className={'pulse pulse-light'}
                onClick={() => {
                  resetHandler()
                }}
              >
                <span>닫기</span>
                <span className={'pulse-ring  border-4'}></span>
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerAddModal
