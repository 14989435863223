import React from 'react'
import {Button} from '@mui/material'
import TextField from '@mui/material/TextField'
import {DateRangePicker, LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import {DateRange} from '@mui/x-date-pickers-pro/DateRangePicker'
import Box from '@mui/material/Box'
import {Dayjs} from 'dayjs'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'

const DownloadOffcanvas = (props: any) => {
  const [value, setValue] = React.useState<DateRange<Dayjs>>([null, null])
  return (
    <div
      className='offcanvas offcanvas-end'
      tabIndex={-1}
      id='downloadOffcanvas'
      aria-labelledby='downloadOffcanvasLabel'
      style={{width: !isMobileDevice() ? '40%' : '100%'}}
    >
      <div className='offcanvas-header'>
        <h2 className='offcanvas-title' id='downloadOffcanvasLabel'>
          로그기록 다운로드
        </h2>
        <button
          type='button'
          className='btn-close text-reset'
          data-bs-dismiss='offcanvas'
          aria-label='Close'
        ></button>
      </div>
      <div className='offcanvas-body'>
        <div id={'date-range'}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{start: '시작날짜', end: '마지막 날짜'}}
          >
            <DateRangePicker
              className={'justify-content-center mb-5'}
              value={value}
              onChange={(newValue) => {
                setValue(newValue)
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{mx: 1}}> 부터 </Box>
                  <TextField {...endProps} />
                  <Box sx={{mx: 1}}> 까지 </Box>
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </div>
        {value[1] && (
          <div id={'log-download'} className={'my-7 text-center'}>
            <Button variant={'contained'} className={'mx-1'} color={'success'}>
              엑셀다운로드
            </Button>
          </div>
        )}
        <div className={'d-flex gap-7 justify-content-end '} style={{marginTop: '100px'}}>
          <Button variant={'contained'} size={'large'} color={'error'} data-bs-dismiss='offcanvas'>
            나가기
          </Button>
        </div>
      </div>
    </div>
  )
}
export default DownloadOffcanvas
