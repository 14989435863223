import {GridColumnGroupingModel} from '@mui/x-data-grid-pro'

export const customerAllocationGroup: GridColumnGroupingModel = [
  {
    groupId: '사업소코드',
    description: '',
    headerAlign: 'center',
    children: [{field: 'officeName'}],
  },
]
export const customerInfoGroup: GridColumnGroupingModel = [
  {
    groupId: '사업소 코드',
    description: '사업소에 관한 정보입니다.',
    children: [{field: 'officeName'}],
    headerAlign: 'center',
  },
  {
    groupId: '이름',
    headerAlign: 'center',
    description: '수용가에 관한 정보입니다.',
    children: [{field: 'customerInfo'}],
  },
  {
    groupId: '단말기 SN',
    headerAlign: 'center',

    description: '단말기에 관한 정보입니다.',
    children: [{field: 'deviceInfo'}],
  },
  {
    groupId: '검침정보',
    headerAlign: 'center',
    description: '검침내역에 관한 정보입니다.',
    children: [{field: 'checkInfo'}],
  },
  {
    groupId: '구경',
    headerAlign: 'center',
    description: '수용가에 관한 정보입니다.',
    children: [{field: 'customerDetail'}],
  },
]
export const customerInfoBoardVersionGroup: GridColumnGroupingModel = [
  {
    groupId: '사업소 코드',
    description: '사업소에 관한 정보입니다.',
    children: [{field: 'officeName'}],
    headerAlign: 'center',
  },
  {
    groupId: '수용가 번호',
    headerAlign: 'center',
    description: '수용가에 관한 정보입니다.',
    children: [{field: 'customerNumber'}],
  },
  {
    groupId: '이름',
    headerAlign: 'center',
    description: '수용가에 관한 정보입니다.',
    children: [{field: 'customerInfo'}],
  },
  {
    groupId: '단말기 SN',
    headerAlign: 'center',

    description: '단말기에 관한 정보입니다.',
    children: [{field: 'deviceInfo'}],
  },
  {
    groupId: '검침정보',
    headerAlign: 'center',
    description: '검침내역에 관한 정보입니다.',
    children: [{field: 'checkInfo'}],
  },
  {
    groupId: '구경',
    headerAlign: 'center',
    description: '수용가에 관한 정보입니다.',
    children: [{field: 'customerDetail'}],
  },
  {
    groupId: '단말기 통신상태',
    headerAlign: 'center',
    description: '단말기 통신상태에 대한 정보입니다.',
    children: [{field: 'deviceStatus'}],
  },
]

export const CustomerActivityGroup: GridColumnGroupingModel = [
  {
    groupId: '이름',
    headerAlign: 'center',
    description: '수용가에 관한 정보입니다.',
    children: [{field: 'customerInfo'}],
  },
  {
    groupId: '검침정보',
    headerAlign: 'center',
    description: '검침내역에 관한 정보입니다.',
    children: [{field: 'checkInfo'}],
  },
]

export const CustomerStatusGroup: GridColumnGroupingModel = [
  {
    groupId: '수용가 번호',
    headerAlign: 'center',
    description: '수용가에 관한 정보입니다.',
    children: [{field: 'customerNumber'}],
  },

  {
    groupId: '이름',
    headerAlign: 'center',
    description: '수용가에 관한 정보입니다.',
    children: [{field: 'customerInfo'}],
  },
  {
    groupId: '단말기 SN',
    headerAlign: 'center',

    description: '단말기에 관한 정보입니다.',
    children: [{field: 'deviceInfo'}],
  },
  {
    groupId: '검침정보',
    headerAlign: 'center',
    description: '검침내역에 관한 정보입니다.',
    children: [{field: 'checkInfo'}],
  },
  {
    groupId: '구경',
    headerAlign: 'center',
    description: '수용가에 관한 정보입니다.',
    children: [{field: 'customerDetail'}],
  },
  {
    groupId: '통신상태',
    headerAlign: 'center',
    description: '단말기에 관한 정보입니다.',
    children: [{field: 'deviceStatus'}, {field: 'efosStatus'}],
  },
]
export const customerCheckListGroup: GridColumnGroupingModel = [
  {
    groupId: '이름',
    headerAlign: 'center',
    description: '수용가에 관한 정보입니다.',
    children: [{field: 'customerInfo'}],
  },
]
