import {useSelector} from 'react-redux'
import {Link, useLocation} from 'react-router-dom'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import './../../common/globalFontSize.css'
const DeviceHeader = (props: any) => {
  const location = useLocation()
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const isMobile = isMobileDevice()
  return (
    <>
      {!isMobile && (
        <div className='card mb-5 '>
          <div className='card-body pt-4 pb-0'>
            <div className='d-flex overflow-auto h-55px dynamic-font-size'>
              {userInfo?.accountType === 'sys_admin' && (
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent  fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/device/register' && 'active')
                      }
                      to={`/device/register`}
                    >
                      단말기 등록 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/device/allocation' && 'active')
                      }
                      to={`/device/allocation`}
                    >
                      단말기 검수/할당 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/device/info' && 'active')
                      }
                      to={`/device/info`}
                    >
                      단말기 정보 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/device/images' && 'active')
                      }
                      to={`/device/images`}
                    >
                      단말기 수신영상 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/device/history' && 'active')
                      }
                      to={`/device/history`}
                    >
                      단말기 HISTORY
                    </Link>
                  </li>
                  {/* 단말기 펌웨어 */}
                  {/*<li className='nav-item'>*/}
                  {/*  <Link*/}
                  {/*    className={*/}
                  {/*      `nav-link text-active-primary me-6 ` +*/}
                  {/*      (location.pathname === '/pages/device/firmware' && 'active')*/}
                  {/*    }*/}
                  {/*    to='/pages/device/firmware'*/}
                  {/*  >*/}
                  {/*    단말기 펌웨어 관리*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                </ul>
              )}
              {userInfo?.accountType === 'sys_user' && (
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent  fw-bolder flex-nowrap'
                  style={{fontSize: '22px'}}
                >
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/device/register' && 'active')
                      }
                      to={`/device/register`}
                    >
                      단말기 등록 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/device/allocation' && 'active')
                      }
                      to={`/device/allocation`}
                    >
                      단말기 검수/할당 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/device/info' && 'active')
                      }
                      to={`/device/info`}
                    >
                      단말기 정보 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/device/images' && 'active')
                      }
                      to={`/device/images`}
                    >
                      단말기 수신영상 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/device/history' && 'active')
                      }
                      to={`/device/history`}
                    >
                      단말기 HISTORY
                    </Link>
                  </li>
                </ul>
              )}
              {userInfo?.accountType === 'inspector' && (
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent  fw-bolder flex-nowrap'
                  style={{fontSize: '22px'}}
                >
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/device/register' && 'active')
                      }
                      to={`/device/register`}
                    >
                      단말기 등록 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/device/allocation' && 'active')
                      }
                      to={`/device/allocation`}
                    >
                      단말기 검수/할당 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/device/info' && 'active')
                      }
                      to={`/device/info`}
                    >
                      단말기 정보 관리
                    </Link>
                  </li>
                </ul>
              )}
              {userInfo?.accountType === 'gov_user' && (
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent  fw-bolder flex-nowrap'
                  style={{fontSize: '22px'}}
                >
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/device/info' && 'active')
                      }
                      to={`/device/info`}
                    >
                      단말기 정보 관리
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className='card mb-5 mb-xl-10 mt-5'>
          <div className='card-body pt-4 pb-0'>
            <div className='d-flex overflow-auto h-auto'>
              {userInfo?.accountType === 'sys_admin' && (
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-bolder justify-content-evenly'
                  style={{fontSize: '20px'}}
                >
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary mx-3 ` +
                        (location.pathname === '/device/register' && 'active')
                      }
                      to='/device/register'
                    >
                      단말기 등록 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary mx-3 ` +
                        (location.pathname === '/device/allocation' && 'active')
                      }
                      to='/device/allocation'
                    >
                      단말기 검수/할당 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary mx-3 ` +
                        (location.pathname === '/device/info' && 'active')
                      }
                      to='/device/info'
                    >
                      단말기 정보 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary mx-3 ` +
                        (location.pathname === '/device/images' && 'active')
                      }
                      to='/device/images'
                    >
                      단말기 수신영상 관리
                    </Link>
                  </li>
                  {/* 단말기 펌웨어 */}
                  {/*<li className='nav-item'>*/}
                  {/*  <Link*/}
                  {/*    className={*/}
                  {/*      `nav-link text-active-primary me-6 ` +*/}
                  {/*      (location.pathname === '/pages/device/firmware' && 'active')*/}
                  {/*    }*/}
                  {/*    to='/pages/device/firmware'*/}
                  {/*  >*/}
                  {/*    단말기 펌웨어 관리*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                </ul>
              )}
              {userInfo?.accountType === 'sys_user' && (
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-bolder justify-content-evenly'
                  style={{fontSize: '20px'}}
                >
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary mx-3 ` +
                        (location.pathname === '/device/register' && 'active')
                      }
                      to='/device/register'
                    >
                      단말기 등록 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary mx-3 ` +
                        (location.pathname === '/device/allocation' && 'active')
                      }
                      to='/device/allocation'
                    >
                      단말기 검수/할당 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary mx-3 ` +
                        (location.pathname === '/device/info' && 'active')
                      }
                      to='/device/info'
                    >
                      단말기 정보 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary mx-3 ` +
                        (location.pathname === '/device/images' && 'active')
                      }
                      to='/device/images'
                    >
                      단말기 수신영상 관리
                    </Link>
                  </li>
                  {/* 단말기 펌웨어 */}
                  {/*<li className='nav-item'>*/}
                  {/*  <Link*/}
                  {/*    className={*/}
                  {/*      `nav-link text-active-primary me-6 ` +*/}
                  {/*      (location.pathname === '/pages/device/firmware' && 'active')*/}
                  {/*    }*/}
                  {/*    to='/pages/device/firmware'*/}
                  {/*  >*/}
                  {/*    단말기 펌웨어 관리*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                </ul>
              )}
              {userInfo?.accountType === 'inspector' && (
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-bolder justify-content-evenly'
                  style={{fontSize: '20px'}}
                >
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary mx-3 ` +
                        (location.pathname === '/device/register' && 'active')
                      }
                      to='/device/register'
                    >
                      단말기 등록 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary mx-3 ` +
                        (location.pathname === '/device/allocation' && 'active')
                      }
                      to='/device/allocation'
                    >
                      단말기 검수/할당 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary mx-3 ` +
                        (location.pathname === '/device/info' && 'active')
                      }
                      to='/device/info'
                    >
                      단말기 정보 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary mx-3 ` +
                        (location.pathname === '/device/images' && 'active')
                      }
                      to='/device/images'
                    >
                      단말기 수신영상 관리
                    </Link>
                  </li>
                  {/* 단말기 펌웨어 */}
                  {/*<li className='nav-item'>*/}
                  {/*  <Link*/}
                  {/*    className={*/}
                  {/*      `nav-link text-active-primary me-6 ` +*/}
                  {/*      (location.pathname === '/pages/device/firmware' && 'active')*/}
                  {/*    }*/}
                  {/*    to='/pages/device/firmware'*/}
                  {/*  >*/}
                  {/*    단말기 펌웨어 관리*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                </ul>
              )}
              {userInfo?.accountType === 'gov_user' && (
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-bolder justify-content-evenly'
                  style={{fontSize: '20px'}}
                >
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary mx-3 ` +
                        (location.pathname === '/device/info' && 'active')
                      }
                      to='/device/info'
                    >
                      단말기 정보 관리
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default DeviceHeader
