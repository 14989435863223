import dayjs from 'dayjs'

export const customerAllocationFilter = {
  searchOption: {
    customerNumber: false,
    customerName: false,
    address: false,
    installName: false,
  },
  searchData: '',
  assignStatus: 'all',
  customerStatus: 'all',
}
export const customerInfoFilter = (pageState: any) => {
  return {
    searchOption: {
      customerNumber: false,
      customerName: false,
      address: false,
      managerName: false,
      memo: false,
      serialNumber: false,
      installerName: false,
    },
    searchData: '',
    deviceStatus: pageState?.deviceStatus === undefined ? 'all' : pageState?.deviceStatus,
    efosStatus: 'all',
    freezeWarningType: 'all',
    recognizedType: pageState?.recognizedType === undefined ? 'all' : pageState?.recognizedType,
    deviceType: pageState?.deviceType === undefined ? 'all' : pageState?.deviceType,
    batteryStatus: pageState?.batteryStatus === undefined ? 'all' : pageState?.batteryStatus,
    rowPerPage: 30,
    page: 1,
  }
}
export const customerActivityFilter = {
  searchOption: {
    customerNumber: false,
    customerName: false,
    managerName: false,
    address: false,
    memo: false,
  },
  searchData: '',
  date: dayjs().format('YYYYMMDD'),
}

export const customerStatusFilter = (pageState: any) => {
  return {
    searchOption: {
      customerNumber: false,
      customerName: false,
      address: false,
      managerName: false,
      memo: false,
      serialNumber: false,
    },
    // date: dayjs('2024-06-19').format('YYYYMMDD'),
    searchData: '',
    deviceStatus: pageState?.deviceStatus === undefined ? 'all' : pageState?.deviceStatus,
    efosStatus: 'all',
    freezeWarningType: 'all',
    deviceType: pageState?.deviceType === undefined ? 'all' : pageState?.deviceType,
    batteryStatus: pageState?.batteryStatus === undefined ? 'all' : pageState?.batteryStatus,
    recognizedType: pageState?.recognizedType === undefined ? 'all' : pageState?.recognizedType,
    rowPerPage: 30,
    page: 1,
  }
}

export const customerCheckListFilter = {
  flag: 'week',
  start: 4,
  end: dayjs().format('YYYYMMDD'),
  rowPerPage: 30,
  page: 1,
  searchOption: {
    customerNumber: false,
    customerName: false,
    address: false,
  },
  searchData: '',
}
export const customerCheckListChartFilter = {
  flag: 'month',
  end: dayjs().format('YYYYMM'),

  searchOption: {
    customerNumber: false,
    customerName: false,
    address: false,
  },
  searchData: '',
}
export const customerCheckReportFilter = {
  searchOption: {
    customerNumber: false,
    customerName: false,
    address: false,
    meterDiameter: false,
  },
  searchData: '',
  date: dayjs().format('YYYYMM'),
  rowPerPage: 30,
  page: 1,
}
export const customerMinimumFlowFilter = {
  searchOption: {
    address: false,
    customerName: false,
    customerNumber: false,
  },
  searchData: '',
  date: dayjs().format('YYYYMMDD'),
  start: 0,
  end: 6,
  flag: false,
  rowPerPage: 30,
  page: 1,
}

export const customerLeakageFilter = {
  searchOption: {
    customerNumber: false,
    customerName: false,
    address: false,
  },

  searchData: '',
  date: dayjs().format('YYYYMMDD'),
  rowPerPage: 30,
  page: 1,
}

export const customerFreezeWarningFilter = {
  searchOption: {
    customerNumber: false,
    customerName: false,
    address: false,
  },
  searchData: '',
  freezeWarningType: 'all',
  date: dayjs().format('YYYYMMDD'),
  rowPerPage: 30,
  page: 1,
}
