import React, {useEffect, useState} from 'react'
import {DataGridPro} from '@mui/x-data-grid-pro'
import {useSelector} from 'react-redux'
import {getMonitoringList} from 'utils/apiCalling/api'
import {scrollTop} from 'utils/func/justUtils'
import {CustomerActivityGroup} from 'pages/customer/dataConfig/declearGroupingModel'
import {customerActivityRows} from 'pages/customer/dataConfig/declearRows'
import {customerActivityColumns} from 'pages/customer/dataConfig/declearColumns'
import {customerActivityFilter} from 'pages/customer/dataConfig/declearFilters'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import {toolTipText} from 'utils/toolTips/toolTipText'

const CustomerActivity = (props: any) => {
  const [pageSize, setPageSize] = useState<number>(30)
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const [loading, setLoading] = useState<boolean>(false)
  const [filter, setFilter] = useState<any>(customerActivityFilter)
  const [monitoringList, setMonitoringList] = useState<any>(null)
  const getMonitoringListCall = () => {
    getMonitoringList(officeInfo, cooperInfo, filter, {
      success: (res: any) => {
        setMonitoringList(res)
        setLoading(false)
      },
      fail: (err: any) => {
        setLoading(false)
      },
      loading: (loading: boolean) => {
        setLoading(loading)
      },
    })
  }
  const columns: any = customerActivityColumns(filter)

  const rows = customerActivityRows(monitoringList)
  useEffect(() => {
    getMonitoringListCall()
  }, [officeInfo, cooperInfo])
  useEffect(() => {
    scrollTop()
    getMonitoringListCall()
  }, [filter.date])
  return (
    <>
      <MeterariumPageLayout
        headerDescription={toolTipText.customerActivity.description}
        tooltipText={toolTipText.customerActivity.text}
        filter={filter}
        id={'customer-activity'}
        setFilter={setFilter}
        searchCallback={getMonitoringListCall}
        total={rows.length}
        control={['selectDate']}
        actions={[]}
        searchOptions={['customerNumber', 'customerName', 'address', 'managerName', 'memo']}
        checkData={null}
        onDelete={null}
        onCancel={null}
        setTypeName={null}
      >
        <DataGridPro
          headerHeight={30}
          rowHeight={210}
          sx={{height: 685, width: '100%'}}
          columnGroupingModel={CustomerActivityGroup}
          experimentalFeatures={{columnGrouping: true}}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 30, 50, 70, 100]}
          disableColumnMenu
          pagination
          loading={loading}
        />
      </MeterariumPageLayout>
    </>
  )
}
export default CustomerActivity
