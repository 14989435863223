import React, {useEffect, useState} from 'react'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {Button, Fade, IconButton, InputAdornment, TextField, Tooltip} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import SearchIcon from '@mui/icons-material/Search'
import {DataGridPro, GridColDef} from '@mui/x-data-grid-pro'
import {axiosWithAuth} from '../../../helper/axiosWithAuth'
import {envBackHost} from '../../../GLOBAL/envVariables'
import _ from 'lodash'
import dayjs from 'dayjs'
import HelpIcon from '@mui/icons-material/Help'
import ClickDevice from './../images/clickDevice.png'
import {CustomWidthTooltip} from 'utils/designSytem/CustomTooltip'
import {toolTipText} from 'utils/toolTips/toolTipText'
interface Device {
  imei: string
  id: number
  serialNumber: string
}
const DeviceHistory = () => {
  const [deviceList, setDeviceList] = useState(null)
  const [selectDevice, setSelectDevice] = useState<Device | null>(null)
  const [selectDeviceDetailInfo, setSelectDeviceDetailInfo] = useState<any>(null)
  const [filter, setFilter] = useState({
    searchData: '',
    searchOption: {
      imei: false,
      serialNumber: false,
    },
  })
  const [loadingFlag, setLoadingFlag] = useState(false)
  /*********************** API ************************/
  const getDeviceList = () => {
    axiosWithAuth()
      .get(`${envBackHost}/device/list`, {
        params: filter,
      })
      .then((res) => {
        setDeviceList(res.data.res)
      })
  }
  const getDeviceDetailInfo = () => {
    if (selectDevice === null) return
    axiosWithAuth()
      .get(`${envBackHost}/device/${selectDevice.imei}/sendinfo`)
      .then((res) => {
        setSelectDeviceDetailInfo(res.data.res)
      })
  }
  /*********************** variable ************************/
  const rows: any = _.map(deviceList, (el: any, i) => {
    return {
      id: i + 1,
      imei: el.imei,
      serialNumber: el.serialNumber,
    }
  })
  const columns: GridColDef[] = [
    {
      field: 'imei',
      headerName: '단말기 IMEI',
      width: 270,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'serialNumber',
      headerName: '단말기 S/N',
      width: 280,
      align: 'center',
      headerAlign: 'center',
    },
  ]
  const detailColumns: GridColDef[] = [
    {
      field: 'createAt',
      headerName: '업데이트 일시',
      width: 220,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'batteryLevel',
      headerName: '배터리 잔량',
      width: 130,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <span className={params.row.batteryLevel <= 20 ? 'text-danger' : 'text-dark'}>
            {params.row.batteryLevel}%
          </span>
        )
      },
    },
    {
      field: 'brightness',
      headerName: '밝기',
      width: 110,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'contrast',
      headerName: '명암',
      width: 110,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'deviceApplyStatus',
      headerName: '업데이트 대기상태',
      width: 170,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return <span>{params.row.deviceApplyStatus === false ? '없음' : '대기'}</span>
      },
    },
    {
      field: 'deviceVersion',
      headerName: '단말기 버전',
      width: 180,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'serverCycle',
      headerName: '서버주기',
      width: 130,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return <span>{params.row.serverCycle}시간</span>
      },
    },
    {
      field: 'signalStrength',
      headerName: '전파강도',
      width: 130,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return <span>{params.row.signalStrength}dBm</span>
      },
    },
    {
      field: 'temperature',
      headerName: '온도',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return <span>{params.row.temperature}°C</span>
      },
    },
  ]
  const detailRows: any = _.map(selectDeviceDetailInfo, (el: any, i) => {
    return {
      id: i + 1,
      createAt: dayjs(el.createAt).format('YYYY-MM-DD HH:mm:ss'),
      batteryLevel: el.batteryLevel,
      batteryStatus: el.batteryStatus,
      brightness: el.brightness,
      contrast: el.contrast,
      deviceApplyStatus: el.deviceApplyStatus,
      deviceVersion: el.deviceVersion,
      serverCycle: el.serverCycle,
      signalStrength: el.signalStrength,
      temperature: el.temperature,
      imei: el.imei,
    }
  })

  useEffect(() => {
    getDeviceList()
  }, [])
  useEffect(() => {
    getDeviceDetailInfo()
  }, [selectDevice])
  useEffect(() => {
    if (loadingFlag) {
      setTimeout(() => {
        setLoadingFlag(false)
      }, 500)
    }
  }, [loadingFlag])
  return (
    <>
      {!isMobileDevice() && (
        <div className='card flex-grow-1' id='device-allocation'>
          <div className='card-header '>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>{toolTipText.deviceHistory.description}</h3>
              <CustomWidthTooltip
                title={toolTipText.deviceHistory.text}
                TransitionComponent={Fade}
                TransitionProps={{timeout: 600}}
                placement={'right-start'}
                arrow={true}
                sx={{fontSize: '10px', letterSpacing: '1px', cursor: 'pointer'}}
              >
                <HelpIcon
                  color={'primary'}
                  sx={{fontSize: '24px'}}
                  className={'ms-4 mb-2 cursor-pointer'}
                />
              </CustomWidthTooltip>
            </div>
          </div>
          <div className={'card-body d-flex col gap-3'}>
            <div className={'col-3 mx-0 px-0'} id={'left-section'}>
              <div className='d-flex gap-3 col'>
                <div className={'d-flex w-100 mb-3 gap-1'}>
                  <TextField
                    variant={'outlined'}
                    id={'search-field'}
                    placeholder={'검색어를 입력하세요.'}
                    autoComplete={'off'}
                    value={filter.searchData}
                    onChange={(e) => setFilter({...filter, searchData: e.target.value})}
                    fullWidth
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        getDeviceList()
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='resetBtn'
                            onClick={() => {
                              setFilter({...filter, searchData: ''})
                              setSelectDevice(null)
                              setSelectDeviceDetailInfo(null)
                              setTimeout(() => {
                                document.getElementById('image-search')?.click()
                              }, 100)
                            }}
                            edge='end'
                          >
                            <CancelIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/*<Button variant={'outlined'}>검색</Button>*/}
                  <Button
                    variant={'contained'}
                    size={'medium'}
                    id={'image-search'}
                    type='button'
                    onClick={() => {
                      getDeviceList()
                    }}
                  >
                    <SearchIcon sx={{width: 24, height: 24}} />
                  </Button>
                </div>
              </div>
              <div>
                <DataGridPro
                  rowHeight={150}
                  rows={rows}
                  columns={columns}
                  disableColumnMenu
                  loading={deviceList === null}
                  pagination={true}
                  hideFooterSelectedRowCount={true}
                  paginationMode={'client'}
                  sx={{height: 800}}
                  onSelectionModelChange={(newSelection: any) => {
                    if (newSelection.length > 0) {
                      const selectedRow: any = rows.find((row: any) => row.id === newSelection[0])
                      setSelectDevice(selectedRow)
                    } else {
                      setSelectDevice(null) // 체크박스 해제될 경우 null 설정
                    }
                  }}
                  onCellClick={() => {
                    setLoadingFlag(true)
                  }}
                />
              </div>
            </div>
            <div
              className={'col w-100 h-100 align-items-center justify-content-center'}
              style={{display: loadingFlag ? 'grid' : 'block'}}
            >
              {selectDeviceDetailInfo === null ? (
                <div
                  className={'w-100  text-center d-flex justify-content-center align-items-center'}
                  style={{height: document?.querySelector('#left-section')?.clientHeight}}
                >
                  <img
                    src={ClickDevice}
                    alt={'click-device'}
                    style={{
                      width: '40%',
                      height: '30%',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              ) : (
                <>
                  {!loadingFlag ? (
                    <>
                      <div className={'mb-3 ps-3'}>
                        <span className={'fs-2 fw-bold'} style={{color: '#077fd9'}}>
                          {selectDevice?.imei} 단말기 HISTORY
                        </span>
                      </div>
                      <DataGridPro
                        rowHeight={150}
                        rows={detailRows}
                        columns={detailColumns}
                        loading={false}
                        disableColumnMenu
                        paginationMode={'client'}
                        pagination={true}
                        sx={{height: 835}}
                      />
                    </>
                  ) : (
                    <div
                      className='d-flex justify-content-center'
                      style={{width: '10rem', height: '10rem'}}
                    >
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DeviceHistory
