import React, {Suspense, useEffect} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import {I18nProvider} from '_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '_metronic/layout/core'
import {MasterInit} from '_metronic/layout/MasterInit'
import {AuthInit} from '_component/modules/auth'
import {useSelector} from 'react-redux'
import {ToastContainer} from 'react-toastify'
import {ThemeProvider} from '@mui/material'
import {theme} from 'utils/designSytem/customMui'

const App = () => {
  const navigate = useNavigate()
  const userInfo = useSelector((state: any) => state?.userInfo?.userInfo)
  const titleName =
    process.env.REACT_APP_CUSTOM_TITLE || '미터리움 - 스마트 디지털 이미지 원격검침 시스템'
  if (process.env.NODE_ENV === 'production') {
    console.log = function no_console() {}
    console.warn = function no_console() {}
    console.info = function no_console() {}
    console.error = function no_console() {}
  }
  if (process.env.NODE_ENV === 'development') {
    console.warn = function no_console() {}
  }

  useEffect(() => {
    document.title = titleName
    if (
      !userInfo &&
      window.location.pathname !== '/auth' &&
      window.location.pathname !== '/join/agree' &&
      window.location.pathname !== '/join/registration' &&
      window.location.pathname !== '/password/certification' &&
      window.location.pathname !== '/password/reset'
    ) {
      navigate('/auth')
    }
  }, [titleName, userInfo, navigate])

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <ThemeProvider theme={theme}>
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </ThemeProvider>
      </Suspense>
      <ToastContainer />
    </>
  )
}

export {App}
