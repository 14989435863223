import {FC, useEffect} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../core'
import {DrawerComponent} from '../../assets/ts/components'
import {WithChildren} from '../../helpers'

const Content: FC<WithChildren> = ({children}) => {
  const {classes} = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])
  return (
    // <div id='kt_content_container' className={clsx(classes.contentContainer.join(' '))}>
    <div
      id='kt_content_container'
      className={
        location?.pathname?.includes('/install/') ? 'w-100 p-xl-5' : 'container-fluid w-100 p-xl-5 '
      }
      style={{backgroundColor: '#1e1e2d', display: 'flex', flexDirection: 'column'}}
    >
      {children}
    </div>
  )
}

export {Content}
