import React, {useState} from 'react'
import Efos from 'pages/install/modules/asModalComponents/efos'
import {Button} from '@mui/material'
import Device from 'pages/install/modules/asModalComponents/device'
import NormalAs from 'pages/install/modules/asModalComponents/normalAs'

const AsDynamicComponent = (props: any) => {
  const {type, asSubmit, set, setRenderStep} = props
  const [selected, setSelected] = useState<any>(false)

  // const resultAsContent = () => {
  //   switch (type) {
  //     case 1:
  //       return <Efos asSubmit={asSubmit} set={set} />
  //     case 2:
  //       return <Device asSubmit={asSubmit} set={set} />
  //     case 3:
  //       return <Device asSubmit={asSubmit} set={set} />
  //     case 4:
  //       return <Efos asSubmit={asSubmit} set={set} />
  //     case 5:
  //       return <NormalAs asSubmit={asSubmit} set={set} />
  //     case 6:
  //       return <NormalAs asSubmit={asSubmit} set={set} />
  //     case 7:
  //       return <NormalAs asSubmit={asSubmit} set={set} />
  //     case 8:
  //       return <NormalAs asSubmit={asSubmit} set={set} />
  //     case 9:
  //       return <NormalAs asSubmit={asSubmit} set={set} />
  //     case 10:
  //       return <NormalAs asSubmit={asSubmit} set={set} />
  //   }
  // }

  const resultAsContent = () => {
    switch (type) {
      case 1:
        return <Efos asSubmit={asSubmit} set={set} setSelected={setSelected} />
      case 2:
        return <Device asSubmit={asSubmit} set={set} setSelected={setSelected} />
      case 3:
        return <Device asSubmit={asSubmit} set={set} setSelected={setSelected} />
      case 4:
        return <Efos asSubmit={asSubmit} set={set} setSelected={setSelected} />
      case 5:
        return <NormalAs asSubmit={asSubmit} set={set} />
      case 6:
        return <NormalAs asSubmit={asSubmit} set={set} />
      case 7:
        return <NormalAs asSubmit={asSubmit} set={set} />
      case 8:
        return <NormalAs asSubmit={asSubmit} set={set} />
      case 9:
        return <NormalAs asSubmit={asSubmit} set={set} />
      case 10:
        return <NormalAs asSubmit={asSubmit} set={set} />
    }
  }

  return (
    <article>
      {resultAsContent()}
      <Button
        variant={'contained'}
        color={'primary'}
        fullWidth
        disabled={type < 6 && !selected}
        style={{
          position: !selected ? 'fixed' : 'static',
          bottom: '10px',
          marginBottom: '10px',
        }}
        onClick={() => {
          if (asSubmit?.deviceType === 'analog') {
            setRenderStep(2)
          } else if (asSubmit?.deviceType === 'digital') {
            setRenderStep(3)
            set((prev: any) => ({
              ...prev,
              idxDate: '20230626',
              xstart: 0,
              ystart: 0,
              xend: 0,
              yend: 0,
              recognizedType: null,
              rotate: 0,
            }))
          }
        }}
      >
        {asSubmit?.resultIdxName} 완료
      </Button>
    </article>
  )
}

export default AsDynamicComponent
