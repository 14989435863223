import {Autocomplete, TextField} from '@mui/material'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {envBackHost} from 'GLOBAL/envVariables'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import _ from 'lodash'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {ToastContainer} from 'react-toastify'
import FotaCustomerRegisterOffcanvas from '../modules/batchCustomerCanvas'
import FotaBatchRegisterOffcanvas from '../modules/batchOffcanvas'
import FotaListTable from '../modules/fotaListTable'

document.body.setAttribute('data-kt-aside-minimize', 'on')
const MatchingSystem = (prsop: any) => {
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const [officeList, setOfficeList] = useState<any>([])
  const [batchInfo, setBatchInfo] = useState<any>({
    officeId: '',
    userId: '',
    officeLabel: '',
  })
  const getOfficeList = async () => {
    await axiosWithAuth()
      .get(`${envBackHost}/office/${userInfo?.officeId}`)
      .then((res) => {
        setOfficeList(res.data.res)
      })
  }
  useEffect(() => {
    getOfficeList()
  }, [])
  return (
    <>
      {!isMobileDevice() && (
        <>
          <div className='card flex-grow-1' id='customer-register' style={{fontSize: '16px'}}>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className=' fw-bolder m-0'>FOTA 단말기를 미터리움과 매칭합니다.</h3>
              </div>
              <div className={'align-self-center'}>
                <button
                  className={'btn  align-self-center me-5'}
                  style={{
                    backgroundColor: '#587123',
                    color: '#fff',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  }}
                  data-bs-toggle={'offcanvas'}
                  data-bs-target='#fotaCustomerRegister'
                  aria-controls='fotaCustomerRegister'
                >
                  수용가 일괄등록
                </button>
                <button
                  className={'btn  align-self-center'}
                  style={{
                    backgroundColor: '#914203',
                    color: '#fff',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  }}
                  data-bs-toggle={'offcanvas'}
                  data-bs-target='#fotaBatchRegister'
                  aria-controls='fotaBatchRegister'
                >
                  단말기 일괄등록
                </button>
              </div>
            </div>
            <div className='card-body p-9'>
              <div className={'d-flex justify-content-between w-100'}>
                <div className={'d-flex gap-3'}>
                  <Autocomplete
                    disablePortal
                    id='choose-office'
                    loading={true}
                    loadingText={'로딩중...'}
                    options={officeList}
                    defaultValue={_.find(officeList, {officeId: 'all'})}
                    className={'px-0'}
                    disableClearable={true}
                    clearText={'초기화'}
                    noOptionsText={'검색 결과가 없습니다.'}
                    getOptionLabel={(option: any) => option.officeName}
                    onChange={(e, rowValue: any) => {
                      setBatchInfo({
                        ...batchInfo,
                        officeId: rowValue.officeId,
                        officeLabel: rowValue.officeName,
                      })
                    }}
                    sx={{fontSize: '1.5rem', color: '#fff'}}
                    renderInput={(params) => (
                      <div className={'d-flex gap-3'}>
                        <TextField
                          {...params}
                          className={'w-400px mb-3'}
                          label='사업소 선택'
                          focused
                          placeholder={'검색하거나 선택할 수 있습니다.'}
                        />
                      </div>
                    )}
                  />

                  <div
                    className={'row fs-5 fw-bold w-450px '}
                    style={{alignItems: 'start', color: '#ff0000'}}
                  >
                    <span className={'text-primary'}>
                      FOTA 단말기를 매칭시키기 위한 페이지입니다.
                    </span>
                    <span>* FOTA로 펌웨어 변경시 복구시킬 수 없습니다. 반드시 확인해주세요.</span>
                    <span>* 단말기 일괄 매칭은 최대 50개로 제한됩니다.</span>
                  </div>
                  <div
                    className={'row fs-5 fw-bold w-450px '}
                    style={{alignItems: 'start', color: '#ff0000'}}
                  >
                    <span>* [수용가 명 : 등록필요] 수용가는 미터리움에 등록이 필요합니다.</span>
                    <span>* 매칭 후 이미지 인식영역은 필수로 해야합니다.</span>
                    <span>
                      * 위/경도가 없는 수용가는 수용가 정보관리에서 매칭 후 수정해야합니다.
                    </span>
                  </div>
                </div>
                <div className={'row fs-5 fw-bold text-end'} style={{alignItems: 'start'}}>
                  <span>FOTA 단말기를 일괄등록 할 수 있습니다.</span>
                </div>
              </div>
              <FotaListTable batchInfo={batchInfo} />
            </div>
          </div>
        </>
      )}
      <FotaBatchRegisterOffcanvas />
      <FotaCustomerRegisterOffcanvas />
      <ToastContainer />
    </>
  )
}

export default MatchingSystem
