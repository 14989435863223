import React, {useEffect, useState} from 'react'
import {Button, TextField} from '@mui/material'
import {envBackHost} from '../../../GLOBAL/envVariables'
import {axiosWithAuth} from '../../../helper/axiosWithAuth'
import {confirmAlert, normalAlert, resultAlert} from '../../../utils/CustomAlert/Alert'

const ChangeAccountTypeModal = (props: any) => {
  const {singleAccountType, setSingleAccountType} = props
  const [changeAccountType, setChangeAccountType] = useState<any>({
    accountTypeName: '',
  })

  const deleteAccountType = async () => {
    if (await confirmAlert('삭제', 'info')) {
      axiosWithAuth()
        .delete(`${envBackHost}/accounttype/${singleAccountType.accountType}`)
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            clearState()
            document.getElementById('closeModal2')?.click()
            return
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    } else {
      return
    }
  }

  const putAccountType = async () => {
    if (changeAccountType?.accountTypeName === '') {
      await normalAlert('계정타입 권한을 입력해주세요.')
      return
    }
    if (await confirmAlert('수정', 'info')) {
      axiosWithAuth()
        .put(`${envBackHost}/accounttype`, {
          accountType: singleAccountType?.accountType,
          accountTypeName: changeAccountType?.accountTypeName,
        })
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            clearState()
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    } else {
      return
    }
  }

  const clearState = () => {
    setChangeAccountType({
      accountTypeName: '',
    })
    setSingleAccountType({
      accountType: '',
      accountTypeName: '',
    })
  }

  return (
    <div
      className='modal fade'
      id='changeAccountType'
      tabIndex={-1}
      aria-labelledby='changeAccountTypeLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered modal-xl'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='changeAccountTypeLabel'>
              계정타입 변경
            </h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              id={'closeModal2'}
            ></button>
          </div>
          <div className='modal-body'>
            <div className={'d-flex'}>
              <div className={'col w-100'}>
                <table
                  className='table table-rounded table-row-bordered border'
                  style={{cursor: 'pointer'}}
                >
                  <thead>
                    <tr className={'text-center fw-bold fs-4'}>
                      <th className='text-muted'>현재 정보</th>
                      <th>변경 정보</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={'fs-4 text-center'}>
                      <td>
                        <TextField
                          className={'m-3'}
                          variant={'outlined'}
                          label={'계정타입'}
                          disabled
                          value={singleAccountType?.accountType}
                        ></TextField>
                        <TextField
                          variant={'outlined'}
                          label={'계정타입 권한'}
                          disabled
                          value={singleAccountType?.accountTypeName}
                        />
                      </td>
                      <td className=''>
                        <TextField
                          className={'m-3'}
                          variant={'outlined'}
                          label={'계정타입'}
                          disabled
                          value={singleAccountType?.accountType}
                        />
                        <TextField
                          variant={'outlined'}
                          label={'계정타입 권한'}
                          value={changeAccountType?.accountTypeName}
                          onChange={(e) => {
                            setChangeAccountType({accountTypeName: e.target.value})
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex gap-7 m-5'>
              <Button
                variant={'contained'}
                className='btn btn-secondary'
                data-bs-toggle='modal'
                data-bs-target='#addAccountType'
                onClick={() => {
                  clearState()
                }}
              >
                이전
              </Button>
              <Button
                variant={'contained'}
                color={'error'}
                onClick={() => {
                  deleteAccountType()
                }}
              >
                삭제
              </Button>
              <Button
                variant={'contained'}
                color={'primary'}
                data-bs-dismiss='modal'
                data-bs-toggle='modal'
                data-bs-target='#addAccountType'
                onClick={() => {
                  putAccountType()
                }}
              >
                수정
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangeAccountTypeModal
