import cookies from 'js-cookie'
import axios from 'axios'
import {chargeBackHost, envBackHost} from 'GLOBAL/envVariables'
import CryptoJS from 'crypto-js'
import * as React from 'react'

export const handleCellClick = (event: any) => {
  event.stopPropagation()
}
export const resetHandler = (...stateUpdateFunctions: any) => {
  stateUpdateFunctions.forEach((updateFunction: any) => {
    if (typeof updateFunction === 'function') {
      updateFunction()
    }
  })
}
type UpdateFunction<T> = (data: T) => void

export const onRowsSelectionHandler = (
  ids: Array<string | number>,
  updateFunctions: Array<[UpdateFunction<any>, any]>
) => {
  updateFunctions.forEach(([updateFunction, data]) => {
    if (typeof updateFunction === 'function') {
      updateFunction(data)
    }
  })
}
export const imageDetailModalOpen = (callback: any) => {
  return callback(true)
}
export const imageDetailModalClose = (callback: any) => {
  return callback(false)
}
export const deviceDetailModalOpen = (callback: any) => {
  return callback(true)
}
export const deviceDetailModalClose = (callback: any) => {
  return callback(false)
}
export const customerDetailModalOpen = (callback: any) => {
  return callback(true)
}
export const customerDetailModalClose = (callback: any) => {
  return callback(false)
}

export const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

export const loginCookieAction = async (token: string) => {
  return cookies.set('license_youngit', token, {
    expires: 1,
    path: '/',
  })
}

export const removeLoginCookieAction = async () => {
  return cookies.remove('license_youngit')
}

export const loginHashAction = async (values: any) => {
  const lastTwoDigits = values.id.slice(-2)
  const userId = 'yit' + lastTwoDigits
  const salt = generateSaltBasedOnUserId(userId)
  const hashedPassword = hashPassword(values?.password, salt)
  const _res = await axios.post(`${envBackHost}/login`, {
    id: values.id,
    password: hashedPassword,
  })
  return _res?.data.res
}
export function hashPassword(password: any, salt: any) {
  return CryptoJS.SHA512(password + salt)?.toString()
}

export function generateSaltBasedOnUserId(userId: string) {
  return CryptoJS.SHA256(userId).toString()
}
export const hashPasswordParser = async (id: any, pw: any) => {
  const lastTwoDigits = id?.slice(-2)
  const userId = 'yit' + lastTwoDigits
  const salt = generateSaltBasedOnUserId(userId)
  // const hashedPassword = hashPassword(pw?.password, salt)

  return hashPassword(pw, salt)
}
export interface TabPanelProps {
  id?: string
  children?: React.ReactNode
  index: number
  value: number
}

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const tapHandleChange = (newValue: any, callback: any) => {
  callback(newValue)
}

export const convertImageToBase64 = (url: any, callback: any) => {
  var xhr = new XMLHttpRequest()
  xhr.onload = function () {
    var reader = new FileReader()
    reader.onloadend = function () {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

export const windowWidthResize = (size: number) => {
  if (size > 1920) {
    return 20
  } else if (size > 1800) {
    return 19.5
  } else if (size > 1700) {
    return 18
  } else if (size > 1500) {
    return 16
  } else if (size > 1200) {
    return 15
  } else {
    return 13
  }
}

//feature/sc

export const saeolDataPost = async (formData: any, officeInfo: any) => {
  const _res = await axios.post(
    `${chargeBackHost}/saeol/?office_code=${
      officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
    }`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return _res
}
