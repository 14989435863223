import React, {useEffect, useState} from 'react'
import {Autocomplete, Button, IconButton, TextField} from '@mui/material'
import {envBackHost} from '../../../GLOBAL/envVariables'
import _ from 'lodash'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {axiosWithAuth} from '../../../helper/axiosWithAuth'
import {useSelector} from 'react-redux'
import {toast, ToastContainer} from 'react-toastify'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import fileDownload from 'js-file-download'
import './../scrollbar.css'
import {normalAlert, resultAlert} from '../../../utils/CustomAlert/Alert'
const BatchRegisterOffcanvas = (props: any) => {
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const [file, setFile] = useState<File>()
  const [fileName, setFileName] = useState<string>('')
  const [processing, setProcessing] = useState<boolean>(false)
  const [failList, setFailList] = useState<any[]>([])
  const [officeList, setOfficeList] = useState<any[]>([])
  const [selectOffice, setSelectOffice] = useState<any>(null)
  const [batchInfo, setBatchInfo] = useState<any>({
    officeId: '',
    userId: '',
    officeLabel: '',
  })

  const exampleExcelFile = async () => {
    if (!batchInfo?.officeId || batchInfo?.officeId === '') {
      await normalAlert('사업소를 선택해주세요.')
      return
    }
    axiosWithAuth()
      .get(`${envBackHost}/${batchInfo?.officeId}/customer/example`, {
        responseType: 'blob',
      })
      .then(async (res) => {
        await resultAlert(
          {resultCode: 0},
          `[ ${batchInfo?.officeLabel} ] 사업소 샘플엑셀양식이 다운로드 완료되었습니다.`
        )
        fileDownload(res.data, `${batchInfo?.officeLabel}_excel_example_download.xlsx`)
      })
      .catch(async (e) => {
        await resultAlert({resultCode: e.response.status})
      })
  }
  const fileValidationUploadHandler = async (e: any) => {
    const target = e.currentTarget
    const file = (target.files as FileList)[0]

    setFile(file)
    setFileName(file.name)
  }
  const getOfficeList = async () => {
    await axiosWithAuth()
      .get(`${envBackHost}/office/${userInfo?.officeId}`)
      .then((res) => {
        setOfficeList(res.data.res)
      })
  }
  const fileUploadHandler = async () => {
    const formData = new FormData()
    formData.append('file', file as Blob)
    formData.append('id', batchInfo?.userId)
    formData.append('officeId', batchInfo?.officeId)
    setProcessing(true)

    await toast.promise(
      axiosWithAuth()
        .post(`${envBackHost}/customer/excel`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(async (res) => {
          console.log('res', res.data)
          setProcessing(false)
          if (res.data.resultCode === 0) {
            document.getElementById('batchRegisterCustomerClose')?.click()
            setFileName('')
            setBatchInfo({
              ...batchInfo,
              officeId: '',
            })
            setProcessing(false)
            return await resultAlert(res.data)
          } else if (res.data.resultCode === 30) {
            setFailList(res?.data?.res)
            setProcessing(false)
            return await resultAlert(res.data)
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
          setProcessing(false)
        })
        .finally(() => {
          setProcessing(false)
        }),
      {
        pending: '수용가 일괄 등록 중 입니다.',
      },
      {
        position: 'top-center',
        closeButton: false,
        className: 'fs-3  fw-bold',
        style: {width: '400px'},
      }
    )
  }
  useEffect(() => {
    setFailList([])
  }, [fileName])
  useEffect(() => {
    getOfficeList()
    setBatchInfo({
      officeId: '',
      userId: userInfo?.userId,
    })
  }, [])
  return (
    <>
      <div
        className='offcanvas offcanvas-end'
        tabIndex={-1}
        id='batchRegister'
        aria-labelledby='batchRegisterLabel'
        style={{width: isMobileDevice() ? '100%' : '40%'}}
      >
        <div className='offcanvas-header'>
          <h2 className='offcanvas-title' id='batchRegisterLabel'>
            수용가 일괄등록
          </h2>
          <button
            type='button'
            className='btn-close text-reset'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          ></button>
        </div>
        <div className='offcanvas-body batch-scrollbar'>
          <div className={'row gap-4'}>
            <div className={'d-flex col gap-3 w-100 px-0 mx-0'}>
              <div className={'col-6'}>
                <TextField
                  variant={'outlined'}
                  label={'파일 이름 　　'}
                  value={fileName}
                  disabled={true}
                  fullWidth
                  helperText={
                    fileName === '' ? (
                      <strong className={'fs-5'}>파일을 선택해주세요.</strong>
                    ) : failList?.length > 0 ? (
                      <strong className={'text-primary fs-5'}>
                        잘못 업로드한 경우 다시 찾아보기를 클릭해주세요.
                      </strong>
                    ) : (
                      <strong className={'text-primary fs-5'}>
                        사업소를 선택하고 일괄등록을 눌러주세요 !
                      </strong>
                    )
                  }
                  inputProps={{
                    style: {
                      fontSize: 20,
                      color: 'black',
                      fontWeight: 'bold',
                    },
                  }} // font size of input text
                  InputLabelProps={{style: {fontSize: 20, color: 'black'}}} // font size of input label
                />
              </div>
              <div className={'d-flex gap-4 col-6'}>
                <Button variant='contained' component='label'>
                  찾아보기
                  <input
                    type={'file'}
                    id={'fileItem'}
                    accept={'.xlsx'}
                    onChange={(e) => fileValidationUploadHandler(e)}
                    hidden
                  />
                </Button>
                <Button
                  variant={'contained'}
                  color={'success'}
                  onClick={() => exampleExcelFile()}
                  // onClick={() => exampleExcelDownload()}
                >
                  샘플양식 다운로드
                </Button>
              </div>
            </div>
            <Autocomplete
              disablePortal
              fullWidth
              id='choose-office'
              options={officeList}
              defaultValue={_.find(officeList, ['officeName', batchInfo?.officeLabel])}
              className={'px-0'}
              clearText={'초기화'}
              noOptionsText={'검색 결과가 없습니다.'}
              getOptionLabel={(option: any) => option.officeName}
              // value={selectedOffice}
              clearIcon={<HighlightOffIcon id={'autocomplete-clear-btn'} />}
              onChange={(e, rowValue: any) => {
                setBatchInfo({
                  ...batchInfo,
                  officeId: rowValue.officeId,
                  officeLabel: rowValue.officeName,
                })
              }}
              sx={{fontSize: '1.5rem', color: '#fff'}}
              renderInput={(params) => {
                return (
                  <div className={'d-flex gap-3'}>
                    <TextField
                      {...params}
                      fullWidth
                      label='사업소 선택'
                      focused
                      placeholder={'검색하거나 선택할 수 있습니다.'}
                    />
                  </div>
                )
              }}
            />
          </div>
          {failList?.length > 0 && (
            <>
              <div className={'my-4 d-flex px-2'}>
                <h3 className={'text-danger fs-2'}> {fileName} 업로드 실패 목록</h3>
                <span className={'text-muted fw-bolder mx-2 fs-5'}>( {failList?.length}건 )</span>
              </div>
              <table
                className='table table-hover table-rounded  border  gy-3 gs-3 text-center'
                style={{overflow: 'scroll', maxHeight: '1024px'}}
              >
                <thead style={{position: 'sticky', top: 0}} className={'bg-gray-600 '}>
                  <tr className='fw-bolder fs-5  border-bottom-2 border-gray-200 text-white'>
                    <th>No.</th>
                    <th>수용가 번호</th>
                    <th>수용가 이름</th>
                    <th>에러 이유</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(failList, (el, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{el?.customerNumber}</td>
                        <td>{el?.customerName}</td>
                        <td>{el?.message}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
        <div className={'offcanvas-footer'}>
          <div
            className={'d-flex gap-7 justify-content-end '}
            style={{marginBottom: '50px', marginRight: '30px'}}
          >
            <Button
              variant={'contained'}
              size={'large'}
              color={'info'}
              onClick={() => fileUploadHandler()}
              disabled={batchInfo?.officeId === '' || processing}
            >
              일괄등록
            </Button>
            <Button
              id={'batchRegisterCustomerClose'}
              variant={'contained'}
              size={'large'}
              color={'error'}
              data-bs-dismiss='offcanvas'
              disabled={processing}
              onClick={() => {
                setBatchInfo({
                  ...batchInfo,
                  officeId: '',
                })
                document.getElementById('autocomplete-clear-btn')?.click()
              }}
            >
              나가기
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}
export default BatchRegisterOffcanvas
