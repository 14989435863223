import React, {useEffect, useState} from 'react'
import {DataGridPro, GridRowId} from '@mui/x-data-grid-pro'
import _ from 'lodash'
import AssginedModal from '../modules/AssignedModal'
import {useDispatch, useSelector} from 'react-redux'
import {ToastContainer} from 'react-toastify'
import {noDataAlert} from 'utils/CustomAlert/Alert'
import {onRowsSelectionHandler, resetHandler, scrollTop} from 'utils/func/justUtils'
import {pageAction} from 'redux/action'
import {
  cancelCustomerAllocation,
  changeInstallStatus,
  deleteCustomerAllocation,
  getAllocationList,
} from 'utils/apiCalling/api'
import {customerAllocationFilter} from 'pages/customer/dataConfig/declearFilters'
import {customerAllocationRows} from 'pages/customer/dataConfig/declearRows'
import {customerAllocationColumns} from 'pages/customer/dataConfig/declearColumns'
import {customerAllocationGroup} from 'pages/customer/dataConfig/declearGroupingModel'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import {toolTipText} from 'utils/toolTips/toolTipText'
import {reduxType} from 'utils/interface/reduxType'

const CustomerAllocation = (props: any) => {
  const dispatch = useDispatch()
  const officeInfo = useSelector((state: reduxType) => state.officeInfo)
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([])
  const pageInfo = useSelector((state: reduxType) => state.pageInfo)
  const [allocationData, setAllocationData] = useState<any>()
  const [checkData, setCheckData] = useState<any>([])
  const [effectFlag, setEffectFlag] = useState<boolean>(false)
  const [typeName, setTypeName] = useState<any>(null)
  const [filter, setFilter] = useState<any>(customerAllocationFilter)

  const getAllocationListCall = () => {
    getAllocationList(officeInfo, filter, {
      success: (res: any) => {
        setAllocationData(res)
      },
      fail: (err: any) => {
        console.log(err)
      },
    })
  }
  const setToInstallable = async (data: any) => {
    const _res = await changeInstallStatus(officeInfo, data)
    if (_res) {
      await getAllocationListCall()
    }
  }
  const columns: any = customerAllocationColumns(setToInstallable)

  const columnGroupingModel = customerAllocationGroup

  const rows = customerAllocationRows(allocationData)
  const cancelHandler = async () => {
    if (checkData?.length === 0) {
      await noDataAlert()
      return
    }
    const submitData = _.map(checkData, (el: any) => ({
      officeId: el.officeId,
      customerNumber: el.customerNumber,
    }))
    const data = {
      customer: submitData,
      formId: null,
      toId: null,
    }
    await cancelCustomerAllocation(officeInfo, data, [handleReset, getAllocationListCall()])
  }

  const deleteAllocationHandler = async () => {
    await deleteCustomerAllocation(checkData, [handleReset, getAllocationListCall()])
  }
  const handleReset = () => {
    resetHandler(
      () => setEffectFlag(!effectFlag),
      () => setSelectionModel([]),
      () => setCheckData([])
    )
  }

  useEffect(() => {
    scrollTop()
    getAllocationListCall()
  }, [filter.assignStatus, filter.customerStatus, effectFlag, officeInfo.value, officeInfo, filter])
  return (
    <>
      <MeterariumPageLayout
        headerDescription={toolTipText.customerAllocation.description}
        tooltipText={toolTipText.customerAllocation.text}
        filter={filter}
        id={'customer-allocation'}
        setFilter={setFilter}
        searchCallback={getAllocationListCall}
        total={rows?.length}
        control={['assign', 'installable']}
        actions={['assign', 'delete']}
        searchOptions={['customerNumber', 'customerName', 'address', 'installerName']}
        checkData={checkData}
        onDelete={deleteAllocationHandler}
        onCancel={cancelHandler}
        setTypeName={setTypeName}
      >
        <DataGridPro
          experimentalFeatures={{columnGrouping: true, lazyLoading: true}}
          columnGroupingModel={columnGroupingModel}
          headerHeight={30}
          rowHeight={80}
          autoHeight={true}
          rows={rows}
          loading={!allocationData}
          columns={columns}
          pageSize={pageInfo.pageSize}
          onPageSizeChange={async (newPageSize) =>
            dispatch(await pageAction('update', newPageSize))
          }
          rowsPerPageOptions={pageInfo.selectPageSize}
          disableColumnMenu
          pagination
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={(ids, selection) => {
            const selectedRowsData = ids.map((id) => rows.find((row: any) => row.id === id))
            onRowsSelectionHandler(ids, [
              [setCheckData, selectedRowsData],
              [setSelectionModel, ids],
            ])
          }}
          paginationMode={'client'}
          onPageChange={(params) => {
            scrollTop()
          }}
        />
      </MeterariumPageLayout>
      <AssginedModal
        typeName={typeName}
        setTypeName={setTypeName}
        checkData={checkData}
        handleReset={handleReset}
      />
      <ToastContainer />
    </>
  )
}
export default CustomerAllocation
