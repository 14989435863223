import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import './../css/error404.css'

const Error404: FC = () => {
  return (
    // <div className='d-flex flex-column flex-root'>
    //   <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
    //     {/* begin::Illustration */}
    //     <img
    //       src={toAbsoluteUrl('/media/illustrations/sketchy-1/18.png')}
    //       alt=''
    //       className='mw-100 mb-10 h-lg-450px'
    //     />
    //     {/* end::Illustration */}
    //     {/* begin::Message */}
    //     <h1 className='fw-bold mb-10' style={{color: '#A3A3C7'}}>
    //       Seems there is nothing here
    //     </h1>
    //     {/* end::Message */}
    //     {/* begin::Link */}
    //     <Link to='/' className='btn btn-primary'>
    //       Return Home
    //     </Link>
    //     {/* end::Link */}
    //   </div>
    // </div>
    <div className={'container-fluid px-0'}>
      <div className={'body-404'}>
        <div>
          <h1 className={'gradient-text'}>Error 404</h1>
        </div>
        <Link to={'/home'}>
          <div className={'ball'}>
            <div className={'button-text'}>HOME</div>
          </div>
        </Link>
        <div className={'shadow'}></div>
      </div>
    </div>
  )
}

export {Error404}
