import React from 'react'
import {Route, Routes} from 'react-router-dom'
import PasswordCertification from 'pages/certification/components/passwordCertification'
import PasswordReset from 'pages/certification/components/passwordReset'

const CertificationPage = () => {
  return (
    <Routes>
      <>
        <Route path={'certification'} element={<PasswordCertification />} />
        <Route path={'reset'} element={<PasswordReset />} />
      </>
    </Routes>
  )
}
export default CertificationPage
