/*email 정규식*/
/**
 * - 이메일 정규식
 * @param email
 */
const isEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  return emailRegex.test(email)
}
/**
 * - 비밀번호 정규식
 * @param phoneNum
 */
const isPhone = (phoneNum: any) => {
  const regExp = /^(?:01[016789]|02|0[3-6]\d|070)(?:\d{3,4})\d{4}$/

  return regExp.test(phoneNum)
}
const isId = (id: any) => {
  const regExp = /^[a-zA-Z0-9_]{2,13}$/ // 영문, 숫자, 밑줄(_), 3~13자리
  return regExp.test(id)
}

const isPw = (pw: any) => {
  const RegExp = /^[A-Za-z\d!@#$%^&*]{6,}$/
  // 비밀번호는 6~20자리의 영문 대소문자, 숫자, 특수문자(!@#$%^&*)로 구성
  // 특수문자는 !@#$%^&* 만 허용
  // 특수문자 사용시 알파벳 및 숫자가 있어야함
  return RegExp.test(pw)
}
const requireField = (data: Record<string, any>, keys: string[]): boolean => {
  return keys.some((key) => data[key] === '')
}

const uniqueOfficeList = (list: any) =>
  Array.from(new Set(list?.map((item: any) => item.label))).map((label) => {
    return list.find((item: any) => item.label === label)
  })

const isNumber = (num: any) => {
  console.log(num)
  const RegExp = /^[0-9]*$/
  return RegExp.test(num)
}
const phoneValidation = (value: any, setState: any, setSubmitData: any) => {
  if (isPhone(value)) {
    setState(true)
    setSubmitData((prevData: any) => ({...prevData, phoneNumber: value}))
  } else {
    setState(false)
  }
}
export {isEmail, isId, isNumber, isPhone, isPw, phoneValidation, requireField, uniqueOfficeList}
