/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue, isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import RefreshIcon from '@mui/icons-material/Refresh'
import {Tooltip} from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import {useSelector} from 'react-redux'

type Props = {
  className: string
  checkList?: any
  filter: any
  value: any
  useChart: any
  loading: boolean
}

const CheckListChart: React.FC<Props> = ({className, useChart, filter, loading}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, filter, useChart, loading)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }
  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, useChart, cooperInfo, officeInfo])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='d-flex card-header border-0 p-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            검침내역
            <strong className={'text-danger mx-4'}>
              {dayjs(useChart[0]?.checkDate).format('MM월 DD일') +
                ' ' +
                '부터' +
                ' ' +
                dayjs(useChart[useChart?.length - 1]?.checkDate).format('MM월 DD일')}
            </strong>
          </span>

          <span className='text-muted fw-semibold fs-7'>
            검침내역을 차트로 한눈에 볼 수 있습니다.
          </span>
        </h3>
        <div className='card-toolbar' data-kt-buttons='true'>
          <Tooltip title={<span className={'fs-7'}>차트를 초기화합니다.</span>}>
            <a
              className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
              id='kt_charts_widget_4_year_btn'
              onClick={() => {
                refreshChart()
                refreshChart()
              }}
            >
              <RefreshIcon />
            </a>
          </Tooltip>
        </div>
      </div>
      {/* end::Header */}
      {loading ? (
        <div className='d-flex align-items-center justify-content-center h-100 gap-2'>
          <div className='spinner-border text-muted' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
          <div className='spinner spinner-primary spinner-lg mr-15'></div>
          <span className='text-muted fw-bold fs-5'>데이터를 불러오는 중 입니다...</span>
        </div>
      ) : null}
      {!loading && (
        <div className='card-body p-0'>
          <div ref={chartRef} id='kt_charts_widget_4_chart' style={{height: '100%'}}></div>
        </div>
      )}
    </div>
  )
}

export {CheckListChart}

function getChartOptions(height: number, filter: any, useChart: any, loading: any): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-800')
  const borderColor = getCSSVariableValue('--kt-gray-400')

  const baseColor = getCSSVariableValue('--kt-success')
  const baseLightColor = getCSSVariableValue('--kt-success-light')
  const secondaryColor = getCSSVariableValue('--kt-warning')
  const secondaryLightColor = getCSSVariableValue('--kt-warning-light')
  const useSum = _.map(useChart, (el, index) => {
    const accumulatedUse = _.reduce(useChart.slice(0, index + 1), (sum, item) => sum + item.use, 0)
    return accumulatedUse
  })
  const fontSize = isMobileDevice() ? '12px' : '17px'
  return {
    series: [
      {
        name: `사용량`,
        data: _.map(useChart, (el: any) => {
          return el.use
        }),
      },
      {
        name: '누적 사용량',
        data: useSum,
      },
    ],
    chart: {
      fontFamily: 'inter',
      type: 'area',
      height: 650,
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
        export: {
          csv: {
            filename: `${dayjs(useChart[0]?.checkDate).format('MM월 DD일')}_${
              dayjs(useChart[useChart?.length - 1]?.checkDate).format('MM월 DD일') +
              ' ' +
              '사용량 차트'
            }`,
            headerCategory: '날짜',
          },
          png: {
            filename: `${dayjs(useChart[0]?.checkDate).format('MM월 DD일')}_${
              dayjs(useChart[useChart?.length - 1]?.checkDate).format('MM월 DD일') +
              ' ' +
              '사용량 차트'
            }`,
          },
        },
      },
    },
    legend: {
      show: true,
      fontSize: fontSize,
      fontWeight: 600,
    },

    dataLabels: {
      enabled: true,
      style: {
        fontSize: fontSize,
        fontWeight: 500,
      },
      formatter(val: string | number | number[], opts?: any): string | number {
        return Number(val).toLocaleString('ko-KR') + 't'
      },
    },
    fill: {
      opacity: [0.8, 0.5],
      type: ['solid', 'gradient'],
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: _.map(useChart, (el: any) => {
        return dayjs(el.checkDate).format('MM월 DD일')
      }),

      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: fontSize,
          fontWeight: 600,
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: labelColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: fontSize,
        },
      },
    },
    yaxis: {
      tickAmount: 8,
      labels: {
        style: {
          colors: labelColor,
          fontSize: fontSize,
        },
        offsetX: -20,
        formatter(val: number, opts?: any): string | string[] {
          return Number(val).toLocaleString('ko-KR') + 't'
        },
      },
    },

    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: fontSize,
      },
      x: {
        show: true,
      },
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString('ko-KR') + 't'
        },
      },
    },
    colors: [baseColor, '#2568ee'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: 6,
      colors: [baseLightColor, secondaryLightColor],
      strokeColors: [baseLightColor, secondaryLightColor],
      strokeWidth: 3,
    },
  }
}
