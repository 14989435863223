import dayjs from 'dayjs'

export const deviceCheckFilter = (date: any) => {
  return {
    url: 'check',
    searchOption: {
      imei: false,
      serialNumber: false,
    },
    searchData: '',
    checkStatus: 'all',
    startDate: !undefined ? 'all' : date[0]?.format('YYYY-MM-DD'),
    endDate: !undefined ? 'all' : date[1]?.format('YYYY-MM-DD'),
  }
}

export const deviceAssignFilter = {
  url: 'assign',
  searchOption: {
    imei: false,
    serialNumber: false,
    installId: false,
    installName: false,
  },
  searchData: '',
  assignStatus: 'all',
}

export const deviceInfoFilter = {
  searchOption: {
    serialNumber: false,
    imei: false,
    deviceVersion: false,
  },
  searchData: '',
  deviceStatus: 'all',
  efosStatus: 'all',
  deviceType: 'all',
  batteryStatus: 'all',
  rowPerPage: 30,
  page: 1,
}
export const deviceImagesFilter = {
  imageSearchOption: {
    complete: false,
    fail: false,
    digit: false,
    wait: false,
    imgerr: false,
    fix: false,
  },
  searchType: 'all',
  searchData: '',
  startDate: dayjs().format('YYYYMMDD'),
  endDate: dayjs().format('YYYYMMDD'),
  rowPerPage: 30,
  page: 1,
}
