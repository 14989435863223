import _ from 'lodash'

export const asManagementRows = (list: any) => {
  return _.map(list, (el: any, i: any) => {
    return {
      id: i + 1,
      address: el?.address,
      afterFilePath: el?.afterFilePath,
      asId: el?.asId,
      asIdName: el?.asIdName,
      beforeFilePath: el?.beforeFilePath,
      causeIdx: el?.causeIdx,
      causeName: el?.causeName,
      createAt: el?.createAt,
      customerName: el?.customerName,
      customerNumber: el?.customerNumber,
      deviceType: el?.deviceType,
      deviceTypeName: el?.deviceTypeName,
      asIdx: el?.asIdx,
      idx: el?.idx,
      imei: el?.imei,
      officeId: el?.officeId,
      officeName: el?.officeName,
      phoneNumber: el?.phoneNumber,
      registerId: el?.registerId,
      registerIdName: el?.registerIdName,
      resultIdx: el?.resultIdx,
      resultName: el?.resultName,
      serialNumber: el?.serialNumber,
      updateAt: el?.updateAt,
      updateId: el?.updateId,
      updateIdName: el?.updateIdName,
      installerId: el?.installerId,
      installerName: el?.installerName,
      afterComment: el?.afterComment,
    }
  })
}

export const recoveryInfoRows = (list: any) => {
  return _.map(list, (el: any, i: any) => {
    return {
      id: i + 1,
      deviceConnectStatus: el.deviceConnectStatus,
      deviceStatus: el.deviceStatus,
      deviceType: el.deviceType,
      deviceTypeName: el.deviceTypeName,
      efosConnectStatus: el.efosConnectStatus,
      filePath: el.filePath,
      iccid: el.iccid,
      imageConnectAt: el.imageConnectAt,
      imei: el.imei,
      psn: el.psn,
      deviceVersion: el.deviceVersion,
      batteryLevel: el.batteryLevel,
      serialNumber: el.serialNumber,
      serverConnectAt: el.serverConnectAt,
    }
  })
}
