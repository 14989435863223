import {useEffect, useState} from 'react'
import {DataGridPro} from '@mui/x-data-grid-pro'
import {useSelector} from 'react-redux'
import {scrollTop} from 'utils/func/justUtils'
import {customerLeakageFilter} from 'pages/customer/dataConfig/declearFilters'
import {
  customerLeakageColumns,
  customerLeakageColumnsBongHwa,
  customerLongTermUnusedColumn,
} from 'pages/customer/dataConfig/declearColumns'
import {
  customerLeakageRows,
  customerLongTermUnusedUserRows,
} from 'pages/customer/dataConfig/declearRows'
import {getLeakList, getLongTermUnusedList} from 'utils/apiCalling/api'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import {toolTipText} from 'utils/toolTips/toolTipText'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import {envBackHost} from 'GLOBAL/envVariables'
import dayjs from 'dayjs'
import {
  customerLeakageFilterType,
  getLeakListCallType,
  leakOptionsDataType,
} from 'utils/interface/customerType'
import {reduxType} from 'utils/interface/reduxType'
import CustomPagination from 'utils/designSytem/CustomPagination'

const CustomerUnused = (props: any) => {
  const unusedOptionsDataInit: any = {
    officeName: '',
    longtermStandard: '',
  }

  const [pageSize, setPageSize] = useState<number>(10)
  const [unusedList, setUnusedList] = useState<any>()
  const whatInfo = useSelector((state: any) => state)
  const officeInfo = useSelector((state: reduxType) => state.officeInfo)
  const cooperInfo = useSelector((state: reduxType) => state.cooperInfo)
  const [loading, setLoading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [filter, setFilter] = useState<any>(customerLeakageFilter)
  const [unusedOptionsData, setunusedOptionData] = useState<any>(unusedOptionsDataInit)

  const getUnusedCustomerList = () => {
    // getLongTermUnusedList(officeInfo, cooperInfo, filter, {
    //   success: (res: any) => {
    //     setUnusedList(res)
    //   },
    //   fail: (err: any) => {
    //     console.log(err)
    //   },
    //   loading: (loading: boolean) => {
    //     setLoading(loading)
    //   },
    //   count: (count: number) => {
    //     setTotalCount(count)
    //   },
    // })
  }

  const getOfficeDetailInfo = async () => {
    axiosWithAuth()
      .get(`${envBackHost}/office/${officeInfo.value}`)
      .then((res: any) => {
        let response = res.data?.res[0]
        if (response.officeId === 'all') {
          setunusedOptionData({
            ...unusedOptionsData,
            longtermStandard: 0,
            officeName: response.officeName,
          })
        } else {
          setunusedOptionData({
            ...unusedOptionsData,
            longtermStandard: response.officeCustomerTypeModel[0].longtermStandard,
            officeName: response.officeName,
          })
        }
      })
  }

  const columns: any = customerLongTermUnusedColumn(filter)
  const rows = customerLongTermUnusedUserRows(unusedList)

  useEffect(() => {
    scrollTop()
    getUnusedCustomerList()
    getOfficeDetailInfo()
  }, [officeInfo, cooperInfo, filter.rowPerPage, filter.page])

  return (
    <>
      <MeterariumPageLayout
        headerDescription={toolTipText.customerUnused.description}
        tooltipText={toolTipText.customerUnused.text}
        filter={filter}
        id={'customer-unused'}
        setFilter={setFilter}
        searchCallback={getUnusedCustomerList}
        total={totalCount}
        hideSearchOptions={true}
        control={[]}
        actions={['unused']}
        date={[{date: dayjs().format('YYYYMMDD')}]}
        asValue={unusedOptionsData}
        searchOptions={[]}
        checkData={null}
        onDelete={null}
        onCancel={null}
        setTypeName={null}
      >
        <DataGridPro
          rows={rows}
          columns={columns}
          rowHeight={240}
          disableColumnMenu
          loading={loading}
          autoHeight={true}
          paginationMode={'server'}
          hideFooterPagination={true}
          pagination={true}
          hideFooter={true}
          sx={{
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
        />
        <CustomPagination filter={filter} setFilter={setFilter} totalCount={totalCount} />
      </MeterariumPageLayout>
    </>
  )
}

export default CustomerUnused
