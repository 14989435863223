import _ from 'lodash'

export const deviceCheckRows = (list: any) => {
  return _.map(list, (el: any, i: number) => {
    return {
      id: i + 1,
      checkStatus: el?.checkStatus,
      checkId: el?.checkId,
      checkName: el?.checkName,
      imageConnectAt: el?.imageConnectAt,
      deviceType: el?.deviceType,
      deviceTypeName: el?.deviceTypeName,
      imei: el?.imei,
      iccid: el?.iccid,
      fileName: el?.fileName,
      filePath: el?.filePath,
      psn: el?.psn,
      temperature: el?.temperature,
      deviceCommFailCount: el?.deviceCommFailCount,
      batteryLevel: el?.batteryLevel,
      serverCycle: el?.serverCycle,
      registerId: el?.registerId,
      serialNumber: el?.serialNumber,
      signalStrength: el?.signalStrength,
      updateAt: el?.updateAt,
      serverConnectAt: el?.serverConnectAt,
    }
  })
}
export const deviceAllocationRows = (list: any) => {
  return _.map(list, (el: any, i: number) => {
    return {
      id: i + 1,
      assignFromId: el?.assignFromId,
      assignStatus: el?.assignStatus,
      assignToId: el?.assignToId,
      assignToName: el?.assignToName,
      checkStatus: el?.checkStatus,
      checkId: el?.checkId,
      signalStrength: el?.signalStrength,
      batteryLevel: el?.batteryLevel,
      checkName: el?.checkName,
      createAt: el?.createAt,
      deviceType: el?.deviceType,
      deviceTypeName: el?.deviceTypeName,
      imei: el?.imei,
      iccid: el?.iccid,
      psn: el?.psn,
      temperature: el?.temperature,
      registerId: el?.registerId,
      registerName: el?.registerName,
      serialNumber: el?.serialNumber,
      updateAt: el?.updateAt,
      filePath: el?.filePath,
      imageConnectAt: el?.imageConnectAt,
    }
  })
}
export const deviceInfoRows = (list: any) => {
  return _.map(list, (el: any, i: number) => {
    return {
      id: i + 1,
      batteryLevel: el.batteryLevel,
      batteryStatus: el.batteryStatus,
      brightness: el.brightness,
      checkDecimalValue: el.checkDecimalValue,
      checkValue: el.checkValue,
      customerNumber: el.customerNumber,
      contrast: el.contrast,
      createAt: el.createAt,
      cooperIdx: el.cooperIdx,
      cooperName: el.cooperName,
      remainDurability: el.remainDurability,
      deviceApplyStatus: el.deviceApplyStatus,
      deviceCommFailCount: el.deviceCommFailCount,
      deviceConnectAt: el.deviceConnectAt,
      deviceStatus: el.deviceStatus,
      deviceType: el.deviceType,
      deviceTypeName: el.deviceTypeName,
      deviceVersion: el.deviceVersion,
      digit: el.digit,
      efosConnectAt: el.efosConnectAt,
      efosStatus: el.efosStatus,
      filePath: el.filePath,
      imei: el.imei,
      installStatus: el.installStatus,
      installerId: el.installerId,
      installerName: el.installerName,
      latitude: el.latitude,
      longitude: el.longitude,
      meterReadCommFailCount: el.meterReadCommFailCount,
      meterReadInstallDate: el.meterReadInstallDate,
      meterReadSerialNumber: el.meterReadSerialNumber,
      meterReadVersion: el.meterReadVersion,
      rotate: el.rotate,
      serialNumber: el.serialNumber,
      serverCycle: el.serverCycle,
      signalStrength: el.signalStrength,
      temperature: el.temperature,
      updateCheckValue: el.updateCheckValue,
      updateCheckdecimalValue: el.updateCheckdecimalValue,
      xfrom: el.xfrom,
      xto: el.xto,
      yfrom: el.yfrom,
      yto: el.yto,
      recognizedType: el.recognizedType,
    }
  })
}
export const deviceImagesRows = (list: any) => {
  return _.map(list, (el: any, i: number) => {
    return {
      id: i + 1,
      officeId: el?.officeId,
      checkDecimalValue: el.checkDecimalValue,
      checkValue: el.checkValue,
      customerNumber: el.customerNumber,
      createAt: el.createAt,
      fileName: el.fileName,
      filePath: el.filePath,
      idxDate: el.idxDate,
      serialNumber: el.serialNumber,
      imei: el.imei,
      processStatus: el.processStatus,
      updateAt: el.updateAt,
      updateCheckDecimalValue: el.updateCheckDecimalValue,
      updateCheckValue: el.updateCheckValue,
      use: el.use,
      updateId: el.updateId,
      meterDiameter: el.meterDiameter,
    }
  })
}
