import {Button} from '@mui/material'
import React from 'react'
import {envImagePath} from 'GLOBAL/envVariables'
import dayjs from 'dayjs'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import {handleCellClick} from 'utils/func/justUtils'
import {globalFormatWithHyphens} from 'utils/const/globalConst'

export const asManagementColumns = (openImageDetailModal: any) => {
  return [
    {
      field: 'officeName',
      headerName: '사업소 이름',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center gap-2'}>
            <span>{params.row.officeId}</span>
            <span>{params.row.officeName}</span>
          </div>
        )
      },
    },
    {
      field: 'customerNumber',
      headerName: '수용가 번호',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center gap-2'}>
            <span>{globalFormatWithHyphens(params?.row.officeId, params?.row.customerNumber)}</span>
            <Button
              variant={'outlined'}
              color={'info'}
              size={'small'}
              onClick={(e: any) => {
                openImageDetailModal(params.row)
                handleCellClick(e)
              }}
            >
              상세 이미지 확인
            </Button>
          </div>
        )
      },
    },
    {
      field: 'customerInfo',
      headerName: '주소',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center gap-2'}>
            <span>{params.row.customerName}</span>
            <span>{params.row.address}</span>
          </div>
        )
      },
      width: 360,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'serialNumber',
      headerName: '단말기 S/N',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'solutionName',
      headerName: '해결명',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center gap-2'}>
            <span>{params.row.causeName}</span>
            <span>{params.row.resultName ? params.row.resultName : '-'}</span>
          </div>
        )
      },
    },
    {
      field: 'engineer',
      headerName: 'A/S 기사',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center gap-2'}>
            <span>{params.row.asId}</span>
            <span>{params.row.asIdName}</span>
          </div>
        )
      },
    },
    {
      field: 'installInfo',
      headerName: '설치기사',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center gap-2'}>
            <span>{params.row.installerId}</span>
            <span>{params.row.installerName}</span>
          </div>
        )
      },
    },
    {
      field: 'date',
      headerName: '수정일',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center gap-2'}>
            <span>{dayjs(params.row.createAt).format('YYYY-MM-DD')}</span>
            <span>{dayjs(params.row.updateAt).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'afterComment',
      headerName: '특이사항',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center gap-2'}>
            <span>{params.row.afterComment}</span>
          </div>
        )
      },
      width: 250,
      align: 'left',
      headerAlign: 'left',
    },
  ]
}

export const asRecoveryColumns = (callback: any) => {
  return [
    {
      field: 'serialNumber',
      headerName: '단말기 S/N',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div>
            <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
              {params.row.serialNumber}
            </span>
          </div>
        )
      },
    },
    {
      field: 'imei',
      headerName: 'IMEI',
      width: 180,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'serverConnectAt',
      headerName: '서버 연결시간',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div>
            <span className={''}>
              {dayjs(params.row.serverConnectAt).format('YYYY-MM-DD HH:mm:ss')}
            </span>
          </div>
        )
      },
    },
    {
      field: 'imageConnectAt',
      headerName: '이미지 연결시간',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div>
            <span className={''}>
              {dayjs(params.row.imageConnectAt).format('YYYY-MM-DD HH:mm:ss')}
            </span>
          </div>
        )
      },
    },
    {
      field: 'deviceVersion',
      headerName: '펌웨어',
      width: 220,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'batteryLevel',
      headerName: '배터리',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div>
            <span className={''}>{params.row.batteryLevel}%</span>
          </div>
        )
      },
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 400,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <img
            alt={'device'}
            className={'cursor-pointer'}
            src={envImagePath + params.row.filePath}
          />
        )
      },
    },
    {
      field: 'disuse',
      headerName: '폐기처리',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <Button
            variant={'contained'}
            className={'fw-bold fs-3'}
            color={'error'}
            size={'large'}
            sx={{width: '150px', height: '150px'}}
            startIcon={<RemoveCircleIcon sx={{width: '25px'}} />}
            onClick={(event) => {
              event.stopPropagation()
              callback(params.row.imei)
            }}
          >
            <div className={'row'}>
              <span>폐기</span>
              <span>(복구불가)</span>
            </div>
          </Button>
        )
      },
    },
  ]
}
