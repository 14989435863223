import React, {useEffect, useState} from 'react'
import {DateRange} from '@mui/x-date-pickers-pro/DateRangePicker'
import dayjs, {Dayjs} from 'dayjs'
import {DataGridPro, GridRowId} from '@mui/x-data-grid-pro'
import _ from 'lodash'
import {useSelector} from 'react-redux'
import AsAssignModal from '../modules/AssignedModal'
import {asManagementFilter} from 'pages/task/dataConfig/declearFilters'
import {asManagementColumns} from 'pages/task/dataConfig/declearColumns'
import {asManagementGroupingModel} from 'pages/task/dataConfig/declearGroupingModel'
import {asManagementRows} from 'pages/task/dataConfig/declearRows'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import {
  imageDetailModalClose,
  imageDetailModalOpen,
  onRowsSelectionHandler,
  scrollTop,
} from 'utils/func/justUtils'
import {getAsList, getAsListCode, putAsCancel, putAsUnassign} from 'utils/apiCalling/api'
import ExcelDownLoadModal from 'pages/common/ExcelDownloadModal'
import AsDetailImageModal from 'pages/task/modules/asDetailImageModal'
import {toolTipText} from 'utils/toolTips/toolTipText'
import AsBatchOffcanvas from 'pages/customer/modules/asBatchOffcanvas'
import {toast} from 'react-toastify'
const AsManagement = (props: any) => {
  const [pageSize, setPageSize] = useState<number>(50)
  const [date, setDate] = useState<DateRange<Dayjs>>([dayjs().subtract(1, 'month'), dayjs()])
  const [asList, setAsList] = useState<any>(null)
  const [selectData, setSelectData] = useState<any>([])
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([])
  const [causeList, setCauseList] = useState<any>(null)
  const [solutionList, setSolutionList] = useState<any>(null)
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const userInfo = useSelector((state: any) => state.userInfo.userInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const [loading, setLoading] = useState(false)
  const [imageDetailOpen, setImageDetailOpen] = useState<boolean>(false)
  const [detailImageParams, setDetailImageParams] = useState<any>(null)
  const openImageDetailModal = (asIdx: any) => {
    setDetailImageParams(asIdx)
    imageDetailModalOpen(setImageDetailOpen)
  }
  const closeImageDetailModal = () => imageDetailModalClose(setImageDetailOpen)
  const [filter, setFilter] = useState<any>(asManagementFilter)
  const columns: any = asManagementColumns(openImageDetailModal)
  const columnGroupingModel: any = asManagementGroupingModel
  const rows = asManagementRows(asList)
  const getAsListCall = () => {
    getAsList(officeInfo, cooperInfo, filter, {
      success: (res: any) => {
        setAsList(res)
      },
      fail: (error: any) => {
        console.log(error)
      },
      loading: (loading: boolean) => {
        setLoading(loading)
      },
    })
  }

  const getAsListCodeCall = async () => {
    getAsListCode({
      success: (res: any) => {
        const causeData = _.filter(res, {codeType: 'cause'})
        setCauseList(causeData)
        const solutionData = _.filter(res, {codeType: 'result'})
        setSolutionList(solutionData)
      },
      fail: (error: any) => {
        console.log(error)
      },
    })
  }

  const AsUnAssignHandler = async () => {
    putAsUnassign(selectData, userInfo, {
      success: (res: any) => {
        if (res.resultCode === 0) {
          document.getElementById('asAssign-close-btn')?.click()
          setSelectionModel([])
          setSelectData([])
          getAsListCall()
          return
        }
      },
      fail: (error: any) => {
        console.log(error)
      },
    })
  }
  const asCancelHandler = async () => {
    putAsCancel(selectData, {
      success: (res: any) => {
        if (res.resultCode === 0) {
          setSelectData([])
          setSelectionModel([])
          getAsListCall()
          return
        }
      },
      fail: (error: any) => {
        console.log(error)
      },
    })
  }

  useEffect(() => {
    getAsListCodeCall()
  }, [])

  useEffect(() => {
    scrollTop()
    getAsListCall()
  }, [
    officeInfo,
    cooperInfo,
    filter.cause,
    filter.solution,
    filter.result,
    filter.assignStatus,
    filter.startDate,
    filter.endDate,
  ])
  return (
    <>
      <MeterariumPageLayout
        headerDescription={toolTipText.asManagement.description}
        tooltipText={toolTipText.asManagement.text}
        filter={filter}
        id={'as-management'}
        setFilter={setFilter}
        searchCallback={getAsListCall}
        total={asList?.length}
        control={['reason', 'solution', 'result', 'assign', 'deviceDateRange']}
        actions={[
          'asCancel',
          'download',
          'asAssign',
          //  'AsAssignAll'
        ]}
        onCancel={asCancelHandler}
        onAssignCancel={AsUnAssignHandler}
        setDate={setDate}
        asValue={{causeList, solutionList}}
        date={date}
        searchOptions={[
          'customerNumber',
          'customerName',
          'asSerialNumber',
          'installerName',
          'installerId',
          'asName',
          'asId',
          'address',
        ]}
        checkData={selectData}
      >
        <DataGridPro
          headerHeight={30}
          rowHeight={80}
          autoHeight
          columnGroupingModel={columnGroupingModel}
          experimentalFeatures={{columnGrouping: true}}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 30, 50, 70, 100]}
          disableColumnMenu
          pagination
          loading={loading}
          selectionModel={selectionModel}
          checkboxSelection
          onSelectionModelChange={(ids, selection) => {
            const selectedRowsData = ids.map((id) => rows.find((row: any) => row.id === id))
            onRowsSelectionHandler(ids, [
              [setSelectData, selectedRowsData],
              [setSelectionModel, ids],
            ])
          }}
          onPageChange={(params) => {
            scrollTop()
          }}
        />
      </MeterariumPageLayout>
      <ExcelDownLoadModal type={'A/S 관리'} search={filter} />
      <AsAssignModal
        checkData={selectData}
        asManagementSelectionModel={setSelectionModel}
        getAsList={getAsListCall}
      />
      <AsDetailImageModal
        open={imageDetailOpen}
        onClose={closeImageDetailModal}
        data={detailImageParams}
        setDetailImageParams={setDetailImageParams}
      />
      <AsBatchOffcanvas toast={toast} />
    </>
  )
}

export default AsManagement
