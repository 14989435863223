import React from 'react'
import './joinPage.css'
import {Route, Routes} from 'react-router-dom'
import AgreePage from './components/agreePage'
import JoinRegitstration from './components/joinRegitstration'
const MeterariumJoinPage = () => {
  return (
    <Routes>
      <>
        <Route path={'agree'} element={<AgreePage />} />
        <Route path={'registration'} element={<JoinRegitstration />} />
      </>
    </Routes>
  )
}

export default MeterariumJoinPage
