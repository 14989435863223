import React, {useEffect} from 'react'
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '_metronic/layout/core'
/* component */
import DeviceHeader from './layout/DeviceHeader'
import DeviceRegister from './component/DeviceRegister'
import DeviceAllocation from './component/DeviceAllocation'
import DeviceFirmware from './component/DeviceFirmware'
import DeviceImages from './component/DeviceImages'
import DeviceInfo from './component/DeviceInfo'
import {useAuth} from '../../_component/modules/auth'
import {useSelector} from 'react-redux'
import DeviceHistory from './component/DeviceHistory'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: '단말기',
    path: '/pages/device',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const DevicePage = (props: any) => {
  const {toast} = props
  const {logout} = useAuth()
  const navigate = useNavigate()
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  useEffect(() => {
    if (
      userInfo === null ||
      (userInfo.accountType !== 'sys_admin' &&
        userInfo.accountType !== 'sys_user' &&
        userInfo.accountType !== 'gov_user' &&
        userInfo.accountType !== 'inspector')
    ) {
      navigate('/auth')
      logout()
    }
  }, [])
  return (
    <Routes>
      <Route
        element={
          <>
            <DeviceHeader toast={toast} />
            <Outlet />
          </>
        }
      >
        <Route
          path={'register'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>단말기 등록</PageTitle>
              <DeviceRegister toast={toast} />
            </>
          }
        />
        <Route
          path={'allocation'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>단말기 할당</PageTitle>
              <DeviceAllocation />
            </>
          }
        />
        <Route
          path={'info'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>단말기 정보조회</PageTitle>
              <DeviceInfo toast={toast} />
            </>
          }
        />
        <Route
          path={'images'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>단말기 수신영상</PageTitle>
              <DeviceImages toast={toast} />
            </>
          }
        />
        <Route
          path={'history'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>단말기 HISTORY</PageTitle>
              <DeviceHistory />
            </>
          }
        />
        {/* 단말기 펌웨어 */}
        {/*<Route*/}
        {/*  path={'firmware'}*/}
        {/*  element={*/}
        {/*    <>*/}
        {/*      <PageTitle breadcrumbs={profileBreadCrumbs}>단말기 펌웨어</PageTitle>*/}
        {/*      <DeviceFirmware toast={toast} />*/}
        {/*    </>*/}
        {/*  }*/}
        {/*/>*/}
      </Route>
    </Routes>
  )
}
export default DevicePage
