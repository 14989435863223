import {Autocomplete, TextField} from '@mui/material'
import {styled} from '@mui/material/styles'
import _ from 'lodash'
import qs from 'qs'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {uniqueOfficeList} from 'utils/validation/validation'
import {useAuth} from '../../../../_component/modules/auth'
import {envBackHost} from '../../../../GLOBAL/envVariables'
import {axiosWithAuth} from '../../../../helper/axiosWithAuth'
import './asside.css'

/* eslint-disable jsx-a11y/anchor-is-valid */

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'red',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
    '&.Mui-disabled fieldset': {
      borderColor: 'white',
      borderWidth: '2px', // 이 라인이 테두리 두께를 설정합니다.
    },
    '&.Mui-disabled fieldset-input': {
      color: 'white',
    },
  },
  '& .MuiSvgIcon-root': {
    color: 'white',
    fontSize: '33px',
  },
  '& .Mui-disabled': {
    color: 'white',
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiOutlinedInput-input': {
      color: 'white',
    },
  },
})

const AsideToolbar = () => {
  const {currentUser} = useAuth()
  const cul = window.location.href.split('?')[1]
  const url = qs.parse(cul)

  const location = useLocation()
  const [officeList, setOfficeList] = useState<any>()
  const [originList, setOriginList] = useState<any>()
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const [selectedOffice, setSelectedOffice] = useState<any>(null)
  const [selectedOfficeName, setSelectedOfficeName] = useState<any>(null)
  const dispatch = useDispatch()
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const [cooperList, setCooperList] = useState<any>(null)
  const getOfficeList = () => {
    axiosWithAuth()
      .get(`/office/${userInfo?.officeId}`)
      .then((res) => {
        const data = res.data.res
        setOriginList(data)
        const transformedData = data.map((el: any) => ({
          label: el?.officeName,
          value: el?.officeId,
        }))
        setOfficeList((prev: any[]) => [...(prev || []), ...transformedData])
      })
      .catch((err) => {})
  }

  const getCooperList = () => {
    axiosWithAuth()
      .get(`${envBackHost}/cooperation/device`)
      .then((res) => {
        const transformedData = res.data.res.map((item: any) => {
          return {label: item.cooperName, value: item.idx}
        })
        setCooperList(transformedData)
      })
  }
  useEffect(() => {
    const office = _.find(originList, (el: any) => {
      return el?.officeId === url?.officeId
    })
    setSelectedOfficeName(office)
  }, [selectedOffice])

  useEffect(() => {
    getOfficeList()
    getCooperList()
  }, [])
  return (
    <>
      <div className='aside-user  align-items-sm-center justify-content-center  p-0'>
        <div className='w-100 mb-4 p-4 '>
          {officeList &&
            userInfo?.accountType !== 'installer' &&
            userInfo?.accountType !== 'customer_client' && (
              <Autocomplete
                disablePortal
                fullWidth
                defaultValue={
                  officeInfo?.value === 'all'
                    ? officeList[0]
                    : {value: officeInfo?.value, label: officeInfo?.label}
                }
                /***
                 @todo 텍스트 밀림현상
                 **/
                // value={
                //   officeInfo?.value === 'all'
                //     ? officeList[0]
                //     : {value: officeInfo?.value, label: officeInfo?.label}
                // }
                id='choose-office'
                options={uniqueOfficeList(officeList)}
                disableClearable={true}
                clearText={'초기화'}
                noOptionsText={'검색 결과가 없습니다.'}
                getOptionLabel={(option: any) =>
                  option.label === undefined ? '사업소를 입력해주세요.' : option.label
                }
                // value={selectedOffice}
                onChange={(e, rowValue: any) => {
                  // const {value} = rowValue
                  dispatch({type: 'OFFICE_INFO_REQUEST', payload: rowValue})
                }}
                sx={{fontSize: '1.5rem', color: '#fff'}}
                renderInput={(params) => (
                  <div className={'d-flex gap-3'}>
                    <CssTextField
                      {...params}
                      fullWidth
                      label={
                        userInfo?.accountType === 'gov_user' ||
                        userInfo?.accountType === 'gov_admin'
                          ? `자원 구분`
                          : '사업소 선택'
                      }
                      focused
                      placeholder={'검색하거나 선택할 수 있습니다.'}
                      sx={{
                        input: {
                          color: '#fff',
                        },
                      }}
                    />
                  </div>
                )}
              />
            )}
          {userInfo?.accountType === 'installer' ||
            (userInfo?.accountType === 'customer_client' && (
              <div className={'text-white text-center'}>
                <span className={'fw-bold fs-5'}>영아이티와 함께합니다.</span>
              </div>
            ))}
        </div>
        <div className='w-100 mb-4 p-4 '>
          {cooperList &&
            (userInfo.accountType.includes('sys') || userInfo.accountType.includes('sys')) && (
              <Autocomplete
                disablePortal
                fullWidth
                defaultValue={{value: cooperInfo?.value, label: cooperInfo?.label}}
                id='choose-cooper'
                options={cooperList}
                disableClearable={true}
                clearText={'초기화'}
                noOptionsText={'검색 결과가 없습니다.'}
                getOptionLabel={(option: any) =>
                  option.label === undefined ? '구분 자원을 선택해주세요.' : option.label
                }
                /***
                   @todo 텍스트 밀림현상
                   **/
                // value={selectedOffice}
                onChange={(e, rowValue: any) => {
                  const {value} = rowValue
                  dispatch({type: 'COOPER_INFO_REQUEST', payload: rowValue})
                }}
                sx={{fontSize: '1.5rem', color: '#fff'}}
                renderInput={(params) => (
                  <div className={'d-flex gap-3'}>
                    <CssTextField
                      {...params}
                      fullWidth
                      label='단말기 제조사'
                      focused
                      placeholder={'검색하거나 선택할 수 있습니다.'}
                      sx={{
                        input: {
                          color: '#fff',
                        },
                      }}
                    />
                  </div>
                )}
              />
            )}
        </div>
        <div className='aside-user-info flex-row-fluid flex-wrap ms-5 justify-content-end'>
          <div className='d-flex'>
            <div className='flex-grow-1 me-2 '>
              <div className='d-flex align-items-center text-light fs-5 justify-content-end'>
                {/*<span className='bullet bullet-dot bg-light me-1 my-4'></span>서구사업소*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*{userInfo.accountType === 'sys_user' ||*/}
      {/*  (userInfo.accountType === 'sys_admin' && (*/}
      {/*    <div className='d-flex align-items-center'>*/}
      {/*      <div className='bullet bg-secondary h-35px w-1px mx-5'></div>*/}
      {/*      <div className={'d-flex gap-2 text-center'}>*/}
      {/*        <span*/}
      {/*          className='text-gray-700  fw-bolder text-hover-primary cursor-pointer'*/}
      {/*          data-bs-toggle='modal'*/}
      {/*          data-bs-target='#qrModal'*/}
      {/*        >*/}
      {/*          {userInfo.accountTypeName} 메뉴얼*/}
      {/*        </span>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*<div className='aside-search py-5'>*/}
      {/*  /!*<Search />*!/*/}
      {/*  <ThemeProvider theme={theme}>*/}
      {/*    <FormControl sx={{m: 1}} fullWidth>*/}
      {/*      <InputLabel id='demo-simple-select-autowidth-label' sx={{color: '#ffffff'}}>*/}
      {/*        클릭하여 하위 사업소 선택*/}
      {/*      </InputLabel>*/}
      {/*      <Select*/}
      {/*        labelId='demo-simple-select-autowidth-label'*/}
      {/*        id='demo-simple-select-autowidth'*/}
      {/*        fullWidth*/}
      {/*        label={`클릭하여 하위 사업소 선택`}*/}
      {/*        className='bg-white bg-opacity-10'*/}
      {/*      >*/}
      {/*        <MenuItem value={10}>서구</MenuItem>*/}
      {/*        <MenuItem value={21}>동구</MenuItem>*/}
      {/*        <MenuItem value={22}>대덕구</MenuItem>*/}
      {/*      </Select>*/}
      {/*    </FormControl>*/}
      {/*  </ThemeProvider>*/}
      {/*</div>*/}
      {/*<HeaderQrModal />*/}
    </>
  )
}

export {AsideToolbar}
