import {styled} from '@mui/material/styles'
import {Tooltip, tooltipClasses, TooltipProps} from '@mui/material'

export const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
})
