import React, {useEffect, useState} from 'react'
import {TextField} from '@mui/material'
import {envBackHost} from '../../../GLOBAL/envVariables'
import axios from 'axios'
import {axiosWithAuth} from '../../../helper/axiosWithAuth'
import {ToastContainer} from 'react-toastify'
import {confirmAlert, normalAlert, resultAlert} from '../../../utils/CustomAlert/Alert'
const TypeUpdateModal = (props: any) => {
  const {setDetailInfoFlag, detailInfoFlag, selectedOfficeInfo, selectedType, setSelectedType} =
    props
  const [updateInfo, setUpdateInfo] = useState<any>(null)

  const myModal: any = document.getElementById('typeUpdateModal')
  myModal?.addEventListener('hidden.bs.modal', function () {
    setSelectedType(null)
  })
  const updateHandler = async () => {
    if (updateInfo?.customerTypeName === '') {
      await normalAlert('수용가 대상 명을 입력해주세요.')
      return
    }
    if (updateInfo?.customerTypeName === selectedType?.customerTypeName) {
      await normalAlert('수용가 대상명에 변화가 없습니다.')
      return
    }
    axiosWithAuth()
      .put(`${envBackHost}/office/${selectedType.officeId}/customertype`, updateInfo)
      .then(async (res) => {
        await resultAlert(res.data)
        if (res.data.resultCode === 0) {
          setDetailInfoFlag(!detailInfoFlag)
          setUpdateInfo(null)
          document.getElementById('exitButton2')?.click()
          return
        }
      })
      .catch(async (e) => {
        await resultAlert({resultCode: e.response.status})
      })
  }

  const deleteHandler = async () => {
    if (await confirmAlert('삭제', 'info')) {
      axiosWithAuth()
        .delete(`${envBackHost}/office/${selectedType.officeId}/customertype/${selectedType.idx}`)
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            setDetailInfoFlag(!detailInfoFlag)
            setUpdateInfo(null)
            document.getElementById('exitButton2')?.click()
            return
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    } else {
      return
    }
  }

  useEffect(() => {
    setUpdateInfo({
      customerTypeName: selectedType?.customerTypeName,
      idx: selectedType?.idx,
      lowUseCycle: selectedType?.lowUseCycle,
      lowUseStandard: selectedType?.lowUseStandard,
      lowUseUnit: selectedType?.lowUseUnit,
      leakUseCycle: selectedType?.leakUseCycle,
      leakUseStandard: selectedType?.leakUseStandard,
      leakUseUnit: selectedType?.leakUseUnit,
    })
  }, [selectedType])

  return (
    <>
      <div
        className='modal fade'
        id='typeUpdateModal'
        tabIndex={-1}
        aria-labelledby='typeUpdateModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='typeUpdateModalLabel'>
                수용가 대상 수정
              </h5>
              <button
                id={'exitButton2'}
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                // onClick={() => }
              ></button>
            </div>
            <div className='modal-body'>
              {selectedType?.customerTypeName && (
                <TextField
                  variant={'outlined'}
                  id={'textArea'}
                  label={'변경할 수용가 대상이름'}
                  defaultValue={selectedType?.customerTypeName}
                  placeholder={'수용가 대상 이름을 입력해주세요.'}
                  onChange={(e) => {
                    setUpdateInfo({
                      ...updateInfo,
                      customerTypeName: e.target.value,
                    })
                  }}
                  fullWidth
                />
              )}
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-danger'
                onClick={() => {
                  deleteHandler()
                }}
              >
                삭제
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => {
                  updateHandler()
                }}
              >
                수정
              </button>
              <button
                type='button'
                className='btn btn-secondary'
                data-bs-dismiss='modal'
                onClick={() => {
                  // setUpdateInfo(null)
                }}
              >
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default TypeUpdateModal
