import {useEffect, useRef, useState} from 'react'
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from '@mui/material'
import _ from 'lodash'
import {useSelector} from 'react-redux'
import {KeyboardDoubleArrowRight} from '@mui/icons-material'
import './CustomPagination.css'
import {normalAlert} from 'utils/CustomAlert/Alert'
const CustomPagination = (props: any) => {
  const {filter, setFilter, totalCount, mini} = props
  const pageInfo = useSelector((state: any) => state.pageInfo)
  const inputRef: any = useRef(null)
  const [movePage, setMovePage] = useState<any>(1)
  useEffect(() => {
    setMovePage('')
  }, [filter.rowPerPage])

  return (
    <>
      {!mini && (
        <div
          className={'mt-3 d-flex gap-3 justify-content-center align-items-center '}
          style={{marginTop: 15}}
        >
          <Pagination
            count={Math.ceil(totalCount / (filter?.rowPerPage || 1))}
            color='primary'
            size={'large'}
            variant='outlined'
            shape='rounded'
            defaultPage={1}
            siblingCount={4}
            page={filter?.page}
            onChange={(e, page) => {
              setFilter({...filter, page: page})
              setMovePage(page)
            }}
          />
          <div className={'d-flex gap-2'}>
            <TextField
              variant={'outlined'}
              ref={inputRef}
              size={'small'}
              sx={{
                width: 150,
                '& .MuiOutlinedInput-input': {
                  textAlign: 'end',
                },
              }}
              value={movePage}
              id={'go-page'}
              label={'페이지 이동'}
              onKeyDown={async (e) => {
                if (e.key === 'Enter') {
                  if (Number(movePage) < 1) {
                    await normalAlert('이동할 수 있는 페이지 범위를 벗어났습니다.')
                  } else {
                    await setFilter({...filter, page: movePage})
                  }
                }
              }}
              onChange={async (e) => {
                if (!/^\d*$/.test(e.target.value)) {
                  await normalAlert('숫자만 입력 가능합니다.')
                  return
                } else {
                  if (Number(e.target.value) > Math.ceil(totalCount / (filter?.rowPerPage || 1))) {
                    await normalAlert('이동할 수 있는 페이지 범위를 벗어났습니다.')
                    return
                  } else {
                    setMovePage(Number(e.target.value))
                  }
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='move-page'
                      onClick={async () => {
                        if (!/^\d*$/.test(movePage)) {
                          await normalAlert('숫자만 입력 가능합니다.')
                          return
                        } else {
                          if (Number(movePage) < 1) {
                            await normalAlert('이동할 수 있는 페이지 범위를 벗어났습니다.')
                          } else {
                            await setFilter({...filter, page: movePage})
                          }
                        }
                      }}
                      edge='end'
                    >
                      <KeyboardDoubleArrowRight />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <FormControl sx={{width: 200}}>
            <InputLabel id='demo-simple-select-label'>한 페이지에 표시될 항목</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='customer-target'
              label='한 페이지에 표시될 항목'
              // sx={{paddingY: 0}}
              size={'small'}
              value={filter?.rowPerPage}
              onChange={(e) => {
                setFilter({...filter, page: 1, rowPerPage: e.target.value})
              }}
            >
              {_.map(pageInfo.selectPageSize, (el, i) => {
                return (
                  <MenuItem value={el} key={i}>
                    {el}개 / 페이지 당
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </div>
      )}
      {mini && (
        <div className={'mt-3 row gap-3 justify-content-center align-items-center'}>
          <Pagination
            count={Math.ceil(totalCount / (filter?.rowPerPage || 1))}
            color='primary'
            size={'large'}
            variant='outlined'
            shape='rounded'
            defaultPage={1}
            siblingCount={2}
            boundaryCount={1}
            page={filter?.page}
            onChange={(e, page) => {
              setFilter({...filter, page: page})
            }}
          />
          <FormControl sx={{width: 200}}>
            <InputLabel id='demo-simple-select-label'>한 페이지에 표시될 항목</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='customer-target'
              label='한 페이지에 표시될 항목'
              // sx={{paddingY: 0}}
              size={'small'}
              value={filter?.rowPerPage}
              onChange={(e) => {
                setFilter({...filter, page: 1, rowPerPage: e.target.value})
              }}
            >
              {_.map(pageInfo.selectPageSize, (el, i) => {
                return (
                  <MenuItem value={el} key={i}>
                    {el}개 / 페이지 당
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </div>
      )}
    </>
  )
}

export default CustomPagination
