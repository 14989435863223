import React, {useState} from 'react'
import {Button, Checkbox, FormControlLabel, FormGroup} from '@mui/material'
import TextField from '@mui/material/TextField'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import dayjs, {Dayjs} from 'dayjs'
/*icons*/
import DownloadIcon from '@mui/icons-material/Download'
import _ from 'lodash'
import {isMobileDevice} from '../../_metronic/assets/ts/_utils'
import {axiosWithAuth} from '../../helper/axiosWithAuth'
import {envBackHost} from '../../GLOBAL/envVariables'
import {useSelector} from 'react-redux'
import {toast, ToastContainer} from 'react-toastify'
import fileDownload from 'js-file-download'
import {normalAlert, resultAlert} from '../../utils/CustomAlert/Alert'
import ExcelFormEditModal from './../common/ExcelFormEditModal'
interface DOWNLOAD_TYPE {
  type: string
  data?: any
  search?: any
}

const ExcelDownLoadModal = (props: DOWNLOAD_TYPE) => {
  const {type, data, search} = props
  const [filter, setFilter] = useState({
    date: dayjs().format('YYYYMMDD'),
  })
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const getDownloadData = async () => {
    await toast
      .promise(
        axiosWithAuth()
          .get(
            `${envBackHost}/${officeInfo?.value}/${cooperInfo?.value}${window.location.pathname}/excel?date=${filter.date}&userId=${userInfo?.userId}`,
            {
              params: search,
              responseType: 'blob',
            }
          )
          .then(async (res) => {
            await resultAlert({resultCode: 0})
            fileDownload(res.data, `${officeInfo?.label}_${filter.date}_${type}.xlsx`)
            document.getElementById('downloadModalClose')?.click()
          })
          .catch(async (e) => {
            await resultAlert({resultCode: 400})
          }),
        {
          pending: '다운로드 중입니다.',
        },
        {
          position: 'top-center',
          autoClose: 1000,
          className: 'fs-3  fw-bold',
          style: {width: '400px'},
        }
      )
      .catch(async (e) => {
        await resultAlert({resultCode: 400})
      })
  }

  return (
    <>
      <div
        className='modal fade'
        id='downloadModal'
        tabIndex={-1}
        aria-labelledby='downloadModal'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered modal-xl p-3'>
          <div className='modal-content  rounded-2'>
            <div className='modal-header'>
              <h2 className='modal-title' id='downloadModalLabel'>
                {type} 다운로드
              </h2>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                id={'downloadModalClose'}
              ></button>
            </div>

            <div className='modal-body'>
              <div className={'d-flex gap-4 col'}>
                {window.location.pathname !== '/task/as' ? (
                  <div id={'date-range'} className={'text-center col-5'}>
                    <div className={'d-flex gap-6 text-center w-100 justify-content-center'}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label='날짜 선택'
                          value={filter.date}
                          mask='____/__/__'
                          inputFormat={'YYYY/MM/DD'}
                          onChange={(newValue: any) => {
                            setFilter({
                              ...filter,
                              date: dayjs(newValue).format('YYYYMMDD'),
                            })
                          }}
                          className={'w-100'}
                          renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                ) : null}
                <>
                  {window.location.pathname === '/task/as' ? (
                    <div className={'align-self-end'}>
                      <span className={'fs-5 text-danger fw-bold'}>
                        A/S 관리에서는 검색필터에 따라 데이터가 다운로드 됩니다.
                      </span>
                    </div>
                  ) : (
                    <Button
                      variant={'contained'}
                      size={'large'}
                      color={'secondary'}
                      className={'mx-2 col-3'}
                      data-bs-toggle='modal'
                      data-bs-target='#ExcelFormEditModal'
                    >
                      엑셀 양식지정
                    </Button>
                  )}

                  <Button
                    variant={'contained'}
                    size={'large'}
                    className={'mx-2 col'}
                    onClick={() => {
                      getDownloadData()
                    }}
                  >
                    <DownloadIcon />
                    <span>데이터 다운로드</span>
                  </Button>
                </>
              </div>
            </div>
            <div className={'modal-footer text-end m-4'}>
              <Button variant={'contained'} size={'large'} color={'error'} data-bs-dismiss='modal'>
                나가기
              </Button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      <ExcelFormEditModal />
    </>
  )
}
export default ExcelDownLoadModal
