import React, {useState} from 'react'
import Paper from '@mui/material/Paper'
import '../../join/joinPage.css'
import Logo from 'pages/join/images/meterarium_new_logo.gif'
import Box from '@mui/material/Box'
import {Button, Step, StepLabel, Stepper, TextField} from '@mui/material'
import Typography from '@mui/material/Typography'
import {isId, isPhone} from 'utils/validation/validation'
import {Link, useNavigate} from 'react-router-dom'
import {certifitaionRequest} from 'utils/apiCalling/api'
import {validationAlert} from 'utils/CustomAlert/Alert'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
const PasswordCertification = () => {
  const steps = ['인증', '비밀번호 초기화']
  const navigate = useNavigate()
  const [info, setInfo] = useState({
    id: '',
    phoneNumber: '',
  })
  const [validationCheck, setValidationCheck] = useState<any>({
    id: false,
    phoneNumber: false,
  })
  const phoneValidation = (e: any) => {
    const {value} = e.target
    if (isPhone(value)) {
      setValidationCheck({
        ...validationCheck,
        phoneNumber: false,
      })
      setInfo({...info, phoneNumber: value})
    } else if (value === '') {
      setValidationCheck({
        ...validationCheck,
        phoneNumber: false,
      })
    } else {
      setValidationCheck({
        ...validationCheck,
        phoneNumber: true,
      })
    }
  }
  const idValidation = (e: any) => {
    const {value} = e.target
    if (isId(value)) {
      setValidationCheck({
        ...validationCheck,
        id: false,
      })
      setInfo({...info, id: value})
    } else if (value === '') {
      setValidationCheck({
        ...validationCheck,
        id: false,
      })
    } else {
      setValidationCheck({
        ...validationCheck,
        id: true,
      })
    }
  }
  const certifitaion = () => {
    certifitaionRequest(info, {
      success: async (res: any) => {
        if (res.result) {
          await validationAlert('인증이 완료되었습니다.', 'success')
          navigate('/password/reset', {state: {data: res, info: info}})
        } else {
          await validationAlert('인증이 실패하였습니다.', 'error')
        }
      },
      fail: (err: any) => {
        console.log(err)
      },
    })
  }

  return (
    <>
      {!isMobileDevice() && (
        <div className={'container-fluid vh-100 join-body p-3'}>
          <div className={'join-header text-center'}>
            <img src={Logo} alt={'logo'} width={'700'} style={{borderRadius: '28px'}} />
          </div>
          <div className={'w-100 '} style={{display: 'grid', justifyItems: 'center'}}>
            <Paper elevation={3} className={'p-5 w-50'}>
              <Box sx={{width: '100%'}} className={'my-7 '}>
                <Stepper activeStep={0} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <div className={'text-center mb-5'}>
                <h2 style={{fontSize: 35}}>본인인증</h2>
                <Typography variant='h6' gutterBottom className={'text-muted'}>
                  비밀번호 재설정을 위해 본인인증을 진행합니다.
                </Typography>
                <Typography variant='h6' gutterBottom className={'text-primary'}>
                  계정 생성 시 등록한 아아디(ID)와 전화번호를 입력하세요.
                </Typography>
              </div>
              <div
                className={'d-flex gap-5 justify-content-center'}
                style={{padding: '0px 100px 0px 100px', marginBottom: 30}}
              >
                <TextField
                  variant={'outlined'}
                  label={'아이디'}
                  helperText={validationCheck.id && '아이디를 확인해주세요.'}
                  fullWidth={true}
                  defaultValue={info.id}
                  error={validationCheck.id}
                  autoComplete={'off'}
                  onChange={(e) => idValidation(e)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (
                        validationCheck.id ||
                        validationCheck.phoneNumber ||
                        info.id === '' ||
                        info.phoneNumber === ''
                      ) {
                      } else {
                        certifitaion()
                      }
                    }
                  }}
                />
                <TextField
                  variant={'outlined'}
                  label={'전화번호'}
                  fullWidth={true}
                  defaultValue={info.phoneNumber}
                  helperText={
                    validationCheck.phoneNumber
                      ? '전화번호를 확인해주세요.'
                      : '( - )를 제외하고 입력해주세요.'
                  }
                  error={validationCheck.phoneNumber}
                  autoComplete={'off'}
                  onChange={(e) => phoneValidation(e)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (
                        validationCheck.id ||
                        validationCheck.phoneNumber ||
                        info.id === '' ||
                        info.phoneNumber === ''
                      ) {
                      } else {
                        certifitaion()
                      }
                    }
                  }}
                />
              </div>

              <div className={'d-flex gap-4 justify-content-end'} style={{marginBottom: 30}}>
                <Link to={'/auth'}>
                  <Button variant={'outlined'} color={'error'}>
                    로그인 페이지이동
                  </Button>
                </Link>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => certifitaion()}
                  disabled={
                    validationCheck.id ||
                    validationCheck.phoneNumber ||
                    info.id === '' ||
                    info.phoneNumber === ''
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      certifitaion()
                    }
                  }}
                >
                  인증하기
                </Button>
              </div>
              <div
                className={'row gap-2 fs-6 text-danger'}
                style={{padding: '0px 100px 0px 100px'}}
              >
                <span>비밀번호를 재설정하기 위해서는 아이디와 전화번호 입력이 필수입니다.</span>
                <span>
                  인증 후 비밀번호 재설정 시간은 5분입니다. 인증만료시 재인증 절차가 필요합니다.
                </span>
                <span>인증이 실패할 경우, 입력하신 아이디와 전화번호가 올바른지 확인해주세요.</span>
                <span className={'fw-bold text-primary'}>
                  등록된 전화번호를 모르실 경우, 영아이티로 문의부탁드립니다.
                </span>
              </div>
            </Paper>
          </div>
        </div>
      )}
      {isMobileDevice() && (
        <div className={'container-fluid vh-100 join-body p-3'}>
          <div className={'join-header text-center'}>
            <img src={Logo} alt={'logo'} width={'100%'} style={{borderRadius: '28px'}} />
          </div>
          <div className={'w-100 px-2'} style={{display: 'grid', justifyItems: 'center'}}>
            <Paper elevation={3} className={'p-5 w-100 '}>
              <Box sx={{width: '100%'}} className={'my-7 '}>
                <Stepper activeStep={0} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <div className={'text-center mb-5'}>
                <h2 style={{fontSize: 35}}>본인인증</h2>
                <Typography variant='h6' gutterBottom className={'text-muted'}>
                  비밀번호 재설정을 위해 본인인증을 진행합니다.
                </Typography>
                <Typography variant='h6' gutterBottom className={'text-primary'}>
                  계정 생성 시 등록한 아아디(ID)와 전화번호를 입력하세요.
                </Typography>
              </div>
              <div className={'d-flex gap-5 justify-content-center'} style={{marginBottom: 30}}>
                <TextField
                  variant={'outlined'}
                  label={'아이디'}
                  helperText={validationCheck.id && '아이디를 확인해주세요.'}
                  fullWidth={true}
                  defaultValue={info.id}
                  error={validationCheck.id}
                  autoComplete={'off'}
                  name={'id'}
                  onChange={(e) => idValidation(e)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (
                        validationCheck.id ||
                        validationCheck.phoneNumber ||
                        info.id === '' ||
                        info.phoneNumber === ''
                      ) {
                      } else {
                        certifitaion()
                      }
                    }
                  }}
                />
                <TextField
                  variant={'outlined'}
                  label={'전화번호'}
                  fullWidth={true}
                  defaultValue={info.phoneNumber}
                  helperText={
                    validationCheck.phoneNumber
                      ? '전화번호를 확인해주세요.'
                      : '( - )를 제외하고 입력해주세요.'
                  }
                  error={validationCheck.phoneNumber}
                  autoComplete={'off'}
                  onChange={(e) => phoneValidation(e)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (
                        validationCheck.id ||
                        validationCheck.phoneNumber ||
                        info.id === '' ||
                        info.phoneNumber === ''
                      ) {
                      } else {
                        certifitaion()
                      }
                    }
                  }}
                />
              </div>

              <div className={'row gap-2 fs-6 text-danger'}>
                <span>비밀번호를 재설정하기 위해서는 아이디와 전화번호 입력이 필수입니다.</span>
                <span>
                  인증 후 비밀번호 재설정 시간은 5분입니다. 인증만료시 재인증 절차가 필요합니다.
                </span>
                <span>인증이 실패할 경우, 입력하신 아이디와 전화번호가 올바른지 확인해주세요.</span>
                <span className={'fw-bold text-primary'}>
                  등록된 전화번호를 모르실 경우, 영아이티로 문의부탁드립니다.
                </span>
              </div>
              <div className={'d-flex gap-4 justify-content-end'} style={{marginBottom: 50}}>
                <Link to={'/auth'}>
                  <Button variant={'outlined'} color={'error'}>
                    로그인 페이지이동
                  </Button>
                </Link>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => certifitaion()}
                  disabled={
                    validationCheck.id ||
                    validationCheck.phoneNumber ||
                    info.id === '' ||
                    info.phoneNumber === ''
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      certifitaion()
                    }
                  }}
                >
                  인증하기
                </Button>
              </div>
            </Paper>
          </div>
        </div>
      )}
    </>
  )
}

export default PasswordCertification
