import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {useSelector} from 'react-redux'

const UerManagemnetHeader = (props: any) => {
  const location = useLocation()
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  return (
    <>
      {!isMobileDevice() && (
        <div className='card mb-5 '>
          <div className='card-body pt-4 pb-0'>
            <div className='d-flex overflow-auto h-55px'>
              <ul
                className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder flex-nowrap'
                style={{fontSize: '22px'}}
              >
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/account/group' && 'active')
                    }
                    to='/account/group'
                  >
                    사용자 그룹 관리
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {isMobileDevice() && (
        <div className='card mb-5 mb-xl-10 mt-5'>
          <div className='card-body pt-4 pb-0'>
            <div className='d-flex overflow-auto h-auto'>
              {userInfo?.accountType === 'sys_admin' && (
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-bolder justify-content-evenly'
                  style={{fontSize: '20px'}}
                >
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/account/group' && 'active')
                      }
                      to='/account/group'
                    >
                      사용자 그룹 관리
                    </Link>
                  </li>
                </ul>
              )}
              {userInfo?.accountType === 'sys_user' && (
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-bolder justify-content-evenly'
                  style={{fontSize: '20px'}}
                >
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/account/group' && 'active')
                      }
                      to='/account/group'
                    >
                      사용자 그룹 관리
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default UerManagemnetHeader
