//@ts-ignore
import swal from 'sweetalert'
import Swal from 'sweetalert2'

export async function resultAlert(res: any, message?: any) {
  switch (res.resultCode) {
    case 0:
      await swal({
        title: '성공',
        text: message === undefined ? '성공적으로 처리되었습니다.' : message,
        icon: 'success',
        buttons: [false],
        timer: 2000,
      })
      break
    case 1:
      await swal({
        title: '실패',
        text: message === undefined ? '실패하였습니다.' : message,
        icon: 'error',
        buttons: [false],
        timer: 2000,
      })
      break
    case 10:
      await swal({
        title: '실패',
        text: message === undefined ? '필수값이 누락되어있습니다.' : message,
        icon: 'error',
        buttons: [false],
        timer: 2000,
      })
      break
    case 20:
      await swal({
        title: '실패',
        text: message === undefined ? '중복된 데이터입니다.' : message,
        icon: 'error',
        buttons: [false],
        timer: 2000,
      })
      break
    case 30:
      await swal({
        title: '실패',
        text: message === undefined ? '데이터 형식이 맞지않습니다.' : message,
        icon: 'error',
        buttons: [false],
        timer: 2000,
      })
      break
    case 40:
      await swal({
        title: '실패',
        text: message === undefined ? '입력하신 값이 허용범위를 초과하였습니다.' : message,
        icon: 'error',
        buttons: [false],
        timer: 2000,
      })
      break
    case 41:
      await swal({
        title: '실패',
        text: message === undefined ? '권한이 없습니다.' : message,
        icon: 'error',
        buttons: [false],
        timer: 2000,
      })
      break
    case 50:
      await swal({
        title: '실패',
        text: message === undefined ? '연관된 데이터가 존재합니다.' : message,
        icon: 'error',
        buttons: [false],
        timer: 2000,
      })
      break
    case 200:
      await swal({
        title: '성공',
        text: message === undefined ? '성공적으로 처리되었습니다.' : message,
        icon: 'success',
        buttons: [false],
        timer: 2000,
      })
      break
    case 401:
      await swal({
        title: '실패',
        text: message === undefined ? '인증에 오류가 있습니다. 다시 로그인해주세요.' : message,
        icon: 'error',
        buttons: [false],
        timer: 2000,
      })
      break
    case 500:
      await swal({
        title: '실패',
        text: message === undefined ? '서버에 오류가 있습니다. 관리자에게 문의해주세요.' : message,
        icon: 'error',
        buttons: [false],
        timer: 2000,
      })
      break
    default:
      await swal({
        title: '실패',
        text: message === undefined ? '알수없는 오류가 발생하였습니다.' : message,
        icon: 'error',
        buttons: [false],
        timer: 2000,
      })
      break
  }
}
export async function failAlert() {
  return await swal({
    title: '실패',
    text: '관리자에게 문의해주세요.',
    icon: 'error',
    buttons: [false],
    timer: 2000,
  })
}
export async function confirmAlert(
  type:
    | '삭제'
    | '초기화'
    | 'pass'
    | 'fail'
    | '등록'
    | '수정'
    | '생성'
    | '할당'
    | '할당취소'
    | '설치'
    | '설치취소'
    | '설치불가'
    | '설치가능'
    | 'AS등록'
    | 'AS취소'
    | 'AS완료'
    | '재사용'
    | '폐기처리'
    | '다운로드'
    | string,
  icon: string
) {
  return await swal({
    title: `${type}`,
    text: `${type} 하시겠습니까?`,
    icon: `${icon}`,
    buttons: {
      cancel: {
        text: `취소`,
        value: false,
        visible: true,
        className: 'fw-bold',
      },
      confirm: {
        text: `${type}`,
        value: true,
        visible: true,
        className: 'bg-primary fw-bold',
      },
    },

    className: 'fw-bold',
    dangerMode:
      type === '삭제' ||
      type === '설치취소' ||
      type === 'AS취소' ||
      type === '할당취소' ||
      type === '초기화',
  })
}

export async function confirmASAlert(type: string, icon: any, res: any) {
  const options: Record<string, string> = {}
  res.data.res.forEach((code: any, index: number) => {
    options[`option${index}`] = code.codeName
  })
  const {value: selectedOption, isConfirmed} = await Swal.fire({
    title: `${type}`,
    text: `${type} 하시겠습니까?`,
    icon: icon,
    input: 'select',
    inputOptions: options,
    inputPlaceholder: '원인을 골라주세요.',
    showCancelButton: true,
    confirmButtonText: `${type}`,
    confirmButtonColor: '#3085d6',
    cancelButtonText: '취소',
    cancelButtonColor: '#d33',
    reverseButtons: true,

    didOpen: () => {
      const selectElement = Swal.getInput()
      if (selectElement) {
        selectElement.style.fontFamily = 'Nanum Gothic, sans-serif'
        selectElement.addEventListener('click', (event) => {
          event.stopPropagation()
        })
      }
    },
  })
  if (isConfirmed) {
    if (selectedOption) {
      let sliced = parseInt(selectedOption.replace('option', '')) + 1
      return sliced
    } else {
      await Swal.fire({
        icon: 'warning',
        title: '사유는 필수값입니다.',
        text: '사유를 선택해주세요!',
        confirmButtonText: '확인',
        confirmButtonColor: '#3085d6',
      })
      await confirmASAlert(type, icon, res)
    }
  } else {
    return false
  }
}
export async function confirmASCancleAlert(type: string, icon: any) {
  const {isConfirmed} = await Swal.fire({
    title: `${type}`,
    text: `${type} 하시겠습니까?`,
    icon: icon,
    showCancelButton: true,
    confirmButtonText: `${type}`,
    confirmButtonColor: '#3085d6',
    cancelButtonText: '취소',
    cancelButtonColor: '#d33',
    reverseButtons: true,
  })
  if (isConfirmed) {
    return true
  } else {
    return false
  }
}

export async function noDataAlert() {
  return await swal({
    title: `경고`,
    text: `선택된 데이터가 없습니다.`,
    icon: `warning`,
    buttons: [false],
    timer: 2000,
  })
}
export async function normalAlert(message: string) {
  return await swal({
    title: `경고`,
    text: `${message}`,
    icon: `warning`,
    buttons: [false],
    timer: 2000,
  })
}
export async function validationAlert(message: string, icon: string) {
  return await swal({
    title: `확인`,
    text: `${message}`,
    icon: icon,
    buttons: [false],
    timer: 1400,
  })
}
export async function customConfirm(text: string, icon: string, button: string) {
  return await swal({
    title: '경고',
    text: `${text}`,
    icon: `${icon}`,
    buttons: {
      cancel: {
        text: `취소`,
        value: false,
        visible: true,
        className: 'fw-bold',
      },
      confirm: {
        text: `${button}`,
        value: true,
        visible: true,
        className: 'bg-primary fw-bold',
      },
    },
    className: 'fw-bold',
  })
}
export async function playConfirm(title: string, text: string, icon: string, button: string) {
  return await swal({
    title: `${title}`,
    text: `${text}`,
    icon: `${icon}`,
    buttons: {
      cancel: {
        text: `취소`,
        value: false,
        visible: true,
        className: 'fw-bold',
      },
      confirm: {
        text: `${button}`,
        value: true,
        visible: true,
        className: 'bg-primary fw-bold',
      },
    },
    className: 'fw-bold',
  })
}

export const loginAlert = async (name: string) => {
  return await swal({
    title: '안녕하세요, 미터리움입니다.',
    content: {
      element: 'div',
      attributes: {
        innerHTML: `<div class="row"><div class="mb-4" style="text-align: center;">${name}님 환영합니다.</div><div class="fw-bold" style="text-align: center;">미터리움을 통해 스마트한 미터링과 <br />검침의 미래를 경험해보세요.</div></div>`,
      },
    },
    icon: 'success',
    buttons: [false],
    timer: 1500,
  })
}

export const loginFailAlert = async () => {
  return await swal({
    title: '로그인 실패',
    text: '로그인 정보가 올바르지 않습니다.',
    icon: 'error',
    buttons: [false],
    timer: 1500,
  })
}

export const ErrorAlert = async (message: string) => {
  return await swal({
    title: '실패',
    text: `${message}`,
    icon: 'error',
    buttons: [false],
    timer: 1500,
  })
}
export const SuccessAlert = async (message: string) => {
  return await swal({
    title: '성공',
    text: `${message}`,
    icon: 'success',

    buttons: [false],
    timer: 1500,
  })
}
export const installConfirm = async (text: string, icon: string, button: string) => {
  return await swal({
    title: '경고',
    content: {
      element: 'div',
      attributes: {
        innerHTML: `<div class="row"><div class="mb-2" style="text-align: center;">${text}</div><div class="fw-bold text-danger" style="text-align: center;">설치 중인 데이터는 사라집니다.</div></div>`,
      },
    },
    icon: `${icon}`,
    buttons: {
      cancel: {
        text: `취소`,
        value: false,
        visible: true,
        className: 'fw-bold',
      },
      confirm: {
        text: `${button}`,
        value: true,
        visible: true,
        className: 'bg-primary fw-bold',
      },
    },
    className: 'fw-bold',
  })
}
export const asConfirm = async (text: string, icon: string, button: string) => {
  return await swal({
    title: '경고',
    content: {
      element: 'div',
      attributes: {
        innerHTML: `<div class="row"><div class="mb-2" style="text-align: center;">${text}</div><div class="fw-bold text-danger" style="text-align: center;">A/S 중인 데이터는 사라집니다.</div></div>`,
      },
    },
    icon: `${icon}`,
    buttons: {
      cancel: {
        text: `취소`,
        value: false,
        visible: true,
        className: 'fw-bold',
      },
      confirm: {
        text: `${button}`,
        value: true,
        visible: true,
        className: 'bg-primary fw-bold',
      },
    },
    className: 'fw-bold',
  })
}
