import {userInfoActionTypes} from '../actionTypes'

interface UserInfo {
  username: string
}

interface InfoState {
  userInfo: UserInfo | null
  isLoading: boolean
  error: string | null
}

const initialState: InfoState = {
  userInfo: null,
  isLoading: false,
  error: null,
}

const userInfoReducer = (state = initialState, action: any): InfoState => {
  switch (action.type) {
    case userInfoActionTypes.USER_INFO_REQUEST:
      return {
        ...action.payload,
        isLoading: true,
        error: null,
      }

    case userInfoActionTypes.USER_INFO_DELETE:
      return {
        userInfo: null,
        isLoading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default userInfoReducer
