import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import {Step, StepLabel, Stepper} from '@mui/material'
import Box from '@mui/material/Box'

const ChargeHeader = (props: any) => {
  const {selectStep} = props
  const navigate = useNavigate()
  const officeInfo = useSelector((state: any) => state?.officeInfo)
  const steps = ['요금프로그램 선택', '납기일 선택', '요금연계 진행']
  return (
    <>
      <div className='card mb-5' id='charge-service'>
        <div className={'card-body align-self-center w-100 p-2 pt-3'}>
          <Box sx={{width: '100%'}} className={''}>
            <Stepper
              activeStep={!selectStep.step1 ? 0 : selectStep.step1 && !selectStep.step2 ? 1 : 2}
              alternativeLabel
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>
      </div>
    </>
  )
}

export default ChargeHeader
