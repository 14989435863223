import React, {useEffect, useState} from 'react'
import {Autocomplete, TextField} from '@mui/material'
import {useSelector} from 'react-redux'
import {getOfficeList} from 'utils/apiCalling/api'
import {uniqueOfficeList} from 'utils/validation/validation'

/**
 * @param setSubmitData useState setState
 * @param submitData  useState Value
 * @param register react-hook-from register
 * @param setValue react-hook-from setValue (errors 지우는 용)
 * @param name react-hook-from (register에 name을
 *
 *
 */
const CustomerRegisterSearch = (props: any) => {
  const {setSubmitData, submitData, register, setValue, name, errors} = props
  const [officeList, setOfficeList] = useState<any>(null)
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  useEffect(() => {
    getOfficeList(userInfo, setOfficeList)
  }, [])
  return (
    <>
      {officeList && (
        <>
          <Autocomplete
            disablePortal
            fullWidth
            options={uniqueOfficeList(officeList)}
            onChange={(event, value) => {
              setSubmitData({...submitData, officeId: value?.value, officeName: value?.label})
              setValue('officeName', value?.label)
            }}
            clearText={'초기화'}
            noOptionsText={'검색 결과가 없습니다.'}
            getOptionLabel={(option: any) => option.label}
            renderInput={(params) => (
              <div className={'d-flex gap-3'}>
                <TextField
                  {...params}
                  {...register('officeName', {
                    required: {
                      value: true,
                      message: '필수 입력 사항입니다.',
                    },
                  })}
                  label='사업소 검색'
                  error={submitData[name] === '' && errors[name] ? true : false}
                  helperText={
                    submitData[name] === '' && errors[name] ? (
                      <span className={'fs-6 fw-bold text-danger'}>
                        {String(errors[name].message)}
                      </span>
                    ) : (
                      ''
                    )
                  }
                />
              </div>
            )}
          />
          <TextField
            required
            id='customer-id'
            // label='사업소 코드'
            placeholder={'사업소 코드가 자동으로 입력됩니다.'}
            variant='outlined'
            disabled
            fullWidth
            value={
              submitData?.officeName ? submitData?.officeName + '-' + submitData?.officeId : '-'
            }
          />
        </>
      )}
    </>
  )
}

export default CustomerRegisterSearch
