import {Button, TextField} from '@mui/material'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import {envImagePath} from 'GLOBAL/envVariables'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {KTSVG} from '_metronic/helpers'
import {yMarker} from 'assets/imageDeclear'
import dayjs from 'dayjs'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {failAlert, validationAlert} from 'utils/CustomAlert/Alert'
import {getClientInfo} from 'utils/apiCalling/api'

const CustomerClient = () => {
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const userInfo = useSelector((state: any) => state.userInfo.userInfo)
  const [clientInfo, setClientInfo] = useState<any>(null)
  const [date, setDate] = useState<any>(dayjs().format('YYYYMMDD'))
  const submitData = {
    id: userInfo?.userId,
    customerNumber: userInfo?.userName,
    date: date,
  }
  const [imagePath, setImagePath] = useState<any>()
  const [listNum, setListNum] = useState<any>(0)
  const [digitalNum, setDigitalNum] = useState<any>(0)

  const getClientInfoCall = async () => {
    await getClientInfo(officeInfo, submitData, {
      success: (res) => {
        setClientInfo(res)
        if (res.deviceType === 'analog') {
          setImagePath(res.checkList[listNum]?.filePath || '')
        } else if (res.deviceType === 'digital') {
          setDigitalNum(res.checkList[listNum]?.checkValue || '')
        }
      },

      fail: (err: any) => {
        failAlert()
      },
      loading: (loading: boolean) => {},
    })
  }

  const handlePrevious = () => {
    if (listNum === clientInfo?.checkList?.length - 1) {
      validationAlert('마지막 검침 데이터입니다.', 'warning')
      return false
    } else {
      if (clientInfo?.checkList[listNum + 1] !== null) {
        setListNum(listNum + 1)
        if (clientInfo.deviceType === 'analog') {
          setImagePath(clientInfo?.checkList[listNum + 1]?.filePath || '')
        } else if (clientInfo.deviceType === 'digital') {
          setDigitalNum(clientInfo?.checkList[listNum + 1]?.checkValue || '')
        }
      } else {
        return false
      }
    }
  }

  const handleNext = () => {
    if (listNum === 0) {
      validationAlert('가장 최근 검침 데이터입니다.', 'warning')
      return false
    } else {
      if (clientInfo?.checkList[listNum - 1] !== null) {
        setListNum(listNum - 1)
        if (clientInfo.deviceType === 'analog') {
          setImagePath(clientInfo?.checkList[listNum - 1]?.filePath || '')
        } else if (clientInfo.deviceType === 'digital') {
          setDigitalNum(clientInfo?.checkList[listNum - 1]?.checkValue || '')
        }
      } else {
        return false
      }
    }
  }

  useEffect(() => {
    getClientInfoCall()
  }, [date])

  useEffect(() => {
    if (clientInfo && clientInfo.latitude && clientInfo.longitude) {
      let container = document?.getElementById('map')
      if (container) {
        const map = new window.kakao.maps.Map(container, {
          center: new window.kakao.maps.LatLng(clientInfo.latitude, clientInfo.longitude),
          mapTypeId: window.kakao.maps.MapTypeId.HYBRID,
          level: 1,
          draggable: false,
          scrollwheel: false,
          zoomable: false,
        })

        var markerPosition = new window.kakao.maps.LatLng(clientInfo.latitude, clientInfo.longitude)
        var marker = new window.kakao.maps.Marker({
          position: markerPosition,
          image: new window.kakao.maps.MarkerImage(yMarker, new window.kakao.maps.Size(40, 59)),
        })
        marker.setMap(map)
      }
    }
  }, [clientInfo])

  return (
    <>
      {!isMobileDevice() && (
        <div className='card flex-grow-1' id='client-page'>
          <div className='card-header '>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>수용가의 정보를 확인해보세요.</h3>
            </div>
          </div>
          {clientInfo ? (
            <div className='card-body p-9'>
              <div className={'container-fluid d-flex h-100 gap-4'}>
                <div className={'col-6'}>
                  <div id='map' style={{width: '100%', height: '100%'}} className={'rounded-3'} />
                </div>
                <div className={'col row px-0 mx-0 h-100'}>
                  <div className={'col d-flex gap-3'}>
                    <div className={'col-6 fw-bold fs-2'}>
                      <div className={'row px-0 mx-0 gap-6'}>
                        <h2 className={'text-center'} style={{fontSize: '24px'}}>
                          수용가 정보
                        </h2>
                        <TextField
                          variant={'outlined'}
                          value={clientInfo?.customerName}
                          label={'수용가 이름'}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <TextField
                          variant={'outlined'}
                          value={userInfo?.userName}
                          label={'수용가 번호'}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <TextField
                          variant={'outlined'}
                          value={clientInfo?.meterNumber}
                          label={'계량기 번호'}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <TextField
                          variant={'outlined'}
                          value={clientInfo?.address}
                          label={'수용가 주소'}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <TextField
                          variant={'outlined'}
                          value={clientInfo?.managerName}
                          label={'담당자 이름'}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </div>
                    </div>
                    <div className={'col-6 fw-bold fs-2'}>
                      <div className={'row px-0 mx-0 gap-6'}>
                        <h2 className={'text-center'} style={{fontSize: '24px'}}>
                          단말기 정보
                        </h2>
                        <TextField
                          variant={'outlined'}
                          value={clientInfo?.serialNumber}
                          label={'단말기 S/N'}
                          InputProps={{
                            readOnly: true,
                          }}
                        />

                        <h2
                          className={'text-center text-primary'}
                          style={{fontSize: '24px', marginTop: '20px'}}
                        >
                          검침 정보
                        </h2>

                        <>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label='날짜 선택'
                              onChange={(newDate) => {
                                setDate(dayjs(newDate).format('YYYYMMDD'))
                              }}
                              value={date}
                              minDate={dayjs().subtract(3, 'month')}
                              maxDate={dayjs()}
                              disableFuture={true}
                              inputFormat={'YYYY년 MM월 DD일'}
                              renderInput={(params) => (
                                <TextField {...params} variant={'outlined'} />
                              )}
                            />
                          </LocalizationProvider>
                          <div className={'text-center'}>
                            {clientInfo?.deviceType === 'analog' &&
                            clientInfo?.checkValue !== null ? (
                              <div style={{position: 'relative', width: '100%', height: '100%'}}>
                                <img
                                  src={envImagePath + imagePath}
                                  width={'100%'}
                                  height={'100%'}
                                  alt={`${clientInfo?.customerName}님의 검침 이미지`}
                                  style={{
                                    transform:
                                      (Number(clientInfo?.rotate) > 90 &&
                                        Number(clientInfo?.rotate) < 270) ||
                                      (Number(clientInfo?.rotate) < -90 &&
                                        Number(clientInfo?.rotate) > -270)
                                        ? `rotate(${clientInfo?.rotate}deg)`
                                        : `rotate(0deg)`,
                                  }}
                                />
                              </div>
                            ) : null}

                            {clientInfo?.deviceType === 'digital' ? (
                              <TextField
                                variant={'outlined'}
                                inputProps={{style: {textAlign: 'center'}}}
                                value={digitalNum}
                                label={'검침값'}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            ) : null}
                          </div>

                          {clientInfo?.checkValue === null ? (
                            <TextField
                              variant={'outlined'}
                              inputProps={{style: {textAlign: 'center'}}}
                              label={`${dayjs(date).format('YYYY년 MM월 DD일')} 검침 대기중`}
                              placeholder={'당일 검침데이터가 존재하지 않습니다.'}
                              InputProps={{readOnly: true}}
                              fullWidth
                            />
                          ) : (
                            <TextField
                              variant={'standard'}
                              inputProps={{style: {textAlign: 'center'}}}
                              value={
                                clientInfo?.checkList[listNum]?.createAt
                                  ? dayjs(clientInfo?.checkList[listNum].createAt)?.format(
                                      'YYYY년 MM월 DD일 HH시 mm분'
                                    )
                                  : '검침 데이터 확인중'
                              }
                              label={'검침일시'}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          )}
                        </>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '10px',
                          }}
                        >
                          <Button
                            disabled={imagePath === ''}
                            variant='contained'
                            sx={{
                              backgroundColor:
                                listNum !== clientInfo?.checkList?.length - 1
                                  ? '#007bff'
                                  : '#778877',

                              color: '#fff',
                              '&:hover': {
                                backgroundColor:
                                  listNum !== clientInfo?.checkList?.length - 1
                                    ? '#0056b3'
                                    : '#778877',
                              },
                            }}
                            onClick={handlePrevious}
                          >
                            이전
                          </Button>
                          <Button
                            disabled={imagePath === ''}
                            variant='contained'
                            sx={{
                              backgroundColor: listNum !== 0 ? '#007bff' : '#778877',
                              color: '#fff',
                              '&:hover': {backgroundColor: listNum !== 0 ? '#0056b3' : '#778877'},
                            }}
                            onClick={handleNext}
                          >
                            다음
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='notice align-self-center d-flex  bg-light-primary rounded border-primary  border border-dashed p-2 bottom-0  w-100 '
                    style={{height: '170px'}}
                  >
                    <KTSVG
                      path='icons/duotune/general/gen044.svg'
                      className='svg-icon-2tx svg-icon-warning me-4'
                    />
                    <div className='d-flex flex-stack flex-grow-1'>
                      <div className='fw-bold'>
                        <h4 className='text-gray-800 fw-bold fs-2'>
                          <u>미터리움 일일 검침</u>
                        </h4>
                        <div className='fs-4 text-gray-600 row gap-4'>
                          <span>
                            미터리움[스마트 디지털이미지 원격검침시스템 플랫폼]은 개인정보처리방침을
                            준수하고 있으며, 회원가입 시 동의한 이용약관에 의거하여 서비스를
                            제공합니다.
                          </span>
                          <a
                            className='fw-bolder'
                            href='https://young-it.co.kr'
                            target={'_blank'}
                            rel='noreferrer'
                          >
                            YOUNG-IT .inc
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='card-body p-9'>
              <div className='d-flex justify-content-center align-items-center h-100'>
                <div className='spinner spinner-primary spinner-lg mr-15'></div>
                <div className={'row gap-4 align-items-center justify-content-center text-center'}>
                  <div className={'spinner-border spinner-border-m'}></div>
                  <span className='text-primary fs-2 fw-bold'>데이터를 불러오고 있습니다.</span>
                  <span className={'text-danger fw-bold fs-5'}>
                    대기가 장시간 지연될경우 영아이티 개발팀에 문의해주세요.
                  </span>
                  <span className={'fs-5'}>문의 : 042-932-0170</span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {isMobileDevice() && (
        <div className='card flex-grow-1 my-3' id='client-page'>
          <div className='card-header '>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>수용가의 정보를 확인해보세요.</h3>
            </div>
          </div>
          {clientInfo ? (
            <div className='card-body p-6'>
              <div className={'container-fluid  '}>
                <div className={'row px-0 mx-0 gap-6'}>
                  <h2 className={'text-center text-primary'} style={{fontSize: '24px'}}>
                    수용가 정보
                  </h2>
                  <TextField
                    variant={'outlined'}
                    value={clientInfo?.customerName}
                    label={'수용가 이름'}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    variant={'outlined'}
                    value={userInfo?.userName}
                    label={'수용가 번호'}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    variant={'outlined'}
                    value={clientInfo?.meterNumber}
                    label={'계량기 번호'}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <div className={'px-0 mx-0 row gap-2'}>
                    <TextField
                      variant={'outlined'}
                      value={clientInfo?.address}
                      label={'수용가 주소'}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <div
                      id='map'
                      style={{width: '100%', height: '200px'}}
                      className={'rounded-3'}
                    />
                  </div>
                  <TextField
                    variant={'outlined'}
                    value={clientInfo?.managerName}
                    label={'담당자 이름'}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
                <div className={'row px-0 mx-0 gap-6'}>
                  <h2
                    className={'text-center text-primary'}
                    style={{fontSize: '24px', marginTop: '20px'}}
                  >
                    단말기 정보
                  </h2>
                  <TextField
                    variant={'outlined'}
                    value={clientInfo?.serialNumber}
                    label={'단말기 S/N'}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <h2
                    className={'text-center text-primary'}
                    style={{fontSize: '24px', marginTop: '20px'}}
                  >
                    검침 정보
                  </h2>

                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label='날짜 선택'
                        onChange={(newDate) => {
                          setDate(dayjs(newDate).format('YYYYMMDD'))
                        }}
                        value={date}
                        minDate={dayjs().subtract(3, 'month')}
                        maxDate={dayjs()}
                        disableFuture={true}
                        inputFormat={'YYYY년 MM월 DD일'}
                        renderInput={(params) => <TextField {...params} variant={'outlined'} />}
                      />
                    </LocalizationProvider>
                    <div className={'text-center'}>
                      {clientInfo?.deviceType === 'analog' && clientInfo?.checkValue !== null ? (
                        <div style={{position: 'relative', width: '100%', height: '100%'}}>
                          <img
                            src={envImagePath + imagePath}
                            width={'100%'}
                            height={'100%'}
                            alt={`${clientInfo?.customerName}님의 검침 이미지`}
                            style={{
                              transform:
                                (Number(clientInfo?.rotate) > 90 &&
                                  Number(clientInfo?.rotate) < 270) ||
                                (Number(clientInfo?.rotate) < -90 &&
                                  Number(clientInfo?.rotate) > -270)
                                  ? `rotate(${clientInfo?.rotate}deg)`
                                  : `rotate(0deg)`,
                            }}
                          />
                        </div>
                      ) : null}

                      {clientInfo?.deviceType === 'digital' ? (
                        <TextField
                          variant={'outlined'}
                          inputProps={{style: {textAlign: 'center'}}}
                          value={digitalNum}
                          label={'검침값'}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      ) : null}
                    </div>

                    {clientInfo?.checkValue === null ? (
                      <TextField
                        variant={'outlined'}
                        inputProps={{style: {textAlign: 'center'}}}
                        label={`${dayjs(date).format('YYYY년 MM월 DD일')} 검침 대기중`}
                        placeholder={'당일 검침데이터가 존재하지 않습니다.'}
                        InputProps={{readOnly: true}}
                        fullWidth
                      />
                    ) : (
                      <TextField
                        variant={'standard'}
                        inputProps={{style: {textAlign: 'center'}}}
                        value={
                          clientInfo?.checkList[listNum]?.createAt
                            ? dayjs(clientInfo?.checkList[listNum].createAt)?.format(
                                'YYYY년 MM월 DD일 HH시 mm분'
                              )
                            : '검침 데이터 확인중'
                        }
                        label={'검침일시'}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  </>

                  <div
                    style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}
                  >
                    <Button
                      disabled={imagePath === ''}
                      variant='contained'
                      sx={{
                        backgroundColor:
                          listNum !== clientInfo?.checkList?.length - 1 ? '#007bff' : '#778877',

                        color: '#fff',
                        '&:hover': {
                          backgroundColor:
                            listNum !== clientInfo?.checkList?.length - 1 ? '#0056b3' : '#778877',
                        },
                      }}
                      onClick={handlePrevious}
                    >
                      이전
                    </Button>
                    <Button
                      disabled={imagePath === ''}
                      variant='contained'
                      sx={{
                        backgroundColor: listNum !== 0 ? '#007bff' : '#778877',
                        color: '#fff',
                        '&:hover': {backgroundColor: listNum !== 0 ? '#0056b3' : '#778877'},
                      }}
                      onClick={handleNext}
                    >
                      다음
                    </Button>
                  </div>
                </div>

                <div
                  className='notice align-self-center d-flex  bg-light-primary my-5 rounded border-primary  border border-dashed p-2 bottom-0  w-100 '
                  // style={{height: '170px'}}
                >
                  <KTSVG
                    path='icons/duotune/general/gen044.svg'
                    className='svg-icon-2tx svg-icon-warning me-4'
                  />
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <h4 className='text-gray-800 fw-bold fs-2'>
                        <u>미터리움 일일 검침</u>
                      </h4>
                      <div className='fs-4 text-gray-600 row gap-4'>
                        <span>
                          미터리움[스마트 디지털이미지 원격검침시스템 플랫폼]은 개인정보처리방침을
                          준수하고 있으며, 회원가입 시 동의한 이용약관에 의거하여 서비스를
                          제공합니다.
                        </span>
                        <a
                          className='fw-bolder'
                          href='https://young-it.co.kr'
                          target={'_blank'}
                          rel='noreferrer'
                        >
                          YOUNG-IT .inc
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='card-body p-9'>
              <div className='d-flex justify-content-center align-items-center h-100'>
                <div className='spinner spinner-primary spinner-lg mr-15'></div>
                <div className={'row gap-4 align-items-center justify-content-center text-center'}>
                  <div className={'spinner-border spinner-border-m'}></div>
                  <span className='text-primary fs-2 fw-bold'>데이터를 불러오고 있습니다.</span>
                  <span className={'text-danger fw-bold fs-5'}>
                    대기가 장시간 지연될경우 영아이티 개발팀에 문의해주세요.
                  </span>
                  <span className={'fs-5'}>문의 : 042-932-0170</span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}
export default CustomerClient
//////

// import {TextField} from '@mui/material'
// import {envImagePath} from 'GLOBAL/envVariables'
// import {isMobileDevice} from '_metronic/assets/ts/_utils'
// import {KTSVG} from '_metronic/helpers'
// import {yMarker} from 'assets/imageDeclear'
// import dayjs from 'dayjs'
// import {useEffect, useState} from 'react'
// import {useSelector} from 'react-redux'
// import {failAlert} from 'utils/CustomAlert/Alert'
// import {getClientInfo} from 'utils/apiCalling/api'

// const CustomerClient = () => {
//   const officeInfo = useSelector((state: any) => state.officeInfo)
//   const userInfo = useSelector((state: any) => state.userInfo.userInfo)
//   const [clientInfo, setClientInfo] = useState<any>(null)
//   const submitData = {
//     id: userInfo?.userId,
//     customerNumber: userInfo?.userName,
//     date: dayjs().format('YYYYMMDD'),
//   }
//   const getClientInfoCall = async () => {
//     await getClientInfo(officeInfo, submitData, {
//       success: (res) => {
//         setClientInfo(res)
//       },
//       fail: (err: any) => {
//         failAlert()
//       },
//       loading: (loading: boolean) => {},
//     })
//   }
//   useEffect(() => {
//     getClientInfoCall()
//   }, [])

//   useEffect(() => {
//     if (clientInfo && clientInfo.latitude && clientInfo.longitude) {
//       let container = document?.getElementById('map')
//       if (container) {
//         const map = new window.kakao.maps.Map(container, {
//           center: new window.kakao.maps.LatLng(clientInfo.latitude, clientInfo.longitude),
//           mapTypeId: window.kakao.maps.MapTypeId.HYBRID,
//           level: 1,
//           draggable: false,
//           scrollwheel: false,
//           zoomable: false,
//         })

//         var markerPosition = new window.kakao.maps.LatLng(clientInfo.latitude, clientInfo.longitude)

//         var marker = new window.kakao.maps.Marker({
//           position: markerPosition,
//           image: new window.kakao.maps.MarkerImage(yMarker, new window.kakao.maps.Size(40, 59)),
//         })

//         marker.setMap(map)
//       }
//     }
//   }, [clientInfo])
//   return (
//     <>
//       {!isMobileDevice() && (
//         <div className='card flex-grow-1' id='client-page'>
//           <div className='card-header '>
//             <div className='card-title m-0'>
//               <h3 className='fw-bolder m-0'>수용가의 정보를 확인해보세요.</h3>
//             </div>
//           </div>
//           {clientInfo ? (
//             <div className='card-body p-9'>
//               <div className={'container-fluid d-flex h-100 gap-4'}>
//                 <div className={'col-6'}>
//                   <div id='map' style={{width: '100%', height: '100%'}} className={'rounded-3'} />
//                 </div>
//                 <div className={'col row px-0 mx-0 h-100'}>
//                   <div className={'col d-flex gap-3'}>
//                     <div className={'col-6 fw-bold fs-2'}>
//                       <div className={'row px-0 mx-0 gap-6'}>
//                         <h2 className={'text-center'} style={{fontSize: '24px'}}>
//                           수용가 정보
//                         </h2>
//                         <TextField
//                           variant={'outlined'}
//                           value={clientInfo?.customerName}
//                           label={'수용가 이름'}
//                           InputProps={{
//                             readOnly: true,
//                           }}
//                         />
//                         <TextField
//                           variant={'outlined'}
//                           value={userInfo?.userName}
//                           label={'수용가 번호'}
//                           InputProps={{
//                             readOnly: true,
//                           }}
//                         />
//                         <TextField
//                           variant={'outlined'}
//                           value={clientInfo?.meterNumber}
//                           label={'계량기 번호'}
//                           InputProps={{
//                             readOnly: true,
//                           }}
//                         />
//                         <TextField
//                           variant={'outlined'}
//                           value={clientInfo?.address}
//                           label={'수용가 주소'}
//                           InputProps={{
//                             readOnly: true,
//                           }}
//                         />
//                         <TextField
//                           variant={'outlined'}
//                           value={clientInfo?.managerName}
//                           label={'담당자 이름'}
//                           InputProps={{
//                             readOnly: true,
//                           }}
//                         />
//                       </div>
//                     </div>
//                     <div className={'col-6 fw-bold fs-2'}>
//                       <div className={'row px-0 mx-0 gap-6'}>
//                         <h2 className={'text-center'} style={{fontSize: '24px'}}>
//                           단말기 정보
//                         </h2>
//                         <TextField
//                           variant={'outlined'}
//                           value={clientInfo?.serialNumber}
//                           label={'단말기 S/N'}
//                           InputProps={{
//                             readOnly: true,
//                           }}
//                         />
//                         <h2 className={'text-center'} style={{fontSize: '24px', marginTop: '20px'}}>
//                           검침 정보
//                         </h2>
//                         {clientInfo?.checkValue === null ? (
//                           <TextField
//                             variant={'outlined'}
//                             inputProps={{style: {textAlign: 'center'}}}
//                             label={`${dayjs().format('YYYY년 MM월 DD일')} 검침 대기중`}
//                             placeholder={'당일 검침데이터가 존재하지 않습니다.'}
//                             InputProps={{readOnly: true}}
//                             fullWidth
//                           />
//                         ) : (
//                           <TextField
//                             variant={'standard'}
//                             inputProps={{style: {textAlign: 'center'}}}
//                             value={dayjs(clientInfo?.createAt)?.format(
//                               'YYYY년 MM월 DD일 HH시 mm분'
//                             )}
//                             label={'검침일시'}
//                             InputProps={{
//                               readOnly: true,
//                             }}
//                           />
//                         )}
//                         <div className={'text-center'}>
//                           {clientInfo?.deviceType === 'analog' &&
//                           clientInfo?.checkValue !== null ? (
//                             <img
//                               src={envImagePath + clientInfo?.filePath}
//                               width={'100%'}
//                               height={'100%'}
//                               alt={`${clientInfo?.customerName}님의 검침 이미지`}
//                               style={{
//                                 transform:
//                                   (Number(clientInfo?.rotate) > 90 &&
//                                     Number(clientInfo?.rotate) < 270) ||
//                                   (Number(clientInfo?.rotate) < -90 &&
//                                     Number(clientInfo?.rotate) > -270)
//                                     ? `rotate(${clientInfo?.rotate}deg)`
//                                     : `rotate(0deg)`,
//                               }}
//                             />
//                           ) : null}
//                           {clientInfo?.deviceType === 'digital' &&
//                           clientInfo?.checkValue !== null ? (
//                             <TextField
//                               variant={'outlined'}
//                               inputProps={{style: {textAlign: 'center'}}}
//                               value={clientInfo?.checkValue}
//                               label={'검침값'}
//                               InputProps={{
//                                 readOnly: true,
//                               }}
//                             />
//                           ) : null}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div
//                     className='notice align-self-center d-flex  bg-light-primary rounded border-primary  border border-dashed p-2 bottom-0  w-100 '
//                     style={{height: '170px'}}
//                   >
//                     <KTSVG
//                       path='icons/duotune/general/gen044.svg'
//                       className='svg-icon-2tx svg-icon-warning me-4'
//                     />
//                     <div className='d-flex flex-stack flex-grow-1'>
//                       <div className='fw-bold'>
//                         <h4 className='text-gray-800 fw-bold fs-2'>
//                           <u>미터리움 일일 검침</u>
//                         </h4>
//                         <div className='fs-4 text-gray-600 row gap-4'>
//                           <span>
//                             미터리움[스마트 디지털이미지 원격검침시스템 플랫폼]은 개인정보처리방침을
//                             준수하고 있으며, 회원가입 시 동의한 이용약관에 의거하여 서비스를
//                             제공합니다.
//                           </span>
//                           <a
//                             className='fw-bolder'
//                             href='https://young-it.co.kr'
//                             target={'_blank'}
//                             rel='noreferrer'
//                           >
//                             YOUNG-IT .inc
//                           </a>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ) : (
//             <div className='card-body p-9'>
//               <div className='d-flex justify-content-center align-items-center h-100'>
//                 <div className='spinner spinner-primary spinner-lg mr-15'></div>
//                 <div className={'row gap-4 align-items-center justify-content-center text-center'}>
//                   <div className={'spinner-border spinner-border-m'}></div>
//                   <span className='text-primary fs-2 fw-bold'>데이터를 불러오고 있습니다.</span>
//                   <span className={'text-danger fw-bold fs-5'}>
//                     대기가 장시간 지연될경우 영아이티 개발팀에 문의해주세요.
//                   </span>
//                   <span className={'fs-5'}>문의 : 042-932-0170</span>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       )}
//       {isMobileDevice() && (
//         <div className='card flex-grow-1 my-3' id='client-page'>
//           <div className='card-header '>
//             <div className='card-title m-0'>
//               <h3 className='fw-bolder m-0'>수용가의 정보를 확인해보세요.</h3>
//             </div>
//           </div>
//           {clientInfo ? (
//             <div className='card-body p-6'>
//               <div className={'container-fluid  '}>
//                 <div className={'row px-0 mx-0 gap-6'}>
//                   <h2 className={'text-center text-primary'} style={{fontSize: '24px'}}>
//                     수용가 정보
//                   </h2>
//                   <TextField
//                     variant={'outlined'}
//                     value={clientInfo?.customerName}
//                     label={'수용가 이름'}
//                     InputProps={{
//                       readOnly: true,
//                     }}
//                   />

//                   <TextField
//                     variant={'outlined'}
//                     value={userInfo?.userName}
//                     label={'수용가 번호'}
//                     InputProps={{
//                       readOnly: true,
//                     }}
//                   />
//                   <TextField
//                     variant={'outlined'}
//                     value={clientInfo?.meterNumber}
//                     label={'계량기 번호'}
//                     InputProps={{
//                       readOnly: true,
//                     }}
//                   />
//                   <div className={'px-0 mx-0 row gap-2'}>
//                     <TextField
//                       variant={'outlined'}
//                       value={clientInfo?.address}
//                       label={'수용가 주소'}
//                       InputProps={{
//                         readOnly: true,
//                       }}
//                     />
//                     <div
//                       id='map'
//                       style={{width: '100%', height: '200px'}}
//                       className={'rounded-3'}
//                     />
//                   </div>
//                   <TextField
//                     variant={'outlined'}
//                     value={clientInfo?.managerName}
//                     label={'담당자 이름'}
//                     InputProps={{
//                       readOnly: true,
//                     }}
//                   />
//                 </div>
//                 <div className={'row px-0 mx-0 gap-6'}>
//                   <h2
//                     className={'text-center text-primary'}
//                     style={{fontSize: '24px', marginTop: '20px'}}
//                   >
//                     단말기 정보
//                   </h2>
//                   <TextField
//                     variant={'outlined'}
//                     value={clientInfo?.serialNumber}
//                     label={'단말기 S/N'}
//                     InputProps={{
//                       readOnly: true,
//                     }}
//                   />
//                   <h2
//                     className={'text-center text-primary'}
//                     style={{fontSize: '24px', marginTop: '20px'}}
//                   >
//                     검침 정보
//                   </h2>
//                   {clientInfo?.checkValue === null ? (
//                     <TextField
//                       variant={'outlined'}
//                       inputProps={{style: {textAlign: 'center'}}}
//                       label={`${dayjs().format('YYYY년 MM월 DD일')} 검침 대기중`}
//                       placeholder={'당일 검침데이터가 존재하지 않습니다.'}
//                       InputProps={{readOnly: true}}
//                       fullWidth
//                     />
//                   ) : (
//                     <TextField
//                       variant={'standard'}
//                       inputProps={{style: {textAlign: 'center'}}}
//                       value={dayjs(clientInfo?.createAt)?.format('YYYY년 MM월 DD일 HH시 mm분')}
//                       label={'검침일시'}
//                       InputProps={{
//                         readOnly: true,
//                       }}
//                     />
//                   )}
//                   <div className={'text-center'}>
//                     {clientInfo?.deviceType === 'analog' && clientInfo?.checkValue !== null ? (
//                       <img
//                         src={envImagePath + clientInfo?.filePath}
//                         width={'100%'}
//                         height={'100%'}
//                         alt={`${clientInfo?.customerName}님의 검침 이미지`}
//                         style={{
//                           transform:
//                             (Number(clientInfo?.rotate) > 90 && Number(clientInfo?.rotate) < 270) ||
//                             (Number(clientInfo?.rotate) < -90 && Number(clientInfo?.rotate) > -270)
//                               ? `rotate(${clientInfo?.rotate}deg)`
//                               : `rotate(0deg)`,
//                         }}
//                       />
//                     ) : null}
//                     {clientInfo?.deviceType === 'digital' && clientInfo?.checkValue !== null ? (
//                       <TextField
//                         variant={'outlined'}
//                         inputProps={{style: {textAlign: 'center'}}}
//                         value={clientInfo?.checkValue}
//                         label={'검침값'}
//                         InputProps={{
//                           readOnly: true,
//                         }}
//                       />
//                     ) : null}
//                   </div>
//                 </div>

//                 <div
//                   className='notice align-self-center d-flex  bg-light-primary my-5 rounded border-primary  border border-dashed p-2 bottom-0  w-100 '
//                   // style={{height: '170px'}}
//                 >
//                   <KTSVG
//                     path='icons/duotune/general/gen044.svg'
//                     className='svg-icon-2tx svg-icon-warning me-4'
//                   />
//                   <div className='d-flex flex-stack flex-grow-1'>
//                     <div className='fw-bold'>
//                       <h4 className='text-gray-800 fw-bold fs-2'>
//                         <u>미터리움 일일 검침</u>
//                       </h4>
//                       <div className='fs-4 text-gray-600 row gap-4'>
//                         <span>
//                           미터리움[스마트 디지털이미지 원격검침시스템 플랫폼]은 개인정보처리방침을
//                           준수하고 있으며, 회원가입 시 동의한 이용약관에 의거하여 서비스를
//                           제공합니다.
//                         </span>
//                         <a
//                           className='fw-bolder'
//                           href='https://young-it.co.kr'
//                           target={'_blank'}
//                           rel='noreferrer'
//                         >
//                           YOUNG-IT .inc
//                         </a>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ) : (
//             <div className='card-body p-9'>
//               <div className='d-flex justify-content-center align-items-center h-100'>
//                 <div className='spinner spinner-primary spinner-lg mr-15'></div>
//                 <div className={'row gap-4 align-items-center justify-content-center text-center'}>
//                   <div className={'spinner-border spinner-border-m'}></div>
//                   <span className='text-primary fs-2 fw-bold'>데이터를 불러오고 있습니다.</span>
//                   <span className={'text-danger fw-bold fs-5'}>
//                     대기가 장시간 지연될경우 영아이티 개발팀에 문의해주세요.
//                   </span>
//                   <span className={'fs-5'}>문의 : 042-932-0170</span>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       )}
//     </>
//   )
// }
// export default CustomerClient
