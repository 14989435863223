import React, {useEffect, useState} from 'react'
import {Map, MapMarker, MapTypeControl, Polygon, ZoomControl} from 'react-kakao-maps-sdk'
import _ from 'lodash'
import markerImage from '../../install/images/maker.png'
import gyeonggi from '../polyLine/gyeonggi_poly.json'

const NoListKakaoMap = (props: any) => {
  return (
    <>
      <div id={'kakao-map'}>
        <Map // 지도를 표시할 Container
          center={{
            // 지도의 중심좌표
            lat: 36.4311526304608,
            lng: 127.390951737224,
          }}
          style={{
            // 지도의 크기
            width: '100%',
            height: window.innerWidth > 1900 ? 800 : 600,
          }}
          mapTypeId={kakao.maps.MapTypeId.ROADMAP}
          level={12} // 지도의 확대 레벨
          className={'rounded-3'}
          zoomable={true}
        >
          <MapTypeControl position={kakao.maps.ControlPosition.TOPLEFT} />
          <ZoomControl position={kakao.maps.ControlPosition.TOPRIGHT} />
          {/*<MapMarker*/}
          {/*  position={{*/}
          {/*    // 마커가 표시될 위치입니다*/}
          {/*    lat: 36.43113,*/}
          {/*    lng: 127.3909,*/}
          {/*    // lat: el?.latitude,*/}
          {/*    // lng: el?.longitude,*/}
          {/*  }}*/}
          {/*  image={{src: markerImage, size: {width: 30, height: 45}}}*/}
          {/*/>*/}
        </Map>
      </div>
    </>
  )
}
export default NoListKakaoMap
