import ForwardIcon from '@mui/icons-material/Forward'
import {Button} from '@mui/material'
import {Splide, SplideSlide} from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import {envBackHost} from 'GLOBAL/envVariables'
import dayjs from 'dayjs'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import _ from 'lodash'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {leakModelController} from 'utils/const/globalConst'
import {isMobileDevice} from '../../../assets/ts/_utils'
import FreezeImg from './FrozenWarning.png'
import GrandParent from './gradParent.png'
import InstallImg from './installImg.png'
import LeakImg from './leakImage.jpg'
const AsideStatusCard = () => {
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const [filter, setFilter] = useState<any>({
    searchOption: {
      customerNumber: false,
      customerName: false,
      managerName: false,
      address: false,
      memo: false,
    },
    searchData: '',
    date: dayjs().format('YYYYMMDD'),
  })
  const [leakFilter, setLeakFilter] = useState<any>({
    searchOption: {
      customerNumber: false,
      customerName: false,
      address: false,
    },
    searchData: '',
    date: dayjs().format('YYYYMMDD'),
  })
  const [customerInfoFilter, setCustomerInfoFilter] = useState<any>({
    searchOption: {
      customerNumber: false,
      customerName: false,
      address: false,
      managerName: false,
      memo: false,
      serialNumber: false,
      installerName: false,
    },
    searchData: '',
    deviceStatus: 'all',
    efosStatus: 'all',
    deviceType: 'all',
    batteryStatus: 'all',
    rowPerPage: 30,
    page: 1,
  })
  const [monitoringList, setMonitoringList] = useState<any>(null)
  const [leakList, setLeakList] = useState<any>(null)
  const [customerInfoCount, setCustomerInfoCount] = useState<any>(null)
  const [freezeCount, setFreezeCount] = useState<any>(0)
  const getCustomerInfo = () => {
    axiosWithAuth()
      .get(
        `${envBackHost}/${officeInfo?.value === 'yit' ? 'all' : officeInfo?.value}/${
          cooperInfo?.value
        }/customer/info`,
        {
          params: customerInfoFilter,
        }
      )
      .then((res) => {
        setCustomerInfoCount(res.data.count)
      })
  }
  const getMonitoringList = () => {
    axiosWithAuth()
      .get(
        `${envBackHost}/${officeInfo?.value === 'yit' ? 'all' : officeInfo?.value}/${
          cooperInfo?.value
        }/customer/monitoring`,
        {
          params: filter,
        }
      )
      .then((res) => {
        setMonitoringList(res.data.res)
      })
  }
  const speak = (text: any) => {
    const utterance = new SpeechSynthesisUtterance(text)
    utterance.lang = 'ko-KR'
    utterance.voice = speechSynthesis.getVoices()[0]
    utterance.pitch = 1.3 // 0 to 2
    utterance.rate = 1.5 // 0.1 to 10
    utterance.volume = 1 // 0 to 1
    if (text) {
      window.speechSynthesis.speak(utterance)
    }
  }

  const getLeakInfo = () => {
    if (officeInfo?.value !== 'yit' || officeInfo?.value !== 'all') {
      axiosWithAuth()
        .get(
          `${envBackHost}/${officeInfo?.value === 'yit' ? 'all' : officeInfo?.value}/${
            cooperInfo?.value
          }/customer/leak`,
          {
            params: leakFilter,
          }
        )
        .then(async (res) => {
          if (res && userInfo?.userId) {
            setLeakList(res.data.res)
          }
        })
    }
  }

  const getLeakInfos = () => {
    if (officeInfo?.value !== 'yit') {
      axiosWithAuth()
        .get(
          `${envBackHost}/${officeInfo?.value === 'yit' ? 'all' : officeInfo?.value}/${
            cooperInfo?.value
          }/customer/leakTemp`,
          {
            params: leakFilter,
          }
        )
        .then(async (res) => {
          if (res && userInfo?.userId) {
            setLeakList(res.data.res)
          }
        })
    }
  }

  const getFrozenStatus = () => {
    axiosWithAuth()
      .get(
        `${envBackHost}/${officeInfo?.value === 'yit' ? 'all' : officeInfo?.value}/${
          cooperInfo.value
        }/dashboard/freezewarningcount`
      )
      .then((res) => {
        let count = 0
        _.map(res.data.res, (el, idx) => {
          if (el.freezeWarningTypeName !== '양호') {
            count = count + el.freezeWarningCount
          }
        })

        setFreezeCount(count)
      })
  }

  useEffect(() => {
    getFrozenStatus()
    getMonitoringList()
    getCustomerInfo()
    if (userInfo?.accountType?.includes('admin') || userInfo?.accountType?.includes('sys')) {
      if (leakModelController.model === '1') {
        getLeakInfo()
      }

      if (leakModelController.model === '2') {
        getLeakInfos()
      }
    }
  }, [officeInfo, cooperInfo])
  return (
    <>
      {!isMobileDevice() &&
        (userInfo?.accountType.includes('sys') || userInfo?.accountType.includes('admin')) && (
          <div className={'row gap-3 px-0 mx-0'}>
            <Splide
              options={{
                rewind: true,
                autoplay: true,
                infinite: true,
                speed: 3000,
                interval: 5000,
                height: 240,
              }}
            >
              {officeInfo?.value !== 'yit' && (
                <SplideSlide>
                  <div
                    className={' bg-white align-items-stretch '}
                    style={{border: '2px solid red', borderRadius: '12px'}}
                  >
                    <div className={'p-2 py-3 '}>
                      <div
                        className={'d-flex justify-content-between align-items-center'}
                        id={'leak-header'}
                      >
                        <span className={'text-danger fs-3 fw-bold'}>누수 의심현황</span>
                        <span className={'text-muted fw-bold'}>
                          {dayjs().format('YYYY-MM-DD')} 기준
                        </span>
                      </div>
                      <div className={'text-center'}>
                        <img src={LeakImg} alt={'leak'} style={{width: '70%'}} />
                      </div>
                      <div className={'row px-6 fs-4 fw-bold  '}>
                        <div className={'d-flex justify-content-around'}>
                          <span>누수 의심</span>
                          <span>
                            {leakList?.length !== undefined
                              ? `( ${leakList?.length}건 )`
                              : '조회 중'}
                          </span>
                        </div>
                        <div className={'text-center mt-2'}>
                          <Link to={'/customer/customer-leakage'}>
                            <Button
                              variant={'contained'}
                              size={'small'}
                              color={'error'}
                              endIcon={<ForwardIcon />}
                            >
                              바로가기
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </SplideSlide>
              )}
              <SplideSlide>
                <div
                  className={' bg-white align-items-stretch '}
                  style={{border: '2px solid green', borderRadius: '12px'}}
                >
                  <div className={'p-2 py-3'}>
                    <div
                      className={'d-flex justify-content-between align-items-center'}
                      id={'leak-header'}
                    >
                      <span className={' fs-3 fw-bold'} style={{color: '#128d00'}}>
                        건강모니터링
                      </span>
                      <span className={'text-muted fw-bold'}>
                        {dayjs().format('YYYY-MM-DD')} 기준
                      </span>
                    </div>
                    <div className={'text-center'}>
                      <img src={GrandParent} alt={'grand-parent'} style={{width: '45%'}} />
                    </div>
                    <div className={'row px-6 fs-4 fw-bold  '}>
                      <div className={'d-flex justify-content-around mt-2'}>
                        <span>모니터링</span>
                        <span>
                          {monitoringList?.length !== undefined
                            ? `( ${monitoringList?.length}건 )`
                            : '조회 중'}
                        </span>
                      </div>
                      <div className={'text-center mt-2'}>
                        <Link to={'/customer/activity'}>
                          <Button
                            variant={'contained'}
                            size={'small'}
                            color={'success'}
                            endIcon={<ForwardIcon />}
                          >
                            바로가기
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div
                  className={' bg-white align-items-stretch '}
                  style={{border: '2px solid #C5B358', borderRadius: '12px'}}
                >
                  <div className={'p-2 py-3'}>
                    <div
                      className={'d-flex justify-content-between align-items-center'}
                      id={'leak-header'}
                    >
                      <span className={' fs-3 fw-bold'} style={{color: '#a6a919'}}>
                        설치현황
                      </span>
                      <span className={'text-muted fw-bold'}>
                        {dayjs().format('YYYY-MM-DD')} 기준
                      </span>
                    </div>
                    <div className={'text-center'}>
                      <img src={InstallImg} alt={'grand-parent'} style={{width: '45%'}} />
                    </div>
                    <div className={'row px-4 fs-4 fw-bold px-0  '}>
                      <div className={'d-flex justify-content-around mt-2'}>
                        <span
                          style={{
                            width: 80,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {officeInfo?.label}
                        </span>
                        <span>
                          {customerInfoCount !== undefined
                            ? `( ${customerInfoCount}건 )`
                            : '조회 중'}
                        </span>
                      </div>
                      <div className={'text-center mt-2'}>
                        <Link
                          to={
                            userInfo?.accountType?.includes('sys')
                              ? '/customer/info'
                              : '/customer/customer-status'
                          }
                        >
                          <Button
                            variant={'contained'}
                            size={'small'}
                            sx={{backgroundColor: '#C5B358'}}
                            endIcon={<ForwardIcon />}
                          >
                            바로가기
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div
                  className={' bg-white align-items-stretch '}
                  style={{border: '2px solid #4788c4', borderRadius: '12px'}}
                >
                  <div className={'p-2 py-3'}>
                    <div
                      className={'d-flex justify-content-between align-items-center'}
                      id={'leak-header'}
                    >
                      <span className={' fs-3 fw-bold'} style={{color: '#4788c4'}}>
                        동파위험
                      </span>
                      <span className={'text-muted fw-bold'}>
                        {dayjs().format('YYYY-MM-DD')} 기준
                      </span>
                    </div>
                    <div className={'text-center'}>
                      <img
                        src={FreezeImg}
                        alt={'freeze'}
                        style={{width: '31%', marginRight: '25px', paddingTop: '10px'}}
                      />
                    </div>
                    <div className={'row px-4 fs-4 fw-bold px-0  '}>
                      <div className={'d-flex justify-content-between mt-2'}>
                        <span
                          style={{
                            width: 110,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          동파위험
                        </span>
                        <span>( {freezeCount}건 )</span>
                      </div>
                      <div className={'text-center mt-2'}>
                        <Link to={'/customer/freeze-warning'}>
                          <Button
                            variant={'contained'}
                            size={'small'}
                            sx={{backgroundColor: '#4788c4'}}
                            endIcon={<ForwardIcon />}
                          >
                            바로가기
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        )}
    </>
  )
}

export default AsideStatusCard
