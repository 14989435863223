import React, {useState} from 'react'
import {Button, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip} from '@mui/material'
import axios from 'axios'
import {useAuth} from '../../../_component/modules/auth'
import {envBackHost} from '../../../GLOBAL/envVariables'
import {resultAlert} from '../../../utils/CustomAlert/Alert'

const CodeModal = (props: any) => {
  const {getAsListCode, type} = props
  const {currentUser} = useAuth()
  const [submitData, setSubmitData] = useState<any>({
    workType: 'AS',
    codeType: '',
    editable: '',
    codeName: '',
    registerAccountId: currentUser?.id,
  })
  /* state */
  const myModal: any = document.getElementById('codeModal')
  myModal?.addEventListener('hidden.bs.modal', function () {
    setSubmitData({
      workType: 'AS',
      codeType: '',
      editable: '',
      codeName: '',
      registerAccountId: currentUser?.id,
    })
  })

  const buttonHandler = async () => {
    setSubmitData({...submitData, codeType: type})
    await axios
      .post(`${envBackHost}/workcode`, submitData)
      .then(async (res) => {
        await resultAlert(res.data)
        if (res.data.resultCode === 0) {
          document.getElementById('deleteCodeModalCloseButton')?.click()
          getAsListCode()
          return
        }
      })
      .catch(async (e) => {
        await resultAlert({resultCode: e.response.status})
      })
  }

  return (
    <div
      className='modal fade'
      id='codeModal'
      tabIndex={-1}
      aria-labelledby='codeModalLabel'
      aria-hidden='true'
      data-bs-backdrop={'true'}
    >
      <div className='modal-dialog modal-dialog-centered modal-sm'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h3 className='modal-title' id='codeModalLabel'>
              코드 생성
            </h3>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>

          <div className={'modal-body text-center'}>
            <div className={'row gap-4'}>
              <TextField
                variant={'outlined'}
                label={'코드명'}
                fullWidth
                value={submitData?.codeName}
                onChange={(e) => {
                  setSubmitData({...submitData, codeName: e.target.value})
                }}
              />
              <TextField
                variant={'outlined'}
                label={'작업코드'}
                fullWidth
                value={'AS'}
                aria-readonly={true}
              />
              <FormControl className={'col-lg-xl'}>
                <InputLabel id='demo-simple-select-label'>코드유형</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='customer-target'
                  label='코드유형'
                  value={submitData?.codeType}
                  onChange={(e: any) => setSubmitData({...submitData, codeType: e.target.value})}
                >
                  <MenuItem disabled value=''>
                    <em>코드 유형선택</em>
                  </MenuItem>
                  <MenuItem value={'cause'}>원인</MenuItem>
                  <MenuItem value={'result'}>결과</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={'col-lg-xl'}>
                <InputLabel id='demo-simple-select-label'>수정여부</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='customer-target'
                  label='수정여부'
                  onChange={(e: any) => setSubmitData({...submitData, editable: e.target.value})}
                  value={submitData?.editable}
                >
                  <MenuItem disabled value=''>
                    <em>수정여부 선택</em>
                  </MenuItem>
                  <MenuItem value={'true'}>수정 가능</MenuItem>
                  <MenuItem value={'false'}>수정 불가능</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant={'outlined'}
                label={'등록자'}
                fullWidth
                value={currentUser?.id}
                aria-readonly={true}
              />
            </div>
          </div>

          <div className='modal-footer d-flex gap-4'>
            <Button
              variant={'contained'}
              size={'large'}
              disabled={
                submitData?.codeName === '' ||
                submitData?.editable === '' ||
                submitData?.codeType === ''
              }
              onClick={() => {
                buttonHandler()
              }}
            >
              등록
            </Button>
            <Tooltip
              title={<span className={'fs-7 fw-bolder'}>바깥화면을 클릭해도 창이 닫힙니다.</span>}
              placement={'right'}
              arrow={true}
            >
              <Button
                id={'deleteCodeModalCloseButton'}
                variant={'contained'}
                color={'error'}
                size={'large'}
                data-bs-dismiss='modal'
                className={'pulse pulse-light'}
              >
                <span>취소</span>
                <span className={'pulse-ring  border-4'}></span>
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CodeModal
