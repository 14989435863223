/**
 * Cooper Select Actions
 */

export const cooperActionTypes = {
  COOPER_INFO_REQUEST: 'COOPER_INFO_REQUEST',
  COOPER_INFO_DELETE: 'COOPER_INFO_DELETE',
}

/**
 * Office Select Actions
 */
export const officeActionsTypes = {
  OFFICE_INFO_REQUEST: 'OFFICE_INFO_REQUEST',
  OFFICE_INFO_DELETE: 'OFFICE_INFO_DELETE',
}

export const userInfoActionTypes = {
  USER_INFO_REQUEST: 'USER_INFO_REQUEST',
  USER_INFO_DELETE: 'USER_INFO_DELETE',
}

export const pageSizeActionTypes = {
  PAGE_SIZE_UPDATE: 'PAGE_SIZE_UPDATE',
}

export const currentPositionTypes = {
  POSITION_UPDATE: 'POSITION_UPDATE',
}
