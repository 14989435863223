import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './connectors.redux'
import {isDev} from '../GLOBAL/envVariables'
import {persistStore} from 'redux-persist'
import {composeWithDevTools} from '@redux-devtools/extension'

// declare global {
//   interface Window {
//     __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
//   }
// }
window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
export const persistor = persistStore(store)

export type RootReducerType = ReturnType<typeof rootReducer>
const devTools = isDev && composeWithDevTools(applyMiddleware(thunk))
const composeEnhancers = devTools || compose

// const Store = createStore(rootReducer, composeEnhancers(applyMiddleware(mwPender())))
export default store
