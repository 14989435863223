/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import './aiText.css'
import noUiSlider from 'nouislider'
import {useLayout} from '../../core'
import {DefaultTitle} from './page-title/DefaultTitle'
import {ThemeModeSwitcher} from '../../../partials'
import {useAuth} from '../../../../_component/modules/auth'
import {Link, useNavigate} from 'react-router-dom'
import {Autocomplete, Badge, Box, Button, Modal, TextField, Typography} from '@mui/material'
import {isMobileDevice} from '../../../assets/ts/_utils'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import {useSelector} from 'react-redux'
import {useSpeechRecognition} from '../../../../helper/useSpeeechRecognition'
import MicIcon from '@mui/icons-material/Mic'
import logo from './youngit-white.png'
import OfficeAPK from './../../../../YITMETERARIRUM.apk'
import SecurityUpdateIcon from '@mui/icons-material/SecurityUpdate'
import './HeaderToolbar.css'
import './../../../../../src/pages/common/globalFontSize.css'
import ChatIcon from './chatIcon.png'
// @ts-ignore
import swal from 'sweetalert'
import ChatModal from '../../../../pages/common/ChatModal'
import AdminChatModal from '../../../../pages/common/AdminChatModal'
import {io} from 'socket.io-client'
import axios from 'axios'
import {wssBackHost} from '../../../../GLOBAL/envVariables'
// @ts-ignore
import Marquee from 'react-fast-marquee'

dayjs.locale('ko')

const HeaderToolbar = () => {
  const isMobile = isMobileDevice()
  const realTime = require('dayjs/plugin/relativeTime')
  const {classes} = useLayout()
  const [position, setPosition] = useState<{top: number; left: number}>({top: 0, left: 0})
  const {currentUser, logout} = useAuth()
  const navigate = useNavigate()
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const {result, startListening, stopListening, isListening, setIsListening} =
    useSpeechRecognition()
  const [count, setCount] = useState<any>(null)
  const [chatOpen, setChatOpen] = useState<boolean>(false)

  const chatModalOnOpen = () => {
    setChatOpen(true)
  }
  const chatModalOnClose = () => {
    setChatOpen(false)
  }
  const handleModalPosition = (e: MouseEvent) => {
    const x = e.clientX
    const y = e.clientY
    setPosition({
      top: y,
      left: x,
    })
  }
  useEffect(() => {
    const rangeSlider = document.querySelector('#kt_toolbar_slider')
    const rangeSliderValueElement = document.querySelector('#kt_toolbar_slider_value')

    if (!rangeSlider || !rangeSliderValueElement) {
      return
    }

    // @ts-ignore
    noUiSlider.create(rangeSlider, {
      start: [5],
      connect: [true, false],
      step: 1,
      format: {
        to: function (value) {
          const val = +value
          return Math.round(val).toString()
        },
        from: function (value) {
          return value
        },
      },
      range: {
        min: [1],
        max: [10],
      },
    })

    // @ts-ignore
    rangeSlider.noUiSlider.on('update', function (values, handle) {
      rangeSliderValueElement.innerHTML = values[handle]
    })

    const handle = rangeSlider.querySelector('.noUi-handle')
    if (handle) {
      handle.setAttribute('tabindex', '0')
    }

    // @ts-ignore
    handle.addEventListener('click', function () {
      // @ts-ignore
      this.focus()
    })

    // @ts-ignore
    handle.addEventListener('keydown', function (event) {
      // @ts-ignore
      const value = Number(rangeSlider.noUiSlider.get())
      // @ts-ignore
      switch (event.which) {
        case 37:
          // @ts-ignore
          rangeSlider.noUiSlider.set(value - 1)
          break
        case 39:
          // @ts-ignore
          rangeSlider.noUiSlider.set(value + 1)
          break
      }
    })
    return () => {
      // @ts-ignore
      rangeSlider.noUiSlider.destroy()
    }
  }, [])
  const handleStart = () => {
    startListening()
  }
  const getAllMessageList = async () => {
    await axios
      .get(
        `${wssBackHost}/chat/count?id=${
          userInfo?.accountType?.includes('sys') ? 'admin' : userInfo?.userName
        }`,
        {
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
          },
        }
      )
      .then((res) => {
        setCount(res?.data?.length)
      })
  }
  // useEffect(() => {
  //   const socket = io(`${wssBackHost}`)
  //   socket.on('connect', () => {
  //     // console.log('ws connect')
  //     getAllMessageList()
  //   })
  //   socket.on('disconnect', () => {
  //     // console.log('disconnect')
  //   })
  //   socket.on('message', async (data) => {
  //     getAllMessageList()
  //   })
  // }, [])
  useEffect(() => {
    if (result.isFinal) {
      if (result.transcript.includes('검침 리포트')) {
        navigate('/customer/check-report')
      }
      if (result.transcript.includes('수용가 등록')) {
        navigate('/customer/register')
      }
      if (result.transcript.includes('최소유량')) {
        navigate('/customer/minimum-flow')
      }
      if (
        result.transcript.includes('수용가 정보 관리') ||
        result.transcript.includes('수용가 정보') ||
        result.transcript.includes('수용가 정보관리') ||
        result.transcript.includes('수용과 정보 관리') ||
        result.transcript.includes('수용과 정보관리')
      ) {
        navigate('/customer/info')
      }
      if (result.transcript.includes('건강 모니터링')) {
        navigate('/customer/activity')
      }
      if (result.transcript.includes('수용가 현황')) {
        navigate('/customer/customer-status')
      }
      if (
        result.transcript.includes('누수 의심') ||
        result.transcript.includes('누수의심') ||
        result.transcript.includes('누수')
      ) {
        navigate('/customer/customer-leakage')
      }
      if (result.transcript.includes('단말기 등록') || result.transcript.includes('단말기등록')) {
        navigate('/device/register')
      }
      if (result.transcript.includes('단말기 검수') || result.transcript.includes('단말기검수')) {
        navigate('/device/allocation')
      }
      if (result.transcript.includes('단말기 할당') || result.transcript.includes('단말기할당')) {
        navigate('/device/allocation')
      }
      if (
        result.transcript.includes('단말기 정보 관리') ||
        result.transcript.includes('단말기 정보관리') ||
        result.transcript.includes('단말기 정보')
      ) {
        navigate('/device/info')
      }
      if (
        result.transcript.includes('단말기 수신 영상') ||
        result.transcript.includes('단말기 수신영상')
      ) {
        navigate('/device/images')
      }
    }
  }, [result])
  // useEffect(() => {
  //   getAllMessageList()
  // }, [])
  return (
    <>
      {!isMobile && (
        <>
          <div className='toolbar d-flex align-items-stretch'>
            {/* begin::Toolbar container */}
            <div
              className={`${classes.headerContainer.join(
                ' '
              )} py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between`}
            >
              <DefaultTitle />
              {/* <div*/}
              {/*  className='w-25 d-flex flex-column flex-lg-row align-items-lg-stretch my-2'*/}
              {/*  style={{*/}
              {/*    backgroundColor: 'rgb(25, 25, 25)',*/}
              {/*    border: '2px solid rgb(25, 25, 25)',*/}
              {/*    borderRadius: '10px'*/}
              {/*  }}*/}
              {/*>*/}
              {/*<div*/}
              {/*  className='w-100 m-1 d-flex text-warning align-items-center fs-4 fw-bold'*/}
              {/*  style={{*/}
              {/*    border: '2px solid white',*/}
              {/*    borderRadius: '10px'*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <Marquee>*/}
              {/*    <div*/}
              {/*      style={{*/}
              {/*        color: '#ffef45'*/}

              {/*      }}*/}
              {/*    >*/}
              {/*      <span className='ms-lg-15 me-lg-15'>*/}
              {/*        [ 알림 ] 동파경보 1 건 ({dayjs().format('YYYY-MM-DD')} 기준)*/}
              {/*      </span>*/}
              {/*      <span className='ms-lg-15 me-lg-15'>*/}
              {/*        [ 알림 ] 누수의심 1 건 ({dayjs().format('YYYY-MM-DD')} 기준)*/}
              {/*      </span>*/}
              {/*    </div>*/}
              {/*  </Marquee>*/}

              {/*</div>*/}
              {/*</div>*/}
              <div
                className='d-flex align-items-stretch overflow-auto pt-3 pt-lg-0 header-dynamic-font-size'
                // style={{fontSize: '15px'}}
              >
                {/* {(userInfo?.accountType?.includes('sys') ||
                  userInfo?.accountType?.includes('gov')) && (
                  <div className='d-flex align-items-center'>
                    <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
                    <div
                      className={'d-flex gap-2 text-center cursor-pointer'}
                      onClick={(e: any) => {
                        chatModalOnOpen()
                        handleModalPosition(e)
                      }}
                    >
                      <Badge badgeContent={count} color='error' max={15}>
                        <img src={ChatIcon} width={40} alt={'chat-icon'} style={{color: 'blue'}} />
                      </Badge>
                    </div>
                  </div>
                )} */}

                <div className='d-flex align-items-center'>
                  <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
                  <div className={'d-flex gap-2 text-center'}>
                    <a
                      className='text-gray-700  fw-bolder d-none d-sm-block text-hover-primary'
                      href={'https://young-it.co.kr'}
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      영아이티 홈페이지
                    </a>
                  </div>
                </div>

                <div className='d-flex align-items-center'>
                  <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
                  <div className={'d-flex gap-2 text-center'}>
                    <span className=' text-gray-700 fw-bolder d-none d-sm-block'>
                      {dayjs().format('YYYY년 MM월 DD일 (ddd)')}
                    </span>
                  </div>
                </div>

                <div className='d-flex align-items-center'>
                  <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
                  <span className=' text-gray-700 fw-bolder d-none d-sm-block'>
                    안녕하세요,
                    <strong className={'mx-2 text-hover-primary cursor-pointer '}>
                      {userInfo?.userName}
                    </strong>
                    님
                  </span>
                </div>

                <div className='d-flex align-items-center'>
                  <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
                  <span className=' text-gray-700 fw-bolder d-none d-sm-block'>
                    권한 -
                    <strong
                      className={'mx-2 text-hover-primary cursor-pointer '}
                      // onClick={() =>  }
                    >
                      {userInfo?.accountTypeName}
                    </strong>
                  </span>
                  <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
                </div>

                {/*<div className='d-flex align-items-center gap-2'>*/}
                {/*    <span className={' text-gray-700 fw-bolder '}>자동 로그아웃까지</span>*/}
                {/*    /!*<Timer/>*!/*/}

                {/*    <div className='bullet bg-secondary h-35px w-1px mx-5'></div>*/}
                {/*</div>*/}
                <div className={'d-flex align-items-center'}>
                  <a
                    onClick={async () => {
                      await swal({
                        title: '로그아웃',
                        content: {
                          element: 'div',
                          attributes: {
                            innerHTML:
                              '<div class="row"><div class="mb-4" style="text-align: center;">로그아웃 하시겠습니까?</div><div class="fw-bold" style="text-align: center;">스마트 디지털 이미지 원격검침 시스템</div><div class="fw-bold text-primary" style="text-align: center;">영아이티(주)</div></div>',
                          },
                        },

                        buttons: {
                          cancel: {
                            text: '취소',
                            value: false,
                            visible: true,
                            className: 'fw-bold text-dark',
                          },
                          confirm: {
                            text: '확인',
                            value: true,
                            visible: true,
                            className: 'fw-bold text-white bg-primary',
                          },
                        },
                        className: '',
                        icon: 'info',
                      }).then((value: any) => {
                        if (value) {
                          logout()
                          navigate('/auth')
                        }
                      })
                    }}
                    className='text-dark px-5 fw-bolder cursor-pointer text-hover-danger'
                  >
                    로그아웃
                  </a>
                </div>
              </div>
              {/* end::Toolbar container */}
            </div>
          </div>
        </>
      )}
      {isMobile && (
        <>
          <div className='toolbar d-flex align-items-stretch'>
            {/* begin::Toolbar container */}
            <div
              className={`${classes.headerContainer.join(
                ' '
              )} pt-3 pb-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between`}
            >
              <DefaultTitle />
              <div className={'d-flex align-items-center justify-content-center gap-10 mb-3'}>
                <span className={'fs-6 text-gray-700 fw-bolder'}>
                  {dayjs().format('YYYY년 MM월 DD일 (ddd)')}
                </span>

                <Button
                  variant={'contained'}
                  size={'small'}
                  color={'warning'}
                  sx={{fontSize: '1rem'}}
                  onClick={async () => {
                    await swal({
                      title: '로그아웃 하시겠습니까?',
                      buttons: {
                        cancel: {
                          text: '취소',
                          value: false,
                          visible: true,
                          className: 'fw-bold text-dark',
                        },
                        confirm: {
                          text: '확인',
                          value: true,
                          visible: true,
                          className: 'fw-bold text-white bg-primary',
                        },
                      },
                      icon: 'info',
                    }).then((value: any) => {
                      if (value) {
                        logout()
                        navigate('/auth')
                      }
                    })
                  }}
                >
                  {' '}
                  로그아웃
                </Button>
              </div>
              <div className={'d-flex gap-3 align-self-center w-100 mt-2'}>
                <div className='d-flex   w-100 justify-content-center'>
                  <div className='d-flex align-items-center'>
                    <div className='fs-6 text-gray-700 fw-bolder d-flex'>
                      <span style={{width: 70}}>안녕하세요,</span>
                      <span
                        className={
                          'ms-2 me-1 text-hover-primary cursor-pointer d-flex truncate-text '
                        }
                      >
                        {userInfo?.userName}
                      </span>
                      님
                    </div>
                  </div>
                </div>
                <div className='bullet bg-secondary h-25px w-2px mx-2'></div>
                <div className='d-flex   w-100 justify-content-center'>
                  <div className='d-flex align-items-center'>
                    <div className='fs-6 text-gray-700 fw-bolder d-flex'>
                      <span style={{width: 35}}>권한 -</span>
                      <span
                        className={'mx-2 text-hover-primary cursor-pointer d-flex truncate-text'}
                      >
                        {userInfo?.accountTypeName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/*{userInfo?.accountType?.includes('sys') ? (*/}
      {/*  <AdminChatModal open={chatOpen} onClose={chatModalOnClose} position={position} />*/}
      {/*) : (*/}
      {/*  <>*/}
      {/*    {userInfo.accountType?.includes('gov') && (*/}
      {/*      <ChatModal open={chatOpen} onClose={chatModalOnClose} position={position} />*/}
      {/*    )}*/}
      {/*  </>*/}
      {/*)}*/}
    </>
  )
}

export {HeaderToolbar}
