// import React, {useState} from 'react'
// import {Box, Button, IconButton, InputAdornment, TextField} from '@mui/material'
// import UninstallChooseCustomer from '../modules/UninstallChooseCustomer'
// import {toast, ToastContainer} from 'react-toastify'
// import {DataGridPro, GridColDef} from '@mui/x-data-grid-pro'
// import * as bootstrap from 'bootstrap'
// import _ from 'lodash'
// import CancelIcon from '@mui/icons-material/Cancel'
// import InstallDetailInfo from '../modules/installModalComponents/installDetailInfoModal'
// import SearchIcon from '@mui/icons-material/Search'
//
// const InstallAsStatus = (props: any) => {
//   const {
//     installAsCompleteList,
//     resetIndexHandler,
//     getAsCompleteInstallList,
//     asFilter,
//     setAsFilter,
//     getInstallCount,
//     getCompleteCount,
//   } = props
//   const [pageSize, setPageSize] = useState<number>(10)
//   const [installDetailInfo, setInstallDetailInfo] = useState<any>(null)
//
//   const columns: GridColDef[] = [
//     {
//       field: 'customerName',
//       headerName: '수용가 이름',
//       width: 150,
//       align: 'center',
//       headerAlign: 'center',
//     },
//     {
//       field: 'addressRoad',
//       headerName: '주소',
//       width: 270,
//       align: 'left',
//       headerAlign: 'left',
//       renderCell: (params) => {
//         return (
//           <div className={'d-flex'}>
//             <span>{params.row.addressRoad}</span>
//             {params.row.addressDetail !== null && <span> - {params.row.addressDetail}</span>}
//           </div>
//         )
//       },
//     },
//   ]
//   const rows = _.map(installAsCompleteList, (el, i) => {
//     return {
//       id: i + 1,
//       idx: el?.idx,
//       customerName: el?.customerName,
//       customerNumber: el?.customerNumber,
//       imei: el?.imei,
//       createAt: el?.createAt,
//       serialNumber: el?.serialNumber,
//       addressNumber: el?.addressNumber,
//       addressRoad: el?.addressRoad,
//       addressDetail: el?.addressDetail,
//       deviceType: el?.deviceType,
//     }
//   })
//   return (
//     <>
//       <div className={'container-fluid w-100 mb-5 px-0'}>
//         <div className={'card card-body '}>
//           <div className={'row gap-2 my-3 px-0 mx-0'}>
//             <div className={'d-flex gap-3 px-0 mx-0'}>
//               <TextField
//                 variant={'outlined'}
//                 id={'search-field'}
//                 className={'w-100'}
//                 placeholder={'수용가 검색'}
//                 fullWidth
//                 value={asFilter?.searchData}
//                 // onChange={(e) => searchCustomer(e)}
//                 onChange={(e) => {
//                   setAsFilter({
//                     ...asFilter,
//                     searchData: e.target.value,
//                   })
//                 }}
//                 onKeyDown={(e) => {
//                   if (e.key === 'Enter') {
//                     getAsCompleteInstallList()
//                   }
//                 }}
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position='end'>
//                       <IconButton
//                         aria-label='resetBtn'
//                         edge='end'
//                         onClick={() => {
//                           setAsFilter({...asFilter, searchData: ''})
//                           setTimeout(() => {
//                             document.getElementById('install-status-search')?.click()
//                           }, 100)
//                         }}
//                       >
//                         <CancelIcon />
//                       </IconButton>
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//               <Button
//                 variant={'contained'}
//                 size={'large'}
//                 type='button'
//                 id={'install-status-search'}
//                 onClick={() => {
//                   getAsCompleteInstallList()
//                 }}
//               >
//                 <SearchIcon />
//               </Button>
//             </div>
//             <span className={'fs-5 fw-bold text-danger'}>
//               입력한 정보로 이름 및 주소 모두 검색합니다.
//             </span>
//             <div
//               className={'mb-3 px-0 mx-0'}
//               id={'table-content'}
//               style={{height: 630, width: '100%'}}
//             >
//               <DataGridPro
//                 rows={rows}
//                 columns={columns}
//                 pageSize={pageSize}
//                 onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//                 rowsPerPageOptions={[10, 30, 50, 70, 100]}
//                 disableColumnMenu
//                 onCellClick={(params) => {
//                   // setPackageData(params)
//                   setInstallDetailInfo(params.row)
//                   const myModal = new bootstrap.Modal(
//                     document.getElementById('installDetailInfoModal') as HTMLElement,
//                     {
//                       keyboard: false,
//                       backdrop: true,
//                     }
//                   )
//                   myModal.show()
//                 }}
//                 pagination
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//       <InstallDetailInfo data={installDetailInfo} {...props} />
//       <ToastContainer />
//     </>
//   )
// }
// export default InstallAsStatus
import React, {useEffect, useState} from 'react'
import {getInstallList} from 'utils/apiCalling/api'
import {installAsFilter, installerAssignFilter} from 'pages/install/dataConfig/declearFilters'
import {useSelector} from 'react-redux'
import {Autocomplete, Button, TextField} from '@mui/material'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import {envBackHost, envImagePath} from 'GLOBAL/envVariables'
import dayjs from 'dayjs'
import InstallCompleteListModal from 'pages/install/modules/installModalComponents/installCompleteListModal'
import Lottie from 'lottie-react'
import {searchWaitingLottie} from 'assets/imageDeclear'

const InstallAsStatus = (props: any) => {
  const userInfo = useSelector((state: any) => state.userInfo.userInfo)
  const [asInstallCompleteList, setAsInstallCompleteList] = useState<any>(null)
  const [installDetailInfo, setInstallDetailInfo] = useState<any>(null)
  const [detailCustomerInfo, setDetailCustomerInfo] = useState<any>(null)
  const [modalStatus, setModalStatus] = useState<boolean>(false)
  const modalOpen = () => setModalStatus(true)
  const modalClose = () => setModalStatus(false)
  const getInstallCompleteListCall = () => {
    getInstallList(userInfo, 'asComplete', installAsFilter, {
      success: (res: any) => {
        setAsInstallCompleteList(res)
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: () => {},
    })
  }
  const getInstallDetailInfo = (imei: string) => {
    axiosWithAuth()
      .get(`${envBackHost}/device/${imei}/checkmeter`)
      .then((res) => {
        setInstallDetailInfo(res.data.res[0])
      })
  }

  useEffect(() => {
    getInstallCompleteListCall()
  }, [])

  return (
    <div className={'container-fluid w-100 px-0 mb-5 flex-grow-1'}>
      <div className={'card card-body h-100'}>
        <div className={'d-grid justify-content-end'}>
          <Button variant={'contained'} color={'primary'} onClick={modalOpen}>
            모든 A/S 완료 고객 보기
          </Button>
        </div>
        <Autocomplete
          fullWidth
          id='choose-install-customer'
          options={asInstallCompleteList}
          className={'px-0 my-3'}
          disableClearable={true}
          clearText={'초기화'}
          noOptionsText={'검색 결과가 없습니다.'}
          getOptionLabel={(option: any) =>
            `${option?.customerName}[${option?.customerNumber}] - ${dayjs(
              option?.completeAt
            )?.format('YY.MM.DD')}`
          }
          onChange={(e, rowValue: any) => {
            setDetailCustomerInfo(rowValue)
            getInstallDetailInfo(rowValue.imei)
          }}
          sx={{fontSize: '1.5rem', color: '#fff'}}
          renderInput={(params) => (
            <div className={'d-flex gap-3'}>
              <TextField
                {...params}
                fullWidth
                label='A/S 완료 고객 검색'
                focused
                placeholder={'A/S 완료한 고객 검색해주세요.'}
              />
            </div>
          )}
        />
        <div className={'border border-gray-400 rounded-2'} style={{minHeight: 100}}>
          {!detailCustomerInfo && (
            <div className={'d-grid justify-content-center'}>
              <Lottie animationData={searchWaitingLottie} style={{width: 180}} />
            </div>
          )}
          {detailCustomerInfo && (
            <div>
              {/*<img src={envImagePath + installDetailInfo?.filePath} alt={'current'} />*/}
              <div className={'bg-dark text-center py-1 '}>
                <span className={'text-white fs-3 fw-bolder'}>단말기 이미지</span>
              </div>
              {detailCustomerInfo?.deviceType === 'analog' ? (
                <img
                  src={`${envImagePath}${installDetailInfo?.filePath}`}
                  alt={'current'}
                  className={''}
                  width={'100%'}
                />
              ) : (
                <h5 className={'mt-4 text-center text-danger fw-bolder'}>디지털 단말기 입니다.</h5>
              )}
              <div className={'row gap-2 fs-4 fw-bolder px-5 my-3'}>
                <div className={'d-flex gap-2'}>
                  <span>이름:</span>
                  <span>{detailCustomerInfo?.customerName}</span>
                </div>
                <div className={'d-flex gap-2'}>
                  <span>고객 번호:</span>
                  <span>{detailCustomerInfo?.customerNumber}</span>
                </div>
                <div className={'d-flex gap-2'}>
                  <span>주소:</span>
                  <span>
                    {detailCustomerInfo?.addressNumber === ''
                      ? detailCustomerInfo?.addressRoad
                      : detailCustomerInfo?.addressNumber}
                  </span>
                </div>
                <div className={'d-flex gap-2'}>
                  <span>A/S완료 일자:</span>
                  <span>{dayjs(detailCustomerInfo?.completeAt)?.format('YY년 MM월 DD일 ')}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <InstallCompleteListModal
        open={modalStatus}
        onClose={modalClose}
        user={userInfo}
        allList={asInstallCompleteList}
        name={'install-complete-modal'}
      />
    </div>
  )
}

export default InstallAsStatus
