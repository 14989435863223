import React, {useEffect, useState} from 'react'
import {Box, Button, createTheme} from '@mui/material'
import Typography from '@mui/material/Typography'
import {DateRange} from '@mui/x-date-pickers-pro/DateRangePicker'
import dayjs, {Dayjs} from 'dayjs'
import {DataGridPro, GridColDef, GridRowId, koKR} from '@mui/x-data-grid-pro'
import _ from 'lodash'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
/* icons*/
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import DeleteIcon from '@mui/icons-material/Delete'
import CodeModal from '../modules/codeModal'
import axios from 'axios'
import {envBackHost} from '../../../GLOBAL/envVariables'
import UpdateCodeModal from '../modules/updateCodeModal'
import {axiosWithAuth} from '../../../helper/axiosWithAuth'
import {confirmAlert, normalAlert, resultAlert} from '../../../utils/CustomAlert/Alert'
interface TabPanelProps {
  id?: string
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const CodeManagement = (props: any) => {
  const {toast} = props
  const [value, setValue] = React.useState(0)
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([])
  const [errorList, setErrorList] = useState<any>([])

  const [solutionList, setSolutionList] = useState<any>([])
  const tapHandleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const [date, setDate] = React.useState<DateRange<Dayjs>>([null, null])
  const theme = createTheme(
    {
      typography: {
        fontFamily: 'sans-serif',
      },
      palette: {
        primary: {main: '#1976d2'},
      },
    },
    koKR
  )
  const [pickData, setPickData] = useState<any>(null)
  const [modalType, setModalType] = useState<string>('')
  const [pageSize, setPageSize] = useState<number>(10)
  const errorColumns: GridColDef[] = [
    // {field: 'id', headerName: 'No.', width: 60, align: 'center', headerAlign: 'center'},
    {
      field: 'editable',
      headerName: '수정여부',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <>
            {params.row.editable ? (
              <span className={'text-primary'}>수정가능</span>
            ) : (
              <span className={'text-danger'}>수정불가</span>
            )}
          </>
        )
      },
    },
    {
      field: 'codeIdx',
      headerName: '코드',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'codeName',
      headerName: '코드 명',
      width: 300,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'register',
      headerName: '등록자',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'createAt',
      headerName: '생성일시',
      width: 300,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'updateAt',
      headerName: '수정일시',
      width: 300,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'updateBtn',
      headerName: '수정',
      width: 100,
      align: 'center',
      headerAlign: 'center',

      renderCell: (params) => {
        return (
          <Button
            variant='outlined'
            color='primary'
            data-bs-toggle='modal'
            disabled={params.row.editable === '수정불가'}
            data-bs-target='#updateCodeModal'
            onClick={() => {
              setPickData(params.row)
            }}
          >
            수정
          </Button>
        )
      },
    },
  ]
  const solutionColumns: GridColDef[] = [
    // {field: 'id', headerName: 'No.', width: 60, align: 'center', headerAlign: 'center'},
    {
      field: 'editable',
      headerName: '수정여부',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <>
            {params.row.editable ? (
              <span className={'text-primary'}>수정가능</span>
            ) : (
              <span className={'text-danger'}>수정불가</span>
            )}
          </>
        )
      },
    },
    {
      field: 'codeIdx',
      headerName: '코드',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'codeName',
      headerName: '코드 명',
      width: 300,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'register',
      headerName: '등록자',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'createAt',
      headerName: '생성일시',
      width: 300,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'updateAt',
      headerName: '수정일시',
      width: 300,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'updateBtn',
      headerName: '수정',
      width: 100,
      align: 'center',
      headerAlign: 'center',

      renderCell: (params) => {
        return (
          <Button
            variant='outlined'
            color='primary'
            data-bs-toggle='modal'
            disabled={params.row.editable === '수정불가'}
            data-bs-target='#updateCodeModal'
            onClick={() => {
              setPickData(params.row)
            }}
          >
            수정
          </Button>
        )
      },
    },
  ]
  const errorRows = _.map(errorList, (el, i) => {
    return {
      id: i + 1,
      editable: el?.editable,
      codeType: el?.codeType,
      codeIdx: el?.codeIdx,
      codeName: el?.codeName,
      register: el?.registerAccountName ? el?.registerAccountName : '관리자',
      createAt: dayjs(el?.createAt).format('YYYY-MM-DD'),
      updateAt: dayjs(el?.updateAt).format('YYYY-MM-DD'),
      workType: el?.workType,
    }
  })
  const solutionRows = _.map(solutionList, (el, i) => {
    return {
      id: i + 1,
      editable: el?.editable,
      codeType: el?.codeType,
      codeIdx: el?.codeIdx,
      codeName: el?.codeName,
      register: el?.registerAccountName ? el?.registerAccountName : '관리자',
      createAt: dayjs(el?.createAt).format('YYYY-MM-DD'),
      updateAt: dayjs(el?.updateAt).format('YYYY-MM-DD'),
      workType: el?.workType,
    }
  })
  const [effectFlag, setEffectFlag] = useState<boolean>(false)
  const getAsListCode = async () => {
    await axiosWithAuth()
      .get(`${envBackHost}/worktype/as/codetype/all`)
      .then((res) => {
        const causeList = _.filter(res.data.res, (el) => {
          return el.codeType === 'cause'
        })
        const errorList = _.filter(res.data.res, (el) => {
          return el.codeType === 'result'
        })
        setErrorList(causeList)
        setSolutionList(errorList)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const deleteButtonHandler = async () => {
    if (pickData.editable === '수정불가') {
      await normalAlert('수정불가 코드는 삭제할 수 없습니다.')

      return
    }
    if (selectionModel.length === 0) {
      await normalAlert('코드를 선택해주세요.')
      return
    }
    if (await confirmAlert('삭제', 'info')) {
      await axiosWithAuth()
        .delete(
          `${envBackHost}/workcode/${pickData.workType}/${pickData.codeType}/${pickData.code}`
        )
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            await getAsListCode()
            setEffectFlag(!effectFlag)
            return
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    }
  }

  useEffect(() => {
    getAsListCode()
  }, [])

  useEffect(() => {
    getAsListCode()
    setSolutionList([])
  }, [effectFlag])
  return (
    <div className='card flex-grow-1' id='system-code'>
      <div className='card-header border-0 pb-0'>
        <div className='card-title pb-0 m-0'>
          <h2 className='fw-bolder m-0'>
            Meterarium System에 관련된 <code className={'fw-bolder'}>CODE</code>입니다.
          </h2>
        </div>
      </div>
      <Box sx={{width: '100%'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider', paddingLeft: 3}}>
          <Tabs value={value} onChange={tapHandleChange} aria-label='check-list-tab'>
            <Tab label='에러코드' {...a11yProps(0)} className={'fw-bolder'} />
            <Tab label='해결코드' className={'fw-bolder'} {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel id={'device-check'} value={value} index={0}>
          <div className={'container-fluid'}>
            <div className={'row row-cols mt-5'} id={'content'}>
              <div id={'table-section'} className={'col-lg mt-3'}>
                <div
                  id={'table-header'}
                  className={'d-flex my-2 justify-content-between align-items-center'}
                >
                  <div id={'table-header-start'} className={'align-self-center'}>
                    <span className={'fs-3'}>
                      전체 : <strong>{errorRows.length}</strong> 개
                    </span>
                  </div>

                  <div id={'table-header-end'} className={'d-flex gap-3'}>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      startIcon={<PlaylistAddIcon />}
                      data-bs-toggle='modal'
                      data-bs-target='#codeModal'
                      onClick={() => {
                        setModalType('cause')
                      }}
                    >
                      생성
                    </Button>
                    <Button
                      variant={'contained'}
                      color={'error'}
                      startIcon={<DeleteIcon />}
                      onClick={() => deleteButtonHandler()}
                    >
                      삭제
                    </Button>
                  </div>
                </div>
                <Box id={'table-content'} sx={{height: 630, width: '100%'}}>
                  <DataGridPro
                    scrollbarSize={60}
                    rows={errorRows}
                    columns={errorColumns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 30, 50, 70, 100]}
                    disableColumnMenu
                    pagination
                    selectionModel={selectionModel}
                    onCellClick={(params) => setPickData(params.row)}
                    onSelectionModelChange={(selection) => {
                      if (selection.length > 1) {
                        const selectionSet = new Set(selectionModel)
                        const result = selection.filter((s) => !selectionSet.has(s))

                        setSelectionModel(result)
                      } else {
                        setSelectionModel(selection)
                      }
                    }}
                  />
                </Box>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel id={'device-allocation'} value={value} index={1}>
          <div className={'container-fluid'}>
            <div className={'row row-cols mt-5'} id={'content'}>
              <div id={'table-section'} className={'col-lg mt-3'}>
                <div
                  id={'table-header'}
                  className={'d-flex my-2 justify-content-between align-items-center'}
                >
                  <div id={'table-header-start'} className={'align-self-center'}>
                    <span className={'fs-3'}>
                      전체 : <strong>{solutionRows.length}</strong> 개
                    </span>
                  </div>

                  <div id={'table-header-end'} className={'d-flex gap-3'}>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      startIcon={<PlaylistAddIcon />}
                      data-bs-toggle='modal'
                      data-bs-target='#codeModal'
                      onClick={() => {
                        setModalType('result')
                      }}
                    >
                      생성
                    </Button>
                    <Button
                      variant={'contained'}
                      color={'error'}
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        deleteButtonHandler()
                      }}
                    >
                      삭제
                    </Button>
                  </div>
                </div>
                <Box id={'table-content'} sx={{height: 630, width: '100%'}}>
                  <DataGridPro
                    scrollbarSize={60}
                    rows={solutionRows}
                    columns={solutionColumns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 30, 50, 70, 100]}
                    disableColumnMenu
                    onCellClick={(params) => setPickData(params.row)}
                    pagination
                  />
                </Box>
              </div>
            </div>
          </div>
        </TabPanel>
      </Box>
      <CodeModal
        toast={toast}
        type={modalType}
        errorList={errorList}
        solutionList={solutionList}
        getAsListCode={getAsListCode}
      />
      <UpdateCodeModal
        data={pickData}
        toast={toast}
        effectFlag={effectFlag}
        setEffectFlag={setEffectFlag}
      />
    </div>
  )
}

export default CodeManagement
