import React from 'react'

const NormalAs = (props: any) => {
  const {asSubmit, set} = props
  return (
    <div>
      <h1>NormalAs</h1>
    </div>
  )
}
export default NormalAs
