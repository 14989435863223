import {officeActionsTypes} from '../actionTypes'

interface OfficeInfoState {
  label: string
  value: string
  isLoading: boolean
  error: string | null
}

const initialState: OfficeInfoState = {
  label: '전체',
  value: 'all',

  isLoading: false,
  error: null,
}

const officeInfoReducer = (state = initialState, action: any): OfficeInfoState => {
  switch (action.type) {
    case officeActionsTypes.OFFICE_INFO_REQUEST:
      return {
        ...action.payload,
        isLoading: true,
        error: null,
      }
    case officeActionsTypes.OFFICE_INFO_DELETE:
      return {
        label: '전체',
        value: 'all',
        isLoading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default officeInfoReducer
