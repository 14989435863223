import {Box, Button, Fade, Grid, TextField, Tooltip} from '@mui/material'
import {DataGridPro, GridColDef, GridColumnGroupingModel, GridRowId} from '@mui/x-data-grid-pro'
import _ from 'lodash'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {chargeBackHost, envBackHost} from '../../../GLOBAL/envVariables'
import {axiosWithAuth} from '../../../helper/axiosWithAuth'

import qs from 'qs'
import {toast} from 'react-toastify'
import {customConfirm, playConfirm, resultAlert} from '../../../utils/CustomAlert/Alert'

function not(a: any, b: any) {
  return a.filter((value: any) => b.indexOf(value.id) === -1)
}

const ChargeList = (props: any) => {
  const {setSelectStep, selectStep} = props
  const navigate = useNavigate()
  const officeInfo = useSelector((state: any) => state?.officeInfo)
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const [loading, setLoading] = useState(false)
  const [exceptLoading, setExceptLoading] = useState(false)
  const [customerList, setCustomerList] = useState<any>([])
  const [exceptionCustomerList, setExceptionCustomerList] = useState<any>([])
  const [checked, setChecked] = useState<any>({left: [], right: []})
  const [leftSelectionModel, setLeftSelectionModel] = useState<GridRowId[]>([])
  const [rightSelectionModel, setRightSelectionModel] = useState<GridRowId[]>([])
  const [searchValue, setSearchValue] = useState<any>({leftValue: '', rightValue: ''})
  const [searchStatus, setSearchStatus] = useState<any>({leftStatus: false, rightStatus: false})
  const [selectDate, setSelectDate] = useState<any>('')
  const cul = window.location.href.split('?')[1]
  const url = qs.parse(cul)

  //feature/sc
  const [file, setFile] = useState<File>()

  const getCustomerData = () => {
    setLoading(true)
    axiosWithAuth()
      .get(
        `${chargeBackHost}/api/select/all/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${userInfo?.userName}`
      )
      .then(async (res) => {
        console.log('left res', res)
        if (res.data !== false) {
          setCustomerList(res.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const fileUploadHandler = async () => {
    const formData = new FormData()
    formData.append('file', file as Blob)
    formData.append('id', userInfo?.userName)
    formData.append('officeId', officeInfo?.value)
    // setProcessing(true)
    await toast.promise(
      axiosWithAuth()
        .post(`${envBackHost}/       `, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(async (res) => {
          // setProcessing(false)
          await resultAlert(res.data)
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
          // setProcessing(false)
        })
        .finally(() => {
          // setProcessing(false)
        }),
      {
        pending: '수용가 일괄 등록 중 입니다.',
      },
      {
        position: 'top-center',
        closeButton: false,
        className: 'fs-3  fw-bold',
        style: {width: '400px'},
      }
    )
  }

  const getExceptCustomerData = () => {
    setExceptLoading(true)
    axiosWithAuth()
      .get(
        `${chargeBackHost}/api/select/except/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${userInfo?.userName}`
      )
      .then(async (res) => {
        if (res.data !== false) {
          setExceptionCustomerList(res.data)
        } else {
          setExceptionCustomerList(res.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setExceptLoading(false)
      })
  }

  const searchData = (searchValue: string, except: boolean) => {
    axiosWithAuth()
      .get(
        `${chargeBackHost}/api/search/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${
          url?.program
        }&search=${searchValue}&except=${except}&check_user=${userInfo?.userName}`
      )
      .then((res) => {
        if (res.data !== false) {
          if (except === true) {
            setExceptionCustomerList(res.data)
          } else {
            setCustomerList(res.data)
          }
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const postExceptCustomerData = (data: any, except: boolean) => {
    setLoading(true)
    axiosWithAuth()
      .post(
        `${chargeBackHost}/api/except/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&except=${except}&check_user=${
          userInfo?.userName
        }`,
        {
          none_data: data,
        }
      )
      .then(async (res) => {
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const columns: GridColDef[] = [
    {
      field: 'customer_number',
      headerName: '수용가 번호',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
              {params.row.customer_number}
            </span>
            <span className={'text-danger'}>
              {params.row.customer_status === null || params.row.customer_status === 'null'
                ? null
                : params.row.customer_status === 'as'
                ? 'A/S'
                : params.row.customer_status === 'move'
                ? '이전 철거'
                : '철거'}
            </span>
          </div>
        )
      },
    },
    {
      field: 'customer_name',
      headerName: '이름',
      width: 210,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'customerInfo',
      headerName: '주소',
      width: 350,
      align: 'left',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row'}>
            {params.row.address_road && (
              <span>
                <strong style={{color: '#104f98'}}>도로명 </strong> : {params.row.address_road}{' '}
              </span>
            )}
            {params.row.address_number && (
              <span>
                <strong style={{color: '#f37005'}}>지번 </strong> : {params.row.address_number}{' '}
              </span>
            )}
            {/* {params.row.addressDetail && (
              <span>
                <strong style={{color: 'rgba(86,31,13,0.7)'}}>상세주소 </strong> :{' '}
                {params.row.addressDetail}{' '}
              </span>
            )} */}
          </div>
        )
      },
    },
    {
      field: 'checkDate',
      headerName: '검침일',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            {params.row.check_month && <span>{params.row.check_month}</span>}
            {params.row.check_day && <span>{params.row.check_day}</span>}
          </div>
        )
      },
    },
  ]

  const rows = _.map(customerList, (el, i) => {
    return {
      id: i + 1,
      office_id: el.office_id,
      address_road: el.address_road,
      address_number: el.address_number,
      check_cycle: el.check_cycle,
      check_day: el.check_day,
      check_month: el.check_month,
      check_value: el.check_value,
      checkuser: el.checkuser,
      city_code: el.city_code,
      customer_name: el.customer_name,
      customer_number: el.customer_number,
      customer_status: el.customer_status,
      device_type: el.device_type,
      exceptstatus: el.exceptstatus,
      file_path: el.file_path,
      idx_date: el.idx_date,
      ip: el.ip,
      now_check_code: el.now_check_code,
      now_check_type: el.now_check_type,
      phone_number: el.phone_number,
      process_status: el.process_status,
      recognized_type: el.recognized_type,
      resource_code: el.resource_code,
      rotate: el.rotate,
      sub_number: el.sub_number,
      trsdat: el.trsdat,
      trstim: el.trstim,
      trsusr: el.trsusr,
      efos_connect_at: el.efos_connect_at,
      check_decimal_value: el.check_decimal_value,
    }
  })

  const checkRows = _.map(exceptionCustomerList, (el, i) => {
    return {
      id: i + 1,
      office_id: el.office_id,
      address_road: el.address_road,
      address_number: el.address_number,
      check_cycle: el.check_cycle,
      check_day: el.check_day,
      check_month: el.check_month,
      check_value: el.check_value,
      checkuser: el.checkuser,
      city_code: el.city_code,
      customer_name: el.customer_name,
      customer_number: el.customer_number,
      customer_status: el.customer_status,
      device_type: el.device_type,
      exceptstatus: el.exceptstatus,
      file_path: el.file_path,
      idx_date: el.idx_date,
      ip: el.ip,
      now_check_code: el.now_check_code,
      now_check_type: el.now_check_type,
      phone_number: el.phone_number,
      process_status: el.process_status,
      recognized_type: el.recognized_type,
      resource_code: el.resource_code,
      rotate: el.rotate,
      sub_number: el.sub_number,
      trsdat: el.trsdat,
      trstim: el.trstim,
      trsusr: el.trsusr,
      efos_connect_at: el.efos_connect_at,
      check_decimal_value: el.check_decimal_value,
    }
  })

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: '납부 고지 월',
      headerAlign: 'center',
      description: '납부 고지에 관한 정보입니다.',
      children: [{field: 'checkDate'}],
    },
  ]

  const handleCheckedRight = () => {
    postExceptCustomerData(exceptionCustomerList?.concat(checked?.left), true)
    setExceptionCustomerList(exceptionCustomerList?.concat(checked?.left))
    setCustomerList(not(rows, leftSelectionModel))
    setChecked([])
    setLeftSelectionModel([])
  }

  const handleCheckedLeft = () => {
    postExceptCustomerData(customerList?.concat(checked?.right), false)
    setCustomerList(customerList?.concat(checked?.right))
    setExceptionCustomerList(not(checkRows, rightSelectionModel))
    setChecked([])
    setRightSelectionModel([])
  }

  const handleAllRight = () => {
    setExceptionCustomerList(exceptionCustomerList?.concat(customerList))
    setCustomerList([])
  }

  const handleAllLeft = () => {
    setCustomerList(customerList?.concat(exceptionCustomerList))
    setExceptionCustomerList([])
  }

  const onRowsSelectionHandler = (ids: any, type: string) => {
    if (type === 'left') {
      const selectedLeftData = ids?.map((id: any) => rows?.find((row) => row.id === id))
      setChecked({...checked, left: selectedLeftData})
    } else {
      const selectedRightData = ids?.map((id: any) => checkRows?.find((row) => row.id === id))
      setChecked({...checked, right: selectedRightData})
    }
  }

  const handleKeydown = (e: any, type: string) => {
    if (e.key === 'Enter') {
      if (type === 'left') {
        searchData(searchValue.leftValue, false)
      } else {
        searchData(searchValue.rightValue, true)
      }
    }
  }

  useEffect(() => {
    if (url.program && url.date) {
      getCustomerData()
      getExceptCustomerData()
    }
    setSelectStep({step1: true, step2: true})
  }, [])

  return (
    <>
      <div className='card flex-grow-1' id='customer-register'>
        <div className='card-header justify-content-between w-100'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0 justify-content-between'>
              <strong className={'text-primary'}>{officeInfo?.label}</strong>의 요금연계를
              진행합니다.
            </h3>
          </div>
          <div className='card-title m-0 gap-3'>
            <Button
              variant={'contained'}
              onClick={async () => {
                if (
                  await customConfirm('처음으로 돌아시겠습니까?', 'warning', '처음으로 돌아가기')
                ) {
                  setSelectStep({step1: false, step2: false})
                  navigate('/charge/select')
                }
              }}
            >
              처음으로
            </Button>
            <Button
              variant={'contained'}
              color={'success'}
              onClick={async () => {
                if (
                  await playConfirm(
                    `${userInfo?.officeName}의 요금연계`,
                    `조회 수용가 : ${customerList.length}`,
                    'info',
                    '조회'
                  )
                ) {
                  setSelectStep({step1: false, step2: false})
                  navigate(`/charge/info?program=${url.program}&date=${url.date}`, {
                    state: {data: customerList},
                  })
                }
              }}
            >
              다음
            </Button>
          </div>
        </div>
        <div className='card-body p-9'>
          <div className={'container-fluid'}>
            <div id={'content'}>
              <div
                id={'table-section'}
                className={'d-flex justify-content-center align-items-center mt-2 mb-xl-10'}
              >
                <Box id={'table-content'} sx={{height: 800, width: '40%'}}>
                  <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <Tooltip
                      TransitionComponent={Fade}
                      TransitionProps={{timeout: 600}}
                      title={<span className={'fs-8'}>요금연계를 진행할 수용가를 선택합니다.</span>}
                    >
                      <label className={'fs-3 fw-bold mt-2'}>
                        선택 수용가 <strong>: {rows?.length}</strong>
                      </label>
                    </Tooltip>
                    <TextField
                      id='search-input'
                      placeholder='검색어를 입력하세요'
                      size='small'
                      value={searchValue?.leftValue}
                      onChange={(e) =>
                        setSearchValue({
                          ...searchValue,
                          leftValue: e.target.value,
                        })
                      }
                      onKeyDown={(e) => handleKeydown(e, 'left')}
                    />
                  </div>
                  <DataGridPro
                    rows={rows}
                    columns={columns}
                    rowHeight={120}
                    headerHeight={30}
                    columnGroupingModel={columnGroupingModel}
                    experimentalFeatures={{columnGrouping: true}}
                    disableColumnMenu
                    checkboxSelection
                    loading={loading}
                    selectionModel={leftSelectionModel}
                    onSelectionModelChange={(ids) => {
                      onRowsSelectionHandler(ids, 'left')
                      setLeftSelectionModel(ids)
                    }}
                  />
                </Box>
                <Grid item>
                  <Grid
                    container
                    direction='column'
                    alignItems='center'
                    paddingLeft={4}
                    paddingRight={4}
                  >
                    <Button
                      sx={{my: 0.5}}
                      variant='outlined'
                      size='small'
                      onClick={handleAllRight}
                      disabled={customerList?.length === 0}
                      aria-label='move all right'
                    >
                      ≫
                    </Button>
                    <Button
                      sx={{my: 0.5}}
                      variant='outlined'
                      size='small'
                      onClick={handleCheckedRight}
                      disabled={checked.left?.length === 0}
                      aria-label='move selected right'
                    >
                      &gt;
                    </Button>
                    <Button
                      sx={{my: 0.5}}
                      variant='outlined'
                      size='small'
                      onClick={handleCheckedLeft}
                      disabled={checked.right?.length === 0}
                      aria-label='move selected left'
                    >
                      &lt;
                    </Button>
                    <Button
                      sx={{my: 0.5}}
                      variant='outlined'
                      size='small'
                      onClick={handleAllLeft}
                      disabled={exceptionCustomerList?.length === 0}
                      aria-label='move all left'
                    >
                      ≪
                    </Button>
                  </Grid>
                </Grid>
                <Box id={'table-content'} sx={{height: 800, width: '40%'}}>
                  <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <Tooltip
                      TransitionComponent={Fade}
                      TransitionProps={{timeout: 600}}
                      title={
                        <span className={'fs-8'}>요금연계에서 제외할 수용가를 선택합니다.</span>
                      }
                    >
                      <label className={'fs-3 fw-bold mt-2'}>
                        제외 수용가 <strong>: {checkRows?.length}</strong>
                      </label>
                    </Tooltip>
                    <TextField
                      id='search-input'
                      placeholder='검색어를 입력하세요'
                      size='small'
                      value={searchValue?.rightValue}
                      onChange={(e) =>
                        setSearchValue({
                          ...searchValue,
                          rightValue: e.target.value,
                        })
                      }
                      onKeyDown={(e) => handleKeydown(e, 'right')}
                    />
                  </div>
                  <DataGridPro
                    rows={checkRows}
                    columns={columns}
                    rowHeight={120}
                    headerHeight={30}
                    columnGroupingModel={columnGroupingModel}
                    experimentalFeatures={{columnGrouping: true}}
                    disableColumnMenu
                    checkboxSelection
                    loading={exceptLoading}
                    selectionModel={rightSelectionModel}
                    onSelectionModelChange={(ids) => {
                      onRowsSelectionHandler(ids, 'right')
                      setRightSelectionModel(ids)
                    }}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChargeList
