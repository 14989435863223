import {createTheme} from '@mui/material'
import {koKR} from '@mui/x-data-grid-pro'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import type {} from '@mui/x-data-grid-pro/themeAugmentation'
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'
const isMobile = isMobileDevice()
export const theme = createTheme(
  {
    components: {
      MuiMonthPicker: {
        styleOverrides: {
          root: {
            fontSize: !isMobile ? '18px' : '14px',
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          label: {
            fontSize: !isMobile ? '20px' : '16px',
            fontWeight: 'bold',
          },
        },
      },

      MuiPickersDay: {
        styleOverrides: {
          today: {
            backgroundColor: '#fff',
            color: 'blue',
            fontWeight: 'bold',
            fontSize: !isMobile ? '22px' : '16px',
          },
          root: {
            fontSize: '18px',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '18px',
            fontWeight: 'bold',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: !isMobile ? '22px' : '16px',
            fontWeight: 'bold',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: !isMobile ? '22px' : '16px',
            fontWeight: 'bold',
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {},
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {},
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '26px',
          },
        },
      },
      PrivatePickersMonth: {
        styleOverrides: {
          root: {
            fontSize: !isMobile ? '18px' : '16px',
            fontWeight: 'bold',
          },
        },
      },
      PrivatePickersYear: {
        styleOverrides: {
          root: {
            button: {
              fontSize: !isMobile ? '20px' : '16px',
            },
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            fontSize: '20px',
            fontWeight: 'bold',
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          ul: {
            fontWeight: 'bold',
            justifyContent: 'center',
          },
        },
      },

      MuiTablePagination: {
        styleOverrides: {
          selectLabel: {
            fontSize: !isMobile ? '17px' : '14px',
            fontWeight: 'bold',
          },
          root: {
            fontSize: !isMobile ? '17px' : '14px',
            fontWeight: 'bold',
          },

          actions: {
            fontSize: !isMobile ? '17px' : '14px',
            fontWeight: 'bold',
          },
          displayedRows: {
            fontSize: !isMobile ? '17px' : '14px',
            fontWeight: 'bold',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          outlined: {
            fontSize: !isMobile ? '17px' : '14px',
            fontWeight: 'bold',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: !isMobile ? '17px' : '14px',
            fontWeight: 'bold',
          },
          subtitle1: {
            fontSize: !isMobile ? '16px' : '16px',
            fontWeight: 'bold',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: !isMobile ? '17px' : '15px',
            fontWeight: 'bold',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          gutters: {
            fontSize: !isMobile ? '17px' : '15px',
            fontWeight: 'bold',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: !isMobile ? '14px' : '14px',
            fontWeight: 'bolder',
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            fontSize: !isMobile ? '18px' : '15px',
            // fontWeight: 'bold',
          },
          labelContainer: {
            '& .MuiStepLabel-label': {
              fontSize: !isMobile ? '18px' : '12px',
            },
            '& .MuiStepLabel-label.Mui-completed': {
              fontSize: !isMobile ? '18px' : '12px',
              fontWeight: 'bold',
            },
            '& .MuiStepLabel-label.Mui-active': {
              fontSize: !isMobile ? '18px' : '12px',
              color: '#c9081e',
              fontWeight: 'bold',
            },
          },
          iconContainer: {
            '& .MuiStepIcon-root.Mui-active': {
              color: '#c9081e',
            },
          },
        },
      },

      MuiDataGrid: {
        styleOverrides: {
          overlay: {
            fontSize: !isMobile ? '30px' : '18px',
            fontWeight: 'bold',
          },
          columnHeaderTitle: {
            fontSize: !isMobile ? '17px' : '15px',
            fontWeight: 'bold',
          },
          cell: {
            fontSize: !isMobile ? '17px' : '15px',
            fontWeight: 'bold',
            cursor: 'pointer',
          },
          selectedRowCount: {
            fontSize: !isMobile ? '17px' : '15px',
          },
          cellContent: {
            rowHeight: '150px',
          },
          root: {
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
          },
          virtualScroller: {
            '::-webkit-scrollbar': {
              width: '15px',
              height: '15px', // 원하는 스크롤바 너비로 조정
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: '#888', // 원하는 스크롤바 썸 색상으로 조정
            },
            '::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1', // 원하는 스크롤바 배경 색상으로 조정
            },
            '::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#0066ff', // 원하는 호버 시 썸 색상으로 조정
            },
            '::-webkit-scrollbar-thumb:active': {
              backgroundColor: '#0066ff', // 원하는 호버 시 썸 색상으로 조정
            },
          },
        },
      },
    },
    palette: {
      primary: {main: '#1976d2'},
    },
  },
  koKR
)
