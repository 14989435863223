import React, {useEffect, useState} from 'react'
import {Backdrop, Modal} from '@mui/material'
import Box from '@mui/material/Box'
import {installModalStyle} from 'pages/install/dataConfig/modalStyle'
import StepHeader from 'pages/install/layout/stepHeader'
import SelectInstallableCustomer from 'pages/install/modules/installModalComponents/selectInstallabelCustomer'
import SelectInstallableDevice from 'pages/install/modules/installModalComponents/selectInstallableDevice'
import ImageEdit from 'pages/install/modules/installModalComponents/imageEdit'
import InstallComplete from 'pages/install/modules/installModalComponents/installComplete'
import '../../dataConfig/installModal.css'
const InstallModalContainer = (props: any) => {
  const {open, onClose, name, renderStep, set, clear, setRenderStep, installSubmit, countCall} =
    props
  const renderComponent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <SelectInstallableCustomer
            set={set}
            setRenderStep={setRenderStep}
            step={renderStep}
            installSubmit={installSubmit}
            countCall={countCall}
            clear={clear}
          />
        )
      case 1:
        return (
          <SelectInstallableDevice
            set={set}
            setRenderStep={setRenderStep}
            step={renderStep}
            installSubmit={installSubmit}
          />
        )
      case 2:
        return (
          <ImageEdit
            set={set}
            setRenderStep={setRenderStep}
            step={renderStep}
            installSubmit={installSubmit}
          />
        )
      case 3:
        return (
          <InstallComplete
            set={set}
            setRenderStep={setRenderStep}
            step={renderStep}
            installSubmit={installSubmit}
            close={onClose}
            clear={clear}
            countCall={countCall}
          />
        )
    }
  }
  return (
    <Modal
      open={open}
      aria-labelledby={`${name}-label`}
      aria-describedby={`${name}-description`}
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      id={'install-modal'}
      className={''}
    >
      <Box sx={installModalStyle} style={{paddingTop: 0}} className={'installer-modal'}>
        <StepHeader
          step={renderStep}
          close={onClose}
          clear={clear}
          setRenderStep={setRenderStep}
          set={set}
          installSubmit={installSubmit}
        />
        <div id={'installer-modal-content'} className={'px-4'}>
          {renderComponent(renderStep)}
        </div>
      </Box>
    </Modal>
  )
}

export default InstallModalContainer
