import React from 'react'
import {DataGridPro} from '@mui/x-data-grid-pro'
import {Grid} from '@mui/material'
import Typography from '@mui/material/Typography'

const MeterariumTable = (props: any) => {
  const {rows, columns, fixed, hideFooter} = props
  return (
    <>
      <Grid item xs={12} md={12} padding={1} height={window.innerHeight * 0.9 - 165}>
        <Typography variant={'subtitle2'} className={'fs-3 px-1'} fontWeight={'bold'}>
          총 : {rows?.length} 건
        </Typography>
        <DataGridPro
          rows={rows}
          columns={columns}
          rowHeight={35}
          rowThreshold={0}
          disableColumnMenu
          pagination
          initialState={{
            pinnedColumns: {
              left: fixed,
            },
          }}
          autoHeight={false}
          experimentalFeatures={{columnGrouping: true}}
          hideFooter={hideFooter}
          // showCellVerticalBorder={true}
          sx={{
            '& .MuiDataGrid-columnHeaders ': {
              backgroundColor: 'rgba(52, 73, 94, 0.94)',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {
              justifyContent: 'center',
              color: '#fff',
            },
            '& .MuiDataGrid-pinnedColumnHeaders': {
              backgroundColor: 'rgba(52, 73, 94, 0.94)',
            },
            '& .MuiDataGrid-pinnedColumns--left': {
              backgroundColor: '#fff',
            },
            '& .MuiDataGrid-columnSeparator': {
              visibility: 'visible',
            },
            '& .MuiDataGrid-cell': {
              border: '1px solid #e0e0e0',
            },
            '& .MuiDataGrid-cellContent': {
              color: '#73879C',
              fontWeight: 400,
              fontSize: 13,
            },
          }}
        />
      </Grid>
    </>
  )
}

export default MeterariumTable
