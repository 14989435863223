import React, {useEffect, useRef, useState} from 'react'
import {Button, TextField, Tooltip} from '@mui/material'
import axios from 'axios'
import {useAuth} from '../../../_component/modules/auth'
import {envBackHost} from '../../../GLOBAL/envVariables'
/*ICONS*/
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import {resultAlert} from '../../../utils/CustomAlert/Alert'

const UpdateCodeModal = (props: any) => {
  const {toast, data, effectFlag, setEffectFlag} = props
  const {currentUser} = useAuth()
  const updateRef = useRef<any>(null)
  const [submitData, setSubmitData] = useState<any>({
    code: '',
    workType: '',
    codeType: '',
    codeName: '',
  })
  /* state */
  const myModal: any = document.getElementById('codeModal')
  myModal?.addEventListener('hidden.bs.modal', function () {
    resetHandler()
  })

  const buttonHandler = async () => {
    setSubmitData({...submitData, codeName: ''})
    await axios
      .put(`${envBackHost}/workcode`, submitData)
      .then(async (res) => {
        await resultAlert(res.data)
        if (res.data.resultCode === 0) {
          setEffectFlag(!effectFlag)
          document.getElementById('updateCodeModalCloseButton')?.click()
          return
        }
      })
      .catch(async (e) => {
        await resultAlert({resultCode: e.response.status})
      })
  }
  const resetHandler = () => {
    setSubmitData({
      code: '',
      workType: '',
      codeType: '',
      codeName: '',
    })
  }
  useEffect(() => {
    setSubmitData({
      code: data?.code,
      workType: data?.workType,
      codeType: data?.codeType,
    })
  }, [data])
  return (
    <div
      className='modal fade'
      id='updateCodeModal'
      tabIndex={-1}
      aria-labelledby='updateCodeModalLabel'
      aria-hidden='true'
      data-bs-backdrop={'true'}
    >
      <div className='modal-dialog modal-dialog-centered modal-sm'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h3 className='modal-title' id='updateCodeModalLabel'>
              코드 수정
            </h3>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>

          <div className={'modal-body text-center'}>
            <div className={'row gap-4'}>
              <div>
                <TextField
                  variant={'outlined'}
                  label={'현재 코드명'}
                  value={data?.codeName ? data?.codeName : ''}
                  disabled
                  fullWidth
                />
              </div>
              <div>
                <KeyboardDoubleArrowDownIcon style={{fontSize: '24px'}} />
              </div>
              <div>
                <TextField
                  variant={'outlined'}
                  label={'수정할 코드명'}
                  fullWidth
                  ref={updateRef}
                  defaultValue={submitData.codeName}
                  value={submitData.codeName}
                  onChange={(e) => {
                    setSubmitData({...submitData, codeName: e.target.value})
                  }}
                />
              </div>
            </div>
          </div>

          <div className='modal-footer d-flex gap-4'>
            <Button
              variant={'contained'}
              size={'large'}
              onClick={() => {
                buttonHandler()
              }}
            >
              수정
            </Button>
            <Tooltip
              title={<span className={'fs-7 fw-bolder'}>바깥화면을 클릭해도 창이 닫힙니다.</span>}
              placement={'right'}
              arrow={true}
            >
              <Button
                id={'updateCodeModalCloseButton'}
                variant={'contained'}
                color={'error'}
                size={'large'}
                data-bs-dismiss='modal'
                className={'pulse pulse-light'}
              >
                <span>취소</span>
                <span className={'pulse-ring  border-4'}></span>
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateCodeModal
