import {useEffect, useState} from 'react'
import {DataGridPro} from '@mui/x-data-grid-pro'
import {useSelector} from 'react-redux'
import {scrollTop} from 'utils/func/justUtils'
import {customerLeakageFilter} from 'pages/customer/dataConfig/declearFilters'
import {
  customerLeakageColumns,
  customerLeakageColumnsBongHwa,
} from 'pages/customer/dataConfig/declearColumns'
import {customerLeakageRows} from 'pages/customer/dataConfig/declearRows'
import {getLeakList, getNewLeakList} from 'utils/apiCalling/api'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import {toolTipText} from 'utils/toolTips/toolTipText'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import {envBackHost} from 'GLOBAL/envVariables'
import dayjs from 'dayjs'
import {
  customerLeakageFilterType,
  getLeakListCallType,
  leakOptionsDataType,
} from 'utils/interface/customerType'
import {reduxType} from 'utils/interface/reduxType'

const CustomerInspection = (props: any) => {
  const leakOptionsDataInit: leakOptionsDataType = {
    officeName: '',
    leakUseCycle: '',
    leakUseStandard: '',
    leakUseUnit: '',
  }

  const [pageSize, setPageSize] = useState<number>(10)
  const [leakInfo, setLeakInfo] = useState<getLeakListCallType>()
  const whatInfo = useSelector((state: any) => state)
  const officeInfo = useSelector((state: reduxType) => state.officeInfo)
  const cooperInfo = useSelector((state: reduxType) => state.cooperInfo)
  const [loading, setLoading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [filter, setFilter] = useState<customerLeakageFilterType>(customerLeakageFilter)
  const [leakOptionsData, setLeakOptionData] = useState<leakOptionsDataType>(leakOptionsDataInit)

  const getLeakListCall = () => {
    getNewLeakList(officeInfo, cooperInfo, filter, {
      success: (res: getLeakListCallType) => {
        setLeakInfo(res)
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {
        setLoading(loading)
      },
      count: (count: number) => {
        setTotalCount(count)
      },
    })
  }

  const columns: any = customerLeakageColumnsBongHwa(filter)
  const rows = customerLeakageRows(leakInfo)
  useEffect(() => {
    scrollTop()
    getLeakListCall()
  }, [officeInfo, cooperInfo, filter.date])

  return (
    <>
      <MeterariumPageLayout
        headerDescription={toolTipText.customerInspection.description}
        tooltipText={toolTipText.customerInspection.text}
        filter={filter}
        id={'customer-unused'}
        setFilter={setFilter}
        searchCallback={getLeakListCall}
        total={totalCount}
        hideSearchOptions={true}
        control={['selectDate']}
        actions={['unused']}
        date={[{date: dayjs().format('YYYYMMDD')}]}
        asValue={[]}
        searchOptions={[]}
        checkData={null}
        onDelete={null}
        onCancel={null}
        setTypeName={null}
      >
        {/* <DataGridPro
          rows={rows}
          columns={columns}
          rowHeight={300}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          disableColumnMenu
          rowsPerPageOptions={[10, 30, 50, 70, 100]}
          pagination
          loading={loading}
          autoHeight={true}
        /> */}
      </MeterariumPageLayout>
    </>
  )
}
export default CustomerInspection
