import dayjs from 'dayjs'

export const asManagementFilter = {
  searchOption: {
    customerNumber: false,
    customerName: false,
    asSerialNumber: false,
    installName: false,
    installId: false,
    asName: false,
    asId: false,
    address: false,
  },
  searchData: '',
  cause: 'all',
  solution: 'all',
  result: 'all',
  assignStatus: 'all',
  startDate: dayjs().subtract(1, 'month').format('YYYYMMDD'),
  endDate: dayjs().format('YYYYMMDD'),
}

export const recoveryInfoFilter = {
  searchOption: {
    serialNumber: false,
    imei: false,
    deviceVersion: false,
  },
  searchData: '',
}
