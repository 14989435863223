import {DoubleArrow} from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import {envImagePath} from 'GLOBAL/envVariables'
import _ from 'lodash'
import {installerAssignFilter} from 'pages/install/dataConfig/declearFilters'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {getInstallList, getSolutionList} from 'utils/apiCalling/api'

const SelectAsInstallableCustomer = (props: any) => {
  const {set, setRenderStep, step, asSubmit} = props
  const userInfo = useSelector((state: any) => state?.userInfo?.userInfo)
  const [asList, setAsList] = useState<any>(null)
  const [solutionList, setSolutionList] = useState<any>(null)
  const getInstallListCall = () => {
    getInstallList(userInfo, 'as', installerAssignFilter, {
      success: (res: any) => {
        setAsList(res)
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {},
    })
  }
  const getSolutions = () => {
    getSolutionList({
      success: (res: any) => {
        setSolutionList(res)
      },
      fail: (err: any) => {
        console.log(err)
      },
    })
  }
  useEffect(() => {
    if (step === 0) {
      getInstallListCall()
      getSolutions()
    }
  }, [step])
  return (
    <div className={'row mx-0 justify-content-center pb-3'}>
      <Autocomplete
        fullWidth
        id='choose-customer'
        options={asList}
        className={'px-0'}
        disableClearable={true}
        clearText={'초기화'}
        noOptionsText={'검색 결과가 없습니다.'}
        getOptionLabel={(option: any) =>
          `${option?.customerName} - [ ${option?.customerNumber} ] : ${option?.officeName}`
        }
        onChange={(e, rowValue: any) => {
          set((prev: any) => ({
            ...prev,
            officeId: rowValue?.officeId,
            customerNumber: rowValue?.customerNumber,
            customerName: rowValue?.customerName,
            beforeImei: rowValue?.imei,
            imei: rowValue?.imei,
            idx: rowValue?.idx,
            contrast: rowValue?.contrast,
            createAt: rowValue?.createAt,
            causeName: rowValue?.causeName,
            causeIdx: rowValue?.causeIdx,
            idxDate: rowValue?.beforeImageIdxDate,
            filePath: rowValue?.filePath,
            updateId: userInfo?.userId,
            serialNumber: rowValue?.serialNumber,
            deviceType: rowValue?.deviceType,
            rssiStatus: rowValue?.rssiStatus,
          }))
        }}
        sx={{fontSize: '1.5rem', color: '#fff'}}
        renderInput={(params) => (
          <div className={'d-flex gap-3'}>
            <TextField
              {...params}
              fullWidth
              label='수용가 선택'
              focused
              placeholder={'수용가를 선택해주세요.'}
            />
          </div>
        )}
      />
      {asSubmit?.customerNumber && (
        <article className={''}>
          <div
            id={'install-info'}
            className={'row gap-2 mt-3 fs-4 border border-gray-600 rounded-2 p-3 '}
          >
            <div className={'d-flex gap-3'}>
              <span className={'fw-bolder'}>이름 -</span>
              <span>{asSubmit.customerName}</span>
            </div>
            <div className={'d-flex gap-3'}>
              <span className={'fw-bolder'}>고객 번호 -</span>
              <span>{asSubmit.customerNumber}</span>
            </div>
            <div className={'d-flex gap-3'}>
              <span className={'fw-bolder'}>원인명 -</span>
              <span>{asSubmit.causeName}</span>
            </div>
            <div className={''}>
              <span className={'fw-bolder'}>현재 검침상태 </span>
              <img
                // src={'https://meterarium.com:5280' + asSubmit.filePath}
                src={`${envImagePath}${asSubmit?.filePath}`}
                alt={'검침상태'}
                style={{width: '100%', height: 144}}
              />
            </div>
            <FormControl fullWidth className={'mt-4'}>
              <InputLabel id='choose-solution-label'>해결명</InputLabel>
              <Select
                labelId='choose-solution-label'
                id='choose-solution'
                label='해결명'
                onChange={(e) => {
                  set((prev: any) => ({
                    ...prev,
                    resultIdx: e.target.value,
                    resultIdxName: _.find(solutionList, {codeIdx: e.target.value})?.codeName,
                  }))
                }}
              >
                {_.map(solutionList, (el, i) => {
                  return <MenuItem value={el.codeIdx}>{el.codeName}</MenuItem>
                })}
              </Select>
            </FormControl>
          </div>
        </article>
      )}
      <Button
        variant={'contained'}
        color={'primary'}
        style={{
          position: asSubmit?.customerNumber ? 'static' : 'fixed',
          bottom: '10px',
          marginTop: asSubmit?.customerNumber ? '10px' : '0px',
        }}
        disabled={!asSubmit?.resultIdx}
        endIcon={<DoubleArrow />}
        onClick={() => {
          if (asSubmit?.resultIdx >= 5 && asSubmit?.resultIdx <= 10) {
            setRenderStep(3)
            return
          }
          setRenderStep(1)
        }}
      >
        A/S 수용가, 해결명 선택 완료
      </Button>
    </div>
  )
}

export default SelectAsInstallableCustomer
