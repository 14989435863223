export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)

  const curruntParsing = current.split(/[?#]/)[0]
  // console.log(curruntParsing)
  const urlTest = url.indexOf('?') > -1 ? url.split('?')[0] : url
  // console.log(urlTest)
  // if (!current || !url) {
  //   return false
  // }
  //
  // if (current === url) {
  //   return true
  // }
  //
  // if (current.indexOf(url) > -1) {
  //   return true
  // }
  if (!current || !urlTest) {
    return false
  }

  if (current === urlTest) {
    return true
  }

  if (current.indexOf(urlTest) > -1) {
    return true
  }

  return false
}
