import React, {useEffect} from 'react'
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import UserManagementHeader from './layout/accountHeader'
import Account from './component/account'
import {useSelector} from 'react-redux'
import {useAuth} from '../../_component/modules/auth'
/* component */

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: '사용자 관리',
    path: '/pages/task',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const UserManagementPage = (props: any) => {
  const {toast} = props
  const navigate = useNavigate()
  const {logout} = useAuth()
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  useEffect(() => {
    if (
      userInfo === null ||
      (userInfo.accountType !== 'sys_admin' && userInfo.accountType !== 'sys_user')
    ) {
      navigate('/auth')
      logout()
    }
  }, [])

  return (
    <Routes>
      <Route
        element={
          <>
            <UserManagementHeader toast={toast} />
            <Outlet />
          </>
        }
      >
        <Route
          path={'group'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>그룹 관리</PageTitle>
              <Account toast={toast} />
            </>
          }
        />
      </Route>
    </Routes>
  )
}
export default UserManagementPage
