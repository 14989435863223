import CancelIcon from '@mui/icons-material/Cancel'
import SearchIcon from '@mui/icons-material/Search'
import {Box, Button, IconButton, InputAdornment, TextField} from '@mui/material'
import {envBackHost} from 'GLOBAL/envVariables'
import _ from 'lodash'
import {useEffect, useState} from 'react'

/*icons*/
import RefreshIcon from '@mui/icons-material/Refresh'
import {DataGridPro, GridColDef} from '@mui/x-data-grid-pro'
import {selectFailImg, selectSuccessImg} from 'assets/imageDeclear'
import dayjs from 'dayjs'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import DashboardDetailModal from 'pages/common/DashboardDetailModal'
import {useSelector} from 'react-redux'
import {entimoreController, globalFormatWithHyphens} from 'utils/const/globalConst'
import CustomPagination from 'utils/designSytem/CustomPagination'
import DeviceErr from './../images/device_err.png'
import DeviceSuc from './../images/device_suc.png'

export default function StickyHeadTable(props: any) {
  const [pageSize, setPageSize] = useState<number>(50)
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const [customerInfoList, setCustomerInfoList] = useState<any>(null)
  const [totalCount, setTotalCount] = useState<any>(null)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const [detailInfo, setDetailInfo] = useState<any>(null)
  const [filter, setFilter] = useState<any>({
    searchOption: {
      customerNumber: false,
      customerName: false,
      address: false,
      managerName: false,
      memo: false,
      serialNumber: false,
    },
    searchData: '',
    deviceStatus: 'all',
    efosStatus: 'all',
    deviceType: 'all',
    recognizedType: 'all',
    batteryStatus: 'all',
    rowPerPage: 30,
    page: 1,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [tempFilter, setTempFilter] = useState<any>(null)

  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false)
  const detailModalOnOpen = () => {
    setDetailModalOpen(true)
  }
  const detailModalOnClose = () => {
    setDetailModalOpen(false)
  }
  const handleCellClick = (event: any) => {
    event.stopPropagation()
  }
  const getCustomerInfo = () => {
    setLoading(true)
    axiosWithAuth()
      .get(
        `${envBackHost}/${officeInfo?.value === 'yit' ? 'all' : officeInfo?.value}/${
          cooperInfo?.value
        }/customer/info`,
        {
          params: filter,
        }
      )
      .then((res) => {
        setCustomerInfoList(res.data.res)
        setTotalCount(res.data.count)
        setLoading(false)
      })
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: '상세보기',
      minWidth: 130,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Button
            variant={'contained'}
            onClick={(e) => {
              setDetailInfo(params.row)
              detailModalOnOpen()
              handleCellClick(e)
            }}
          >
            상세보기
          </Button>
        )
      },
    },
    {
      field: 'officeName',
      headerName: '사업소',
      minWidth: 130,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'deviceStatus',
      headerName: '통신상태',
      renderCell: (params) => {
        const path = params?.row.filePath || '"/imei/image/202202020004"'
        const parts = path.split('/')
        const idxDate = parts[parts.length - 1]
        const checkToday = idxDate.slice(0, -4)
        const todayDate = dayjs().format('YYYYMMDD')
        const cooperIdx = params?.row.cooperIdx
        console.log('dev', params.row)

        return (
          <>
            {entimoreController.model === '2' ? (
              <img
                alt={'yit_device'}
                src={checkToday === todayDate ? DeviceSuc : DeviceErr}
                width={60}
              />
            ) : (
              <img
                alt={'device-check'}
                src={
                  params.row.deviceStatus ? selectSuccessImg(cooperIdx) : selectFailImg(cooperIdx)
                }
                width={60}
              />
            )}
          </>
        )
      },
      width: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'customerNumber',
      headerName: '수용가 번호',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return <>{globalFormatWithHyphens(params.row.officeId, params.row.customerNumber)}</>
      },
    },
    {
      field: 'customerName',
      headerName: '수용가 이름',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'meterDiameter',
      headerName: '구경',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'temperature',
      headerName: '온도',
      minWidth: 80,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const temp = params?.row.temperature
        return <>{temp < 90 ? `${temp}도` : '-'}</>
      },
    },
  ]
  const rows = _.map(customerInfoList, (el, i: any) => {
    return {
      id: i + 1,
      cooperIdx: el?.cooperIdx,
      officeId: el?.officeId,
      officeName: el?.officeName,
      deviceStatus: el?.deviceStatus,
      customerName: el?.customerName,
      customerNumber: el?.customerNumber,
      addressRoad: el?.addressRoad,
      addressDetail: el?.addressDetail,
      meterDiameter: el?.meterDiameter + 'mm',
      addressNumber: el?.addressNumber,
      temperature: el?.temperature,
      filePath: el?.filePath,
      deviceType: el?.deviceType,
      deviceTypeName: el?.deviceTypeName,
      deviceVersion: el?.deviceVersion,
      customerTypeName: el?.customerTypeName,
      customerWorkTypeName: el?.customerWorkTypeName,
      checkValue: el?.checkValue,
      latitude: el?.latitude,
      longitude: el?.longitude,
      rotate: el?.rotate,
    }
  })
  useEffect(() => {
    // setFilter({...filter, page: 1, rowPerPage: 30})
    getCustomerInfo()
  }, [cooperInfo, officeInfo, filter.page, filter.rowPerPage])

  return (
    <>
      <div
        className={'card card-body rounded-end-3'}
        style={{width: '100%', overflow: 'hidden', height: '100%'}}
      >
        <div className={'d-flex w-100 gap-1 mb-1'}>
          <TextField
            variant={'outlined'}
            id={'search-field'}
            placeholder={'수용가 번호 / 이름 / 주소 를 검색해주세요.'}
            sx={{width: '100%'}}
            value={filter.searchData}
            onChange={(e) => {
              setFilter({...filter, searchData: e.target.value})
              setTempFilter(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                getCustomerInfo()
              }
            }}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='resetBtn'
                    onClick={() => {
                      setFilter({...filter, searchData: ''})
                      setDetailInfo(null)
                      setTimeout(() => {
                        document.getElementById('search-btn')?.click()
                      }, 100)
                    }}
                    edge='end'
                  >
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant={'contained'}
            size={'large'}
            type='button'
            id={'search-btn'}
            onClick={async () => {
              getCustomerInfo()
            }}
          >
            <SearchIcon sx={{width: 24, height: 24}} />
          </Button>
          <Button
            variant={'contained'}
            size={'large'}
            type='button'
            color={'error'}
            onClick={() => {
              setFilter({...filter, searchData: '', page: 1, rowPerPage: 30})
              setDetailInfo(null)
              setTimeout(() => {
                document.getElementById('search-btn')?.click()
              }, 100)
            }}
          >
            <RefreshIcon sx={{width: 24, height: 24}} />
          </Button>
        </div>
        <Box id={'table-content'} sx={{height: '100%', width: '100%'}}>
          <DataGridPro
            scrollbarSize={60}
            rows={rows}
            rowHeight={70}
            columns={columns}
            rowCount={totalCount}
            disableColumnMenu
            paginationMode={'server'}
            hideFooterPagination={true}
            pagination={true}
            hideFooter={true}
            loading={loading}
          />
        </Box>
        <CustomPagination totalCount={totalCount} filter={filter} setFilter={setFilter} mini />
        {/*<MarkerDetailModal detailInfo={detailInfo} />*/}
        <DashboardDetailModal
          data={detailInfo}
          open={detailModalOpen}
          onClose={detailModalOnClose}
        />
      </div>
    </>
  )
}
