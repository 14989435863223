import React, {useEffect, useState} from 'react'
import {chargeBackHost, envImagePath} from 'GLOBAL/envVariables'
import _ from 'lodash'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import {useLocation, useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {toast, ToastContainer} from 'react-toastify'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {DataGridPro, GridColDef, GridColumnGroupingModel, GridSortModel} from '@mui/x-data-grid-pro'
import {Box, Button, Chip, FormControl, MenuItem, Select, TextField} from '@mui/material'
import {defaultImage, noImage} from 'assets/imageDeclear'
import dayjs from 'dayjs'
import swal from 'sweetalert'
import qs from 'qs'

const ChargeSubOffcanvas = (props: any) => {
  const {setSelectStep} = props
  const location = useLocation()
  const navigate = useNavigate()
  const data = location.state?.data
  const officeInfo = useSelector((state: any) => state?.officeInfo)
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState<any>('')
  const [searchStatus, setSearchStatus] = useState<boolean>(false)
  const [subMeterData, setSubMeterData] = useState<any>(data)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {field: 'customer_number', sort: 'asc'},
  ])
  const cul = window.location.href.split('?')[1]
  const url = qs.parse(cul)
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  const getSubMeterData = () => {
    setLoading(true)
    axiosWithAuth()
      .get(
        `${chargeBackHost}/sub/subselect/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}`
      )
      .then((res) => {
        setLoading(false)
        if (res.data !== false) {
          setSubMeterData(res.data)
          toggleVisibility()
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateSubMeterData = () => {
    setLoading(true)
    axiosWithAuth()
      .post(
        `${chargeBackHost}/sub/subupdate/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}`,
        {
          subMeter_data: subMeterData,
          account_name: userInfo.userName,
        }
      )
      .then(async (res) => {
        setLoading(false)
        if (res.data !== false) {
          await swal({
            title: '저장이 완료되었습니다.',
            text: ' ',
            buttons: [false],
            icon: 'success',
            timer: 1500,
          })
        } else {
          await swal({
            title: '저장에 실패하였습니다.',
            text: ' ',
            buttons: [false],
            icon: 'error',
            timer: 1500,
          })
        }
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: '이름',
      headerAlign: 'center',
      description: '수용가에 관한 정보입니다.',
      children: [{field: 'customerInfo'}],
    },
    {
      groupId: '당월검침정보',
      headerAlign: 'center',
      description: '당월 검침내역에 관한 정보입니다.',
      children: [{field: 'checkInfo'}],
    },
    {
      groupId: '전월검침정보',
      headerAlign: 'center',
      description: '전월 검침내역에 관한 정보입니다.',
      children: [
        {field: 'befCheckInfo'},
        {field: 'bef_check_code'},
        {field: 'bef_check_value'},
        {field: 'bef_used'},
      ],
    },
    {
      groupId: '변경일',
      headerAlign: 'center',
      description: '변경내역에 관한 정보입니다.',
      children: [{field: 'trsDateInfo'}],
    },
    {
      groupId: '변경자',
      headerAlign: 'center',
      description: '변경내역에 관한 정보입니다.',
      children: [{field: 'trsInfo'}],
    },
  ]

  const filterRows = () => {
    if (searchStatus === true) {
      return _.filter(
        rows,
        (row: any) =>
          row.customer_number?.includes(searchValue) ||
          row.customer_name?.includes(searchValue) ||
          row.address_road?.includes(searchValue) ||
          row.address_number?.includes(searchValue)
        // row.addressDetail?.includes(searchValue)
      )
    }
    return rows
  }

  const rows = _.map(subMeterData, (el, i) => {
    return {
      id: i + 1,
      address_road: el.address_road,
      address_number: el.address_number,
      check_cycle: el.check_cycle,
      check_day: el.check_day,
      check_code: el.check_code,
      check_month: el.check_month,
      check_value: el.check_value,
      check_value_default: el.check_value_default,
      check_user: el.check_user,
      city_code: el.city_code,
      customer_name: el.customer_name,
      customer_number: el.customer_number,
      customer_status: el.customer_status,
      device_type: el.device_type,
      exceptstatus: el.exceptstatus,
      file_path: el.file_path,
      idx_date: el.idx_date,
      ip: el.ip,
      now_check_code: el.now_check_code,
      now_check_type: el.now_check_type,
      phone_number: el.phone_number,
      process_status: el.process_status,
      recognized_type: el.recognized_type,
      resource_code: el.resource_code,
      rotate: el.rotate,
      sub_number: el.sub_number,
      trsdat: el.trsdat,
      trstim: el.trstim,
      trsusr: el.trsusr,
      efos_connect_at: el.efos_connect_at,
      check_decimal_value: el.check_decimal_value,
      check_date: el?.check_date,
      now_used: el?.now_used,
    }
  })

  const saeol_sub_columns: GridColDef[] = [
    {
      field: 'check_date',
      headerName: '검침년월',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'customer_number',
      headerName: '수용가번호',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row'}>
            <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
              {params.row.customer_number}
            </span>
            <span className={'text-danger text-center'}>
              {params.row.customer_status === null || params.row.customer_status === 'null'
                ? null
                : params.row.customer_status === 'as'
                ? 'A/S'
                : params.row.customer_status === 'move'
                ? '이전 철거'
                : '철거'}
            </span>
          </div>
        )
      },
    },
    {
      field: 'customerInfo',
      headerName: '주소',
      width: 400,
      align: 'left',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className={'row text-start'}>
            <span className={''}>{params.row.customer_name}</span>
            {params.row.address_road && (
              <span>
                <strong style={{color: '#104f98'}}>도로명 </strong> : {params.row.address_road}{' '}
              </span>
            )}
            {params.row.address_number && (
              <span>
                <strong style={{color: '#f37005'}}>지번 </strong> : {params.row.address_number}{' '}
              </span>
            )}
            {/* {params.row.addressDetail && (
              <span>
                <strong style={{color: 'rgba(86,31,13,0.7)'}}>상세주소 </strong> :{' '}
                {params.row.addressDetail}{' '}
              </span>
            )} */}
          </div>
        )
      },
    },

    {
      field: 'checkInfo',
      headerName: '검침일',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.check_value_default === '' ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.check_value_default
                )}
              </span>
              <span>
                {params.row.check_decimal_value === '' ||
                params.row.check_decimal_value === null ||
                params.row.check_decimal_value === 'null'
                  ? ''
                  : '.' + params.row.check_decimal_value?.split('.')[1]}
              </span>
            </div>
            <span>{dayjs(params.row.idx_date).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.device_type !== 'analog' ? (
              <img className={'cursor-pointer'} src={defaultImage} />
            ) : (
              <div className={'row'}>
                <div
                  className={'d-flex gap-1 justify-content-end'}
                  style={{position: 'relative', top: '15px'}}
                >
                  {params.row.rotate > 0 && (
                    <Chip
                      label={`${params.row.rotate}° `}
                      color='primary'
                      sx={{fontSize: '17px'}}
                    />
                  )}
                  {params.row.recognized_type === null || params.row.recognized_type === 'null' ? (
                    <Chip label='기계식' color='error' sx={{fontSize: '17px'}} />
                  ) : (
                    <Chip label='디지털' color='warning' sx={{fontSize: '17px'}} />
                  )}
                </div>
                <img
                  alt={'device-image'}
                  className={'cursor-pointer'}
                  src={
                    params.row.file_path === null
                      ? noImage
                      : `${envImagePath}${params.row.file_path}`
                  }
                  width={320}
                  height={144}
                />
              </div>
            )}
          </>
        )
      },
    },
    {
      field: 'updateCheckValue',
      headerName: '당월지침',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            defaultValue={Number(params.row.check_value)}
            onChange={(e) => {
              const updateValue = [...subMeterData]
              updateValue[params.row.id - 1].check_value = e.target.value
              setSubMeterData(updateValue)
            }}
          />
        )
      },
    },
    {
      field: 'now_used',
      headerName: '당월사용량',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            // defaultValue={Number(params.row.now_used)}
            value={params.row.now_used === null ? 0 : params.row.now_used}
            onChange={(e) => {
              const updateValue = [...subMeterData]
              updateValue[params.row.id - 1].now_used = e.target.value
              setSubMeterData(updateValue)
            }}
          />
        )
      },
    },
    {
      field: 'check_code',
      headerName: '당월검침코드',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className='w-100 text-center'>
            <FormControl fullWidth>
              <Select
                label=''
                id='check-code-select'
                value={params.row.now_check_code === null ? '00' : params.row.now_check_code}
                onChange={(e) => {
                  const updateValue = [...subMeterData]
                  updateValue[params.row.id - 1].now_check_code = e.target.value
                  setSubMeterData(updateValue)
                }}
              >
                <MenuItem value='00'>00 (정상)</MenuItem>
                <MenuItem value='01'>01 (회전)</MenuItem>
                <MenuItem value='02'>02 (교체)</MenuItem>
                <MenuItem value='03'>03 (인정)</MenuItem>
                <MenuItem value='04'>04 (부동)</MenuItem>
                <MenuItem value='05'>05 (누수)</MenuItem>
                <MenuItem value='06'>06 (중지)</MenuItem>
                <MenuItem value='07'>07 (미검침)</MenuItem>
                <MenuItem value='08'>08 (자가검침)</MenuItem>
                <MenuItem value='09'>09 (공업용수)</MenuItem>
              </Select>
            </FormControl>
          </div>
        )
      },
    },
    {
      field: 'check_user',
      headerName: '정보수정자',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'phoneNumber',
      headerName: '연락처',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
  ]

  return (
    <>
      <div
        className='offcanvas offcanvas-end'
        tabIndex={-1}
        id='ChargeSub'
        aria-labelledby='ChargeSubLabel'
        style={{width: '80%'}}
      >
        <div className='offcanvas-header '>
          <h3 className='fw-bolder m-0'>
            <strong className={'text-primary'}>{officeInfo?.label}</strong>의 부관 조회를
            진행합니다.
          </h3>
          <Button variant='contained' component='label' onClick={() => getSubMeterData()}>
            부관 조회
          </Button>
          <button
            type='button'
            className='btn-close text-reset'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          ></button>
        </div>
        <div className='offcanvas-body batch-scrollbar'>
          <div className={'row gap-4'}>
            <div className={'d-flex col gap-3 w-100 px-0 mx-0'}>
              <div className={'d-flex gap-4 col-6 ms-19 '}></div>
            </div>
          </div>
        </div>
        <div className={'offcanvas-footer'}>
          {isVisible && (
            <Box id={'table-content'} sx={{height: 850, width: '100%'}}>
              <DataGridPro
                rows={filterRows()}
                columns={saeol_sub_columns}
                rowHeight={160}
                loading={loading}
                columnGroupingModel={columnGroupingModel}
                experimentalFeatures={{columnGrouping: true}}
                disableColumnMenu
                sortModel={sortModel}
                onSortModelChange={(newSortModel: any) => setSortModel(newSortModel)}
              />
            </Box>
          )}

          <div
            className={'d-flex gap-7 justify-content-end '}
            style={{marginBottom: '50px', marginRight: '30px'}}
          >
            <Button
              variant={'contained'}
              size={'large'}
              color={'warning'}
              onClick={() => updateSubMeterData()}
            >
              저장
            </Button>
            <Button
              id={'batchRegisterCustomerClose'}
              variant={'contained'}
              size={'large'}
              color={'error'}
              data-bs-dismiss='offcanvas'
            >
              나가기
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}
export default ChargeSubOffcanvas
