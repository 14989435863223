import React, {useEffect, useRef, useState} from 'react'
import {customerUninstall, getInstallList} from 'utils/apiCalling/api'
import {installerAssignFilter} from 'pages/install/dataConfig/declearFilters'
import {useSelector} from 'react-redux'
import {Autocomplete, Button, TextField} from '@mui/material'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import {envBackHost, envImagePath} from 'GLOBAL/envVariables'
import dayjs from 'dayjs'
import InstallCompleteListModal from 'pages/install/modules/installModalComponents/installCompleteListModal'
import Lottie from 'lottie-react'
import {searchWaitingLottie} from 'assets/imageDeclear'

const InstallStatus = (props: any) => {
  const userInfo = useSelector((state: any) => state.userInfo.userInfo)
  const [filter, setFilter] = useState<any>(installerAssignFilter)
  const [installCompleteList, setInstallCompleteList] = useState<any>(null)
  const [installDetailInfo, setInstallDetailInfo] = useState<any>(null)
  const [detailCustomerInfo, setDetailCustomerInfo] = useState<any>(null)
  const [modalStatus, setModalStatus] = useState<boolean>(false)

  const modalOpen = () => setModalStatus(true)
  const modalClose = () => setModalStatus(false)
  const getInstallCompleteListCall = () => {
    getInstallList(userInfo, 'complete', filter, {
      success: (res: any) => {
        setInstallCompleteList(res)
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: () => {},
    })
  }
  const getInstallDetailInfo = (imei: string) => {
    axiosWithAuth()
      .get(`${envBackHost}/device/${imei}/checkmeter`)
      .then((res) => {
        setInstallDetailInfo(res.data.res[0])
      })
  }
  const unInstall = () => {
    customerUninstall(detailCustomerInfo?.idx, {
      success: (res: any) => {
        console.log(res)
        if (res.resultCode === 0) {
          getInstallCompleteListCall()
          setDetailCustomerInfo(null)
          setInstallDetailInfo(null)
        }
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: () => {},
    })
  }
  useEffect(() => {
    getInstallCompleteListCall()
  }, [])
  return (
    <div className={'container-fluid w-100 px-0 mb-5 flex-grow-1'}>
      <div className={'card card-body h-100'}>
        <div className={'d-grid justify-content-end'}>
          <Button variant={'contained'} color={'primary'} onClick={modalOpen}>
            모든 설치고객 보기
          </Button>
        </div>
        <Autocomplete
          fullWidth
          id='choose-install-customer'
          options={installCompleteList}
          className={'px-0 my-3'}
          disableClearable={true}
          clearText={'초기화'}
          noOptionsText={'검색 결과가 없습니다.'}
          getOptionLabel={(option: any) =>
            `${option?.customerName}[${option?.customerNumber}] - ${dayjs(
              option?.completeAt
            )?.format('YY.MM.DD')}`
          }
          onChange={(e, rowValue: any) => {
            setDetailCustomerInfo(rowValue)
            getInstallDetailInfo(rowValue.imei)
          }}
          sx={{fontSize: '1.5rem', color: '#fff'}}
          renderInput={(params) => {
            return (
              <div className={'d-flex gap-3'}>
                <TextField
                  {...params}
                  fullWidth
                  label='설치완료 고객 검색'
                  focused
                  placeholder={'설치 완료한 고객 검색해주세요.'}
                />
              </div>
            )
          }}
        />
        <div className={'border border-gray-400 rounded-2'} style={{minHeight: 100}}>
          {!detailCustomerInfo && (
            <div className={'d-grid justify-content-center'}>
              <Lottie animationData={searchWaitingLottie} style={{width: 180}} />
            </div>
          )}
          {detailCustomerInfo && (
            <div>
              {/*<img src={envImagePath + installDetailInfo?.filePath} alt={'current'} />*/}
              <div className={'bg-dark text-center py-1 '}>
                <span className={'text-white fs-3 fw-bolder'}>단말기 이미지</span>
              </div>
              {detailCustomerInfo?.deviceType === 'analog' ? (
                <img
                  src={`${envImagePath}${installDetailInfo?.filePath}`}
                  alt={'current'}
                  className={''}
                  width={'100%'}
                />
              ) : (
                <h5 className={'mt-4 text-center text-danger fw-bolder'}>디지털 단말기 입니다.</h5>
              )}
              <div className={'row gap-2 fs-4 fw-bolder px-5 my-3'}>
                <div className={'d-flex gap-2'}>
                  <span>이름:</span>
                  <span>{detailCustomerInfo?.customerName}</span>
                </div>
                <div className={'d-flex gap-2'}>
                  <span>고객 번호:</span>
                  <span>{detailCustomerInfo?.customerNumber}</span>
                </div>
                <div className={'d-flex gap-2'}>
                  <span>주소:</span>
                  <span>
                    {detailCustomerInfo?.addressNumber === ''
                      ? detailCustomerInfo?.addressRoad
                      : detailCustomerInfo?.addressNumber}
                  </span>
                </div>
                <div className={'d-flex gap-2'}>
                  <span>설치완료 일자:</span>
                  <span>{dayjs(detailCustomerInfo?.completeAt)?.format('YY년 MM월 DD일 ')}</span>
                </div>
              </div>
              <div className={'p-2'}>
                <Button variant={'contained'} color={'error'} fullWidth onClick={() => unInstall()}>
                  설치취소
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <InstallCompleteListModal
        open={modalStatus}
        onClose={modalClose}
        user={userInfo}
        allList={installCompleteList}
        name={'install-complete-modal'}
      />
    </div>
  )
}

export default InstallStatus
