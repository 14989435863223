import React, {useLayoutEffect, useRef, useState} from 'react'
import _ from 'lodash'
import {useSelector} from 'react-redux'
import ReactDOMServer from 'react-dom/server'
import {dMarker, meterariumLogo, yMarker} from 'assets/imageDeclear'
import {envImagePath} from 'GLOBAL/envVariables'

declare global {
  interface Window {
    kakao: any
  }
}

const MapContainer = (props: any) => {
  const {cluster} = props
  const [loading, setLoading] = useState(true)
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const mapRef = useRef<any>(null)
  const InfoWindowContent = (content: any) => {
    const {data} = content
    return (
      <div className={'w-100'}>
        <div
          className='px-1 fw-bold fs-4 my-3 border-gray-300 rounded-2 bg-dark text-white text-center'
          style={{width: '350px', position: 'absolute'}}
        >
          <div className='w-100 my-2 d-flex justify-content-between'>
            <div>
              <img src={meterariumLogo} alt='meterarium-logo' width={150} />
            </div>
          </div>
          {data?.filePath == null ? null : (
            <img
              className={'mb-2 border-1 border-gray-300 align-self-center'}
              src={envImagePath + data?.filePath}
              width={'320px'}
              alt={'meter-image'}
            />
          )}
          <div className={'d-flex'}>
            <span>검침값 : </span>
            <span className={'fs-3 ms-2'}>
              <u>{data?.checkValue === null ? '인식 중 입니다.' : data?.checkValue}</u>
            </span>
          </div>
          <div className={'d-flex'}>
            <span>
              수용가 번호 : <strong className={'text-primary ms-2'}>{data?.customerNumber}</strong>{' '}
            </span>
          </div>
          <div className={'d-flex'}>
            <span>
              수용가 이름 : <strong className={'text-primary ms-2'}>{data?.customerName}</strong>{' '}
            </span>
          </div>
          <div className={'d-flex mb-3 '} style={{width: '320px'}}>
            <span>수용가 주소 : </span>
            <span className={' ms-2'}>
              {data?.addressRoad} {data?.addressDetail}
            </span>
          </div>
        </div>
      </div>
    )
  }
  // const infoWindowContents = useMemo(() => {
  //   return _.flatMap(cluster, clusterData =>
  //       clusterData.points.map((el:any) =>
  //           ReactDOMServer.renderToString(<InfoWindowContent data={el} />)
  //       )
  //   );
  // }, [cluster]);

  useLayoutEffect(() => {
    let container = document?.getElementById('map') //지도를 담을 영역의 DOM 레퍼런스
    let options = {
      //지도를 생성할 때 필요한 기본 옵션
      center: new window.kakao.maps.LatLng(36.5194, 127.7256), //지도의 중심좌표.
      level: 12, //지도의 레벨(확대, 축소 정도)
      mapTypeId: window.kakao.maps.MapTypeId.HYBRID, //지도종류
    }
    let map = new window.kakao.maps.Map(container, options) //지도 생성 및 객체 리턴
    const clusterer = new kakao.maps.MarkerClusterer({
      map: map, // 마커들을 클러스터로 관리하고 표시할 지도 객체
      averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정
      minLevel: 3, // 클러스터 할 최소 지도 레벨
      gridSize: 70,
      minClusterSize: 20,
    })
    kakao.maps.event.addListener(clusterer, 'clusterclick', function (cluster: any) {
      // 현재 지도 레벨에서 1레벨 확대한 레벨
      var level = map.getLevel() - 1

      // 지도를 클릭된 클러스터의 마커의 위치를 기준으로 확대합니다
      map.setLevel(level, {anchor: cluster.getCenter()})
    })
    _.flatMap(cluster, (clusterData) => clusterData.points).forEach((el, i) => {
      let marker = new kakao.maps.Marker({
        map: map,
        position: new window.kakao.maps.LatLng(el?.latitude, el?.longitude),
        title: el?.customerName,
        image: new kakao.maps.MarkerImage(
          el?.cooperIdx === 1 ? yMarker : dMarker,
          new kakao.maps.Size(43, 60)
        ),
      })
      clusterer.addMarker(marker)
      const iwContent = ReactDOMServer.renderToString(<InfoWindowContent data={el} />)
      // const iwContent = '<div>test</div>'
      const customOverlay = new kakao.maps.CustomOverlay({
        position: new window.kakao.maps.LatLng(el?.latitude, el?.longitude),
        content: iwContent,
      })
      kakao.maps.event.addListener(marker, 'mouseover', function () {
        customOverlay.setMap(map)
      })

      // 마커에 마우스아웃 이벤트를 등록합니다
      kakao.maps.event.addListener(marker, 'mouseout', function () {
        customOverlay.setMap(null)
      })
      // 정보창 설정 및 이벤트 리스너 추가
    })
    kakao.maps.event.addListener(map, 'tilesloaded', function () {
      setLoading(false)
    })

    kakao.maps.event.addListener(map, 'zoom_changed', function () {
      // 줌 레벨이 변경되면 스피너를 표시
      setLoading(true)
    })

    // idle 이벤트 리스너
    kakao.maps.event.addListener(map, 'idle', function () {
      setLoading(false)
    })
  }, [cluster])
  return (
    <>
      <div
        id='map'
        style={{width: '100%', height: '650px'}}
        ref={mapRef}
        className={'border-1 border-white rounded-2'}
      >
        {loading && (
          <div
            className='row align-items-center text-center gap-8 align-self-center justify-content-center'
            style={{marginTop: '80px'}}
          >
            <div
              className='spinner-border '
              style={{width: '7rem', height: '7rem', color: 'white', borderWidth: '0.5rem'}}
              role='status'
            ></div>
            <span className='fs-3 fw-bold text-white'>지도를 불러오는 중 입니다. </span>
          </div>
        )}
      </div>
    </>
  )
}

export default MapContainer
