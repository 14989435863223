import _ from 'lodash'
export const accountRows = (list: any) => {
  return _.map(list, (el: any) => {
    return {
      id: el.id,
      name: el.name,
      officeId: el.officeId,
      officeName: el.officeName,
      phoneNumber: el.phoneNumber,
      idActiveStatus: el.idActiveStatus,
      email: el.email,
      cooperName: el.cooperName,
      accountType: el.accountType,
      accountTypeName: el.accountTypeName,
      createAt: el.createAt,
      memo: el.memo,
      imageUrl: el.imageUrl,
      cooperIdx: el.cooperIdx,
    }
  })
}
