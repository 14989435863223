import React, {useEffect, useState} from 'react'
import {PageLink, useLayout} from '../../_metronic/layout/core'
import {useSelector} from 'react-redux'
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {useAuth} from '../../_component/modules/auth'
import ChargeList from './component/ChargeList'
import ChargeInfo from './component/ChargeInfo'
import ChargeSelect from './component/ChargeSelect'
import ChargeHeader from './header/ChargeHeader'
const profileBreadCrumbs: Array<PageLink> = [
  {
    title: '요금 부과',
    path: '/charge',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const ChargePage = () => {
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const navigate = useNavigate()
  const {logout} = useAuth()
  const {config} = useLayout()
  const [selectStep, setSelectStep] = useState<any>({
    step1: false,
    step2: false,
  })
  useEffect(() => {
    //feature/sc 2023.12.20
    if (
      userInfo === null ||
      (userInfo.accountType !== 'sys_admin' && userInfo.accountType !== 'sys_user' && userInfo.accountType !== 'charge_user' )
    //feature/sc
    ) {
      navigate('/auth')
      logout()
    }
  }, [])
  document.body.setAttribute('data-kt-aside-minimize', 'on')
  return (
    <Routes>
      <Route
        element={
          <>
            <ChargeHeader selectStep={selectStep} />
            <Outlet />
          </>
        }
      >
        <Route
          path={'select'}
          element={
            <>
              <ChargeSelect setSelectStep={setSelectStep} selectStep={selectStep} />
            </>
          }
        />
        <Route
          path={'list'}
          element={
            <>
              <ChargeList setSelectStep={setSelectStep} selectStep={selectStep} />
            </>
          }
        />
        <Route
          path={'info'}
          element={
            <>
              <ChargeInfo setSelectStep={setSelectStep} selectStep={selectStep} />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ChargePage
