/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Login} from './components/Login'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import './css/AuthPage.css'
import {ToastContainer} from 'react-toastify'
import NewLogo from './images/meter_meterarium_logo.gif'
/*Toast*/

const AuthLayout = () => {
  const isMobile = isMobileDevice()
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])

  return (
    <>
      {!isMobile && (
        <div className='d-flex flex-column flex-column-fluid wrap-bg'>
          {/* begin::Content */}
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            <div className={'d-flex'} style={{gap: '80px'}}>
              <div>
                <img
                  alt='Logo'
                  src={NewLogo}
                  className='theme-light-show  '
                  width={'500px'}
                  style={{borderRadius: '50%'}}
                />
              </div>
              <div className='w-lg-500px login-bg-gradient  shadow-sm p-10 p-lg-15 mx-auto font-monospace '>
                <Outlet />
              </div>
            </div>
          </div>
          <div></div>
          <div className={'d-flex flex-center'}>
            <div className='d-flex flex-center flex-column-auto p-10 pt-3'>
              <div className='d-flex align-items-center fw-bolder fs-5'>
                <span className=' text-hover-primary cursor-pointer fw-bold px-4'>
                  영아이티(주)
                </span>
                <span className='text-hover-primary px-4'>
                  <a
                    href={'https://young-it.co.kr'}
                    className={'cursor-pointer text-hover-dark'}
                    target={'_blank'}
                    rel='noreferrer'
                  >
                    YOUNG-IT
                  </a>{' '}
                  | Meterarium
                </span>

                <a href={'/join/agree'} className={'text-dark text-hover-primary px-4'}>
                  이용약관 및 개인정보처리방침
                </a>
                <span className='text-hover-primary px-4'>대표전화 : 042 - 932 - 0170</span>
                <span className='text-hover-primary px-4'>기술지원 : 070 - 7835 - 7453</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className='d-flex flex-column flex-column-fluid m-wrap-bg'>
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            {/* begin::Logo */}
            <a href='/' className='mb-8 text-center'>
              <img
                alt='Logo'
                src={NewLogo}
                // src={toAbsoluteUrl('/media/logos/meterarium_new_logo.png')}
                className='theme-light-show  '
                style={{width: '230px', borderRadius: '50%'}}
              />
            </a>
            <div className='w-lg-500px login-bg-gradient  shadow-sm p-10 p-lg-15 mx-auto w-100 '>
              <Outlet />
            </div>
          </div>
          <div className={'row  gap-2 w-100 px-0 mx-0'} style={{paddingBottom: '1rem'}}>
            <div className={'d-flex flex-center flex-column-auto gap-6 w-100'}>
              <span className={'text-gray-600 fw-bold fs-4 text-center'}>영아이티(주)</span>
              <span className={'text-gray-600 fw-bold fs-4'}>
                <a
                  className={'text-primary cursor-pointer'}
                  href={'https://young-it.co.kr'}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  YOUNG-IT
                </a>{' '}
                | Meterarium
              </span>
            </div>
            <div className={'d-flex w-100 flex-center'}>
              <div className={'row flex-center flex-column-auto text-center w-100'}>
                <a href={'tel:042-932-0170'} className={'text-gray-600 fw-bolder fs-4 '}>
                  대표전화 : 042 - 932 - 0170
                </a>
                <a href={'tel:070-7835-7453'} className={'text-gray-600 fw-bolder fs-4'}>
                  기술지원 : 070 -7835 - 7453
                </a>
                <a
                  href={'/join/agree'}
                  className={'text-gray-600 text-hover-primary px-4 fs-4 fw-bolder'}
                >
                  이용약관 및 개인정보처리방침
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  )
}

const AuthPage = (props: any) => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route index element={<Login />} />
      </Route>
    </Routes>
  )
}

export {AuthPage}
