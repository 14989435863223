interface AsideFlagState {
  value: boolean
}

const initialState: AsideFlagState = {
  value: false,
}

enum ActionTypes {
  ASIDEFLAG_OFF = 'ASIDEFLAG_OFF',
  ASIDEFLAG_ON = 'ASIDEFLAG_ON',
}

interface AsideFlagOffAction {
  value: any
  type: ActionTypes.ASIDEFLAG_OFF
}
interface AsideFlagOnAction {
  value: any
  type: ActionTypes.ASIDEFLAG_ON
}

type Action = AsideFlagOffAction | AsideFlagOnAction

const asideFlagReducer = (state = initialState, action: Action): AsideFlagState => {
  switch (action.type) {
    case ActionTypes.ASIDEFLAG_OFF:
      return {
        value: false,
      }
    case ActionTypes.ASIDEFLAG_ON:
      return {
        value: true,
      }
    default:
      return state
  }
}

export default asideFlagReducer
export {ActionTypes}
