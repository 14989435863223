import React, {useState} from 'react'
import {Autocomplete, Button, Chip} from '@mui/material'
import Box from '@mui/material/Box'
import dayjs from 'dayjs'
import CachedIcon from '@mui/icons-material/Cached'
import DownloadDoneIcon from '@mui/icons-material/DownloadDone'
import {imageDownload, imageRefresh} from 'utils/apiCalling/api'
import {envImagePath} from 'GLOBAL/envVariables'
import {globalConst, globalText} from 'utils/const/globalConst'
import {installDevicedBmToolTip, toolTipText} from 'utils/toolTips/toolTipText'

const Efos = (props: any) => {
  const {asSubmit, set, setSelected} = props
  const [selectInstallType, setSelectInstallType] = useState<any>(null)
  const imageRefreshCall = () => {
    imageRefresh(asSubmit, {
      success: (res: any) => {
        set((prev: any) => ({
          ...prev,
          imei: res.imei,
          idxDate: res.idxDate,
          filePath: res.filePath,
          createAt: res.createAt,
          rssiStatus: res.rssiStatus,
        }))
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {},
    })
  }
  return (
    <Box className={'my-2 p-2 border border-gray-400 rounded-2'}>
      <div className={'text-center'}>
        <img
          // src={'https://meterarium.com:5280' + asSubmit?.filePath}
          src={`${envImagePath}${asSubmit?.filePath}`}
          alt={'device'}
          style={{width: 320, height: 144}}
        />
        <div className={'d-flex w-100 justify-content-between align-items-end mt-2'}>
          <Chip
            label={`전파 세기 : ${asSubmit?.rssiStatus}dBm `}
            color={Number(asSubmit?.rssiStatus) < globalConst.rssiWarningdBm ? 'error' : 'success'}
            variant={'filled'}
            className={'fs-4'}
          />
          <span className={'text-end fs-3 fw-bold'}>
            {dayjs(asSubmit?.createAt).format('YY.MM.DD HH시 mm분 ss초')}
          </span>
        </div>

        <div className={'alert alert-dark mt-2'}>
          <span className={'fs-3 fw-bold'}>설치 방식을 선택해주세요</span>
          <div className={'d-flex justify-content-around align-items-end mt-2'}>
            <Chip
              label={`${globalText.installInside}`}
              color={'primary'}
              variant={selectInstallType === 'N' ? 'filled' : 'outlined'}
              className={'fs-4'}
              onClick={() => {
                setSelectInstallType('N')
                setSelected(true)
              }}
            />

            <Chip
              label={`${globalText.installOutside}`}
              color={'primary'}
              variant={selectInstallType === 'OUT' ? 'filled' : 'outlined'}
              className={'fs-4'}
              onClick={() => {
                setSelectInstallType('OUT')
                setSelected(true)
              }}
            />
          </div>
        </div>

        {selectInstallType !== null &&
          (asSubmit?.rssiStatus && Number(asSubmit?.rssiStatus) < globalConst.rssiWarningdBm
            ? installDevicedBmToolTip(selectInstallType)
            : installDevicedBmToolTip('success'))}

        <div className={'my-2 d-flex gap-2'}>
          <Button
            variant={'contained'}
            color={'warning'}
            fullWidth
            startIcon={<CachedIcon />}
            onClick={() => imageRefreshCall()}
          >
            새로고침
          </Button>
          <Button
            variant={'contained'}
            color={'success'}
            fullWidth
            startIcon={<DownloadDoneIcon />}
            onClick={async () =>
              await imageDownload(asSubmit?.filePath, 32, 255, 0, asSubmit?.beforeImei)
            }
          >
            다운로드
          </Button>
        </div>
      </div>
    </Box>
  )
}

export default Efos
