import React, {useEffect, useState} from 'react'
import {KTSVG} from '_metronic/helpers'
import {
  Button,
  ClickAwayListener,
  Fade,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material'
import BatchOffcanvas from '../modules/batchOffcanvas'
import _ from 'lodash'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {useDaumPostcodePopup} from 'react-daum-postcode'
import CustomerRegisterSearch from '../modules/CustomerRegisterSearch'
import {phoneValidation, requireField} from 'utils/validation/validation'
import {useSelector} from 'react-redux'
import {toast, ToastContainer} from 'react-toastify'
import HelpIcon from '@mui/icons-material/Help'
import {confirmAlert, normalAlert} from 'utils/CustomAlert/Alert'
import {Controller, useForm} from 'react-hook-form'
import {getCustomerTypeList, getWorkTypeList, registerCustomer} from 'utils/apiCalling/api'
import {CustomWidthTooltip} from 'utils/designSytem/CustomTooltip'
import {toolTipText} from 'utils/toolTips/toolTipText'
import {addressType, submitDataType} from 'utils/interface/customerType'
import {reduxType} from 'utils/interface/reduxType'

const submitDataInit: submitDataType = {
  officeId: '',
  officeName: '',
  customerNumber: '',
  customerName: '',
  customerTypeId: '1',
  customerTypeName: '일반',
  customerWorkTypeId: '1',
  customerWorkTypeName: '일반용',
  addressRoad: '',
  addressNumber: '',
  addressDetail: '',
  zipCode: '',
  meterNumber: '',
  meterDiameter: '',
  checkMonth: '당월',
  checkDay: '15',
  managerName: '',
  phoneNumber: '',
  memo: '',
  contractNumber: '',
  zone: '',
}

const CustomerRegister = (props: any) => {
  const {
    setError,
    register,
    handleSubmit,
    setValue,
    control,
    formState: {errors},
  } = useForm({criteriaMode: 'all', mode: 'onChange'})

  const preventEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') e.preventDefault()
  }

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }
  const handleTooltipOpen = () => {
    setTooltipOpen(true)
  }
  const userInfo = useSelector((state: reduxType) => state.userInfo?.userInfo)
  const [workType, setWorkType] = useState<any>(null)
  const [customerType, setCustomerType] = useState<any>(null)
  const [phoneNumberForm, setPhoneNumberForm] = useState<any>(false)
  const [address, setAddress] = useState<addressType>({
    addressRoad: '',
    addressNumber: '',
  })
  const [submitData, setSubmitData] = useState<submitDataType>(submitDataInit)
  const CURRENT_URL = 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js'
  const open = useDaumPostcodePopup(CURRENT_URL)
  const handleComplete = (data: any) => {
    setAddress({
      ...address,
      addressRoad: data.roadAddress,
      addressNumber: data.jibunAddress === '' ? data.autoJibunAddress : data.jibunAddress,
    })
  }
  const onClickHandler = () => {
    open({onComplete: handleComplete})
  }

  const submitHandler = async () => {
    const resp = await registerCustomer(submitData, resetHandler)
    // await normalAlert('필수정보를 모두 입력해주세요.')
  }
  const resetHandler = async () => {
    if (await confirmAlert('초기화', 'warning')) {
      window.location.reload()
    }
  }

  const handlePhoneNumber = (e: any) => {
    let count = e.target.value.length

    const input: any =
      count < 11
        ? e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
        : e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,4})(\d{0,4})/)
    const formattedPhoneNumber = !input[2]
      ? input[1]
      : input[1] + '-' + input[2] + (input[3] ? '-' + input[3] : '')
    console.log(count, formattedPhoneNumber)
    return formattedPhoneNumber
  }

  useEffect(() => {
    getWorkTypeList(userInfo, setWorkType)
    getCustomerTypeList(userInfo, setCustomerType)
  }, [])
  useEffect(() => {
    setSubmitData({
      ...submitData,
      addressRoad: address.addressRoad,
      addressNumber: address.addressNumber,
    })
    setValue('addressRoad', address.addressRoad)
    setValue('addressNumber', address.addressNumber)
  }, [address.addressRoad, address.addressNumber])

  return (
    <>
      {!isMobileDevice() && (
        <div className='card flex-grow-1' id='customer-register' style={{fontSize: '16px'}}>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>{toolTipText.customerRegister.description}</h3>
              <CustomWidthTooltip
                title={toolTipText.customerRegister.text}
                TransitionComponent={Fade}
                TransitionProps={{timeout: 600}}
                placement={'right-start'}
                arrow={true}
                sx={{fontSize: '10px', letterSpacing: '1px', cursor: 'pointer'}}
              >
                <HelpIcon
                  color={'primary'}
                  sx={{fontSize: '24px'}}
                  className={'ms-4 mb-2 cursor-pointer'}
                />
              </CustomWidthTooltip>
            </div>

            <button
              className={'btn btn-primary align-self-center'}
              data-bs-toggle={'offcanvas'}
              data-bs-target='#batchRegister'
              aria-controls='batchRegister'
            >
              일괄등록
            </button>
          </div>
          <BatchOffcanvas toast={toast} />
          <div className='card-body p-9'>
            <form onSubmit={handleSubmit(submitHandler)}>
              <div className={'row row-cols gap-10 mb-5'}>
                <div className={'col-lg'}>
                  <div className={'row my-4'}>
                    <div className={'pe-0 row'}>
                      <div className={'pe-0 col-9'}>
                        <label className='mb-3 fw-bold text-muted'>
                          사업소
                          <span className={'fw-bolder fs-3 ms-1'} style={{color: 'red'}}>
                            *
                          </span>
                        </label>
                        <div className={'d-flex gap-3'}>
                          <CustomerRegisterSearch
                            register={register}
                            name='officeName'
                            setValue={setValue}
                            setSubmitData={setSubmitData}
                            submitData={submitData}
                            errors={errors}
                          />
                        </div>
                      </div>
                      <div className={'pe-0 col-3'}>
                        <label className='mb-3 fw-bold text-muted'>
                          계약 번호
                          <span className={'fw-bolder fs-3 ms-1'} style={{color: 'white'}}></span>
                        </label>
                        <div className={'d-flex gap-3 '}>
                          <TextField
                            {...register('contractNumber', {
                              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                setSubmitData({...submitData, contractNumber: e.target.value})
                              },
                            })}
                            label='계약번호'
                            variant='outlined'
                            value={submitData?.contractNumber}
                            fullWidth
                            onKeyDown={preventEnter}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={'row my-4'}>
                    <label className='mb-3 fw-bold text-muted'>
                      수용가
                      <span className={'fw-bolder fs-3 ms-1'} style={{color: 'red'}}>
                        *
                      </span>
                    </label>
                    <div className={'d-flex gap-3'}>
                      <TextField
                        {...register('customerNumber', {
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            setSubmitData({...submitData, customerNumber: e.target.value})
                          },
                          required: {
                            value: true,
                            message: '필수 입력 사항입니다.',
                          },
                        })}
                        label='수용가 번호'
                        type={'number'}
                        variant='outlined'
                        value={submitData?.customerNumber}
                        fullWidth
                        onKeyDown={preventEnter}
                        error={errors.customerNumber ? true : false}
                        helperText={
                          errors.customerNumber ? (
                            <span className={'fs-6 fw-bold text-danger'}>
                              {String(errors.customerNumber.message)}
                            </span>
                          ) : (
                            ''
                          )
                        }
                      />
                      <FormControl fullWidth error={errors.customerTypeName ? true : false}>
                        <InputLabel id='demo-simple-select-label'>수용가 종류</InputLabel>
                        <Controller
                          name='customerTypeName'
                          control={control}
                          rules={{required: '필수 입력 사항입니다.'}}
                          defaultValue={submitData?.customerTypeName}
                          render={({field}) => (
                            <Select
                              {...field}
                              error={errors.customerTypeName ? true : false}
                              labelId='demo-simple-select-label'
                              label='수용가 종류'
                              onChange={(e: any) => {
                                field.onChange(e)
                                const findIdx = customerType.findIndex(
                                  (item: any) => item.customerTypeName === e.target.value
                                )
                                setSubmitData({
                                  ...submitData,
                                  customerTypeId: customerType[findIdx].idx,
                                  customerTypeName: customerType[findIdx].customerTypeName,
                                })
                              }}
                            >
                              {_.map(customerType, (el, i) => {
                                return (
                                  <MenuItem value={el.customerTypeName} key={i}>
                                    {el.customerTypeName}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          )}
                        />
                        {errors.customerTypeName ? (
                          <FormHelperText className={'fs-6 fw-bold text-danger'}>
                            {String(errors.customerTypeName.message)}
                          </FormHelperText>
                        ) : (
                          ''
                        )}
                      </FormControl>

                      <FormControl fullWidth error={errors.customerWorkTypeName ? true : false}>
                        <InputLabel id='demo-simple-select-label'>수용가 업종</InputLabel>

                        <Controller
                          name='customerWorkTypeName'
                          control={control}
                          defaultValue={submitData?.customerWorkTypeName}
                          rules={{required: '필수 입력 사항입니다.'}}
                          render={({field}) => (
                            <Select
                              {...field}
                              error={errors.customerWorkTypeName ? true : false}
                              labelId='demo-simple-select-label'
                              label='수용가 업종'
                              onChange={(e: any) => {
                                field.onChange(e)
                                const findIdx = workType.findIndex(
                                  (item: any) => item.customerWorkTypeName === e.target.value
                                )

                                setSubmitData({
                                  ...submitData,
                                  customerWorkTypeId: workType[findIdx].idx,
                                  customerWorkTypeName: workType[findIdx].customerWorkTypeName,
                                })
                              }}
                            >
                              {_.map(workType, (el, i) => {
                                return (
                                  <MenuItem value={el.customerWorkTypeName} key={i}>
                                    {el.customerWorkTypeName}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          )}
                        />
                        {errors.customerWorkTypeName ? (
                          <FormHelperText className={'fs-6 fw-bold text-danger'}>
                            {String(errors.customerWorkTypeName.message)}
                          </FormHelperText>
                        ) : (
                          ''
                        )}
                      </FormControl>
                    </div>
                  </div>

                  <div className={'row my-4'}>
                    <div className={'row row-cols-2 pe-0'}>
                      <div className={'pe-0'}>
                        <label className='mb-3 fw-bold text-muted'>
                          이름
                          <span className={'fw-bolder fs-3 ms-1'} style={{color: 'red'}}>
                            *
                          </span>
                        </label>
                        <div className={'d-flex gap-3'}>
                          <TextField
                            {...register('customerName', {
                              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                setSubmitData({...submitData, customerName: e.target.value})
                              },
                              required: {
                                value: true,
                                message: '필수 입력 사항입니다.',
                              },
                            })}
                            label='이름'
                            variant='outlined'
                            value={submitData?.customerName}
                            fullWidth
                            onKeyDown={preventEnter}
                            error={errors.customerName ? true : false}
                            helperText={
                              errors.customerName ? (
                                <span className={'fs-6 fw-bold text-danger'}>
                                  {String(errors.customerName.message)}
                                </span>
                              ) : (
                                ''
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className={'pe-0'}>
                        <label className='mb-3 fw-bold text-muted d-flex'>전화번호</label>
                        <div className={'d-flex gap-3'}>
                          <TextField
                            {...register('phoneNumber', {
                              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                phoneValidation(e.target.value, setPhoneNumberForm, setSubmitData)
                              },
                            })}
                            label='전화번호'
                            type={'number'}
                            variant='outlined'
                            defaultValue={submitData?.phoneNumber}
                            fullWidth
                            onKeyDown={preventEnter}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={'row my-4 gap-3'}>
                    <label className='mb-3 fw-bold text-muted'>
                      주소
                      <span className={'fw-bolder fs-3 ms-1'} style={{color: 'red'}}>
                        *
                      </span>
                    </label>
                    <div className={'d-flex gap-3 col'}>
                      <TextField
                        {...register('addressRoad', {
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            setValue('addressRoad', e.target.value)
                            setSubmitData({...submitData, addressRoad: e.target.value})
                          },
                          required: {
                            value: true,
                            message: '필수 입력 사항입니다.',
                          },
                        })}
                        label='도로명 주소'
                        variant='filled'
                        value={
                          submitData.addressRoad !== ''
                            ? submitData.addressRoad
                            : address?.addressRoad
                        }
                        onKeyDown={preventEnter}
                        error={errors.addressRoad && address.addressRoad === '' ? true : false}
                        className={'col'}
                        helperText={
                          errors.addressRoad && !address.addressRoad ? (
                            <span className={'fs-6 fw-bold text-danger'}>
                              {String(errors.addressRoad.message)}
                            </span>
                          ) : (
                            ''
                          )
                        }
                      />
                      <TextField
                        {...register('addressNumber', {
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            setSubmitData({...submitData, addressNumber: e.target.value})
                          },
                          required: {
                            value: true,
                            message: '필수 입력 사항입니다.',
                          },
                        })}
                        label='지번주소'
                        variant='filled'
                        value={
                          submitData.addressNumber !== ''
                            ? submitData.addressNumber
                            : address?.addressNumber
                        }
                        onKeyDown={preventEnter}
                        error={errors.addressNumber && address.addressNumber === '' ? true : false}
                        className={'col'}
                      />
                      <Button
                        variant={'contained'}
                        size={'large'}
                        color={'primary'}
                        type='button'
                        onClick={onClickHandler}
                        className={'col-1 fs-6'}
                      >
                        {address.addressRoad !== '' ? '재검색' : '찾기'}
                      </Button>
                    </div>

                    <div className={'gap-3 d-flex'}>
                      <div className={'col-9'}>
                        <TextField
                          {...register('addressDetail', {
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                              setSubmitData({...submitData, addressDetail: e.target.value})
                            },
                          })}
                          label='상세주소'
                          defaultValue={submitData?.addressDetail}
                          variant='outlined'
                          fullWidth
                        />
                      </div>
                      <div>
                        <TextField
                          {...register('zone', {
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                              setSubmitData({...submitData, zone: e.target.value})
                            },
                          })}
                          label='분구'
                          variant='outlined'
                          defaultValue={submitData?.zone}
                          onKeyDown={preventEnter}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'col-lg'}>
                  <div className={'row my-4'}>
                    <div className={'row row-cols-2'}>
                      <div>
                        <label className='mb-3 fw-bold text-muted'>계량기 번호</label>
                        <div className={'d-flex gap-3'}>
                          <TextField
                            {...register('meterNumber', {
                              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                setSubmitData({...submitData, meterNumber: e.target.value})
                              },
                            })}
                            label='계량기 번호'
                            variant='outlined'
                            defaultValue={submitData?.meterNumber}
                            fullWidth
                            placeholder={'계량기 번호를 입력해주세요.'}
                          />
                        </div>
                      </div>
                      <div>
                        <label className='mb-3 fw-bold text-muted d-flex'>구경</label>
                        <div className={'d-flex gap-3'}>
                          <TextField
                            {...register('meterDiameter', {
                              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                setSubmitData({...submitData, meterDiameter: e.target.value})
                              },
                            })}
                            label='구경'
                            type={'number'}
                            variant='outlined'
                            defaultValue={submitData?.meterDiameter}
                            placeholder={'구경의 단위는 mm 입니다.'}
                            fullWidth
                            onKeyDown={preventEnter}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'row my-4'}>
                    <label className='mb-3 fw-bold text-muted'>검침일</label>
                    <div className={'d-flex gap-3'}>
                      <FormControl className={'col-lg-3'}>
                        <InputLabel id='demo-simple-select-label'>납부 고지 월</InputLabel>
                        <Controller
                          name='checkMonth'
                          control={control}
                          defaultValue={submitData?.checkMonth}
                          render={({field}) => (
                            <Select
                              {...field}
                              labelId='demo-simple-select-label'
                              label='납부 고지 월'
                              defaultValue={submitData?.checkMonth}
                              onChange={(e) => {
                                field.onChange(e)
                                setSubmitData({...submitData, checkMonth: e.target.value})
                              }}
                            >
                              <MenuItem value={'당월'}>당월</MenuItem>
                              <MenuItem value={'전월'}>전월</MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                      <TextField
                        {...register('checkDay', {
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            setSubmitData({...submitData, checkDay: e.target.value})
                          },

                          validate: (value) => {
                            const day = parseInt(value)
                            if (isNaN(day) || day < 1 || day > 31) {
                              return '1부터 31까지의 숫자를 입력해주세요.'
                            }
                            return true
                          },
                        })}
                        label='검침일'
                        variant='outlined'
                        type={'number'}
                        placeholder={'ex) 3'}
                        className={'col-3'}
                        defaultValue={submitData?.checkDay}
                        onKeyDown={preventEnter}
                        error={errors.checkDay ? true : false}
                        helperText={
                          errors.checkDay ? (
                            <span className={'fs-6 fw-bold text-danger'}>
                              {String(errors.checkDay.message)}
                            </span>
                          ) : (
                            <span className={'fs-7 fw-semibold'}>숫자만 입력가능합니다.</span>
                          )
                        }
                      />
                      <span className={'fw-bold mt-5'}>일</span>
                    </div>
                  </div>

                  <div className={'row my-4'}>
                    <label className='mb-3 fw-bold text-muted'>담당자</label>
                    <div className={'d-flex gap-3'}>
                      <TextField
                        {...register('managerName', {
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            setSubmitData({...submitData, managerName: e.target.value})
                          },
                        })}
                        label='담당자'
                        variant='outlined'
                        placeholder={'담당자를 입력해주세요'}
                        value={submitData?.managerName}
                        error={errors.managerName ? true : false}
                        helperText={
                          errors.managerName ? (
                            <span className={'fs-6 fw-bold text-danger'}>
                              {String(errors.managerName.message)}
                            </span>
                          ) : (
                            ''
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className={'row my-4'}>
                    <label className='mb-3 fw-bold text-muted'>메모</label>
                    <div className={'d-flex gap-3'}>
                      <TextField
                        {...register('memo', {
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            setSubmitData({...submitData, memo: e.target.value})
                          },
                        })}
                        label='메모'
                        variant='outlined'
                        placeholder={'메모를 입력해주세요.'}
                        fullWidth
                        multiline
                        defaultValue={submitData?.memo}
                        rows={2}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={'card-footer'}>
                <div className={'d-flex justify-content-end gap-5'}>
                  <div
                    className={'btn btn-danger align-self-center'}
                    onKeyDown={preventEnter}
                    onClick={() => {
                      resetHandler()
                    }}
                  >
                    초기화
                  </div>
                  <button className={'btn btn-primary align-self-center'} type='submit'>
                    등록
                  </button>
                </div>
              </div>
            </form>

            <div className='notice d-flex  bg-light-warning rounded border-warning border border-dashed p-6 bottom-0 position-absolute w-75 mb-5 '>
              <KTSVG
                path='icons/duotune/general/gen044.svg'
                className='svg-icon-2tx svg-icon-warning me-4'
              />
              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-bold'>
                  <h4 className='text-gray-800 fw-bolder'>필수정보를 입력해주세요!</h4>
                  <div className='fs-6 text-gray-600'>
                    문의사항은 영아이티 개발팀으로 연락바랍니다.
                    <a
                      className='fw-bolder'
                      href='https://young-it.co.kr'
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      YOUNG-IT
                    </a>
                    .
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobileDevice() && (
        <div className='card flex-grow-1' id='customer-register'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>{toolTipText.customerRegister.description}</h3>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  title={toolTipText.customerRegister.text}
                  className={'align-self-center'}
                  onClose={handleTooltipClose}
                  open={tooltipOpen}
                  TransitionComponent={Fade}
                  TransitionProps={{timeout: 600}}
                  placement={'bottom'}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow={true}
                >
                  <HelpIcon
                    onClick={handleTooltipOpen}
                    color={'primary'}
                    sx={{fontSize: '24px'}}
                    className={'ms-4'}
                  />
                </Tooltip>
              </ClickAwayListener>
            </div>
          </div>
          <div className='card-body p-9'>
            <form onSubmit={handleSubmit(submitHandler)}>
              <div className='container-fluid'>
                <div className='row gap-4 my-3'>
                  <span className={' fs-3 my-0 text-muted fw-bolder'}>
                    수용가 정보<strong className={'text-danger fw-bolder fs-5 mx-1'}>*</strong>
                  </span>
                  <div className={'d-flex gap-3 px-0'}>
                    <CustomerRegisterSearch
                      register={register}
                      name='officeName'
                      setValue={setValue}
                      setSubmitData={setSubmitData}
                      submitData={submitData}
                      errors={errors}
                    />
                  </div>
                  <div className={'d-flex gap-3 px-0'}>
                    <FormControl fullWidth error={errors.customerTypeName ? true : false}>
                      <InputLabel id='demo-simple-select-label'>수용가 종류</InputLabel>
                      <Controller
                        name='customerTypeName'
                        control={control}
                        defaultValue={submitData?.customerTypeName}
                        render={({field}) => (
                          <Select
                            {...field}
                            labelId='demo-simple-select-label'
                            label='수용가 종류'
                            onChange={(e: any) => {
                              field.onChange(e)
                              const findIdx = customerType.findIndex(
                                (item: any) => item.customerTypeName === e.target.value
                              )
                              setSubmitData({
                                ...submitData,
                                customerTypeId: customerType[findIdx].idx,
                                customerTypeName: customerType[findIdx].customerTypeName,
                              })
                            }}
                          >
                            {_.map(customerType, (el, i) => {
                              return (
                                <MenuItem value={el.customerTypeName} key={i}>
                                  {el.customerTypeName}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>

                    <FormControl fullWidth error={errors.customerWorkTypeName ? true : false}>
                      <InputLabel id='demo-simple-select-label'>수용가 업종</InputLabel>

                      <Controller
                        name='customerWorkTypeName'
                        control={control}
                        defaultValue={submitData?.customerWorkTypeName}
                        render={({field}) => (
                          <Select
                            {...field}
                            labelId='demo-simple-select-label'
                            label='수용가 업종'
                            onChange={(e: any) => {
                              field.onChange(e)
                              const findIdx = workType.findIndex(
                                (item: any) => item.customerWorkTypeName === e.target.value
                              )

                              setSubmitData({
                                ...submitData,
                                customerWorkTypeId: workType[findIdx].idx,
                                customerWorkTypeName: workType[findIdx].customerWorkTypeName,
                              })
                            }}
                          >
                            {_.map(workType, (el, i) => {
                              return (
                                <MenuItem value={el.customerWorkTypeName} key={i}>
                                  {el.customerWorkTypeName}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </div>
                  <div className={'d-flex gap-3 px-0'}>
                    <TextField
                      {...register('customerNumber', {
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setSubmitData({...submitData, customerNumber: e.target.value})
                        },
                        required: {
                          value: true,
                          message: '필수 입력 사항입니다.',
                        },
                      })}
                      label='수용가 번호 *'
                      variant='outlined'
                      value={submitData?.customerNumber}
                      fullWidth
                      onKeyDown={preventEnter}
                      error={errors.customerNumber ? true : false}
                      helperText={
                        errors.customerNumber ? (
                          <span className={'fs-6 fw-bold text-danger'}>
                            {String(errors.customerNumber.message)}
                          </span>
                        ) : (
                          ''
                        )
                      }
                    />
                    <TextField
                      {...register('contractNumber', {
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setSubmitData({...submitData, contractNumber: e.target.value})
                        },
                      })}
                      label='계약번호'
                      variant='outlined'
                      value={submitData?.contractNumber}
                      fullWidth
                      onKeyDown={preventEnter}
                    />
                  </div>
                </div>

                <div className='row gap-4 my-3'>
                  <span className={'fs-3 text-muted fw-bolder'}>
                    수용가 이름 및 전화번호
                    <strong className={'text-danger fw-bolder fs-5 mx-1'}>*</strong>
                  </span>
                  <div className={'d-flex gap-3 px-0'}>
                    <TextField
                      {...register('customerName', {
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setSubmitData({...submitData, customerName: e.target.value})
                        },
                        required: {
                          value: true,
                          message: '필수 입력 사항입니다.',
                        },
                      })}
                      label='이름 *'
                      variant='outlined'
                      value={submitData?.customerName}
                      fullWidth
                      onKeyDown={preventEnter}
                      error={errors.customerName ? true : false}
                      helperText={
                        errors.customerName ? (
                          <span className={'fs-6 fw-bold text-danger'}>
                            {String(errors.customerName.message)}
                          </span>
                        ) : (
                          ''
                        )
                      }
                    />
                    <TextField
                      {...register('phoneNumber', {
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          phoneValidation(e.target.value, setPhoneNumberForm, setSubmitData)
                        },
                      })}
                      label='전화번호'
                      type={'number'}
                      variant='outlined'
                      defaultValue={submitData?.phoneNumber}
                      fullWidth
                      onKeyDown={preventEnter}
                    />
                  </div>
                </div>

                <div className='row gap-4 my-3'>
                  <span className={'fs-3 text-muted fw-bolder'}>
                    주소
                    <strong className={'text-danger fw-bolder fs-5 mx-1'}>*</strong>
                  </span>

                  <TextField
                    {...register('addressRoad', {
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setValue('addressRoad', e.target.value)
                        setSubmitData({...submitData, addressRoad: e.target.value})
                      },
                      required: {
                        value: true,
                        message: '필수 입력 사항입니다.',
                      },
                    })}
                    label='도로명 주소 *'
                    variant='filled'
                    value={
                      submitData.addressRoad !== '' ? submitData.addressRoad : address?.addressRoad
                    }
                    onKeyDown={preventEnter}
                    fullWidth
                    hidden={submitData.addressRoad === '' ? true : false}
                  />
                  <TextField
                    {...register('addressNumber', {
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setSubmitData({...submitData, addressNumber: e.target.value})
                      },
                    })}
                    label='지번주소'
                    variant='filled'
                    value={
                      submitData.addressNumber !== ''
                        ? submitData.addressNumber
                        : address?.addressNumber
                    }
                    onKeyDown={preventEnter}
                    fullWidth
                    hidden={submitData.addressRoad === '' ? true : false}
                  />
                  <div className={'d-flex gap-3 px-0'}>
                    <TextField
                      {...register('addressDetail', {
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setSubmitData({...submitData, addressDetail: e.target.value})
                        },
                      })}
                      label='상세주소'
                      defaultValue={submitData?.addressDetail}
                      variant='outlined'
                      className={'col-9'}
                      hidden={submitData.addressRoad === '' ? true : false}
                    />
                    <TextField
                      {...register('zone', {
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setSubmitData({...submitData, zone: e.target.value})
                        },
                      })}
                      label='분구'
                      variant='outlined'
                      defaultValue={submitData?.zone}
                      onKeyDown={preventEnter}
                      hidden={submitData.addressRoad === '' ? true : false}
                    />
                  </div>
                  <Button
                    variant={'contained'}
                    size={'large'}
                    color={'primary'}
                    type='button'
                    onClick={onClickHandler}
                  >
                    {submitData.addressRoad !== '' ? '다시 검색' : '찾기'}
                  </Button>
                  {errors?.addressRoad ? (
                    <FormHelperText className={'fs-6 fw-bold text-danger'}>
                      {String(errors.addressRoad.message)}
                    </FormHelperText>
                  ) : (
                    ''
                  )}
                </div>

                <div className='row gap-4 my-5'>
                  <span className={'fs-3 text-muted fw-bolder'}>계량기 정보</span>
                  <div className={'d-flex gap-3 px-0'}>
                    <TextField
                      {...register('meterNumber', {
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setSubmitData({...submitData, meterNumber: e.target.value})
                        },
                      })}
                      label='계량기 번호'
                      variant='outlined'
                      defaultValue={submitData?.meterNumber}
                      fullWidth
                      placeholder={'계량기 번호를 입력해주세요.'}
                    />
                    <TextField
                      {...register('meterDiameter', {
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setSubmitData({...submitData, meterDiameter: e.target.value})
                        },
                      })}
                      label='구경'
                      type={'number'}
                      variant='outlined'
                      defaultValue={submitData?.meterDiameter}
                      placeholder={'구경의 단위는 mm 입니다.'}
                      fullWidth
                      onKeyDown={preventEnter}
                    />
                  </div>
                </div>

                <div className='row gap-4 my-3'>
                  <span className={'fs-3 text-muted fw-bolder'}>검침일 정보</span>
                  <div className={'d-flex gap-3 px-0'}>
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>납부 고지 월</InputLabel>
                      <Controller
                        name='checkMonth'
                        control={control}
                        defaultValue={submitData?.checkMonth}
                        render={({field}) => (
                          <Select
                            {...field}
                            labelId='demo-simple-select-label'
                            label='납부 고지 월'
                            defaultValue={submitData?.checkMonth}
                            onChange={(e) => {
                              field.onChange(e)
                              setSubmitData({...submitData, checkMonth: e.target.value})
                            }}
                          >
                            <MenuItem value={'당월'}>당월</MenuItem>
                            <MenuItem value={'전월'}>전월</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                    <TextField
                      {...register('checkDay', {
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setSubmitData({...submitData, checkDay: e.target.value})
                        },

                        validate: (value) => {
                          const day = parseInt(value)
                          if (isNaN(day) || day < 1 || day > 31) {
                            return '1부터 31까지의 숫자를 입력해주세요.'
                          }
                          return true
                        },
                      })}
                      label='검침일'
                      variant='outlined'
                      type={'number'}
                      placeholder={'ex) 3'}
                      className={'col-5'}
                      defaultValue={submitData?.checkDay}
                      onKeyDown={preventEnter}
                      error={errors.checkDay ? true : false}
                      helperText={
                        errors.checkDay ? (
                          <span className={'fs-6 fw-bold text-danger'}>
                            {String(errors.checkDay.message)}
                          </span>
                        ) : (
                          <></>
                        )
                      }
                    />
                    <span className={'fw-bold mt-7'} style={{fontSize: '22px'}}>
                      일
                    </span>
                  </div>
                </div>
                <div className='row gap-4 my-3'>
                  <span className={'fs-3 text-muted fw-bolder'}>담당자</span>
                  <div className={'d-flex gap-3 px-0 mx-0'}>
                    <TextField
                      {...register('managerName', {
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setSubmitData({...submitData, managerName: e.target.value})
                        },
                      })}
                      label='담당자'
                      variant='outlined'
                      placeholder={'담당자를 입력해주세요'}
                      value={submitData?.managerName}
                      error={errors.managerName ? true : false}
                      helperText={
                        errors.managerName ? (
                          <span className={'fs-6 fw-bold text-danger'}>
                            {String(errors.managerName.message)}
                          </span>
                        ) : (
                          ''
                        )
                      }
                    />
                  </div>
                </div>
                <div className={'row gap-4 my-3'}>
                  <span className={'fs-3 text-muted fw-bolder'}>메모</span>
                  <div className={'d-flex gap-3 px-0'}>
                    <TextField
                      {...register('memo', {
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setSubmitData({...submitData, memo: e.target.value})
                        },
                      })}
                      label='메모'
                      variant='outlined'
                      placeholder={'메모를 입력해주세요.'}
                      fullWidth
                      multiline
                      defaultValue={submitData?.memo}
                      rows={2}
                    />
                  </div>
                </div>
              </div>

              <div className={'card-footer p-4'}>
                <div className={'d-flex justify-content-end gap-5'}>
                  <Button
                    className={' align-self-center'}
                    variant={'contained'}
                    color={'info'}
                    size={'large'}
                    type={'submit'}
                  >
                    등록
                  </Button>
                </div>
              </div>
            </form>
            <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
              <KTSVG
                path='icons/duotune/general/gen044.svg'
                className='svg-icon-2tx svg-icon-warning me-4'
              />
              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-bold'>
                  <h4 className='text-gray-800 fw-bolder'>필수정보를 입력해주세요!</h4>
                  <div className='fs-6 text-gray-600'>
                    문의사항은 영아이티 개발팀으로 연락바랍니다.
                    <a
                      className='fw-bolder'
                      href='https://young-it.co.kr'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {' '}
                      YOUNG-IT
                    </a>
                    .
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <BatchOffcanvas toast={toast} />
      <ToastContainer />
    </>
  )
}

export default CustomerRegister
