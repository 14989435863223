import React from 'react'
import {Fade, FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material'
import {Help as HelpIcon} from '@mui/icons-material'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import dayjs from 'dayjs'
import {CustomWidthTooltip} from 'utils/designSytem/CustomTooltip'

const MeterariumPageLayout = (props: any) => {
  const {
    headerDescription,
    children,
    filter,
    id,
    setFilter,
    tooltipText,
    control,
    date,
    setDate,
    pannel,
  } = props
  const formControls: any = {
    period: (
      <FormControl className={'col-lg-1'}>
        <InputLabel id='demo-simple-select-label'>검색 기간 구분</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='customer-target'
          label='검색 기간 구분'
          defaultValue={filter.flag}
          onChange={(e) => {
            if (e.target.value === 'day') {
              setFilter({
                ...filter,
                flag: e.target.value,
                start: dayjs(filter.end).subtract(14, 'd').format('YYYYMMDD'),
                end: dayjs().format('YYYYMMDD'),
              })
            } else if (e.target.value === 'week') {
              setFilter({
                ...filter,
                flag: e.target.value,
                start: 4,
                end: dayjs().format('YYYYMMDD'),
              })
            } else if (e.target.value === 'month') {
              setFilter({
                ...filter,
                flag: e.target.value,
                start: 3,
                end: dayjs().format('YYYYMM'),
              })
            }
          }}
        >
          <MenuItem value={'day'}>일</MenuItem>
          <MenuItem value={'week'}>주</MenuItem>
          <MenuItem value={'month'}>월</MenuItem>
          {/*<MenuItem value={'year'}>년</MenuItem>*/}
        </Select>
      </FormControl>
    ),
    chooseMonth: (
      <div id={'date-range'} className={'col-lg-3'}>
        <div className={'d-flex gap-3 col-lg-5'}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['year', 'month']}
              openTo='month'
              mask={'____.__'}
              inputFormat={'YYYY년 MM월'}
              value={date}
              onChange={(newValue: any) => {
                setDate(newValue)
                setFilter({
                  ...filter,
                  end: dayjs(newValue).format('YYYYMM'),
                })
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
              minDate={new Date('2023-01-01')}
              maxDate={new Date('2030-12-31')}
            />
          </LocalizationProvider>
        </div>
      </div>
    ),
  }

  return (
    <div className='card flex-grow-1' id={id}>
      <div className='card-header '>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>
            {headerDescription}
            <CustomWidthTooltip
              title={tooltipText}
              TransitionComponent={Fade}
              TransitionProps={{timeout: 600}}
              placement={'right-start'}
              arrow={true}
              sx={{fontSize: '10px', letterSpacing: '1px', cursor: 'pointer'}}
            >
              <HelpIcon
                color={'primary'}
                sx={{fontSize: '24px'}}
                className={'ms-4 mb-2 cursor-pointer'}
              />
            </CustomWidthTooltip>
          </h3>
        </div>
      </div>
      {pannel}
      <div className='card-body p-9'>
        <div className={'container-fluid'}>
          <div className={'d-flex gap-3'} id={id + '-control-section'}>
            {control?.map((controlName: any) => formControls[controlName])}
          </div>
          <div className={'row row-cols-2 mt-5'} id={'content'}>
            <div id={id + '-chart-section'} className={'col-lg mt-3'}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MeterariumPageLayout
