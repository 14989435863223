import React from 'react'
import {useTimer} from 'react-timer-hook'
import {customConfirm, normalAlert} from 'utils/CustomAlert/Alert'

interface MyTimerProps {
  expiryTimestamp: Date
}

function MyTimer({expiryTimestamp}: MyTimerProps) {
  const {seconds, minutes} = useTimer({
    expiryTimestamp,
    onExpire: async () => {
      await normalAlert('인증이 만료되었습니다. 다시 인증해주세요.')
      window.location.replace('/auth')
    },
  })

  return (
    <div
      className={'border border-2 rounded-2 border-danger 0 py-3 bg-danger text-white'}
      style={{textAlign: 'center', width: 200}}
    >
      <h5 className={'text-white'}>인증만료까지 남은시간</h5>
      <div style={{fontSize: '30px'}}>
        <span>{minutes}</span>분 <span>{seconds}</span>초
      </div>
    </div>
  )
}

export default function Timer() {
  const time = new Date()
  time.setSeconds(time.getSeconds() + 299)
  if (window.location.pathname === '/password/reset') {
    window.addEventListener('keydown', async (e) => {
      if ((e.ctrlKey && e.key === 'r') || (e.metaKey && e.key === 'r') || e.key === 'F5') {
        e.preventDefault()
        if (
          await customConfirm(
            '새로고침 시 인증이 만료될 수 있습니다. 만료되면 재인증 절차를 거쳐야합니다.',
            'warning',
            '새로고침 진행'
          )
        ) {
          window.location.replace('/auth')
        } else return
      }
    })
  }
  return (
    <div>
      <MyTimer expiryTimestamp={time} />
    </div>
  )
}
