import dayjs from 'dayjs'

export const accountFilter = {
  searchOption: {
    id: false,
    name: false,
  },
  searchData: '',
  accountType: 'all',
  idActiveStatus: 'all',
}
