import dayjs from 'dayjs'

export const installerAssignFilter = {
  searchOption: {
    customerNumber: false,
    customerName: false,
    address: false,
  },
  searchData: '',
}

export const installCompleteFilter = {
  searchOption: {
    customerNumber: false,
    customerName: false,
    address: false,
  },
  searchData: '',
}

export const installAsFilter = {
  searchOption: {
    customerNumber: false,
    customerName: false,
    address: false,
  },
  searchData: '',
}
