/**
 * cooperActions
 * @param action
 * @param payload
 */
export const cooperAction = async (action: string, payload: any) => {
  switch (action) {
    case 'request':
      return {
        type: 'COOPER_INFO_REQUEST',
        payload: {
          label: payload?.cooperName,
          value: payload?.cooperIdx,
        },
      }
    case 'delete':
      return {
        type: 'COOPER_INFO_DELETE',
        payload: {
          label: payload,
          value: payload,
        },
      }

    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}
/**
 * officeActions
 * @param action
 * @param payload
 */
export const officeAction = async (action: string, payload: any) => {
  switch (action) {
    case 'request':
      return {
        type: 'OFFICE_INFO_REQUEST',
        payload: {
          label: payload?.officeName,
          value: payload?.officeId,
        },
      }
    case 'delete':
      return {
        type: 'OFFICE_INFO_DELETE',
        payload: {
          label: payload,
          value: payload,
        },
      }

    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}
/**
 * userInfoActions
 * @param action
 * @param payload
 */
export const userAction = async (action: string, payload: any) => {
  switch (action) {
    case 'request':
      return {
        type: 'USER_INFO_REQUEST',
        payload: {
          userInfo: {
            ...payload,
            userId: payload.id,
            userName: payload.name,
          },
        },
      }
    case 'delete':
      return {
        type: 'USER_INFO_DELETE',
        payload: {
          userInfo: null,
        },
      }

    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}
/**
 * PAGE_INFO_ACTIONS
 */
export const pageAction = async (action: string, payload: any) => {
  switch (action) {
    case 'update':
      return {
        type: 'PAGE_SIZE_UPDATE',
        payload: payload,
      }

    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}

export const positionAction = async (action: string, payload: any) => {
  switch (action) {
    case 'update':
      return {
        type: 'POSITION_UPDATE',
        payload: payload,
      }

    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}
