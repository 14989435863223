import axios from 'axios'
import {envBackHost, envImagePath} from 'GLOBAL/envVariables'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import _ from 'lodash'
import {
  confirmAlert,
  confirmASAlert,
  confirmASCancleAlert,
  ErrorAlert,
  noDataAlert,
  normalAlert,
  resultAlert,
  SuccessAlert,
} from 'utils/CustomAlert/Alert'

const apiKey = process.env.REACT_APP_WEATHER_API_KEY
/**
 * Interface
 */
interface Callbacks {
  success: (data: any) => void // API 응답 데이터의 타입을 `any` 대신 구체적인 타입으로 교체해야 합니다.
  fail: (error: any) => void // 오류 객체의 타입을 `any` 대신 구체적인 타입으로 교체해야 합니다.
  loading?: any
  count?: any
}

/**
 * MAP`s API
 */
export const getMarker = async (officeInfo: any, cooperInfo: any, setCluster: any) => {
  await axiosWithAuth()
    .get(
      `${envBackHost}/${officeInfo?.value === 'yit' ? 'all' : officeInfo?.value}/${
        cooperInfo?.value
      }/dashboard/marker`
    )
    .then((res) => {
      setCluster(res.data.res)
    })
    .catch((e) => {
      console.log('GET dashboard Info', e)
    })
}

export const getWeather = (setApiData: any) => {
  fetch(
    `https://api.openweathermap.org/data/2.5/weather?q=seoul&lang=kr&&appid=${apiKey}&units=metric`
  )
    .then((res) => res.json())
    .then((data) => {
      setApiData(data)
    })
}
/**
 * Device API
 */
export const serverCycleChange = async (
  user: any,
  target: any,
  value: number,
  {success, fail}: Callbacks
) => {
  try {
    const response = await axiosWithAuth().post(`${envBackHost}/office/device/cycle`, {
      officeId: target.officeId,
      cycle: value,
      requestId: user.id,
    })
    success(response.data)
    await resultAlert(response.data)
  } catch (error: any) {
    fail(error)
    await resultAlert({resultCode: error.response.status})
  }
}
export const getDeviceCheckList = async (
  cooper: any,
  filter: any,
  {success, fail, loading}: Callbacks
) => {
  loading(true)
  try {
    const response = await axiosWithAuth().get(`${envBackHost}/${cooper?.value}/device/check`, {
      params: {
        url: filter.url,
        searchOption: filter.searchOption,
        searchData: filter.searchData,
        checkStatus: filter.checkStatus,
        startDate: filter.startDate,
        endDate: filter.endDate,
      },
    })
    success(response.data.res)
  } catch (error) {
    fail(error)
  } finally {
    loading(false)
  }
}
export const getDeviceAssignList = async (
  cooper: any,
  filter: any,
  {success, fail, loading}: Callbacks
) => {
  loading(true)
  try {
    const response = await axiosWithAuth().get(`${envBackHost}/${cooper?.value}/device/assign`, {
      params: {
        url: filter.url,
        searchOption: filter.searchOption,
        searchData: filter.searchData,
        assignStatus: filter.assignStatus,
      },
    })
    success(response.data.res)
  } catch (error) {
    fail(error)
  } finally {
    loading(false)
  }
}

export const checkButtonHandler = async (
  type: string,
  data: any,
  user: any,
  {success, fail, loading}: Callbacks
) => {
  if (data.length <= 0) {
    await noDataAlert()
    loading(false)
    return
  }
  if (await confirmAlert(type === 'delete' ? '삭제' : type, 'info')) {
    switch (type) {
      case 'pass':
        if (_.find(data, (el) => el.checkStatus === true)) {
          await normalAlert('이미 승인된 단말기가 존재합니다.')
          return
        }
        loading(true)
        try {
          const response = await axiosWithAuth().post(`${envBackHost}/device/check/${type}`, {
            device: data,
            id: user.userId,
          })
          if (response.data.resultCode === 0) {
            await resultAlert(response.data)
            success(response.data)
          }
        } catch (error: any) {
          fail(error)
          await resultAlert({resultCode: error.response.status})
        } finally {
          loading(false)
        }
        break
      case 'fail':
        loading(true)
        try {
          const response = await axiosWithAuth().post(`${envBackHost}/device/check/${type}`, {
            device: data,
            id: null,
          })
          if (response.data.resultCode === 0) {
            await resultAlert(response.data)
            success(response.data)
          }
        } catch (error: any) {
          fail(error)
          await resultAlert({resultCode: error.response.status})
        } finally {
          loading(false)
        }
        break

      case 'delete':
        loading(true)
        try {
          const response = await axiosWithAuth().post(`${envBackHost}/device/deletelist`, {
            device: data,
          })
          if (response.data.resultCode === 0) {
            await resultAlert(response.data)
            success(response.data)
          }
        } catch (error: any) {
          fail(error)
          await resultAlert({resultCode: error.response.status})
        } finally {
          loading(false)
        }
        break
    }
  } else return
}

export const allocationCancelHandler = async (data: any, {success, fail, loading}: Callbacks) => {
  if (data.length <= 0) {
    await noDataAlert()
    loading(false)
    return
  }
  if (_.find(data, (el) => el.assignToId === null)) {
    await normalAlert('할당이 진행되지 않은 단말기가 존재합니다.')
    return
  }
  if (await confirmAlert('할당취소', 'info')) {
    loading(true)
    try {
      const response = await axiosWithAuth().post(`${envBackHost}/device/assign/unassigned`, {
        device: data,
        fromId: null,
        toId: null,
      })
      if (response.data.resultCode === 0) {
        await resultAlert(response.data)
        success(response.data)
      }
    } catch (error: any) {
      fail(error)
      await resultAlert({resultCode: error.response.status})
    } finally {
      loading(false)
    }
  } else return
}
export const getDeviceInfo = async (
  office: any,
  cooper: any,
  filter: any,
  {success, fail, loading, count}: Callbacks
) => {
  loading(true)
  try {
    const response = await axiosWithAuth().get(
      `${envBackHost}/${office?.value === 'yit' ? 'all' : office?.value}/${
        cooper.value
      }/device/installinfo`,
      {
        params: filter,
      }
    )
    success(response.data.res)
    count(response.data.count)
  } catch (error) {
    fail(error)
  } finally {
    loading(false)
  }
}
export const getDeviceImages = async (
  office: any,
  cooper: any,
  filter: any,
  {success, fail, loading, count}: Callbacks
) => {
  loading(true)
  try {
    const response = await axiosWithAuth().get(
      `${envBackHost}/${office?.value === 'yit' ? 'all' : office?.value}/${
        cooper?.value
      }/checkmeter/list`,
      {
        params: filter,
      }
    )
    success(response.data.res)
    count(response.data.count)
  } catch (error) {
    fail(error)
  } finally {
    loading(false)
  }
}

/**
 * Customer API
 */
export const registerCustomer = async (values: any, reset: any) => {
  await axiosWithAuth()
    .post(`${envBackHost}/customer`, values)
    .then(async (res) => {
      if (res.data.resultCode === 0) {
        await resultAlert(res.data)
        reset()
      } else {
        await resultAlert(res.data, res.data.res)
      }
    })
    .catch(async (e) => {
      console.log('2', e)
      await resultAlert({resultCode: e.response.status})
    })
}
export const getAllocationList1 = async (info: any, filter: any, callback: any) => {
  await axiosWithAuth()
    .get(`${envBackHost}/${info?.value}/customer/assign`, {
      params: filter,
    })
    .then((res) => {
      callback(res.data.res)
    })
}
export const getAllocationList = async (office: any, filter: any, {success, fail}: Callbacks) => {
  try {
    const response = await axiosWithAuth().get(`${envBackHost}/${office?.value}/customer/assign`, {
      params: filter,
    })
    success(response.data.res)
  } catch (error) {
    fail(error)
  } finally {
  }
}
export const cancelCustomerAllocation = async (info: any, data: any, callbacks: any) => {
  await axiosWithAuth()
    .post(`${envBackHost}/${info?.value}/customer/assign/unassigned`, data)
    .then(async (res) => {
      await resultAlert(res.data)
      if (res.data.resultCode === 0) {
        callbacks.forEach((callback: any) => {
          if (typeof callback === 'function') {
            callback()
          }
        })
      }
    })
    .catch(async (e) => {
      await resultAlert({resultCode: e.response.status})
    })
}
export const changeInstallStatus = async (info: any, data: any) => {
  try {
    if (await confirmAlert('설치가능', 'info')) {
      const response = await axiosWithAuth().post(
        `${envBackHost}/${info?.value}/customer/available`,
        data
      )

      if (response.data.resultCode === 0) {
        await resultAlert(response.data)
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  } catch (e: any) {
    await resultAlert({resultCode: e.response.status})
    return false
  }
}
export const deleteCustomerAllocation = async (data: any, callbacks: any) => {
  if (data?.length === 0) {
    await noDataAlert()
    return
  }
  if (await confirmAlert('삭제', 'info')) {
    axiosWithAuth()
      .post(`${envBackHost}/customer/delete`, {
        customer: data,
      })
      .then(async (res) => {
        await resultAlert(res.data)
        if (res.data.resultCode === 0) {
          callbacks.forEach((callback: any) => {
            if (typeof callback === 'function') {
              callback()
            }
          })
        }
      })
      .catch(async (e) => {
        await resultAlert({resultCode: e.response.status})
      })
  } else {
    return
  }
}
export const getCustomerList = async (
  officeInfo: any,
  cooperInfo: any,
  filter: any,
  onSuccess: any,
  onError: any,
  onLoading: any
) => {
  try {
    onLoading(true)
    const res = await axiosWithAuth().get(
      `${envBackHost}/${officeInfo?.value === 'yit' ? 'all' : officeInfo?.value}/${
        cooperInfo?.value
      }/customer/info`,
      {
        params: filter,
      }
    )
    onSuccess(res.data.res, res.data.count)
  } catch (error) {
    onError(error)
  } finally {
    onLoading(false)
  }
}

/**
 * Office API
 */
export const getOfficeList = async (userInfo: any, callBack: any) => {
  axiosWithAuth()
    .get(`/office/${userInfo?.officeId}`)
    .then((res: any) => {
      const data = res.data.res
      const transformedData = data.map((el: any) => ({
        label: el?.officeName,
        value: el?.officeId,
      }))
      callBack((prev: any[]) => [...(prev || []), ...transformedData])
    })
    .catch(async (err) => {
      await resultAlert({resultCode: err.response.status})
    })
}

export const getWorkTypeList = async (userInfo: any, callBack: any) => {
  axiosWithAuth()
    .get(`/office/${userInfo?.officeId}/customerworktype`)
    .then((res) => {
      callBack(res.data.res)
    })
    .catch(async (e) => {
      await resultAlert({resultCode: e.response.status})
    })
}
export const getCustomerTypeList = async (userInfo: any, callBack: any) => {
  axiosWithAuth()
    .get(`/office/${userInfo?.officeId}/customertype`)
    .then((res) => {
      callBack(res.data.res)
    })
    .catch(async (e) => {
      await resultAlert({resultCode: e.response.status})
    })
}
export const getMonitoringList = async (
  office: any,
  cooper: any,
  filter: any,
  {success, fail, loading}: Callbacks
) => {
  loading(true)
  try {
    const response = await axiosWithAuth().get(
      `${envBackHost}/${office?.value === 'yit' ? 'all' : office?.value}/${
        cooper?.value
      }/customer/monitoring`,
      {params: filter}
    )
    success(response?.data?.res)
  } catch (error) {
    fail(error)
  } finally {
    loading(false)
  }
}

export const getCheckList = async (
  office: any,
  cooper: any,
  filter: any,
  {success, fail, loading, count}: Callbacks
) => {
  loading(true)
  try {
    const response = await axiosWithAuth().get(
      `${envBackHost}/${office?.value === 'yit' ? 'all' : office?.value}/${
        cooper?.value
      }/customer/imagelist`,
      {
        params: filter,
      }
    )
    count(response?.data?.count)
    success(response?.data?.res)
  } catch (error) {
    fail(error)
  } finally {
    loading(false)
  }
}
export const getCheckListChart = async (
  office: any,
  cooper: any,
  filter: any,
  {success, fail, loading}: Callbacks
) => {
  loading(true)
  try {
    const response = await axiosWithAuth().get(
      `${envBackHost}/${office?.value === 'yit' ? 'all' : office?.value}/${
        cooper?.value
      }/customer/useinfo`,
      {
        params: filter,
      }
    )
    success(response?.data?.res)
    console.log(response.data)
  } catch (error) {
    fail(error)
  } finally {
    loading(false)
  }
}
export const deleteCustomerList = async (data: any, {success, fail, loading}: Callbacks) => {
  if (data.length === 0) {
    await noDataAlert()
    return
  } else if (data?.length > 1) {
    await normalAlert('수용가 삭제는 1개씩만 가능합니다.')
    return
  } else if (data[0].customerStatus === 'as') {
    await normalAlert('AS가 진행 중인 수용가는 삭제할 수 없습니다.')
    return
  }
  if (await confirmAlert('삭제', 'info')) {
    try {
      loading(true)
      const response = await axiosWithAuth().delete(
        `${envBackHost}/${data[0]?.officeId}/customer/${data[0]?.customerNumber}`
      )
      success(response.data.res)
      await resultAlert(response.data)
      console.log(response)
    } catch (e) {
      fail(e)
    } finally {
      loading(false)
    }
  } else return
}
export const getCheckReportList = async (
  office: any,
  cooper: any,
  filter: any,
  {success, fail, loading, count}: Callbacks
) => {
  loading(true)
  try {
    const response = await axiosWithAuth().get(
      `${envBackHost}/${office?.value === 'yit' ? 'all' : office?.value}/${
        cooper?.value
      }/customer/checkreport`,
      {
        params: filter,
      }
    )
    count(response?.data?.count)
    success(response?.data?.res)
  } catch (error) {
    fail(error)
  } finally {
    loading(false)
  }
}
export const checkValueUpdate = async (data: any, {success, fail, loading}: Callbacks) => {
  loading(true)
  if (data?.value === null && data?.startValue === null) {
    await normalAlert('수정할 검침값을 입력해주세요.')
    loading(false)
    return
  }
  if (await confirmAlert('수정', 'info')) {
    try {
      const response = await axiosWithAuth().post(`${envBackHost}/checkmeter/update`, data)

      await resultAlert(response.data)
      success(response.data)
    } catch (error) {
      fail(error)
    } finally {
      loading(false)
    }
  } else {
    loading(false)
    return
  }
}
export const getClientInfo = async (
  office: any,
  data: any,
  {success, fail, loading}: Callbacks
) => {
  try {
    loading(true)
    const response = await axiosWithAuth().post(`${envBackHost}/${office?.value}/client`, data)
    success(response.data.res)
  } catch (e) {
    fail(e)
  } finally {
    loading(false)
  }
}
export const getMinimumFlowList = async (
  office: any,
  cooper: any,
  filter: any,
  {success, fail, loading, count}: Callbacks
) => {
  try {
    loading(true)
    const response = await axiosWithAuth().get(
      `${envBackHost}/${office?.value === 'yit' ? 'all' : office?.value}/${
        cooper?.value
      }/customer/minimumflow`,
      {
        params: filter,
      }
    )
    success(response.data.res)
    count(response.data.count)
  } catch (e) {
    fail(e)
  } finally {
    loading(false)
  }
}

export const getLeakList = async (
  office: any,
  cooper: any,
  filter: any,
  {success, fail, loading, count}: Callbacks
) => {
  try {
    loading(true)
    const response = await axiosWithAuth().get(
      `${envBackHost}/${office?.value === 'yit' ? 'all' : office?.value}/${
        cooper?.value
      }/customer/leak`,
      {
        params: filter,
      }
    )

    success(response.data.res)
    count(response.data.count)
  } catch (e) {
    fail(e)
  } finally {
    loading(false)
  }
}

export const getNewLeakList = async (
  office: any,
  cooper: any,
  filter: any,
  {success, fail, loading, count}: Callbacks
) => {
  try {
    loading(true)
    const response = await axiosWithAuth().get(
      `${envBackHost}/${office?.value === 'yit' ? 'all' : office?.value}/${
        cooper?.value
      }/customer/leakTemp`,
      {
        params: filter,
      }
    )
    success(response.data.res)
    count(response.data.count)
  } catch (e) {
    fail(e)
  } finally {
    loading(false)
  }
}
export const getLeakListUncertainAll = async (
  office: any,
  cooper: any,
  filter: any,
  {success, fail, loading, count}: Callbacks
) => {
  try {
    loading(true)
    const response = await axiosWithAuth().get(
      `${envBackHost}/${office?.value === 'yit' ? 'all' : office?.value}/${
        cooper?.value
      }/customer/leakTemp/uncertain`,
      {
        params: filter,
      }
    )
    success(response.data.res)
    count(response.data.count)
  } catch (e) {
    fail(e)
  } finally {
    loading(false)
  }
}
export const getLeakListUncertainUser = async (
  office: any,
  cooper: any,
  filter: any,
  {success, fail, loading, count}: Callbacks
) => {
  try {
    loading(true)
    const response = await axiosWithAuth().get(
      `${envBackHost}/${office?.value === 'yit' ? 'all' : office?.value}/${
        cooper?.value
      }/customer/leakTemp/uncertain/customer`,
      {
        params: filter,
      }
    )
    // console.log('uncertainUser res', response.data)
    success(response.data.res)
  } catch (e) {
    fail(e)
  } finally {
    loading(false)
  }
}

export const getLongTermUnusedList = async (
  office: any,
  cooper: any,
  filter: any,
  {success, fail, loading, count}: Callbacks
) => {
  try {
    loading(true)
    const response = await axiosWithAuth().get(
      `${envBackHost}/${office?.value === 'yit' ? 'all' : office?.value}/${
        cooper?.value
      }/customer/longtermunused`,
      {
        params: filter,
      }
    )
    success(response.data.res)
    count(response.data.count)
    loading(false)
  } catch (e) {
    fail(e)
  } finally {
    loading(false)
  }
}

export const getFreezeWarningList = async (
  office: any,
  cooper: any,
  filter: any,
  {success, fail, loading, count}: Callbacks
) => {
  try {
    loading(true)
    const response = await axiosWithAuth().get(
      `${envBackHost}/${office?.value === 'yit' ? 'all' : office?.value}/${
        cooper?.value
      }/customer/freeze-warning`,
      {
        params: filter,
      }
    )
    success(response.data.res)
    count(response.data.count)
  } catch (e) {
    fail(e)
  } finally {
    loading(false)
  }
}

/**
 * Login API
 */
export const certifitaionRequest = async (data: any, {success, fail}: Callbacks) => {
  try {
    const response = await axios.post(`${envBackHost}/change/password/check`, data)
    success(response.data.res)
  } catch (e) {
    fail(e)
  }
}
export const passwordChangeRequest = async (data: any, {success, fail}: Callbacks) => {
  try {
    const response = await axios.post(`${envBackHost}/change/password`, {
      id: data.id,
      password: data.password, //feature/sc
      token: data.token,
    })
    success(response.data)
  } catch (e) {
    fail(e)
  }
}
/**
 * AS API
 */
export const getAsCauseList = async (callback: any) => {
  await axiosWithAuth()
    .get(`${envBackHost}/worktype/as/codetype/cause`)
    .then((res) => {
      callback(res.data.res)
    })
}

export const getAsCauseListT = async () => {
  await axiosWithAuth()
    .get(`${envBackHost}/worktype/as/codetype/cause`)
    .then((res) => {
      return res
    })
}

export const requestAs = async (info: any, data: any, cause: any, callbacks: any) => {
  if (data.length === 0) {
    await noDataAlert()
    return
  } else if (_.some(data, (el) => el.asIdx !== null && typeof el.asIdx === 'string')) {
    await normalAlert('AS가 진행 중인 수용가가 있습니다.')
  } else {
    if (cause === '') {
      await normalAlert('AS 사유를 선택해주세요.')
      return
    }
    if (await confirmAlert('AS등록', 'info')) {
      await axiosWithAuth()
        .post(`${envBackHost}/as`, {
          asList: data,
          asId: null,
          causeIdx: cause,
          registerId: info.userId,
        })
        .then(async (res) => {
          await resultAlert(res.data)
          console.log(res)
          if (res.data.resultCode === 0) {
            callbacks.setCheckData([])
            callbacks.setSelectionModel([])
            callbacks.searchCallback()
            callbacks.setAsReasonData('')
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    } else {
      return
    }
  }
}
export const requestAsNew = async (info: any, data: any, callbacks: any) => {
  await axiosWithAuth()
    .get(`${envBackHost}/worktype/as/codetype/cause`)
    .then(async (res) => {
      const cause = await confirmASAlert('A/S등록', 'info', res)
      console.log('cause', cause)

      if (cause) {
        if (data.length === 0) {
          await noDataAlert()
          return
        } else if (data.asIdx !== null && typeof data.asIdx === 'string') {
          await normalAlert('AS가 진행 중인 수용가가 있습니다.')
          return
        }

        await axiosWithAuth()
          .post(`${envBackHost}/as`, {
            asList: [data],
            asId: null,
            causeIdx: cause,
            registerId: info.userId,
          })
          .then(async (res) => {
            await resultAlert(res.data)

            if (res.data.resultCode === 0) {
              callbacks()
            }
          })
          .catch(async (e) => {
            await resultAlert({resultCode: e.response.status})
          })
      } else {
        return
      }
    })
}

export const requestAsCancel = async (info: any, data: any, callbacks: any) => {
  if (data.length === 0) {
    await noDataAlert()
  } else if (
    _.map(data, (el) => {
      return el.asIdx
    }).includes(null)
  ) {
    await normalAlert('AS가 진행 중이지 않은 수용가가 존재합니다.')
  } else {
    if (await confirmAlert('AS취소', 'info')) {
      axiosWithAuth()
        .put(`${envBackHost}/as/delete`, {
          asList: data,
        })
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            callbacks.setCheckData([])
            callbacks.setSelectionModel([])
            callbacks.searchCallback()
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    }
  }
}

export const requestAsCancelNew = async (info: any, data: any, callbacks: any) => {
  if (data.length === 0) {
    await noDataAlert()
  } else if (data.asIdx === null) {
    await normalAlert('AS가 진행 중이지 않은 수용가가 존재합니다.')
  } else {
    if (await confirmASCancleAlert('A/S취소', 'info')) {
      axiosWithAuth()
        .put(`${envBackHost}/as/delete`, {
          asList: [data],
        })
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            callbacks()
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    }
  }
}

export const getAsList = async (
  office: any,
  cooper: any,
  filter: any,
  {success, fail, loading, count}: Callbacks
) => {
  loading(true)
  try {
    const response = await axiosWithAuth().get(
      `${office?.value === 'yit' ? 'all' : office?.value}/${cooper.value}/task/as`,
      {
        params: filter,
      }
    )
    success(response.data.res)
  } catch (error) {
    fail(error)
  } finally {
    loading(false)
  }
}
export const getAsListCode = async ({success, fail, loading, count}: Callbacks) => {
  try {
    const response = await axiosWithAuth().get(`${envBackHost}/worktype/as/codetype/all`)
    success(response.data.res)
  } catch (error) {
    fail(error)
  } finally {
  }
}
export const putAsUnassign = async (data: any, user: any, {success, fail}: Callbacks) => {
  if (data?.length === 0) {
    await noDataAlert()
    return
  }
  if (
    _.some(
      data,
      (el) =>
        (el.resultIdx !== null && typeof el.resultIdx === 'string') || el.resultIdx === 'number'
    )
  ) {
    await normalAlert('할당 취소할 수 없는 AS가 포함되어 있습니다.')
    return
  }
  if (await confirmAlert('할당취소', 'info')) {
    try {
      const response = await axiosWithAuth().put(`${envBackHost}/as`, {
        asList: data,
        asId: null,
        updateId: user.userId,
      })
      success(response.data)
      await resultAlert(response.data)
    } catch (error: any) {
      fail(error)
      await resultAlert({resultCode: error.response.status})
    }
  } else return
}

export const putAsCancel = async (data: any, {success, fail}: Callbacks) => {
  if (data?.length === 0) {
    await noDataAlert()
    return
  }
  if (
    _.some(
      data,
      (el) =>
        (el.resultIdx !== null && typeof el.resultIdx === 'string') || el.resultIdx === 'number'
    )
  ) {
    await normalAlert('취소할 수 없는 AS가 포함되어 있습니다.')
    return
  }
  if (await confirmAlert('AS취소', 'info')) {
    try {
      const response = await axiosWithAuth().put(`${envBackHost}/as/delete`, {
        asList: data,
      })
      success(response.data)
      await resultAlert(response.data)
    } catch (error: any) {
      fail(error)
      await resultAlert({resultCode: error.response.status})
    }
  } else return
}
export const getAsDetailImage = async (data: any, {success, fail, loading}: Callbacks) => {
  try {
    loading(true)
    const response = await axiosWithAuth().get(`${envBackHost}/task/as/${data}`)

    success(response.data.res)
  } catch (error) {
    fail(error)
  } finally {
    setTimeout(() => {
      loading(false)
    }, 600)
  }
}
export const getRecoveryInfoList = async (
  cooper: any,
  filter: any,
  {success, fail, loading}: Callbacks
) => {
  loading(true)
  try {
    const response = await axiosWithAuth().get(
      `${envBackHost}/${cooper?.value}/recovery/device/list`,
      {
        params: filter,
      }
    )
    success(response.data.res)
  } catch (error) {
    fail(error)
  } finally {
    loading(false)
  }
}
export const deleteDisuseDevice = async (data: any, user: any, {success, fail}: Callbacks) => {
  if (await confirmAlert('폐기처리', 'warning')) {
    try {
      const response = await axiosWithAuth().delete(
        `${envBackHost}/recovery/device/disuse/${user?.userId}/${data}`
      )
      success(response.data.res)
      await resultAlert(response.data)
    } catch (error: any) {
      fail(error)
      await resultAlert({resultCode: error.response.status})
    }
  } else return
}

export const putRecycleDevice = async (data: any, user: any, {success, fail}: Callbacks) => {
  if (data?.length === 0) {
    await noDataAlert()
    return
  }
  if (await confirmAlert('재사용', 'warning')) {
    try {
      const response = await axiosWithAuth().put(`${envBackHost}/recovery/device/recycle`, {
        device: data,
        id: user?.userId,
      })
      success(response.data)
      await resultAlert(response.data)
    } catch (error: any) {
      fail(error)
      await resultAlert({resultCode: error.response.status})
    }
  } else return
}
/**
 * account Api
 */
export const getAllOfficeList = async (user: any, {success, fail}: Callbacks) => {
  try {
    const response = await axiosWithAuth().get(`${envBackHost}/office/${user?.officeId}`)
    success(response.data.res)
  } catch (error) {
    fail(error)
  }
}
export const getAccountList = async (office: any, filter: any, {success, fail}: Callbacks) => {
  try {
    const response = await axiosWithAuth().get(
      `${envBackHost}/${office?.value === 'yit' ? 'all' : office?.value}/account`,
      {
        params: filter,
      }
    )
    success(response.data.res)
  } catch (error) {
    fail(error)
  }
}
export const getCooperList = async ({success, fail}: Callbacks) => {
  try {
    const response = await axiosWithAuth().get(`${envBackHost}/cooperation/device`)
    success(response.data.res)
  } catch (error) {
    fail(error)
  }
}
export const getAccountTypeList = async ({success, fail}: Callbacks) => {
  try {
    const response = await axiosWithAuth().get(`${envBackHost}/accounttype`)
    success(response.data.res)
  } catch (error) {
    fail(error)
  }
}
/**
 * Install API
 */

export const getInstallAllCount = async (user: any, {success, fail}: Callbacks) => {
  try {
    const installAvailable = await axiosWithAuth().get(
      `/install/${user?.userId}/customer/assign/count`
    )
    const installComplete = await axiosWithAuth().get(`/install/${user?.userId}/complete/count`)
    const installAs = await axiosWithAuth().get(`/as/${user?.userId}/customer/assign/count`)
    const installAsComplete = await axiosWithAuth().get(`/as/${user?.userId}/complete/count`)
    success({
      installAvailableCount: installAvailable.data.res,
      installCompleteCount: installComplete.data.res,
      installAsCount: installAs.data.res,
      installAsCompleteCount: installAsComplete.data.res,
    })
  } catch (error) {
    fail(error)
  }
}
export const getInstallList = async (
  user: any,
  location: string,
  filter: any,
  {success, fail, loading}: Callbacks
) => {
  loading(true)
  try {
    switch (location) {
      case 'installCustomer':
        await axiosWithAuth()
          .get(`/install/${user?.userId}/customer/assign`, {
            params: filter,
          })
          .then((res) => {
            success(res.data.res)
          })
          .catch((e) => {
            fail(e)
          })
        break
      case 'installDevice':
        await axiosWithAuth()
          .get(`/install/${user?.userId}/device/assign`)
          .then((res) => {
            success(res.data.res)
          })
          .catch((e) => {
            fail(e)
          })
        break
      case 'complete':
        await axiosWithAuth()
          .get(`/install/${user?.userId}/complete`, {
            params: filter,
          })
          .then((res) => {
            success(res.data.res)
          })
          .catch((e) => {
            fail(e)
          })
        break
      case 'as':
        await axiosWithAuth()
          .get(`/as/${user?.userId}/list`)
          .then((res) => {
            success(res.data.res)
          })
          .catch((e) => {
            fail(e)
          })
        break
      case 'asComplete':
        await axiosWithAuth()
          .get(`/as/${user?.userId}/complete`, {
            params: filter,
          })
          .then((res) => {
            success(res.data.res)
          })
          .catch((e) => {
            fail(e)
          })
        break
      default:
        break
    }
  } catch (e) {
    fail(e)
  } finally {
    loading(false)
  }
}
export const getPosition = async ({success, fail}: Callbacks) => {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        success(position)
      },
      (error) => {
        fail('위치정보를 사용할 수 없습니다.')
      },
      {enableHighAccuracy: true, timeout: 2000, maximumAge: 0}
    )
  } else {
    fail('위치정보를 사용할 수 없습니다.')
  }
}
const applyBrightnessAndContrast = (
  imageData: any,
  brightness: any,
  contrast: any,
  rotate: any
) => {
  const canvas = document.createElement('canvas')
  const context: any = canvas.getContext('2d')
  const image = new Image()

  return new Promise((resolve, reject) => {
    image.onload = () => {
      canvas.width = image.width
      canvas.height = image.height
      context.translate(canvas.width / 2, canvas.height / 2)

      // 회전 각도 설정 (단위는 라디안이므로 각도를 라디안으로 변환)
      context.rotate((rotate * Math.PI) / 180)

      // 원래 위치로 다시 이동
      context.translate(-canvas.width / 2, -canvas.height / 2)

      // context.filter = `brightness(${brightness}%) contrast(${contrast}%)`
      context.drawImage(image, 0, 0)
      resolve(canvas.toDataURL('image/jpeg'))
    }

    image.onerror = reject
    image.src = imageData
  })
}
export const imageDownload = async (
  imageUrl: any,
  brightness: any,
  contrast: any,
  rotate: any,
  device: string
) => {
  fetch(envImagePath + imageUrl, {method: 'GET'})
    .then((res) => res.blob())
    .then((blob) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        const imageData = reader.result
        applyBrightnessAndContrast(imageData, brightness, contrast, rotate)
          .then((modifiedImageUrl: any) => {
            const a = document.createElement('a')
            a.href = modifiedImageUrl
            a.download = `${device}_install.jpg`
            document.body.appendChild(a)
            a.click()
            setTimeout(() => {
              window.URL.revokeObjectURL(modifiedImageUrl)
              a.remove()
            }, 60000)
          })
          .catch((err) => {
            console.error('Failed to apply brightness and contrast:', err)
          })
      }
      reader.readAsDataURL(blob)
    })
    .catch((err) => {
      console.error('Error while fetching image:', err)
    })
}
export const imageRefresh = async (device: any, {success, fail, loading}: Callbacks) => {
  loading(true)
  if (device.imei === null) {
    loading(false)
    await ErrorAlert('이미지를 불러 올 수 없는 상태입니다. 단말기를 확인 해주세요')
    return
  }
  try {
    const response = await axiosWithAuth().get(
      `install/${device?.imei}/send/${device?.startIdxDate}`
    )
    success(response.data.res)
    await SuccessAlert(response.data.res.message)
  } catch (error) {
    fail(error)
  } finally {
    loading(false)
  }
}
export const installComplete = async (data: any, {success, fail, loading}: Callbacks) => {
  loading(true)
  if (await confirmAlert('설치', 'info')) {
    try {
      const response = await axiosWithAuth().post(`install`, data)
      success(response.data.res)
      console.log(response)
      await resultAlert(response.data)
    } catch (error: any) {
      fail(error)
      await resultAlert({resultCode: error.response.status})
    } finally {
      loading(false)
    }
  } else {
    loading(false)
    return
  }
}
export const getSolutionList = async ({success, fail}: Callbacks) => {
  try {
    const response = await axiosWithAuth().get(`${envBackHost}/worktype/as/codetype/result`)
    success(response.data.res)
  } catch (e) {
    fail(e)
  }
}

export const requestAsComplete = async (
  type: string,
  data: any,
  {success, fail, loading}: Callbacks
) => {
  loading(true)
  if (await confirmAlert('AS완료', 'info')) {
    try {
      switch (type) {
        case 'deviceChange':
          const deviceChangeResponse = await axiosWithAuth().post(
            `${envBackHost}/as/${data?.idx}/devicechange/complete`,
            data
          )
          success(deviceChangeResponse.data)
          await resultAlert(deviceChangeResponse.data)
          break
        case 'efosChange':
          const efosChangeResponse = await axiosWithAuth().post(
            `${envBackHost}/as/${data?.idx}/efos/complete`,
            data
          )
          success(efosChangeResponse.data)
          await resultAlert(efosChangeResponse.data)
          break
        case 'normalChange':
          const normalChangeResponse = await axiosWithAuth().post(
            `${envBackHost}/as/${data?.idx}/clear/complete`,
            data
          )
          success(normalChangeResponse.data)
          await resultAlert(normalChangeResponse.data)
          break
      }
    } catch (error: any) {
      fail(error)
      await resultAlert({resultCode: error.response.status})
    } finally {
      loading(false)
    }
  } else {
    loading(false)
    return
  }
}
export const notInstallable = async (data: any, {success, fail, loading}: Callbacks) => {
  loading(true)
  if (await confirmAlert('설치불가', 'info')) {
    try {
      const response = await axiosWithAuth().post(`${envBackHost}/install/not-available`, data)
      success(response.data.res)
      await resultAlert(response.data)
    } catch (error: any) {
      fail(error)
      await resultAlert({resultCode: error.response.status})
    } finally {
      loading(false)
    }
  } else {
    loading(false)
    return
  }
}
export const customerUninstall = async (data: any, {success, fail, loading}: Callbacks) => {
  loading(true)
  if (await confirmAlert('설치취소', 'info')) {
    try {
      const response = await axiosWithAuth().delete(`/install/${data}`)
      success(response.data)
      await resultAlert(response.data)
    } catch (error: any) {
      fail(error)
      await resultAlert({resultCode: error.response.status})
    } finally {
      loading(false)
    }
  } else {
    loading(false)
    return
  }
}
