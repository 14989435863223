import axios, {AxiosInstance} from 'axios'
import {envBackHost} from 'GLOBAL/envVariables'
import cookies from 'js-cookie'
import store from 'redux/store'
import swal from 'sweetalert'
import {cooperAction, officeAction, userAction} from 'redux/action'
export const axiosWithAuth = (): AxiosInstance => {
  const accessToken1 = cookies.get('license_youngit')
  const axiosInstance = axios.create({
    baseURL: envBackHost,
    headers: {
      Authorization: `Bearer ${accessToken1}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
    withCredentials: true,
  })
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        await swal({
          title: '인증이 유효하지 않습니다.',
          text: '미터리움 로그인 페이지로 이동합니다.',
          icon: 'warning',
          buttons: [false],
          timer: 1000,
        })
        cookies.remove('license_youngit')
        store.dispatch({
          type: 'USER_INFO_DELETE',
          payload: null,
        })
        store.dispatch({
          type: 'OFFICE_INFO_DELETE',
          payload: null,
        })
        store.dispatch({
          type: 'COOPER_INFO_DELETE',
          payload: null,
        })
        window.location.assign('/auth')
      }
      return Promise.reject(error)
    }
  )

  return axiosInstance
}
