import React, {useEffect, useState} from 'react'
import {Box} from '@mui/material'
import {DataGridPro, GridRowId} from '@mui/x-data-grid-pro'
import _ from 'lodash'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import {DateRange} from '@mui/x-date-pickers-pro/DateRangePicker'
import {toast, ToastContainer} from 'react-toastify'
import DeviceAssignModal from '../modules/DeviceAssignedModal'
import {useSelector} from 'react-redux'
import DeviceTargetImageListModal from '../../common/DevicetargetImageListModal'
import DeviceDetailInfoModal from '../../common/DeviceDetailInfoModal'
import MeterariumTabPanel from 'pages/common/TabPanel'
import {a11yProps, scrollTop, tapHandleChange} from 'utils/func/justUtils'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import {deviceAssignFilter, deviceCheckFilter} from 'pages/device/dataConfig/declearFilters'
import {deviceAssignColumns, deviceCheckColumns} from 'pages/device/dataConfig/declearColumns'
import {deviceAllocationRows, deviceCheckRows} from 'pages/device/dataConfig/declearRows'
import {Dayjs} from 'dayjs'
import {
  allocationCancelHandler,
  checkButtonHandler,
  getDeviceAssignList,
  getDeviceCheckList,
} from 'utils/apiCalling/api'
import {toolTipText} from 'utils/toolTips/toolTipText'
import AllocationBatchRegisterOffcanvas from 'pages/customer/modules/allocationBatchOffcanvas'
import DeviceAllocationBatchRegisterOffcanvas from 'pages/customer/modules/allocationBatchOffcanvas'

const DeviceAllocation = () => {
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const [totalData, setTotalData] = useState<any>(null)
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([])
  const [assignedData, setAssignedData] = useState<any>()
  const [value, setValue] = useState(0)
  const [date, setDate] = useState<DateRange<Dayjs>>([null, null])
  const [pageSize, setPageSize] = useState<number>(30)
  const [checkPageSize, setCheckPageSize] = useState<number>(10)
  const [loading, setLoading] = useState<boolean>(false)
  const [checkSelectCheckbox, setCheckSelectCheckbox] = useState<any>([])
  const [allocationSelectCheckbox, setAllocationSelectCheckbox] = useState<any>('')
  const [typeName, setTypeName] = useState<any>('')
  const [deviceDetailData, setDeviceDetailData] = useState<any>(null)
  const [checkFilter, setCheckFilter] = useState<any>(deviceCheckFilter)
  const [assignFilter, setAssignFilter] = useState<any>(deviceAssignFilter)
  const [imageDetailOpen, setImageDetailOpen] = useState<boolean>(false)
  const [deviceDetailOpen, setDeviceDetailOpen] = useState<boolean>(false)
  const imageDetailModalOpen = () => {
    setImageDetailOpen(true)
  }
  const imageDetailModalClose = () => {
    setImageDetailOpen(false)
  }
  const deviceDetailModalOpen = () => {
    setDeviceDetailOpen(true)
  }
  const deviceDetailModalClose = () => {
    setDeviceDetailOpen(false)
  }

  const onAllocationsSelectionHandler = (ids: any) => {
    const selectedRowsData = ids.map((id: any) => allocationRows.find((row) => row.id === id))
    setAllocationSelectCheckbox(selectedRowsData)
  }
  const onRowsSelectionHandler = (ids: any) => {
    const selectedRowsData = ids.map((id: any) => checkRows.find((row) => row.id === id))
    setCheckSelectCheckbox(selectedRowsData)
  }
  const checkColumns: any = deviceCheckColumns(
    setDeviceDetailData,
    imageDetailModalOpen,
    deviceDetailModalOpen
  )
  const allocationColumns: any = deviceAssignColumns(setDeviceDetailData, imageDetailModalOpen)
  const checkRows = deviceCheckRows(totalData)
  const allocationRows = deviceAllocationRows(assignedData)
  const getDeviceCheckListCall = () => {
    getDeviceCheckList(cooperInfo, checkFilter, {
      success: (res: any) => {
        setTotalData(res)
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {
        setLoading(loading)
      },
    })
  }
  const getDeviceAssignedListCall = () => {
    getDeviceAssignList(cooperInfo, assignFilter, {
      success: (res: any) => {
        setAssignedData(res)
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {
        setLoading(loading)
      },
    })
  }
  const checkButtonHandlerCall = async (type: string) => {
    checkButtonHandler(type, checkSelectCheckbox, userInfo, {
      success: (res: any) => {
        if (res.resultCode === 0) {
          resetHandler()
        }
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {
        setLoading(loading)
      },
    })
  }
  const allocationHandlerCall = async () => {
    allocationCancelHandler(allocationSelectCheckbox, {
      success: (res: any) => {
        if (res.resultCode === 0) {
          resetHandler()
        }
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {
        setLoading(loading)
      },
    })
  }

  const resetHandler = () => {
    setCheckSelectCheckbox([])
    setAllocationSelectCheckbox([])
    setSelectionModel([])
    if (value === 0) {
      getDeviceCheckListCall()
    } else {
      getDeviceAssignedListCall()
    }
  }

  useEffect(() => {
    scrollTop()
    getDeviceCheckListCall()
  }, [checkFilter.checkStatus, checkFilter.startDate, checkFilter.endDate, cooperInfo])
  useEffect(() => {
    scrollTop()
    getDeviceAssignedListCall()
  }, [assignFilter.assignStatus, cooperInfo])
  useEffect(() => {
    if (value === 1) {
      resetHandler()
    } else {
      resetHandler()
    }
  }, [value])
  return (
    <>
      <MeterariumTabPanel id={'device-allocation-check'} value={value} index={0}>
        <MeterariumPageLayout
          headerDescription={toolTipText.deviceAllocation.description}
          tooltipText={toolTipText.deviceAllocation.text}
          filter={checkFilter}
          id={'device-allocation-check'}
          setFilter={setCheckFilter}
          searchCallback={getDeviceCheckListCall}
          total={checkRows?.length}
          control={['checkStatus', 'betweenRange']}
          actions={['deviceCheck', 'deviceDelete']}
          searchOptions={['serialNumber', 'imei']}
          date={date}
          setDate={setDate}
          buttonHandler={checkButtonHandlerCall}
          checkData={checkSelectCheckbox}
          onDelete={null}
          onCancel={null}
          setTypeName={null}
          pannel={
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                backgroundColor: '#fff',
                paddingLeft: 3,
              }}
            >
              <Tabs
                value={value}
                onChange={(e, newValue) => tapHandleChange(newValue, setValue)}
                aria-label='check-list-tab'
              >
                <Tab label='검수 조회' {...a11yProps(0)} className={'fw-bolder'} />
                <Tab label='할당 조회' className={'fw-bolder'} {...a11yProps(1)} />
              </Tabs>
            </Box>
          }
        >
          <DataGridPro
            rowHeight={160}
            autoHeight={true}
            scrollbarSize={60}
            rows={checkRows}
            columns={checkColumns}
            pageSize={checkPageSize}
            onPageSizeChange={(newPageSize) => setCheckPageSize(newPageSize)}
            rowsPerPageOptions={[10, 30, 50, 70, 100]}
            disableColumnMenu
            selectionModel={selectionModel}
            onSelectionModelChange={(ids) => {
              onRowsSelectionHandler(ids)
              setSelectionModel(ids)
            }}
            pagination
            checkboxSelection
            loading={loading}
            onPageChange={(params) => {
              scrollTop()
            }}
          />
        </MeterariumPageLayout>
      </MeterariumTabPanel>
      <MeterariumTabPanel id={'device-allocation-assign'} value={value} index={1}>
        <MeterariumPageLayout
          headerDescription={toolTipText.deviceAllocation.description}
          tooltipText={toolTipText.deviceAllocation.text}
          filter={assignFilter}
          id={'device-allocation-assign'}
          setFilter={setAssignFilter}
          searchCallback={getDeviceAssignedListCall}
          total={assignedData?.length}
          control={['assign']}
          actions={['deviceAssign']}
          searchOptions={['serialNumber', 'imei', 'installerId', 'installerName']}
          date={date}
          checkData={allocationSelectCheckbox}
          setDate={setDate}
          onDelete={null}
          onCancel={allocationHandlerCall}
          setTypeName={setTypeName}
          pannel={
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                backgroundColor: '#fff',
                paddingLeft: 3,
              }}
            >
              <Tabs
                value={value}
                onChange={(e, newValue) => tapHandleChange(newValue, setValue)}
                aria-label='check-list-tab'
              >
                <Tab label='검수 조회' {...a11yProps(0)} className={'fw-bolder'} />
                <Tab label='할당 조회' className={'fw-bolder'} {...a11yProps(1)} />
              </Tabs>
            </Box>
          }
        >
          <DataGridPro
            scrollbarSize={60}
            rows={allocationRows}
            rowHeight={160}
            autoHeight={true}
            columns={allocationColumns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 30, 50, 70, 100]}
            disableColumnMenu
            selectionModel={selectionModel}
            pagination
            loading={loading}
            checkboxSelection
            onSelectionModelChange={(ids) => {
              onAllocationsSelectionHandler(ids)
              setSelectionModel(ids)
            }}
            onPageChange={(params) => {
              scrollTop()
            }}
          />
        </MeterariumPageLayout>
      </MeterariumTabPanel>
      <DeviceTargetImageListModal
        data={deviceDetailData}
        open={imageDetailOpen}
        onClose={imageDetailModalClose}
      />
      <DeviceAssignModal
        toast={toast}
        data={allocationSelectCheckbox}
        typeName={typeName}
        resetHandler={resetHandler}
      />
      <DeviceDetailInfoModal
        data={deviceDetailData}
        open={deviceDetailOpen}
        onClose={deviceDetailModalClose}
        getList={value === 0 ? getDeviceCheckListCall : getDeviceAssignedListCall}
      />
      <ToastContainer />
      <DeviceAllocationBatchRegisterOffcanvas toast={toast} />
    </>
  )
}

export default DeviceAllocation
