import {combineReducers} from 'redux'
import {penderReducer} from 'redux-pender'
import userInfo from './reducers/userInfo'
import officeInfo from './reducers/selecteOffice'
import cooperInfo from './reducers/selecteCooper'
import {persistReducer} from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage'
import asideFlag from './reducers/asideFlag'
import pageInfo from './reducers/pageInfo'
import currentPosition from './reducers/currenPosition'
const persistConfig = {
  key: 'youngit',
  storage: sessionStorage,
  whitelist: ['userInfo', 'officeInfo', 'cooperInfo', 'pageInfo'],
}

const rootReducer = combineReducers({
  userInfo,
  officeInfo,
  cooperInfo,
  asideFlag,
  pageInfo,
  currentPosition,
  pender: penderReducer,
})

// export default rootReducer

export default persistReducer(persistConfig, rootReducer)

export type RootState = ReturnType<typeof rootReducer>
