import React, {useLayoutEffect, useState} from 'react'
import {Backdrop, Button, FormControl, InputLabel, MenuItem, Modal, Select} from '@mui/material'
import {installModalStyle} from 'pages/install/dataConfig/modalStyle'
import Box from '@mui/material/Box'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import Typography from '@mui/material/Typography'
import MeterariumTable from 'utils/designSytem/MeterariumTable'
import _ from 'lodash'
import RefreshIcon from '@mui/icons-material/Refresh'

const InstallCompleteListModal = (props: any) => {
  const {set, open, onClose, allList, user} = props
  const [selectFilter, setSelectFilter] = useState<any>('all')
  const [filterList, setFilterList] = useState<any>([])
  const uniqueOfficeIds = allList?.reduce((acc: any, current: any) => {
    if (acc.findIndex((item: any) => item.officeId === current.officeId) === -1) {
      acc.push(current)
    }
    return acc
  }, [])
  const changeList = (target: string) => {
    if (target === 'all') return allList
    const test = allList.filter((el: any) => el.officeName === target)

    return setFilterList(test)
  }
  const clear = () => {
    setFilterList([])
    setSelectFilter('all')
  }
  const columns = [
    {
      field: 'customerNumber',
      headerName: '고객번호',
      width: 140,
    },
    {
      field: 'customerName',
      headerName: '고객명',
      width: 120,
    },
    {
      field: 'addressRoad',
      headerName: '주소',
      width: 250,
      align: 'left',
      renderCell: (params: any) => {
        return (
          <div style={{color: '#73879C', fontWeight: 400, fontSize: 13}}>
            {params?.row?.addressNumber === ''
              ? params?.row?.addressRoad
              : params?.row?.addressNumber}
          </div>
        )
      },
    },
  ]
  const rows = _.map(filterList?.length === 0 ? allList : filterList, (el, i) => {
    return {
      id: i + 1,
      idx: el?.idx,
      addressRoad: el?.addressRoad,
      addressNumber: el?.addressNumber,
      createAt: el?.createAt,
      imei: el?.imei,
      officeId: el?.officeId,
      serialNumber: el?.serialNumber,
      customerNumber: el?.customerNumber,
      customerName: el?.customerName,
    }
  })
  useLayoutEffect(() => {
    if (selectFilter === 'all') return setFilterList([])
    changeList(selectFilter)
  }, [selectFilter])
  return (
    <Modal
      id={'install-complete-modal'}
      open={open}
      aria-labelledby={`install-complete-label`}
      aria-describedby={`install-complete-description`}
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      className={''}
    >
      <Box sx={installModalStyle} style={{paddingTop: 0}} id={'installer-complete-modal-content'}>
        <div className={'w-100 d-flex justify-content-between align-items-center mb-5 mt-2 px-3'}>
          <div>
            <Typography
              id={`install-complete-label`}
              variant={'subtitle2'}
              className={'fs-3'}
              fontWeight={'bold'}
            >
              {user?.userName}님의 설치 완료건
            </Typography>
          </div>
          <div className={'text-center'} style={{minWidth: 64}}>
            <CancelPresentationIcon
              sx={{fontSize: '35px'}}
              color={'error'}
              onClick={async () => {
                onClose()
                clear()
              }}
            />
          </div>
        </div>
        <div className={'d-flex px-4 gap-2'}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>사업소 선택</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='choose-office'
              label='사업소 선택'
              value={`${selectFilter}`}
              onChange={(e) => {
                setSelectFilter(e.target.value)
                changeList(e.target.value)
              }}
            >
              <MenuItem value={'all'}>전체</MenuItem>
              {_.map(uniqueOfficeIds, (el, i) => {
                return (
                  <MenuItem value={el.officeName} key={i}>
                    {el?.officeName}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <Button
            variant={'outlined'}
            color={'primary'}
            sx={{width: 30}}
            onClick={() => {
              setSelectFilter('all')
            }}
          >
            <RefreshIcon />
          </Button>
        </div>
        <div>
          <MeterariumTable rows={rows} columns={columns} fixed={['customerNumber']} hideFooter />
        </div>
      </Box>
    </Modal>
  )
}

export default InstallCompleteListModal
