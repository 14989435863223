import React, {useEffect} from 'react'
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import TaskHeader from './layout/TaskHeader'
import RecoveryInfo from './component/recoveryInfo'
import AsManagement from './component/AsManagement'
import {useAuth} from '../../_component/modules/auth'
import {useSelector} from 'react-redux'
/* component */

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: '업무',
    path: '/pages/task',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const CustomerPage = (props: any) => {
  const {toast} = props
  const {logout} = useAuth()
  const navigate = useNavigate()
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  useEffect(() => {
    if (
      userInfo === null ||
      (userInfo.accountType !== 'sys_admin' && userInfo.accountType !== 'sys_user')
    ) {
      navigate('/auth')
      logout()
    }
  }, [])
  return (
    <Routes>
      <Route
        element={
          <>
            <TaskHeader toast={toast} />
            <Outlet />
          </>
        }
      >
        <Route
          path={'as'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>AS 관리</PageTitle>
              <AsManagement toast={toast} type={'AS'} />
            </>
          }
        />
        <Route
          path={'recovery'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>회수 조회</PageTitle>

              <RecoveryInfo toast={toast} />
            </>
          }
        />
      </Route>
    </Routes>
  )
}
export default CustomerPage
