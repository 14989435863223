import React from 'react'
import {Button, Chip, Divider, Step, StepLabel, Stepper} from '@mui/material'
import Box from '@mui/material/Box'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import {customConfirm, installConfirm} from 'utils/CustomAlert/Alert'
import {installSteps} from 'pages/install/dataConfig/Steps'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import Lottie from 'lottie-react'
import {arrowLottie} from 'assets/imageDeclear'
const StepHeader = (props: any) => {
  const {step, close, clear, setRenderStep, set, installSubmit} = props

  return (
    <div
      id={'install-modal-header'}
      className={'bg-white'}
      style={{position: 'sticky', top: 0, zIndex: 9999}}
    >
      <div className={'w-100 d-flex justify-content-between align-items-center mb-5 mt-2 px-4'}>
        {step !== 0 ? (
          <Button
            variant={'text'}
            size={'large'}
            startIcon={
              <KeyboardDoubleArrowLeftIcon
                style={{
                  fontSize: '35px',
                  color: '#000',
                }}
              />
            }
            onClick={async () => {
              if (await customConfirm('이전 단계로 돌아가시겠습니까?', 'warning', '이전단계')) {
                if (installSubmit?.deviceType === 'digital' && step === 3) {
                  setRenderStep((prev: any) => prev - 2)
                } else {
                  setRenderStep((prev: any) => prev - 1)
                }

                if (step === 1) {
                  set((prev: any) => ({
                    ...prev,
                    imei: null,
                    idxDate: null,
                    customerName: null,
                    customerNumber: null,
                    officeId: null,
                    addressNumber: null,
                    rssiStatus: null,
                  }))
                } else if (step === 2) {
                  set((prev: any) => ({
                    ...prev,
                    imei: null,
                    serialNumber: null,
                    idxDate: null,
                    contrast: null,
                    digit: null,
                    deviceTypeName: null,
                    deviceType: null,
                    brightness: 32,
                    createAt: null,
                    filePath: null,
                    xstart: null,
                    ystart: null,
                    rssiStatus: null,
                    xend: null,
                    yend: null,
                    rotate: 0,
                    recognizedType: 'none',
                  }))
                } else if (step === 3) {
                  if (installSubmit?.deviceType === 'analog') {
                    set((prev: any) => ({
                      ...prev,
                      xstart: null,
                      ystart: null,
                      xend: null,
                      yend: null,
                      digit: null,
                      recognizedType: 'none',
                      rotate: 0,
                      brightness: 32,
                      contrast: 255,
                    }))
                  } else if (installSubmit?.deviceType === 'digital') {
                    set((prev: any) => ({
                      ...prev,
                      xstart: null,
                      ystart: null,
                      xend: null,
                      yend: null,
                      createAt: null,
                      deviceTypeName: null,
                      deviceType: null,
                      imei: null,
                      serialNumber: null,
                      rssiStatus: null,
                      idxDate: null,
                      digit: null,
                      recognizedType: 'none',
                      rotate: 0,
                      brightness: 32,
                      contrast: 255,
                    }))
                  }
                }
              }
            }}
          >
            {/*<span className={'text-dark fs-5'}>{installSteps[step - 1]}</span>*/}
          </Button>
        ) : (
          <div />
        )}
        <div className={'text-center'} style={{minWidth: 64}}>
          <CancelPresentationIcon
            sx={{fontSize: '35px'}}
            color={'error'}
            onClick={async () => {
              if (await installConfirm('설치를 종료하시겠습니까?', 'warning', '설치종료')) {
                close()
                clear()
              }
            }}
          />
        </div>
      </div>
      <Box sx={{width: '100%'}}>
        <Stepper activeStep={step} alternativeLabel>
          {installSteps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div
          id={'matching-info'}
          className={'d-flex gap-2 align-items-center justify-content-center my-3'}
        >
          {installSubmit?.customerName && (
            <Chip
              label={installSubmit?.customerName}
              variant={'outlined'}
              color={'primary'}
              className={'fs-4'}
            />
          )}
          {installSubmit?.serialNumber && installSubmit?.customerName && (
            <Lottie
              animationData={arrowLottie}
              loop={true}
              style={{
                width: 50,
                height: 50,
              }}
            />
          )}
          {installSubmit?.serialNumber && (
            <Chip
              label={installSubmit?.serialNumber}
              variant={'outlined'}
              color={'primary'}
              className={'fs-4'}
            />
          )}
        </div>
        <Divider className={'border border-gray-600 mb-5'} />
      </Box>
    </div>
  )
}
export default StepHeader
