import {createRoot} from 'react-dom/client'
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {MetronicI18nProvider} from '_metronic/i18n/Metronici18n'
import 'nouislider/dist/nouislider.css'
import '_metronic/assets/sass/style.scss'
import '_metronic/assets/sass/plugins.scss'
import '_metronic/assets/sass/style.react.scss'
import {AppRoutes} from 'routes/AppRoutes'
import {AuthProvider, setupAxios} from '_component/modules/auth'
import {ThemeModeProvider} from '_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {Provider} from 'react-redux'
import store, {persistor} from 'redux/store'
import {PersistGate} from 'redux-persist/integration/react'
import {LicenseInfo} from '@mui/x-data-grid-pro'
import 'GLOBAL/envVariables'

setupAxios(axios)
Chart.register(...registerables)

const muiKey: any = process.env.REACT_APP_MUI_KEY
LicenseInfo.setLicenseKey(muiKey)
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <MetronicI18nProvider>
          <ThemeModeProvider>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </ThemeModeProvider>
        </MetronicI18nProvider>
      </PersistGate>
    </Provider>
  )
}
