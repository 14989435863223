/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue, isMobileDevice} from '../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import RefreshIcon from '@mui/icons-material/Refresh'
import {Tooltip} from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import {useSelector} from 'react-redux'
import {envImagePath} from '../../GLOBAL/envVariables'

type Props = {
  className?: string
  data?: any
  filter?: any
  value?: any
  imageList?: any
  useChart?: any
  onDataClick?: any
}

const CustomerDetailChart: React.FC<Props> = ({
  className,
  useChart,
  filter,
  data,
  imageList,
  onDataClick,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, data, useChart, imageList, onDataClick)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  document.addEventListener('click', function (event) {
    const tooltip = event.target as Element
    const isDateElement = (element: Element): boolean => {
      const text: any = element.textContent
      const dateRegex = /^\d{1,2}월 \d{1,2}일$/
      return dateRegex.test(text)
    }
    if (isDateElement(tooltip)) {
      onDataClick(tooltip.textContent)
      // 클릭된 툴팁이 날짜 형식인 경우 추가 처리
    }
  })

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, data, filter, useChart, imageList])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='d-flex card-header border-0 p-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {data?.customerName}
            <strong className={'text-danger mx-4'}></strong>
          </span>

          <span className='text-muted fw-semibold fs-7'>
            검침내역을 차트로 한눈에 볼 수 있습니다.
          </span>
        </h3>
        {/*<div className='card-toolbar' data-kt-buttons='true'>*/}
        {/*  <Tooltip title={<span className={'fs-7'}>차트를 초기화합니다.</span>}>*/}
        {/*    <a*/}
        {/*      className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'*/}
        {/*      id='kt_charts_widget_4_year_btn'*/}
        {/*      onClick={() => {*/}
        {/*        refreshChart()*/}
        {/*        refreshChart()*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <RefreshIcon />*/}
        {/*    </a>*/}
        {/*  </Tooltip>*/}
        {/*</div>*/}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_4_chart' style={{height: 494}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {CustomerDetailChart}

function getChartOptions(
  height: number,
  data: any,
  useChart: any,
  imageList: any,
  onDataClick: any
): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-800')
  const borderColor = getCSSVariableValue('--kt-gray-400')

  const baseColor = getCSSVariableValue('--kt-success')
  const baseLightColor = getCSSVariableValue('--kt-success-light')
  const secondaryColor = getCSSVariableValue('--kt-warning')
  const secondaryLightColor = getCSSVariableValue('--kt-warning-light')
  const useSum = _.map(useChart, (el, index) => {
    const accumulatedUse = _.reduce(useChart.slice(0, index + 1), (sum, item) => sum + item.use, 0)
    return accumulatedUse
  })
  const fontSize = isMobileDevice() ? '12px' : '17px'
  const lastIndex = imageList?.length - 1
  // const xDate = _.map(imageList.reverse(), (el) => {
  //   if (el?.idxDate.endsWith('0001')) {
  //     return dayjs(el.createAt).format('M월 DD일')
  //   }
  //   return null
  // }).filter(Boolean)
  const xData: any = _.map(imageList, (el) => {
    if (el?.idxDate.endsWith('0001')) {
      return {
        x: dayjs(el.createAt).format('M월 DD일'),
        y: el?.checkValue,
        goals: el?.filePath,
      }
    }
    return null
  }).filter(Boolean)
  return {
    series: [
      {
        name: '검침 데이터',
        data: xData,
      },
    ],
    chart: {
      fontFamily: 'inter',
      type: 'line',
      height: '250',
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
        export: {
          csv: {
            filename: `${data?.customerName}-${data?.serialNumber} 검침내역 그래프`,
            headerCategory: '날짜',
          },
          png: {
            filename: `${data?.customerName}-${data?.serialNumber} 검침내역 그래프`,
          },
        },
      },
    },
    legend: {
      show: true,
      fontSize: fontSize,
      fontWeight: 600,
    },

    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    stroke: {
      width: 3,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      // categories: _.map(imageList, (el, i) => {
      //   const indexAsNumber = parseInt(i, 10)
      //   const reversedIndex = lastIndex - indexAsNumber // 마지막 인덱스에서 현재 인덱스를 빼서 순서를 뒤집음
      //   if (reversedIndex % 2 === 0) {
      //     // 뒤집힌 순서에서 짝수 인덱스만 포함
      //     return dayjs(el.createAt).format('MM-DD HH:mm')
      //   }
      // }).filter(Boolean),
      // categories: xDate,

      labels: {
        style: {
          colors: labelColor,
          fontSize: '16px',
          fontWeight: 600,
        },
      },
    },
    yaxis: {
      tickAmount: 8,
      labels: {
        style: {
          colors: labelColor,
          fontSize: fontSize,
          fontWeight: 600,
        },
        offsetX: -5,
        formatter(val: number, opts?: any): string | string[] {
          return Number(val).toLocaleString('ko-KR') + 't'
        },
      },
    },

    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: fontSize,
      },
      custom: function ({series, seriesIndex, dataPointIndex, w}) {
        let image = w.config.series[seriesIndex].data[dataPointIndex].goals
        let imageURL = envImagePath + w.config.series[seriesIndex].data[dataPointIndex].goals
        let xValue = w.config.series[seriesIndex].data[dataPointIndex]

        if (image === null) {
          // If image is null, don't show the card-body div
          return `<div class="card" onclick="handleTooltipClick()">
              <div class="card-header row align-items-center " id="tooltip-${seriesIndex}-${dataPointIndex}">
                <h2 class="mt-2">${xValue.x}</h2>
                <h2>검침값 : <u>${xValue.y}t</u></h2>
              </div>
            </div>`
        } else {
          // If image exists, show the card-body div with the image
          return `<div class="card" onclick="handleTooltipClick()">
              <div class="card-header d-flex align-items-center" id="tooltip-${seriesIndex}-${dataPointIndex}">
                <h2>${xValue.x}</h2>
                <h2>검침값 : <u>${xValue.y}</u></h2>
              </div>
              <div class="card-body p-0">
                <img src='${imageURL}' style='width: 100%; '>
              </div>
            </div>`
        }
      },

      x: {
        show: true,
        formatter(val: number, opts?: any): string {
          return dayjs(val).format('DD일 HH:mm')
        },
      },
      y: {
        formatter: function (val, opts) {
          return Number(val).toLocaleString('ko-KR') + 't'
        },
      },
    },

    colors: ['#546E7A'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
