import React from 'react'
import {Button, Chip, Divider, Step, StepLabel, Stepper} from '@mui/material'
import Box from '@mui/material/Box'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import {asConfirm, customConfirm, installConfirm} from 'utils/CustomAlert/Alert'
import {asInstallSteps} from 'pages/install/dataConfig/Steps'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import Lottie from 'lottie-react'
import {arrowLottie} from 'assets/imageDeclear'
const AsStepHeader = (props: any) => {
  const {step, close, clear, setRenderStep, set, asSubmit} = props
  return (
    <div
      id={'as-install-modal-header'}
      className={'bg-white'}
      style={{position: 'sticky', top: 0, zIndex: 9999}}
    >
      <div className={'w-100 d-flex justify-content-between align-items-center mb-5 mt-2 px-4'}>
        {step !== 0 ? (
          <Button
            variant={'text'}
            size={'large'}
            startIcon={
              <KeyboardDoubleArrowLeftIcon
                style={{
                  fontSize: '35px',
                  color: '#000',
                }}
              />
            }
            onClick={async () => {
              if (await customConfirm('이전 단계로 돌아가시겠습니까?', 'warning', '이전단계')) {
                if (asSubmit?.resultIdx >= 5 && asSubmit?.resultIdx <= 10) {
                  setRenderStep(0)
                } else {
                  if (asSubmit?.deviceType === 'digital' && step === 3) {
                    setRenderStep((prev: any) => prev - 2)
                  } else {
                    setRenderStep((prev: any) => prev - 1)
                  }
                }
                if (step === 1) {
                  set((prev: any) => ({
                    ...prev,
                    imei: null,
                    idxDate: null,
                    customerName: null,
                    customerNumber: null,
                    officeId: null,
                    resultIdxName: null,
                    resultIdx: null,
                    beforeImei: null,
                    createAt: null,
                    serialNumber: null,
                    filePath: null,
                    causeIdx: null,
                    causeName: null,
                    deviceType: null,
                    idx: null,
                    rssiStatus: null,
                  }))
                } else if (step === 2) {
                  if (asSubmit.resultIdx === 1 || asSubmit.resultIdx === 4) {
                    set((prev: any) => ({
                      ...prev,
                      contrast: null,
                      digit: null,
                      brightness: 32,
                      changeFlag: false,
                      rotate: 0,
                      xstart: null,
                      ystart: null,
                      xend: null,
                      yend: null,
                      recognizedType: 'none',
                    }))
                  } else {
                    set((prev: any) => ({
                      ...prev,
                      contrast: null,
                      digit: null,
                      brightness: 32,
                      filePath: null,
                      rssiStatus: null,
                      createAt: null,
                      deviceTypeName: null,
                      deviceType: null,
                      imei: null,
                      serialNumber: null,
                      idxDate: null,
                      changeFlag: false,
                      rotate: 0,
                      xstart: null,
                      ystart: null,
                      xend: null,
                      yend: null,
                      recognizedType: 'none',
                    }))
                  }
                } else if (step === 3) {
                  if (asSubmit?.resultIdx >= 5 && asSubmit?.resultIdx <= 10) {
                    set((prev: any) => ({
                      ...prev,
                      resultIdx: null,
                      resultIdxName: null,
                    }))
                  } else {
                    if (asSubmit?.deviceType === 'analog') {
                      set((prev: any) => ({
                        ...prev,
                        xstart: null,
                        ystart: null,
                        xend: null,
                        yend: null,
                        afterComment: '',
                        digit: null,
                        recognizedType: 'none',
                        rotate: 0,
                        brightness: 32,
                        contrast: 255,
                      }))
                    } else if (asSubmit?.deviceType === 'digital') {
                      set((prev: any) => ({
                        ...prev,
                        xstart: null,
                        ystart: null,
                        xend: null,
                        yend: null,
                        createAt: null,
                        deviceTypeName: null,
                        deviceType: null,
                        imei: null,
                        serialNumber: null,
                        rssiStatus: null,
                        changeFlag: false,
                        idxDate: null,
                        digit: null,
                        recognizedType: 'none',
                        rotate: 0,
                        brightness: 32,
                        contrast: 255,
                      }))
                    }
                  }
                }
              }
            }}
          >
            <span className={'text-dark fs-5'}>{asInstallSteps[step - 1]}</span>
          </Button>
        ) : (
          <div />
        )}
        <div className={'text-center'} style={{minWidth: 64}}>
          <CancelPresentationIcon
            sx={{fontSize: '35px'}}
            color={'error'}
            onClick={async () => {
              if (await asConfirm('A/S를 종료하시겠습니까?', 'warning', '종료')) {
                close()
                clear()
              }
            }}
          />
        </div>
      </div>
      <Box sx={{width: '100%'}}>
        <Stepper activeStep={step} alternativeLabel>
          {asInstallSteps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div
          id={'matching-info'}
          className={'d-flex gap-2 align-items-center justify-content-center my-3'}
        >
          {asSubmit?.customerName && (
            <Chip
              label={`${asSubmit?.customerName}님  ${
                asSubmit?.resultIdxName === null ? 'A/S ' : asSubmit?.resultIdxName
              } 진행 중`}
              variant={'outlined'}
              color={'secondary'}
              className={'fs-4 '}
            />
          )}
        </div>
        <Divider className={'border border-gray-600 mb-5'} />
      </Box>
    </div>
  )
}
export default AsStepHeader
