import {Chip, Grow, Tooltip} from '@mui/material'
import {
  defaultImage,
  noImage,
  selectFailEFos,
  selectFailImg,
  selectSuccessEfos,
  selectSuccessImg,
} from 'assets/imageDeclear'
import dayjs from 'dayjs'
import {envImagePath} from 'GLOBAL/envVariables'
import {globalFormatWithHyphens} from 'utils/const/globalConst'
import {handleCellClick} from 'utils/func/justUtils'

export const deviceCheckColumns = (
  setDeviceDetailData: any,
  imageDetailModalOpen: any,
  deviceDetailModalOpen: any
) => {
  return [
    {
      field: 'checkStatus',
      headerName: '승인상태',
      width: 90,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return <>{params.row.checkStatus === false ? <span>NONE</span> : <span>PASS</span>}</>
      },
    },
    {
      field: 'deviceTypeName',
      headerName: '단말기 타입',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return <>{params.row.deviceTypeName}</>
      },
    },
    {
      field: 'serialNumber',
      headerName: '단말기 S/N',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        const temp = params.row.temperature
        return (
          <div className={'row px-0 mx-0 text-center'}>
            <span>{params.row.serialNumber}</span>
            <span>{temp !== null && temp <= 90 ? `${temp}°C` : '-'}</span>
          </div>
        )
      },
    },
    {
      field: 'imei',
      headerName: 'IMEI',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div
            onClick={(e) => {
              handleCellClick(e)
              setDeviceDetailData(params.row)
              deviceDetailModalOpen()
            }}
          >
            <span className={'text-primary'}>{params.row.imei}</span>
          </div>
        )
      },
    },
    {
      headerName: '최근 서버연결 일시',
      field: 'serverConnectAt',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.serverConnectAt === null
              ? '-'
              : dayjs(params.row.serverConnectAt).format('YYYY-MM-DD HH:mm:ss')}
          </>
        )
      },
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 400,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.deviceType === 'analog' ? (
              <Tooltip
                title={
                  <span className={'fs-6'}>
                    클릭하면 해당 단말기에 대한 이미지 리스트들을 볼 수 있습니다.
                  </span>
                }
                TransitionComponent={Grow}
                TransitionProps={{timeout: 300}}
                placement={'right'}
                arrow={true}
              >
                <img
                  className={'p-2  cursor-pointer border border-2'}
                  src={`${envImagePath}${params.row.filePath}`}
                  // style={{borderRadius: '20px'}}
                  alt={'The image cannot be loaded.'}
                  onClick={(e) => {
                    handleCellClick(e)
                    setDeviceDetailData(params.row)
                    imageDetailModalOpen()
                  }}
                />
              </Tooltip>
            ) : (
              <img
                className={'p-2  cursor-pointer'}
                src={defaultImage}
                style={{borderRadius: '20px'}}
                alt={'This device is not exist image'}
              />
            )}
          </>
        )
      },
    },
    {
      field: 'imageConnectAt',
      headerName: '최근 이미지 일시',
      width: 250,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.imageConnectAt === null
              ? '-'
              : dayjs(params.row.imageConnectAt).format('YYYY-MM-DD HH:mm:ss')}
          </>
        )
      },
    },
    {
      field: 'signalStrength',
      headerName: '전파세기',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.signalStrength === null ? (
              <span>-</span>
            ) : (
              <span>{params.row.signalStrength + 'dBm'}</span>
            )}
          </>
        )
      },
    },
  ]
}

export const deviceAssignColumns = (setDeviceDetailData: any, imageDetailModalOpen: any) => {
  return [
    {
      field: 'serialNumber',
      headerName: '단말기 S/N',
      width: 220,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        const temp = params.row.temperature
        return (
          <div className={'row px-0 mx-0 text-center'}>
            <span>{params.row.serialNumber}</span>

            <span>{temp < 90 ? `${temp}°C` : '-'}</span>
          </div>
        )
      },
    },
    {
      field: 'imei',
      headerName: 'IMEI',
      width: 250,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'deviceTypeName',
      headerName: '단말기 타입',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'filePath',
      headerName: '이미지',
      width: 400,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.deviceType === 'analog' ? (
              <Tooltip
                title={
                  <span className={'fs-6'}>
                    클릭하면 해당 단말기에 대한 이미지 리스트들을 볼 수 있습니다.
                  </span>
                }
                TransitionComponent={Grow}
                TransitionProps={{timeout: 300}}
                placement={'right'}
                arrow={true}
              >
                <img
                  className={'p-2  cursor-pointer border border-2'}
                  src={`${envImagePath}${params.row.filePath}`}
                  alt={'Image not yet loaded'}
                  onClick={(e) => {
                    handleCellClick(e)
                    setDeviceDetailData(params.row)
                    imageDetailModalOpen()
                  }}
                />
              </Tooltip>
            ) : (
              <img
                className={'p-2  cursor-pointer'}
                src={defaultImage}
                style={{borderRadius: '20px'}}
                alt={'This device is not exist image'}
              />
            )}
          </>
        )
      },
    },
    {
      field: 'imageConnectAt',
      headerName: '서버 연결시간',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.imageConnectAt === null
              ? '-'
              : dayjs(params.row.imageConnectAt).format('YYYY-MM-DD HH:mm:ss')}
          </>
        )
      },
    },
    {
      field: 'assignToId',
      headerName: '설치자 ID',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <span>
            {params.row.assignToId === null || params.row.assignToId === 'NULL'
              ? '-'
              : params.row.assignToId}
          </span>
        )
      },
    },
    {field: 'registerId', headerName: '생성자', width: 220, align: 'center', headerAlign: 'center'},
    {
      field: 'assignToName',
      headerName: '설치자 이름',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return <span>{params.row.assignToName === null ? '-' : params.row.assignToName}</span>
      },
    },
    {
      field: 'checkName',
      headerName: '검수자',
      width: 180,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'assignStatus',
      headerName: '할당 상태',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div>
            {params.row.assignStatus === true ? (
              <span className={'text-success fw-bolder'}>할당</span>
            ) : (
              <span className={'text-danger fw-bolder'}>미할당</span>
            )}
          </div>
        )
      },
    },
  ]
}

export const deviceInfoColumns = (
  setDeviceDetailData: any,
  deviceDetailModalOpen: any,
  imageDetailModalOpen: any
) => {
  const chipValue = (value: string) => {
    switch (value) {
      case null:
        return '기계식'
      case 'analog':
        return '기계식'
      case 'digital':
        return '디지털'
      case 'gas':
        return '가스'
      default:
        return '기계식'
    }
  }
  const chipColor = (value: string) => {
    switch (value) {
      case null:
        return 'error'
      case 'analog':
        return 'error'
      case 'digital':
        return 'warning'
      case 'gas':
        return 'success'
      default:
        return 'error'
    }
  }
  return [
    {
      field: 'serialNumber',
      headerName: '단말기 S/N',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'customerNumber',
      headerName: '수용가번호',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return <span className={'text-hover-primary'}>{params.row.customerNumber}</span>
      },
    },
    {
      field: 'createAt',
      headerName: '최초 등록일자',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return <span>{dayjs(params.row.createAt).format('YYYY-MM-DD HH:mm')}</span>
      },
    },
    {
      field: 'deviceConnectAt',
      headerName: '서버연결 일시',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center'}>
            <span>{dayjs(params.row.deviceConnectAt).format('YYYY-MM-DD HH:mm')}</span>
            <span>전파세기 : {params.row.signalStrength}dBm</span>
          </div>
        )
      },
    },
    {
      field: 'deviceStatus',
      headerName: '단말기 통신상태',
      renderCell: (params: any) => {
        const cooperIdx = params?.row.cooperIdx
        return (
          <div
            className={'row w-100 h-100 text-center '}
            style={{alignContent: 'center'}}
            onClick={(e) => {
              handleCellClick(e)
              setDeviceDetailData(params.row)
              deviceDetailModalOpen()
            }}
          >
            <img
              alt={'device-image'}
              src={params.row.deviceStatus ? selectSuccessImg(cooperIdx) : selectFailImg(cooperIdx)}
              width={100}
            />
          </div>
        )
      },
      width: 160,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'efosStatus',
      headerName: '계량기 통신상태',
      renderCell: (params: any) => {
        const cooperIdx = params?.row.cooperIdx
        return (
          <>
            <div
              className={'row w-100 h-100 text-center '}
              style={{alignContent: 'center'}}
              onClick={(e) => {
                handleCellClick(e)
                setDeviceDetailData(params.row)
                deviceDetailModalOpen()
              }}
            >
              <img
                alt={'fos-image'}
                src={
                  params?.row.efosStatus ? selectSuccessEfos(cooperIdx) : selectFailEFos(cooperIdx)
                }
                width={100}
              />
            </div>
          </>
        )
      },
      width: 140,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'deviceVersion',
      headerName: '펌웨어',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'batteryLevel',
      headerName: '배터리',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <span className={'fw-bold'}>
            {params.row.cooperName === '두리사업단'
              ? params.row.batteryLevel * 0.1 + 'V'
              : params.row.batteryLevel + '%'}
          </span>
        )
      },
    },
    {
      field: 'filePath',
      headerName: '이미지',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.deviceType !== 'analog' ? (
              <img
                className={'cursor-pointer'}
                src={defaultImage}
                alt={'이미지가 존재하지 않습니다.'}
                onClick={(e) => {
                  setDeviceDetailData(params.row)
                  handleCellClick(e)
                  imageDetailModalOpen()
                }}
              />
            ) : (
              <div className={'row'}>
                <div
                  className={'d-flex gap-1 justify-content-end'}
                  style={{position: 'relative', top: '15px', zIndex: 888}}
                >
                  {params.row.rotate !== 0 && (
                    <Chip
                      label={`${params.row.rotate}° `}
                      color='primary'
                      sx={{fontSize: '17px'}}
                    />
                  )}
                  <Chip
                    label={chipValue(params.row.recognizedType)}
                    color={chipColor(params.row.recognizedType)}
                    sx={{fontSize: '17px'}}
                  />
                </div>
                <img
                  alt={'device'}
                  className={'cursor-pointer'}
                  src={
                    params.row.filePath === null ? noImage : `${envImagePath}${params.row.filePath}`
                  }
                  width={320}
                  height={144}
                  style={{
                    transform: params.row.filePath
                      ? `rotate(${params?.row?.rotate}deg)`
                      : `rotate(0deg)`,
                  }}
                  onClick={(e) => {
                    setDeviceDetailData(params.row)
                    handleCellClick(e)
                    imageDetailModalOpen()
                  }}
                />
              </div>
            )}
          </>
        )
      },
    },
    {
      field: 'meterReadVersion',
      headerName: '검침기 펌웨어',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
  ]
}

export const deviceImagesColumns = [
  {
    field: 'createAt',
    headerName: '이미지 시간',
    width: 190,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: any) => {
      return (
        <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
          {dayjs(params.row.createAt)?.format('YYYY-MM-DD HH:mm:ss')}
        </span>
      )
    },
  },
  {
    field: 'customerNumber',
    headerName: '수용가번호',
    width: 180,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: any) => {
      return <>{globalFormatWithHyphens(params?.row.officeId, params?.row.customerNumber)}</>
    },
  },
  {
    field: 'serialNumber',
    headerName: '단말기 S/N',
    width: 150,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: any) => {
      return <span>{params.row.serialNumber}</span>
    },
  },
  {
    field: 'filePath',
    headerName: '이미지',
    width: 300,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: any) => {
      return (
        <img
          className={'cursor-pointer'}
          alt={'이미지'}
          src={envImagePath + params.row.filePath}
          width={'100%'}
        />
      )
    },
  },
  {
    field: 'meterDiameter',
    headerName: '구경',
    width: 100,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: any) => {
      return <span>{params.row.meterDiameter}mm</span>
    },
  },
  {
    field: 'checkValue',
    headerName: '최초 인식값',
    width: 130,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: any) => {
      return (
        <>
          {params.row.checkDecimalValue === null || params.row.checkDecimalValue === '' ? (
            <span>{params.row.checkValue}</span>
          ) : (
            <span>{params.row.checkValue + '.' + params.row.checkDecimalValue?.split('.')[1]}</span>
          )}
        </>
      )
    },
  },
  {
    field: 'updateCheckValue',
    headerName: '수정 인식값',
    width: 130,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: any) => {
      return (
        <>
          {params.row.updateCheckValue === null ? (
            <span>-</span>
          ) : (
            <span>{params.row.checkValue}</span>
          )}
        </>
      )
    },
  },
  {
    field: 'updateId',
    headerName: '수정자 ID',
    width: 120,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: any) => {
      return <span>{params.row.updateId === null ? '-' : params.row.updateId}</span>
    },
  },
  {
    field: 'processStatus',
    headerName: '이미지 처리상태',
    width: 150,
    align: 'center',
    headerAlign: 'center',
  },
]
