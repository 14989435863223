import {TabPanelProps} from 'utils/func/justUtils'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import * as React from 'react'

const MeterariumTabPanel = (props: TabPanelProps) => {
  const {children, value, index, ...other} = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 0}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
export default MeterariumTabPanel
