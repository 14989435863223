/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {HeaderToolbar} from './HeaderToolbar'
import {useDispatch, useSelector} from 'react-redux'
import {isMobileDevice} from '../../../assets/ts/_utils'
//@ts-ignore
import swal from 'sweetalert'
export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {aside} = config
  const dispatch = useDispatch()
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const asideFlag = useSelector((state: any) => state.asideFlag)
  const isMobile = isMobileDevice()
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch ')}
      {...attributes.headerMenu}
    >
      {/* begin::Brand */}
      <div
        className={
          !isMobileDevice() ? 'header-brand border-end border-3 border-white' : 'header-brand'
        }
      >
        {/* begin::Logo */}
        <Link
          to={
            userInfo?.accountType === 'customer_client'
              ? `/customer/client`
              : userInfo?.accountType === 'installer'
              ? '/install/connect'
              : '/home'
          }
        >
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/meterarium_new_logo.png')}
            style={{width: !isMobile ? '180px' : '200px', paddingBottom: '3px'}}
            className={'me-3'}
            onClick={async () => {
              await swal({
                title: '이동',
                content: {
                  element: 'div',
                  attributes: {
                    innerHTML:
                      '<div class="row"><div class="mb-4" style="text-align: center;">메인화면으로 이동합니다.</div><div class="fw-bold" style="text-align: center;">스마트 디지털 이미지 원격검침 시스템</div><div class="fw-bold text-primary" style="text-align: center;">영아이티(주)</div></div>',
                  },
                },
                buttons: [false],
                className: '',
                icon: 'info',
                timer: 800,
              })
            }}
          />
        </Link>
        {/* end::Logo */}

        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
            // onClick={() => {
            //   if (asideFlag?.value === false) {
            //     dispatch({
            //       type: 'ASIDEFLAG_REQUEST',
            //       value: true,
            //     })
            //   } else if (asideFlag?.value === true) {
            //     dispatch({
            //       type: 'ASIDEFLAG_REQUEST',
            //       value: false,
            //     })
            //   }
            // }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr092.svg'
              className='svg-icon-1 me-n1 minimize-default'
            />
            <KTSVG
              path='/media/icons/duotune/arrows/arr076.svg'
              className='svg-icon-1 minimize-active'
            />
          </div>
        )}

        {/* begin::Aside toggle */}
        <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px'
            id='kt_aside_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-1' />
          </div>
        </div>
        {/* end::Aside toggle */}
      </div>
      {/* end::Brand */}
      <HeaderToolbar />
    </div>
  )
}
