import React, {useEffect, useState} from 'react'
import {Box, Button, TextField} from '@mui/material'
import {envBackHost} from '../../../GLOBAL/envVariables'
import _ from 'lodash'
import {DataGridPro, GridColDef, GridRowId, koKR} from '@mui/x-data-grid-pro'
import {axiosWithAuth} from '../../../helper/axiosWithAuth'
import {useSelector} from 'react-redux'
import dayjs from 'dayjs'
import {toast, ToastContainer} from 'react-toastify'
import {confirmAlert, normalAlert, resultAlert} from '../../../utils/CustomAlert/Alert'

const DeviceTypeAddModal = (props: any) => {
  const {deviceTypeList, getDeviceType} = props
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([])
  const [deviceType, setDeviceType] = useState<any>({
    deviceType: '',
    deviceTypeName: '',
  })
  const [updateDeviceType, setUpdateDeviceType] = useState<any>({
    deviceType: '',
    deviceTypeName: '',
  })
  const [pageSize, setPageSize] = useState<number>(10)
  const myModal: any = document.getElementById('deviceTypeAddModal')
  myModal?.addEventListener('hidden.bs.modal', function () {
    setSelectionModel([])
    setDeviceType({
      deviceType: '',
      deviceTypeName: '',
    })
    setUpdateDeviceType({
      deviceType: '',
      deviceTypeName: '',
    })
  })

  const addNewDeviceType = async () => {
    if (deviceType.deviceType === '' || deviceType.deviceTypeName === '') {
      await normalAlert('단말기 타입 혹은 타입 명이 누락되어있습니다.')
      return
    }
    if (await confirmAlert('생성', 'info')) {
      axiosWithAuth()
        .post(`${envBackHost}/devicetype`, {
          deviceType: deviceType.deviceType,
          deviceTypeName: deviceType.deviceTypeName,
        })
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            getDeviceType()
            setSelectionModel([])
            setDeviceType({
              deviceType: '',
              deviceTypeName: '',
            })
            document.getElementById('deviceTypeBack')?.click()
            return
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    } else {
      return
    }
  }

  const deleteDeviceType = () => {
    axiosWithAuth()
      .delete(`${envBackHost}/devicetype/${updateDeviceType?.deviceType}`)
      .then(async (res) => {
        console.log(res.data.res)
        await resultAlert(res.data)
        if (res.data.resultCode === 0) {
          getDeviceType()
          setSelectionModel([])
          return
        }
      })
      .catch(async (e) => {
        await resultAlert({resultCode: e.response.status})
      })
  }
  const updateDeviceTypeFuc = async () => {
    await normalAlert('준비중입니다.')
  }

  const columns: GridColDef[] = [
    {field: 'id', headerName: 'No.', width: 90, align: 'center', headerAlign: 'center'},
    {
      field: 'deviceType',
      headerName: '단말기 타입',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'deviceTypeName',
      headerName: '단말기 타입명',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'createAt',
      headerName: '생성 일시',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <div>{dayjs(params.row.createAt).format('YYYY-MM-DD HH:mm:ss')}</div>
      },
    },
    {
      field: 'updateAt',
      headerName: '수정 일시',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <div>{dayjs(params.row.updateAt).format('YYYY-MM-DD HH:mm:ss')}</div>
      },
    },
  ]
  const rows = _.map(deviceTypeList, (el, i) => {
    return {
      id: i + 1,
      deviceType: el.deviceType,
      deviceTypeName: el.deviceTypeName,
      createAt: el.createAt,
      updateAt: el.updateAt,
    }
  })

  useEffect(() => {
    getDeviceType()
  }, [])

  return (
    <>
      <div
        className='modal fade'
        id='deviceTypeAddModal'
        tabIndex={-1}
        aria-labelledby='deviceTypeAddModalLabel'
        aria-hidden='true'
        data-bs-backdrop={'true'}
      >
        <div className='modal-dialog modal-dialog-centered p-3'>
          <div className='modal-content  rounded-2'>
            <div className='modal-header'>
              <h5 className='modal-title' id='deviceTypeAddModalLabel'>
                단말기 타입 설정
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                id={'deviceTypeModalClose'}
              ></button>
            </div>
            <div className='modal-body'>
              <Box id={'table-content'} sx={{height: 300, width: '100%'}}>
                <DataGridPro
                  headerHeight={30}
                  rows={rows}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 30, 50, 70, 100]}
                  disableColumnMenu
                  selectionModel={selectionModel}
                  pagination
                  checkboxSelection
                  onSelectionModelChange={(selection) => {
                    if (selection.length > 1) {
                      const selectionSet = new Set(selectionModel)
                      const result = selection.filter((s) => !selectionSet.has(s))

                      setSelectionModel(result)
                    } else {
                      setSelectionModel(selection)
                    }
                  }}
                  // onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                  onCellClick={(params) => {
                    setUpdateDeviceType({
                      deviceType: params.row.deviceType,
                      deviceTypeName: params.row.deviceTypeName,
                    })
                  }}
                />
              </Box>
            </div>
            <div className='modal-footer gap-5'>
              <Button
                variant={'contained'}
                className={'p-5'}
                type={'button'}
                disabled={selectionModel?.length !== 0}
                data-bs-toggle={'modal'}
                data-bs-target={'#deviceTypeAddModal2'}
              >
                추가
              </Button>
              <Button
                variant={'contained'}
                className={'p-5'}
                color={'secondary'}
                type={'button'}
                disabled={selectionModel?.length === 0}
                onClick={() => deleteDeviceType()}
              >
                삭제
              </Button>
              <Button
                variant={'contained'}
                className={'p-5'}
                color={'warning'}
                type={'button'}
                disabled={selectionModel?.length === 0}
                data-bs-toggle={'modal'}
                data-bs-target={'#deviceTypeAddModalUpdate'}
                // onClick={() => buttonHandler()}
              >
                수정
              </Button>
              <Button
                id={'assignedModalClose'}
                variant={'contained'}
                className={'p-5'}
                color={'error'}
                type={'button'}
                data-bs-dismiss='modal'
                onClick={() => {
                  setSelectionModel([])
                  setDeviceType({
                    deviceType: '',
                    deviceTypeName: '',
                  })
                  setUpdateDeviceType({
                    deviceType: '',
                    deviceTypeName: '',
                  })
                }}
              >
                취소
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='deviceTypeAddModal2'
        aria-hidden='true'
        aria-labelledby='deviceTypeAddModalLabel2'
        tabIndex={-1}
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='deviceTypeAddModalLabel2'>
                단말기 타입 설정 - 추가
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <div className={'row gap-5 mx-0 px-0'}>
                <TextField
                  variant={'outlined'}
                  label={'단말기 타입'}
                  value={deviceType.deviceType}
                  fullWidth
                  onChange={(e) => {
                    setDeviceType({...deviceType, deviceType: e.target.value})
                  }}
                />
                <TextField
                  variant={'outlined'}
                  label={'단말기 타입명'}
                  value={deviceType.deviceTypeName}
                  fullWidth
                  onChange={(e) => {
                    setDeviceType({...deviceType, deviceTypeName: e.target.value})
                  }}
                />
              </div>
            </div>
            <div className='modal-footer gap-3'>
              <Button
                data-bs-target='#deviceTypeAddModal'
                data-bs-toggle='modal'
                id={'deviceTypeBack'}
                variant={'contained'}
                color={'error'}
                className={'p-5'}
                type={'button'}
                onClick={() => {
                  setDeviceType({
                    deviceType: '',
                    deviceTypeName: '',
                  })
                }}
              >
                뒤로가기
              </Button>
              <Button
                variant={'contained'}
                className={'p-5 '}
                type={'button'}
                disabled={deviceType?.deviceType === '' || deviceType?.deviceTypeName === ''}
                onClick={() => addNewDeviceType()}
              >
                추가
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='deviceTypeAddModalUpdate'
        aria-hidden='true'
        aria-labelledby='deviceTypeAddModalUpdateLabel'
        tabIndex={-1}
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='deviceTypeAddModalLabel2'>
                단말기 타입 설정 - 수정
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <div className={'row gap-5 mx-0 px-0'}>
                <TextField
                  variant={'outlined'}
                  label={'단말기 타입'}
                  value={updateDeviceType.deviceType}
                  fullWidth
                  onChange={(e) => {
                    setUpdateDeviceType({...updateDeviceType, deviceType: e.target.value})
                  }}
                />
                <TextField
                  variant={'outlined'}
                  label={'단말기 타입명'}
                  value={updateDeviceType.deviceTypeName}
                  fullWidth
                  onChange={(e) => {
                    setUpdateDeviceType({...updateDeviceType, deviceTypeName: e.target.value})
                  }}
                />
              </div>
            </div>
            <div className='modal-footer gap-3'>
              <Button
                data-bs-target='#deviceTypeAddModal'
                data-bs-toggle='modal'
                id={'deviceTypeBack'}
                variant={'contained'}
                color={'error'}
                className={'p-5'}
                type={'button'}
              >
                뒤로가기
              </Button>
              <Button
                variant={'contained'}
                className={'p-5 '}
                type={'button'}
                disabled={deviceType?.deviceType === '' || deviceType?.deviceTypeName === ''}
                onClick={() => updateDeviceTypeFuc()}
              >
                수정
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default DeviceTypeAddModal
