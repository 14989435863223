import React, {useEffect, useState} from 'react'
import {DataGridPro} from '@mui/x-data-grid-pro'
import {useSelector} from 'react-redux'
import DeviceTargetImageListModal from 'pages/common/DevicetargetImageListModal'
import DeviceDetailInfoModal from 'pages/common/DeviceDetailInfoModal'
import {deviceInfoColumns} from 'pages/device/dataConfig/declearColumns'
import {scrollTop} from 'utils/func/justUtils'
import {deviceInfoRows} from 'pages/device/dataConfig/declearRows'
import {deviceInfoFilter} from 'pages/device/dataConfig/declearFilters'
import CustomPagination from 'utils/designSytem/CustomPagination'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import {getDeviceInfo} from 'utils/apiCalling/api'
import {toolTipText} from 'utils/toolTips/toolTipText'

const DeviceInfo = (props: any) => {
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const [loading, setLoading] = useState<boolean>(false)
  const [deviceInfoList, setDeviceInfoList] = useState<any>(null)
  const [deviceDetailData, setDeviceDetailData] = useState<any>(null)
  const [totalCount, setTotalCount] = useState<any>(null)
  const [imageDetailOpen, setImageDetailOpen] = useState<boolean>(false)
  const [deviceDetailOpen, setDeviceDetailOpen] = useState<boolean>(false)
  const [filter, setFilter] = useState<any>(deviceInfoFilter)
  const imageDetailModalOpen = () => {
    setImageDetailOpen(true)
  }
  const imageDetailModalClose = () => {
    setImageDetailOpen(false)
  }
  const deviceDetailModalOpen = () => {
    setDeviceDetailOpen(true)
  }
  const deviceDetailModalClose = () => {
    setDeviceDetailOpen(false)
  }
  const getDeviceInstallListCall = () => {
    getDeviceInfo(officeInfo, cooperInfo, filter, {
      success: (res: any) => {
        setDeviceInfoList(res)
      },
      count: (count: any) => {
        setTotalCount(count)
      },
      fail: (error: any) => {
        console.log(error)
      },
      loading: (loading: boolean) => {
        setLoading(loading)
      },
    })
  }

  const columns: any = deviceInfoColumns(
    setDeviceDetailData,
    deviceDetailModalOpen,
    imageDetailModalOpen
  )
  const rows = deviceInfoRows(deviceInfoList)
  useEffect(() => {
    scrollTop()
    getDeviceInstallListCall()
  }, [
    filter.deviceStatus,
    filter.efosStatus,
    filter.deviceType,
    filter.batteryStatus,
    filter.cooperIdx,
    filter.page,
    filter.rowPerPage,
    cooperInfo,
    officeInfo,
  ])
  return (
    <>
      <MeterariumPageLayout
        headerDescription={toolTipText.deviceInfo.description}
        tooltipText={toolTipText.deviceInfo.text}
        filter={filter}
        id={'device-info'}
        setFilter={setFilter}
        searchCallback={getDeviceInstallListCall}
        total={totalCount}
        control={['device', 'battery', 'efos', 'deviceType']}
        actions={[]}
        onDelete={null}
        searchOptions={['serialNumber', 'imei', 'deviceVersion']}
        checkData={null}
        onCancel={null}
      >
        <DataGridPro
          rowHeight={190}
          rows={rows}
          autoHeight={true}
          columns={columns}
          disableColumnMenu
          checkboxSelection={false}
          loading={loading}
          paginationMode={'server'}
          hideFooterPagination={true}
          pagination={true}
          hideFooter={true}
        />
        <CustomPagination filter={filter} setFilter={setFilter} totalCount={totalCount} />
      </MeterariumPageLayout>
      <DeviceTargetImageListModal
        data={deviceDetailData}
        open={imageDetailOpen}
        onClose={imageDetailModalClose}
      />
      <DeviceDetailInfoModal
        data={deviceDetailData}
        open={deviceDetailOpen}
        onClose={deviceDetailModalClose}
        getList={getDeviceInstallListCall}
      />
    </>
  )
}

export default DeviceInfo
