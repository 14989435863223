import {currentPositionTypes} from '../actionTypes'

interface CooperInfoState {
  position: any
}

const initialState: CooperInfoState = {
  position: {
    latitude: 0,
    longitude: 0,
  },
}

const positionReducer = (state = initialState, action: any): CooperInfoState => {
  switch (action.type) {
    case currentPositionTypes.POSITION_UPDATE:
      return {
        // ...action.payload,
        ...state,
        position: action.payload,
      }

    default:
      return state
  }
}

export default positionReducer
