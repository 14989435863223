import React, {useEffect, useState} from 'react'
import {Autocomplete, Button, TextField} from '@mui/material'
import {useSelector} from 'react-redux'
import {DoubleArrow} from '@mui/icons-material'
import {getInstallList, notInstallable} from 'utils/apiCalling/api'
import {installerAssignFilter} from 'pages/install/dataConfig/declearFilters'

const SelectInstallableCustomer = (props: any) => {
  const {set, setRenderStep, step, installSubmit, countCall, clear} = props
  const userInfo = useSelector((state: any) => state?.userInfo?.userInfo)
  const [installableList, setInstallableList] = useState<any>(null)
  const getInstallListCall = () => {
    getInstallList(userInfo, 'installCustomer', installerAssignFilter, {
      success: (res: any) => {
        setInstallableList(res)
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {},
    })
  }
  const notInstallableCall = () => {
    notInstallable(installSubmit, {
      success: (res: any) => {
        countCall()
        getInstallListCall()
        clear()
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {},
    })
  }
  useEffect(() => {
    if (step === 0) {
      getInstallListCall()
    }
  }, [step])
  return (
    <div className={'row mx-0 justify-content-center pb-3'}>
      <Autocomplete
        fullWidth
        id='choose-customer'
        options={installableList}
        className={'px-0'}
        disableClearable={true}
        clearText={'초기화'}
        noOptionsText={'검색 결과가 없습니다.'}
        getOptionLabel={(option: any) =>
          `${option?.customerName} - [ ${option?.customerNumber} ] : ${option?.addressNumber}`
        }
        onChange={(e, rowValue: any) => {
          set((prev: any) => ({
            ...prev,
            officeId: rowValue?.officeId,
            customerNumber: rowValue?.customerNumber,
            addressNumber: rowValue?.addressNumber,
            customerName: rowValue?.customerName,
            installId: userInfo?.userId,
          }))
        }}
        sx={{fontSize: '1.5rem', color: '#fff'}}
        renderInput={(params) => (
          <div className={'d-flex gap-3'}>
            <TextField
              {...params}
              fullWidth
              label='고객 선택'
              focused
              placeholder={'고객을 검색하거나 선택해주세요.'}
            />
          </div>
        )}
      />
      {installSubmit?.customerNumber && (
        <>
          <div
            id={'install-info'}
            className={'row gap-2 my-3 fs-4 border border-gray-600 rounded-2 p-3 mx-0'}
          >
            <div className={'d-flex gap-3'}>
              <span className={'fw-bolder'}>이름 -</span>
              <span>{installSubmit.customerName}</span>
            </div>
            <div className={'d-flex gap-3'}>
              <span className={'fw-bolder'}>고객 번호 -</span>
              <span>{installSubmit.customerNumber}</span>
            </div>
            <div className={'d-flex gap-3'}>
              <span className={'fw-bolder'}>주소 -</span>
              <span>{installSubmit.addressNumber}</span>
            </div>
          </div>
          <div className={'w-100 px-0 text-end'}>
            <Button variant={'contained'} color={'error'} onClick={() => notInstallableCall()}>
              설치 불가
            </Button>
            <p className={'mt-1 fw-bolder text-danger'}>설치 불가 수용가일 경우에 사용해주세요.</p>
          </div>
        </>
      )}
      <Button
        variant={'contained'}
        color={'primary'}
        style={{position: 'fixed', bottom: '10px'}}
        disabled={!installSubmit?.customerNumber}
        endIcon={<DoubleArrow />}
        onClick={() => setRenderStep(1)}
      >
        고객 선택 완료
      </Button>
    </div>
  )
}

export default SelectInstallableCustomer
