import React, {useEffect, useState} from 'react'
import BatchOffcanvas from '../../device/modules/batchOffcanvas'
import {Fade, FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material'
import {RegsterBarChart} from '../modules/RegisterChart'
import {envBackHost} from 'GLOBAL/envVariables'
import {useAuth} from '_component/modules/auth'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {useSelector} from 'react-redux'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import DeviceTypeAddModal from '../modules/DeviceTypeAddModal'
import _ from 'lodash'
import {toast, ToastContainer} from 'react-toastify'
import dayjs, {Dayjs} from 'dayjs'
import {normalAlert, resultAlert} from 'utils/CustomAlert/Alert'
import {CustomWidthTooltip} from 'utils/designSytem/CustomTooltip'
import HelpIcon from '@mui/icons-material/Help'
import {Controller, useForm} from 'react-hook-form'
import {toolTipText} from 'utils/toolTips/toolTipText'

const DeviceRegister = (props: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: {errors},
  } = useForm({criteriaMode: 'all', mode: 'onChange'})

  const {currentUser} = useAuth()
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const [deviceTypeList, setDeviceTypeList] = useState<any>(null)
  const [date, setDate] = useState<Dayjs | null>(dayjs())
  const [cooperList, setCooperList] = useState<any>(null)
  const [deviceCount, setDeviceCount] = useState<any>(null)
  const [deviceRegisterData, setDeviceRegisterData] = useState<any>({
    imei: '',
    serialNumber: '',
    iccid: '',
    psn: '',
    deviceType: '',
    registerId: userInfo?.userId,
    cooperIdx: '',
    cooperName: '',
  })
  const resetHandler = () => {
    setDeviceRegisterData({
      imei: '',
      serialNumber: '',
      iccid: '',
      psn: '',
      deviceType: '',
      registerId: userInfo?.userId,
      cooperIdx: '',
    })
  }
  const getDeviceCount = () => {
    axiosWithAuth()
      .get(`${envBackHost}/device/count`, {
        params: {
          year: dayjs(date).format('YYYY'),
        },
      })
      .then((res) => {
        setDeviceCount(res.data.res)
      })
  }
  const registerHandler = async () => {
    await toast.promise(
      axiosWithAuth()
        .post(`${envBackHost}/device`, deviceRegisterData)
        .then(async (res) => {
          console.log('re', res)
          if (res.data.resultCode === 0) {
            resetHandler()
          }
          await resultAlert(res.data)
        })
        .catch(async (e) => {
          console.log('err', e)
          await resultAlert({resultCode: e.response.status})
        }),
      {
        pending: '단말기 등록 중입니다.',
      },
      {
        position: 'bottom-left',
        autoClose: 1000,
        className: 'fs-3  fw-bold',
        style: {width: '400px'},
      }
    )
  }

  const getDeviceType = () => {
    axiosWithAuth()
      .get(`${envBackHost}/devicetype`)
      .then((res) => {
        setDeviceTypeList(res.data.res)
      })
  }
  const getCooperList = () => {
    axiosWithAuth()
      .get(`${envBackHost}/cooperation/device`)
      .then((res) => {
        setCooperList(res.data.res)
      })
  }
  useEffect(() => {
    setDeviceRegisterData({
      ...deviceRegisterData,
      registerId: userInfo?.userId,
    })
    getDeviceType()
    getCooperList()

    getDeviceCount()
  }, [date])

  return (
    <>
      {!isMobileDevice() && (
        <div className='card flex-grow-1' id='device-register'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>{toolTipText.deviceRegister.description}</h3>
              <CustomWidthTooltip
                title={toolTipText.deviceRegister.text}
                TransitionComponent={Fade}
                TransitionProps={{timeout: 600}}
                placement={'right-start'}
                arrow={true}
                sx={{fontSize: '10px', letterSpacing: '1px', cursor: 'pointer'}}
              >
                <HelpIcon
                  color={'primary'}
                  sx={{fontSize: '24px'}}
                  className={'ms-4 mb-2 cursor-pointer'}
                />
              </CustomWidthTooltip>
            </div>
            <div className={'d-flex gap-4'}>
              <button
                className={'btn btn-primary align-self-center'}
                data-bs-toggle={'offcanvas'}
                data-bs-target='#batchRegister'
                aria-controls='batchRegister'
              >
                일괄등록
              </button>
              <button
                className={'btn btn-info align-self-center'}
                data-bs-toggle='modal'
                data-bs-target='#deviceTypeAddModal'
              >
                단말기 타입 설정
              </button>
            </div>
          </div>

          <div className='card-body p-9'>
            <div className={'row row-cols-2 gap-10'}>
              <div className={'col-lg-3 mb-5 ps-8 '}>
                <form onSubmit={handleSubmit(registerHandler)}>
                  <div className={'row my-4'}>
                    <label className='mb-3 fw-bold text-muted'>등록자 ID</label>
                    <div className={'d-flex gap-3'}>
                      <TextField
                        label='등록자 ID'
                        variant='outlined'
                        disabled
                        fullWidth
                        value={currentUser?.id}
                      />
                    </div>
                  </div>
                  <div className={'row my-4'}>
                    <label className='mb-3 fw-bold text-muted'>
                      단말기 ID
                      <span className={'fw-bolder fs-3 ms-1'} style={{color: 'red'}}>
                        *
                      </span>
                    </label>
                    <div className={'d-flex gap-3'}>
                      <TextField
                        {...register('imei', {
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            setDeviceRegisterData({...deviceRegisterData, imei: e.target.value})
                          },
                          required: {
                            value: true,
                            message: '필수 입력 사항입니다.',
                          },
                        })}
                        id='device-id'
                        label='단말기 ID'
                        variant='outlined'
                        fullWidth
                        value={deviceRegisterData.imei}
                        error={errors.imei ? true : false}
                        helperText={
                          errors.imei ? (
                            <span className={'fs-6 fw-bold text-danger'}>
                              {String(errors.imei.message)}
                            </span>
                          ) : (
                            ''
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className={'row my-4'}>
                    <label className='mb-3 fw-bold text-muted'>
                      단말기 S/N
                      <span className={'fw-bolder fs-3 ms-1'} style={{color: 'red'}}>
                        *
                      </span>
                    </label>
                    <div className={'d-flex gap-3'}>
                      <TextField
                        {...register('serialNumber', {
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            setDeviceRegisterData({
                              ...deviceRegisterData,
                              serialNumber: e.target.value,
                            })
                          },
                          required: {
                            value: true,
                            message: '필수 입력 사항입니다.',
                          },
                        })}
                        label='단말기 S/N'
                        variant='outlined'
                        fullWidth
                        value={deviceRegisterData.serialNumber}
                        error={errors.serialNumber ? true : false}
                        helperText={
                          errors.serialNumber ? (
                            <span className={'fs-6 fw-bold text-danger'}>
                              {String(errors.serialNumber.message)}
                            </span>
                          ) : (
                            ''
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className={'row my-4'}>
                    <label className='mb-3 fw-bold text-muted'>ICCID</label>
                    <div className={'d-flex gap-3'}>
                      <TextField
                        {...register('iccid', {
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            setDeviceRegisterData({
                              ...deviceRegisterData,
                              iccid: e.target.value,
                            })
                          },
                        })}
                        label='ICCID'
                        variant='outlined'
                        fullWidth
                        value={deviceRegisterData.iccid}
                      />
                    </div>
                  </div>
                  <div className={'row my-4'}>
                    <label className='mb-3 fw-bold text-muted'>PSN</label>
                    <div className={'d-flex gap-3'}>
                      <TextField
                        {...register('psn', {
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            setDeviceRegisterData({
                              ...deviceRegisterData,
                              psn: e.target.value,
                            })
                          },
                        })}
                        label='PSN'
                        variant='outlined'
                        fullWidth
                        value={deviceRegisterData.psn}
                      />
                    </div>
                  </div>
                  <div className={'d-flex col gap-3 px-0 mx-0'}>
                    <div className={'row my-4 col px-0 mx-0'}>
                      <label className='mb-3 fw-bold text-muted ps-0'>단말기 타입</label>
                      <div className={'d-flex gap-3 mx-0 px-0'}>
                        <FormControl fullWidth>
                          <InputLabel id='origin-device-com'>단말기 타입</InputLabel>

                          <Controller
                            name='deviceType'
                            control={control}
                            defaultValue=''
                            render={({field}) => (
                              <Select
                                {...field}
                                labelId='device-type-label'
                                value={deviceRegisterData?.deviceType}
                                label='단말기 타입'
                                onChange={(e) => {
                                  field.onChange(e)
                                  setDeviceRegisterData({
                                    ...deviceRegisterData,
                                    deviceType: e.target.value,
                                  })
                                }}
                              >
                                {_.map(deviceTypeList, (el, i) => {
                                  return (
                                    <MenuItem key={i} value={el.deviceType}>
                                      {el.deviceTypeName}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className={'d-flex col gap-3 px-0 mx-0'}>
                    <div className={'row my-4 col px-0 mx-0'}>
                      <label className='mb-3 fw-bold text-muted ps-0'>단말기 제조사</label>
                      <div className={'d-flex gap-3 mx-0 px-0'}>
                        <FormControl fullWidth>
                          <InputLabel id='origin-device-com'>단말기 제조사</InputLabel>
                          <Select
                            labelId='origin-device-com-label'
                            id='origin-device-com-id'
                            value={deviceRegisterData?.cooperIdx}
                            label='단말기 제조사'
                            onChange={(e) =>
                              setDeviceRegisterData({
                                ...deviceRegisterData,
                                cooperIdx: e.target.value,
                              })
                            }
                          >
                            {_.map(
                              _.filter(cooperList, (el) => el.idx !== 0),
                              (el, i) => (
                                <MenuItem key={i} value={el.idx}>
                                  {el.cooperName}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className={'d-flex justify-content-end mt-5'}>
                    <button
                      className={'btn btn-primary align-self-center fw-bolder'}
                      type={'submit'}
                    >
                      등록
                    </button>
                  </div>
                </form>
              </div>

              <div className={'col-lg h-100'}>
                {deviceCount && (
                  <RegsterBarChart
                    className={'w-100'}
                    deviceCount={deviceCount}
                    date={date}
                    setDeviceCount={setDeviceCount}
                    setDate={setDate}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <BatchOffcanvas toast={toast} />
      <DeviceTypeAddModal
        toast={toast}
        deviceTypeList={deviceTypeList}
        getDeviceType={getDeviceType}
      />
      <ToastContainer />
    </>
  )
}

export default DeviceRegister
