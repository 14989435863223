import React, {useEffect, useState} from 'react'
import {Button, IconButton, TextField} from '@mui/material'
import axios from 'axios'
import {envBackHost} from '../../../GLOBAL/envVariables'
import dayjs from 'dayjs'
import {useAuth} from '../../../_component/modules/auth'
import fileDownload from 'js-file-download'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import {resultAlert} from 'utils/CustomAlert/Alert'
import _ from 'lodash'
import {getOfficeList} from 'utils/apiCalling/api'
import {useSelector} from 'react-redux'

const DeviceAllocationBatchRegisterOffcanvas = (props: any) => {
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const {toast} = props
  const {currentUser} = useAuth()
  const [file, setFile] = useState<File>()
  const [fileName, setFileName] = useState<string>('')
  const [processing, setProcessing] = useState<boolean>(false)
  const [failList, setFailList] = useState<any[]>([])

  const fileValidationUploadHandler = async (e: any) => {
    const target = e.currentTarget
    const file = (target.files as FileList)[0]
    setFile(file)
    setFileName(file.name)
  }
  const exampleExcelDownload = async () => {
    const fileName = `device_assign_batch_example_${dayjs().format('YYYY-MM-DD HH:mm')}.xlsx`
    axiosWithAuth()
      .get(`${envBackHost}/device/assignexample`, {
        responseType: 'blob',
      })
      .then(async (res) => {
        await fileDownload(res.data, fileName)
      })
  }
  console.log(userInfo)
  const fileUploadHandler = async () => {
    console.log(file)
    const formData = new FormData()
    formData.append('file', file as Blob)
    formData.append('id', userInfo?.userId)
    setProcessing(true)
    await toast.promise(
      axiosWithAuth()
        .post(`${envBackHost}/device/assignexcel`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(async (res) => {
          setProcessing(false)
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            document.getElementById('batchRegisterCustomerClose')?.click()
            setFileName('')
            setProcessing(false)
            return
          } else if (res.data.resultCode === 30) {
            setFailList(res?.data?.res)
            setProcessing(false)
            return
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
          setProcessing(false)
        })
        .finally(() => {
          setProcessing(false)
        }),
      {
        pending: '단말기 일괄 할당 중 입니다.',
      },
      {
        position: 'top-center',
        closeButton: false,
        className: 'fs-3  fw-bold',
        style: {width: '400px'},
      }
    )
  }
  useEffect(() => {
    setFailList([])
  }, [fileName])

  return (
    <div
      className='offcanvas offcanvas-end'
      tabIndex={-1}
      id='allocationBatchRegister'
      aria-labelledby='allocationBatchRegisterLabel'
      data-bs-backdrop={'true'}
      style={{width: '30%'}}
    >
      <div className='offcanvas-header'>
        <h2 className='offcanvas-title' id='allocationBatchRegisterLabel'>
          단말기 할당 일괄등록
        </h2>
        <button
          type='button'
          className='btn-close text-reset'
          data-bs-dismiss='offcanvas'
          aria-label='Close'
        ></button>
      </div>
      <div className='offcanvas-body batch-scrollbar'>
        <div className={'row gap-4'}>
          <div className={'d-flex col gap-3 w-100 px-0 mx-0'}>
            <div className={'col-6'}>
              <TextField
                variant={'outlined'}
                label={'파일 이름 　　'}
                value={fileName}
                disabled={true}
                fullWidth
                helperText={
                  fileName === '' ? (
                    <strong className={'fs-5'}>파일을 선택해주세요.</strong>
                  ) : failList?.length > 0 ? (
                    <strong className={'text-primary fs-5'}>
                      잘못 업로드한 경우 다시 찾아보기를 클릭해주세요.
                    </strong>
                  ) : (
                    <strong className={'text-primary fs-5'}>일괄등록을 눌러주세요 !</strong>
                  )
                }
                inputProps={{
                  style: {
                    fontSize: 20,
                    color: 'black',
                    fontWeight: 'bold',
                  },
                }} // font size of input text
                InputLabelProps={{style: {fontSize: 20, color: 'black'}}} // font size of input label
              />
            </div>
            <div className={'d-flex gap-4 col-6'}>
              <Button
                variant='contained'
                component='label'
                style={{minWidth: 'auto', width: '120px', height: '60px'}}
              >
                찾아보기
                <input
                  type={'file'}
                  id={'fileItem'}
                  accept={'.xlsx'}
                  onChange={(e) => fileValidationUploadHandler(e)}
                  hidden
                />
              </Button>
              <Button
                variant={'contained'}
                color={'success'}
                style={{minWidth: 'auto', width: '160px', height: '60px'}}
                onClick={() => exampleExcelDownload()}
              >
                샘플양식 다운로드
              </Button>
            </div>
          </div>
        </div>
        {failList?.length > 0 && (
          <>
            <div className={'my-4 d-flex px-2'}>
              <h3 className={'text-danger fs-2'}> {fileName} 일괄 등록 실패 목록</h3>
              <span className={'text-muted fw-bolder mx-2 fs-5'}>( {failList?.length}건 )</span>
            </div>
            <table
              className='table table-hover table-rounded  border  gy-3 gs-3 text-center'
              style={{overflow: 'scroll', maxHeight: '1024px'}}
            >
              <thead style={{position: 'sticky', top: 0}} className={'bg-gray-600 '}>
                <tr className='fw-bolder fs-5  border-bottom-2 border-gray-200 text-white'>
                  <th>No.</th>
                  <th>단말기 S/N</th>
                  <th>에러 이유</th>
                </tr>
              </thead>
              <tbody>
                {_.map(failList, (el, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{el?.serialNumber}</td>
                      <td>{el?.message}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        )}
      </div>

      <div className={'offcanvas-footer'}>
        <div
          className={'d-flex gap-7 justify-content-end '}
          style={{marginBottom: '50px', marginRight: '30px'}}
        >
          <Button
            variant={'contained'}
            size={'large'}
            color={'info'}
            onClick={() => fileUploadHandler()}
          >
            일괄등록
          </Button>
          <Button
            id={'batchRegisterCustomerClose'}
            variant={'contained'}
            size={'large'}
            color={'error'}
            data-bs-dismiss='offcanvas'
          >
            나가기
          </Button>
        </div>
      </div>
    </div>
  )
}
export default DeviceAllocationBatchRegisterOffcanvas
