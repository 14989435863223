import {Button, Divider} from '@mui/material'
import {yMarker} from 'assets/imageDeclear'
import {submitData} from 'pages/install/dataConfig/submitData'
import InstallModalContainer from 'pages/install/modules/installModalComponents/InstallModalContainer'
import {useRef, useState} from 'react'
import {Map, MapMarker, MapTypeControl} from 'react-kakao-maps-sdk'
import {useSelector} from 'react-redux'

const Install = (props: any) => {
  const {countCall} = props
  const position = useSelector((state: any) => state?.currentPosition.position)
  const userInfo = useSelector((state: any) => state?.userInfo?.userInfo)
  const [installSubmit, setInstallSubmit] = useState<any>(submitData)
  const mapRef = useRef<any>(null)
  const [renderStep, setRenderStep] = useState<number>(0)
  const clear = () => {
    setRenderStep(0)
    setInstallSubmit(submitData)
  }
  const [modalStatus, setModalStatus] = useState<boolean>(false)
  const modalOpen = () => {
    setInstallSubmit((prev: any) => ({
      ...prev,
      latitude: position?.latitude,
      longitude: position?.longitude,
      installId: userInfo?.userId,
    }))

    setModalStatus(true)
  }
  const modalClose = () => setModalStatus(false)
  return (
    <div className={'container-fluid w-100 px-0 mb-5'}>
      <div className={'card card-body '}>
        <span className={'fw-bolder fs-3'}>현재위치</span>
        <Map
          id='map'
          center={{
            lat: position?.latitude,
            lng: position?.longitude,
          }}
          onCreate={(map: any) => (mapRef.current = map)}
          isPanto={false}
          style={{
            width: '100%',
            height: '80vw',
          }}
          level={3}
          mapTypeId={kakao?.maps.MapTypeId.ROADMAP}
          className={' mt-2 rounded'}
          draggable={false}
          zoomable={false}
        >
          <MapTypeControl position={kakao?.maps.ControlPosition.TOPRIGHT} />
          <MapMarker
            position={{
              lat: position?.latitude,
              lng: position?.longitude,
            }}
            draggable={false}
            image={{src: yMarker, size: {width: 33, height: 45}}}
          />
        </Map>

        <Divider className={'my-3 '} color={'#919090'} />
        <Button variant='contained' color='primary' className={'float-end'} onClick={modalOpen}>
          설치 진행하기
        </Button>
      </div>
      <InstallModalContainer
        open={modalStatus}
        onClose={modalClose}
        name={'install-modal-container'}
        renderStep={renderStep}
        set={setInstallSubmit}
        clear={clear}
        countCall={countCall}
        installSubmit={installSubmit}
        setRenderStep={setRenderStep}
      />
    </div>
  )
}
export default Install
