import {Box, SvgIconProps} from '@mui/material'
import Typography from '@mui/material/Typography'
import React from 'react'
import {TreeItemProps} from '@mui/lab'
import {treeItemRoot} from 'pages/officeConfig/config/declare'
declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string
    '--tree-view-bg-color'?: string
  }
}
type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string
  color?: string
  labelIcon?: React.ElementType<SvgIconProps>
  labelInfo?: string
  labelText?: string
}
const StyledTreeItemRoot = treeItemRoot

export const StyledTreeItem = (props: StyledTreeItemProps) => {
  const {bgColor, color, labelIcon: LabelIcon, labelInfo, labelText, ...other} = props

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{display: 'flex', alignItems: 'center', p: 0.5, pr: 0}}>
          <Box component={LabelIcon} color='inherit' sx={{mr: 1}} />
          <Typography variant='body2' sx={{fontWeight: 'inherit', flexGrow: 1, fontSize: '18px'}}>
            {labelText}
          </Typography>
          <Typography variant='caption' color='inherit'>
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
        fontSize: '18px',
        marginTop: '4px',
        marginBottom: '4px',
      }}
      {...other}
    />
  )
}
export default StyledTreeItem
