import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'

const SystemHeader = (props: any) => {
  const location = useLocation()

  return (
    <>
      {!isMobileDevice() && (
        <div className='card mb-5 '>
          <div className='card-body pt-4 pb-0'>
            <div className='d-flex overflow-auto h-55px'>
              <ul
                className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent  fw-bolder flex-nowrap'
                style={{fontSize: '22px'}}
              >
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/system/code' && 'active')
                    }
                    to='/system/code'
                  >
                    시스템 코드관리
                  </Link>
                </li>
                {/*<li className='nav-item'>*/}
                {/*  <Link*/}
                {/*    className={*/}
                {/*      `nav-link text-active-primary me-6 ` +*/}
                {/*      (location.pathname === '/system/log' && 'active')*/}
                {/*    }*/}
                {/*    to='/system/log'*/}
                {/*  >*/}
                {/*    시스템 로그관리*/}
                {/*  </Link>*/}
                {/*</li>*/}
              </ul>
            </div>
          </div>
        </div>
      )}
      {isMobileDevice() && (
        <div className='card mb-5 mb-xl-10 mt-5'>
          <div className='card-body pt-4 pb-0'>
            <div className='d-flex overflow-auto h-55px'>
              <ul
                className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-bolder justify-content-evenly'
                style={{fontSize: '20px'}}
              >
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/system/code' && 'active')
                    }
                    to='/system/code'
                  >
                    시스템 코드관리
                  </Link>
                </li>
                {/*<li className='nav-item'>*/}
                {/*  <Link*/}
                {/*    className={*/}
                {/*      `nav-link text-active-primary me-6 ` +*/}
                {/*      (location.pathname === '/system/log' && 'active')*/}
                {/*    }*/}
                {/*    to='/system/log'*/}
                {/*  >*/}
                {/*    시스템 로그관리*/}
                {/*  </Link>*/}
                {/*</li>*/}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default SystemHeader
