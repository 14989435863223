import {handleCellClick} from 'utils/func/justUtils'
import React from 'react'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'
import ToggleOffIcon from '@mui/icons-material/ToggleOff'

export const accountColumns = [
  {field: 'id', headerName: '유저 ID', width: 150, align: 'center', headerAlign: 'center'},

  {
    field: 'name',
    headerName: '이름',
    width: 230,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'accountType',
    headerName: '권한',
    width: 120,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: any) => {
      return (
        <div>
          <span>{params.row.accountTypeName}</span>
        </div>
      )
    },
  },
  {
    field: 'officeId',
    headerName: '사업소 번호',
    width: 160,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'officeName',
    headerName: '사업소 이름',
    width: 160,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'phoneNumber',
    headerName: '전화번호',
    width: 250,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'email',
    headerName: '이메일',
    width: 250,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'cooperName',
    headerName: '팀',
    width: 170,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'idActiveStatus',
    headerName: '계정상태',
    width: 250,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: any) => {
      return params.row.idActiveStatus === true ? (
        <div>
          <ToggleOnIcon
            color={'success'}
            sx={{fontSize: '50px'}}
            onClick={(e) => {
              handleCellClick(e)
            }}
          />
        </div>
      ) : (
        <div>
          <ToggleOffIcon
            color={'error'}
            sx={{fontSize: '50px'}}
            onClick={(e) => {
              handleCellClick(e)
            }}
          />
        </div>
      )
    },
  },
]
