/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue, isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import dayjs from 'dayjs'
import _ from 'lodash'
import {useSelector} from 'react-redux'
import {envImagePath} from '../../../GLOBAL/envVariables'

type Props = {
  className: string
  checkList?: any
  filter: any
  value?: any
  avg?: any
}

const LeakViewChartBongHwa: React.FC<Props> = ({className, filter, checkList, avg}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, filter, checkList, avg))
    if (chart) {
      chart.render()
    }

    return chart
  }
  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, checkList])

  return (
    <div className={`card ${className}`}>
      <div className='card-body p-0'>
        {/* begin::Chart */}
        <div
          className={'p-4'}
          ref={chartRef}
          id='kt_charts_widget_4_chart'
          style={{height: '100%'}}
        ></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {LeakViewChartBongHwa}

function getChartOptions(height: number, filter: any, checkList: any, avg: any): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-800')
  const borderColor = getCSSVariableValue('--kt-gray-400')

  const baseColor = getCSSVariableValue('--kt-success')
  const baseLightColor = getCSSVariableValue('--kt-success-light')
  const secondaryColor = getCSSVariableValue('--kt-warning')
  const secondaryLightColor = getCSSVariableValue('--kt-warning-light')

  const fontSize = isMobileDevice() ? '12px' : '18px'
  return {
    series: [
      {
        name: `사용량`,
        data: _.map(checkList, (el, i) => {
          return {
            x: dayjs(el.createAt).format('MM월 DD일'),
            y: el.checkValue,
            goals: el?.filePath,
          }
        }),
      },
    ],
    chart: {
      fontFamily: 'inter',
      id: 'area-datetime',
      type: 'area',
      height: isMobileDevice() ? 220 : 280,
      width: '100%',
      toolbar: {
        show: true,
        offsetY: -10,
        tools: {
          download: true,
        },
        export: {
          csv: {
            filename: `누수의심현황${dayjs(filter.date).format('YYYY-MM-DD')}`,
            headerCategory: '날짜',
          },
          png: {
            filename: `누수의심현황${dayjs(filter.date).format('YYYY-MM-DD')}`,
          },
        },
      },
    },
    annotations: {
      yaxis: [
        {
          y: avg,
          borderColor: '#fd0000',
          borderWidth: 4,
          strokeDashArray: 2,
          label: {
            borderColor: 'transparent',
            offsetY: -8,
            text: `누수의심 수치 : 약 ${avg} m³`,
            style: {
              color: '#ff0000',
              background: 'white',
              fontSize: '18px',
              fontWeight: '600',
            },
            offsetX: 197,
            position: 'start',
          },
        },
      ],
      // xaxis: [
      //   {
      //     x: 150,
      //     borderColor: '#fd0000',
      //     borderWidth: 4,
      //     label: {
      //       text: '위험수치',
      //       style: {
      //         color: '#fff',
      //         background: 'black',
      //       },
      //     },
      //   },
      // ],
    },
    legend: {
      show: true,
      fontSize: fontSize,
      fontWeight: 600,
    },

    // 라벨 색
    dataLabels: {
      enabled: true,
      style: {
        fontSize: fontSize,
        fontWeight: 500,
      },
      formatter(val: string | number | number[], el: any, opts?: any): string | number {
        return Number(val).toLocaleString('ko-KR') + 'm³'
      },
    },
    // 아래 영역 색
    fill: {
      colors: [`#21af02`],
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: _.map(checkList, (el, i) => {
        return dayjs(el.createAt).format('DD일 HH:mm')
      }),

      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: fontSize,
          fontWeight: 600,
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: labelColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
        formatter(value: string, opts?: object): string {
          return '1'
        },
        offsetY: 0,
        style: {
          fontSize: fontSize,
        },
      },
    },
    yaxis: {
      tickAmount: 4,
      labels: {
        style: {
          colors: labelColor,
          fontSize: fontSize,
        },
        offsetX: -10,
        formatter(val: number, opts?: any): string | string[] {
          return Number(val).toLocaleString('ko-KR')
        },
      },
    },

    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: fontSize,
      },
      custom: function ({series, seriesIndex, dataPointIndex, w}) {
        let image = w.config.series[seriesIndex].data[dataPointIndex].goals
        let imageURL = envImagePath + w.config.series[seriesIndex].data[dataPointIndex].goals
        let xValue = w.config.series[seriesIndex].data[dataPointIndex]
        if (image === null) {
          // If image is null, don't show the card-body div
          return `<div class="card">
              <div class="card-header row align-items-center ">
                <h2 class="mt-2">${xValue.x}</h2>
                <h2>검침값 : <u>${xValue.y} m³</u></h2>
              </div>
            </div>`
        } else {
          // If image exists, show the card-body div with the image
          return `<div class="card">
              <div class="card-header d-flex align-items-center">
                <h2>${xValue.x}</h2>
                <h2>검침값 : <u>${xValue.y} m³</u></h2>
              </div>
              <div class="card-body p-0">
                <img src='${imageURL}' style='width: 100%; '>
              </div>
            </div>`
        }
      },
      x: {
        show: true,
        formatter(val: number, opts?: any): string {
          return dayjs(val).format('DD일 HH:mm')
        },
      },
      y: {
        formatter: function (val, opts) {
          return Number(val).toLocaleString('ko-KR')
        },
      },
    },
    colors: ['#068029', '#15dc4e'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: 6,
      colors: [baseLightColor, secondaryLightColor],
      strokeColors: [baseLightColor, secondaryLightColor],
      strokeWidth: 3,
    },
  }
}
