import {pageSizeActionTypes} from '../actionTypes'

const initialState = {
  pageSize: 30,
  selectPageSize: [10, 30, 50, 100],
}

const pageSizeControlReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case pageSizeActionTypes.PAGE_SIZE_UPDATE:
      return {
        ...state,
        pageSize: action.payload.pageSize,
        selectPageSize: [10, 30, 50, 100],
      }

    default:
      return state
  }
}

export default pageSizeControlReducer
