import React, {useEffect, useState} from 'react'
import {Button, TextField} from '@mui/material'
import {envBackHost} from '../../../GLOBAL/envVariables'
import {axiosWithAuth} from '../../../helper/axiosWithAuth'
import dayjs from 'dayjs'
import {confirmAlert, normalAlert, resultAlert} from '../../../utils/CustomAlert/Alert'

const AddAccountTypeModal = (props: any) => {
  const {accountTypeList, setSingleAccountType} = props
  const [updateAccountType, setUpdateAccountType] = useState<any>({
    accountType: '',
    accountTypeName: '',
  })
  const postAccountType = async () => {
    if (updateAccountType.accountType === '' || updateAccountType.accountType === '') {
      await normalAlert('계정타입 혹은 타입 명이 누락되어있습니다.')
      return
    }
    if (await confirmAlert('생성', 'info')) {
      axiosWithAuth()
        .post(`${envBackHost}/accounttype`, updateAccountType)
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            setUpdateAccountType({
              accountType: '',
              accountTypeName: '',
            })
            return
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    } else {
      return
    }
  }

  return (
    <div
      className='modal fade'
      id='addAccountType'
      tabIndex={-1}
      aria-labelledby='addAccountTypeLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered modal-xl'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='addAccountTypeLabel'>
              계정타입 생성
            </h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <div className={'d-flex  gap-7'}>
              <div className={'col w-100'} style={{overflow: 'auto', height: '500px'}}>
                <table
                  className='table table-hover table-rounded table-row-bordered  border gy-7 gs-7'
                  style={{cursor: 'pointer'}}
                >
                  <thead>
                    <tr className={'text-center fw-bold fs-5'}>
                      <th>계정타입</th>
                      <th>계정타입 권한</th>
                      <th>계정타입 생성일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountTypeList?.map((el: any, i: number) => {
                      return (
                        <tr
                          key={i}
                          className={'text-center'}
                          data-bs-toggle='modal'
                          data-bs-target='#changeAccountType'
                          onClick={(e) => {
                            setSingleAccountType({
                              accountType: el.accountType,
                              accountTypeName: el.accountTypeName,
                            })
                          }}
                        >
                          <td>{el.accountType}</td>
                          <td>{el.accountTypeName}</td>
                          <td>{dayjs(el.createAt).format('YYYY-MM-DD HH시 mm분 ss초')}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div className={'col gap-3'}>
                <div className={'row gap-4 '}>
                  <div className={'row  mx-0'}>
                    <span className={'text-muted fw-bold mb-4 fs-5'}>
                      계정타입을 입력할 수 있습니다.
                    </span>
                    <TextField
                      variant={'outlined'}
                      label={'계정타입'}
                      placeholder={'계정타입을 입력해주세요.'}
                      value={updateAccountType.accountType}
                      autoComplete={'off'}
                      onChange={(e) => {
                        setUpdateAccountType({
                          ...updateAccountType,
                          accountType: e.target.value,
                        })
                      }}
                    />
                  </div>
                  <div className={'row mx-0'}>
                    <span className={'text-muted fw-bold mb-4 fs-5'}>
                      계정타입 명을 입력할 수 있습니다.
                    </span>
                    <TextField
                      variant={'outlined'}
                      label={'계정타입 명'}
                      placeholder={'계정타입 명을 입력해주세요.'}
                      autoComplete={'off'}
                      value={updateAccountType.accountTypeName}
                      onChange={(e) => {
                        setUpdateAccountType({
                          ...updateAccountType,
                          accountTypeName: e.target.value,
                        })
                      }}
                    />
                  </div>
                  <div className={'text-end'}>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      className={'fs-6'}
                      onClick={() => {
                        postAccountType()
                      }}
                    >
                      생성
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <Button variant={'contained'} color={'error'} data-bs-dismiss='modal'>
              닫기
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddAccountTypeModal
