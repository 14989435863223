/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken} from '../core/_requests'
import {useAuth} from '../core/Auth'
import 'react-toastify/dist/ReactToastify.css'
import {useDispatch} from 'react-redux'
import {loginAlert, loginFailAlert, resultAlert} from 'utils/CustomAlert/Alert'
import {loginCookieAction, loginHashAction} from 'utils/func/justUtils'
import {cooperAction, officeAction, userAction} from 'redux/action'

const loginSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, '올바른 형식의 비밀번호가 아닙니다.')
    .max(13, '올바른 형식의 비밀번호가 아닙니다.')
    .required('비밀번호를 입력해주세요.'),
  id: Yup.string()
    .min(2, '아이디는 2글자 이상입니다.')
    .max(13, '아이디는 13글자 이하입니다.')
    .required('아이디를 입력해주세요.'),
})

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const inputRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()
  document.getElementById('id')?.focus()
  const dispatch = useDispatch()
  const initialValues = {
    id: '',
    password: '',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
    
      try {
        await loginHashAction(values).then(async (res) => {
          if (res) {
            await loginAlert(res.name)
            saveAuth(res)
            await getUserByToken(res?.token)
            await loginCookieAction(res?.token)
            setCurrentUser(res)
            dispatch(await userAction('request', res))
            dispatch(await officeAction('request', res))
            dispatch(await cooperAction('request', res))
            /**
             * 일반수용가 이동
             */
            if (res.accountType === 'customer_client') {
              navigate('/customer/client', {replace: true})
            } else if (res.accountType === 'installer') {
              navigate('/install/connect', {replace: true})
            } else {
              navigate('/home', {replace: true})
            }
          } else {
            saveAuth(undefined)
            setStatus('The login detail is incorrect')
            setSubmitting(false)
            setLoading(false)
            await loginFailAlert()
          }
        })
      } catch (e: any) {
        /*err*/
        console.log(e)
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
        await resultAlert({resultCode: e.response.status})
      }
    },
  })
  useEffect(() => {
    inputRef.current?.focus()
  }, [])
  return (
    <>
      <div className={'h-100'} style={{display: 'grid'}}>
        <form
          className='form w-100 h-100 d-grid align-items-center '
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {/* ID Form */}
          <div className='fv-row mb-10'>
            <label className='form-label fs-4 fw-bold text-white'>아이디</label>
            <input
              style={{imeMode: 'disabled'}}
              placeholder={'아이디를 입력해주세요.'}
              {...formik.getFieldProps('id')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.id && formik.errors.id},
                {
                  'is-valid': formik.touched.id && !formik.errors.id,
                }
              )}
              type='text'
              name='id'
              autoComplete='off'
              ref={inputRef}
            />
            {formik.touched.id && formik.errors.id && (
              <div className='fv-plugins-message-container'>
                <span className={'text-danger fw-bolder'} role='alert'>
                  {formik.errors.id}
                </span>
              </div>
            )}
          </div>

          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2 justify-content-between w-100'>
                <div>
                  <span className='form-label fw-bolder text-white fs-4 mb-0'>비밀번호</span>
                </div>
                <div onClick={() => navigate('/password/certification')}>
                  <span className={' ms-4 link-secondary fs-5 text-white fw-bold cursor-pointer'}>
                    비밀번호 재설정
                  </span>
                </div>
              </div>
            </div>
            <input
              type='password'
              placeholder={'비밀번호를 입력해주세요.'}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className={'text-danger fw-bolder'} role='alert'>
                    {formik.errors.password}
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-dark w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label fw-bolder'>로그인</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  로그인 중입니다. 잠시만 기다려주세요...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
          <div className={'align-self-center'}>
            <Link to={'/join/agree'}>
              <div className='text-center'>
                <button type='button' className='btn btn-lg bg-white  w-100 '>
                  <span className='fw-bolder text-dark'>회원가입</span>
                </button>
              </div>
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}
