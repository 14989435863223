import {useEffect, useState} from 'react'
// @ts-ignore
import swal from 'sweetalert'
import {normalAlert} from '../utils/CustomAlert/Alert'
interface ISpeechRecognitionResult {
  transcript: string
  isFinal: boolean
}

interface webkitSpeechRecognition {
  lang: string
  interimResults: boolean
  start: () => void
  stop: () => void
  onresult?: (event: any) => void
  maxAlternatives?: number
  onend?: (event: any) => void
}

declare global {
  interface Window {
    webkitSpeechRecognition: new () => webkitSpeechRecognition
  }
}
export const useSpeechRecognition = () => {
  const [result, setResult] = useState<ISpeechRecognitionResult>({transcript: '', isFinal: false})
  const [isListening, setIsListening] = useState(false)
  let recognition: webkitSpeechRecognition | null = null
  const showWarning = async () => {
    await normalAlert('음성인식을 지원하지 않는 브라우저입니다.')

    // `response`를 기반으로 추가적인 로직 처리 (필요한 경우)
  }
  if ('webkitSpeechRecognition' in window) {
    recognition = new (window as any).webkitSpeechRecognition()
  } else {
    // 음성인식 지원하지 않는 경우 경고 표시
    showWarning()
  }

  useEffect(() => {
    if (recognition) {
      recognition.interimResults = true
      recognition.lang = 'ko-KR'
      recognition.maxAlternatives = 100000
      recognition.onresult = (event: any) => {
        let isFinal = false
        let transcript = ''

        for (let i = event.resultIndex; i < event.results.length; i++) {
          transcript += event.results[i][0].transcript
          if (event.results[i].isFinal) {
            isFinal = true
          }
        }

        if (isFinal) {
          setResult({transcript, isFinal})
        }
      }
      recognition.onend = () => {
        setIsListening(false)
        setResult({transcript: '', isFinal: false})
      }
    }

    return () => {
      if (recognition) {
        recognition.stop()
      }
    }
  }, [recognition])

  const startListening = () => {
    if (recognition) {
      recognition.start()
      setIsListening(true)
    }
  }

  const stopListening = () => {
    if (recognition) {
      recognition.stop()
    }
  }
  return {result, startListening, stopListening, isListening, setIsListening}
}
