import {Box, Button, Chip, FormControl, MenuItem, Select, TextField} from '@mui/material'
import {DataGridPro, GridColDef, GridColumnGroupingModel, GridSortModel} from '@mui/x-data-grid-pro'
import {chargeBackHost, envImagePath, serverLocalName} from 'GLOBAL/envVariables'
import {defaultImage, noImage} from 'assets/imageDeclear'
import dayjs from 'dayjs'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import _ from 'lodash'
import qs from 'qs'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import swal from 'sweetalert'
import {customConfirm} from 'utils/CustomAlert/Alert'
import ChargeSubOffcanvas from '../modules/chargeOffCanvas'

const ChargeInfo = (props: any) => {
  const {setSelectStep} = props
  const location = useLocation()
  const navigate = useNavigate()
  const data = location.state?.data
  const officeInfo = useSelector((state: any) => state?.officeInfo)
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState<any>('')
  const [searchStatus, setSearchStatus] = useState<boolean>(false)
  const [checkMeterData, setCheckMeterData] = useState<any>(data)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {field: 'customer_number', sort: 'asc'},
  ])
  const cul = window.location.href.split('?')[1]
  const url = qs.parse(cul)
  const selectedDate = url.date

  console.log(url)
  const keyDownHandler = (e: any) => {
    if (e.key === 'Enter') {
      setSearchStatus(true)
    } else {
      setSearchStatus(false)
    }
  }

  const getCheckMeterData = () => {
    setLoading(true)
    axiosWithAuth()
      .get(
        `${chargeBackHost}/api/reselect/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${userInfo?.userName}`
      )
      .then((res) => {
        setLoading(false)
        if (res.data !== false) {
          setCheckMeterData(res.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const postCheckMeterData = () => {
    // console.log(checkMeterData)
    setLoading(true)
    axiosWithAuth()
      .post(
        `${chargeBackHost}/api/update/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${userInfo?.userName}`,
        {
          charge_data: checkMeterData,
          account_name: userInfo.userName,
        }
      )
      .then(async (res) => {
        setLoading(false)
        if (res.data !== false) {
          await swal({
            title: '저장이 완료되었습니다.',
            text: ' ',
            buttons: [false],
            icon: 'success',
            timer: 1500,
          })
        } else {
          await swal({
            title: '저장에 실패하였습니다.',
            text: ' ',
            buttons: [false],
            icon: 'error',
            timer: 1500,
          })
        }
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }

  const resultFileDownload = () => {
    axiosWithAuth()
      .get(
        `${chargeBackHost}/down/result/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${userInfo?.userName}`,
        {responseType: 'blob'}
      )
      .then(async (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${userInfo.officeName}_${dayjs().format('YY-MM-DD')}.dat`)
        document.body.appendChild(link)
        link.click()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const fileDownload = async () => {
    setLoading(true)
    await axiosWithAuth()
      .get(
        `${chargeBackHost}/down/excel/?office_code=${
          officeInfo?.value === 'yit' ? 'all' : officeInfo?.value
        }&check_date=${url?.date}&company=${url?.program}&check_user=${userInfo?.userName}`,
        {responseType: 'arraybuffer'}
      )
      .then(async (res) => {
        if (res.status === 200) {
          console.log('res', res)
          const blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `${officeInfo?.label.replace(' ', '_')}_${selectedDate}.xlsx`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
          setLoading(false)
        } else {
          console.log('실패')
          setLoading(false)
          await swal({
            title: '저장에 실패하였습니다.',
            text: ' ',
            buttons: [false],
            icon: 'error',
            timer: 1500,
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const sendChargeData = () => {
    setLoading(true)
    axiosWithAuth()
      .post(`/`)
      .then((res) => {
        setLoading(false)
        if (res.data !== false) {
        }
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }

  const WIMS_columns: GridColDef[] = [
    {
      field: 'check_date',
      headerName: '납기마감일',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'customer_number',
      headerName: '수용가 번호',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row'}>
            <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
              {params.row.customer_number}
            </span>
            <span className={'text-danger text-center'}>
              {params.row.customer_status === null || params.row.customer_status === 'null'
                ? null
                : params.row.customer_status === 'as'
                ? 'A/S'
                : params.row.customer_status === 'move'
                ? '이전 철거'
                : '철거'}
            </span>
          </div>
        )
      },
    },
    {
      field: 'customerInfo',
      headerName: '주소',
      width: 350,
      align: 'left',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className={'row'}>
            <span className={''}>{params.row.customer_name}</span>
            {params.row.address_road && (
              <span>
                <strong style={{color: '#104f98'}}>도로명 </strong> : {params.row.address_road}{' '}
              </span>
            )}
            {params.row.address_number && (
              <span>
                <strong style={{color: '#f37005'}}>지번 </strong> : {params.row.address_number}{' '}
              </span>
            )}
            {/* {params.row.addressDetail && (
              <span>
                <strong style={{color: 'rgba(86,31,13,0.7)'}}>상세주소 </strong> :{' '}
                {params.row.addressDetail}{' '}
              </span>
            )} */}
          </div>
        )
      },
    },
    {
      field: 'city_code',
      headerName: '구코드',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'resource_code',
      headerName: '자원구분코드',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'sub_number',
      headerName: '부번호',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'now_check_code',
      headerName: '당월검침코드',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'checkInfo',
      headerName: '검침일',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.check_value_default === '' ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.check_value_default
                )}
              </span>
              <span>
                {params.row.check_decimal_value === '' ||
                params.row.check_decimal_value === null ||
                params.row.check_decimal_value === 'null'
                  ? ''
                  : '.' + params.row.check_decimal_value?.split('.')[1]}
              </span>
            </div>
            <span>{dayjs(params.row.idx_date).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.device_type !== 'analog' ? (
              <img className={'cursor-pointer'} src={defaultImage} />
            ) : (
              <div className={'row'}>
                <div
                  className={'d-flex gap-1 justify-content-end'}
                  style={{position: 'relative', top: '15px'}}
                >
                  {params.row.rotate > 0 && (
                    <Chip
                      label={`${params.row.rotate}° `}
                      color='primary'
                      sx={{fontSize: '17px'}}
                    />
                  )}
                  {params.row.recognized_type === null || params.row.recognized_type === 'null' ? (
                    <Chip label='기계식' color='error' sx={{fontSize: '17px'}} />
                  ) : (
                    <Chip label='디지털' color='warning' sx={{fontSize: '17px'}} />
                  )}
                </div>
                <img
                  alt={'device-image'}
                  className={'cursor-pointer'}
                  src={
                    params.row.file_path === null
                      ? noImage
                      : `${envImagePath}${params.row.file_path}`
                  }
                  width={320}
                  height={144}
                />
              </div>
            )}
          </>
        )
      },
    },
    {
      field: 'updateCheckValue',
      headerName: '당월지침',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.check_value === null || params.row.check_value === 'null') {
          params.row.check_value = params.row.check_value_default
        }
        return (
          <TextField
            variant='outlined'
            defaultValue={Number(params.row.check_value)}
            onChange={(e) => {
              const updateValue = [...checkMeterData]
              updateValue[params.row.id - 1].check_value = e.target.value
              setCheckMeterData(updateValue)
            }}
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
          />
        )
      },
    },
    {
      field: 'now_check_type',
      headerName: '검침방법구분코드',
      width: 170,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'trsDateInfo',
      headerName: '변경시간',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            <span>{params.row.trsdat}</span>
            <span>{params.row.trstim}</span>
          </div>
        )
      },
    },
    {
      field: 'trsInfo',
      headerName: 'IP',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            <span>{params.row.trsusr}</span>
            <span>{params.row.ip}</span>
          </div>
        )
      },
    },
    {
      field: 'check_user',
      headerName: '정보수정자',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
  ]

  const blueWater_columns: GridColDef[] = [
    {
      field: 'check_date',
      headerName: '납기마감일',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'customer_number',
      headerName: '고객 번호',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row'}>
            <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
              {params.row.customer_number}
            </span>
            <span className={'text-danger text-center'}>
              {params.row.customer_status === null || params.row.customer_status === 'null'
                ? null
                : params.row.customer_status === 'as'
                ? 'A/S'
                : params.row.customer_status === 'move'
                ? '이전 철거'
                : '철거'}
            </span>
          </div>
        )
      },
    },
    {
      field: 'customer_name',
      headerName: '이름',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'address_Info',
      headerName: '주소',
      width: 400,
      align: 'left',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className={'row'}>
            {params.row.address_road && (
              <span>
                <strong style={{color: '#104f98'}}>도로명 </strong> : {params.row.address_road}{' '}
              </span>
            )}
            {params.row.address_number && (
              <span>
                <strong style={{color: '#f37005'}}>지번 </strong> : {params.row.address_number}{' '}
              </span>
            )}
            {/* {params.row.addressDetail && (
              <span>
                <strong style={{color: 'rgba(86,31,13,0.7)'}}>상세주소 </strong> :{' '}
                {params.row.addressDetail}{' '}
              </span>
            )} */}
          </div>
        )
      },
    },
    {
      field: 'city_code',
      headerName: '시군코드',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'now_check_date',
      headerName: '검침일',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div>
            <span>{dayjs(params.row.idx_date).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
    },
    {
      field: 'check_value',
      headerName: '검침정보',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.check_value_default === '' ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.check_value_default
                )}
              </span>
              <span>
                {params.row.check_decimal_value === '' ||
                params.row.check_decimal_value === null ||
                params.row.check_decimal_value === 'null'
                  ? ''
                  : '.' + params.row.check_decimal_value?.split('.')[1]}
              </span>
            </div>
          </div>
        )
      },
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.device_type !== 'analog' ? (
              <img className={'cursor-pointer'} src={defaultImage} />
            ) : (
              <div className={'row'}>
                <div
                  className={'d-flex gap-1 justify-content-end'}
                  style={{position: 'relative', top: '15px'}}
                >
                  {params.row.rotate > 0 && (
                    <Chip
                      label={`${params.row.rotate}° `}
                      color='primary'
                      sx={{fontSize: '17px'}}
                    />
                  )}
                  {params.row.recognized_type === null || params.row.recognized_type === 'null' ? (
                    <Chip label='기계식' color='error' sx={{fontSize: '17px'}} />
                  ) : (
                    <Chip label='디지털' color='warning' sx={{fontSize: '17px'}} />
                  )}
                </div>
                <img
                  alt={'device-image'}
                  className={'cursor-pointer'}
                  src={
                    params.row.file_path === null
                      ? noImage
                      : `${envImagePath}${params.row.file_path}`
                  }
                  width={320}
                  height={144}
                />
              </div>
            )}
          </>
        )
      },
    },
    {
      field: 'updateCheckValue',
      headerName: '당월지침',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            defaultValue={Number(params.row.check_value)}
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
            onChange={(e) => {
              const updateValue = [...checkMeterData]
              updateValue[params.row.id - 1].check_value = e.target.value
              setCheckMeterData(updateValue)
            }}
          />
        )
      },
    },
    {
      field: 'now_check_code',
      headerName: '검침코드',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className='w-75 text-center'>
            <FormControl fullWidth>
              <Select
                label=''
                id='check-code-select'
                value={params.row.now_check_code === null ? '1' : params.row.now_check_code}
                onChange={(e) => {
                  const updateValue = [...checkMeterData]
                  updateValue[params.row.id - 1].now_check_code = e.target.value
                  setCheckMeterData(updateValue)
                }}
              >
                <MenuItem value='1'>정상</MenuItem>
                <MenuItem value='2'>점검필요</MenuItem>
              </Select>
            </FormControl>
          </div>
        )
      },
    },
    {
      field: 'check_user',
      headerName: '정보수정자',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
  ]

  const saeol_columns: GridColDef[] = [
    {
      field: 'check_date',
      headerName: '검침년월',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'customer_number',
      headerName: '수용가번호',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row'}>
            <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
              {params.row.customer_number}
            </span>
            <span className={'text-danger text-center'}>
              {params.row.customer_status === null || params.row.customer_status === 'null'
                ? null
                : params.row.customer_status === 'as'
                ? 'A/S'
                : params.row.customer_status === 'move'
                ? '이전 철거'
                : '철거'}
            </span>
          </div>
        )
      },
    },
    {
      field: 'customerInfo',
      headerName: '주소',
      width: 400,
      align: 'left',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className={'row text-start'}>
            <span className={''}>{params.row.customer_name}</span>
            {params.row.address_road && (
              <span>
                <strong style={{color: '#104f98'}}>도로명 </strong> : {params.row.address_road}{' '}
              </span>
            )}
            {params.row.address_number && (
              <span>
                <strong style={{color: '#f37005'}}>지번 </strong> : {params.row.address_number}{' '}
              </span>
            )}
            {/* {params.row.addressDetail && (
              <span>
                <strong style={{color: 'rgba(86,31,13,0.7)'}}>상세주소 </strong> :{' '}
                {params.row.addressDetail}{' '}
              </span>
            )} */}
          </div>
        )
      },
    },
    {
      field: 'bef_check_code',
      headerName: '전월 검침코드',
      width: 140,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'bef_check_value',
      headerName: '전월 지침',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'bef_used',
      headerName: '전월 사용량',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'checkInfo',
      headerName: '검침일',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.check_value_default === '' ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.check_value_default
                )}
              </span>
              <span>
                {params.row.check_decimal_value === '' ||
                params.row.check_decimal_value === null ||
                params.row.check_decimal_value === 'null'
                  ? ''
                  : '.' + params.row.check_decimal_value?.split('.')[1]}
              </span>
            </div>
            <span>{dayjs(params.row.idx_date).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.device_type !== 'analog' ? (
              <img className={'cursor-pointer'} src={defaultImage} />
            ) : (
              <div className={'row'}>
                <div
                  className={'d-flex gap-1 justify-content-end'}
                  style={{position: 'relative', top: '15px'}}
                >
                  {params.row.rotate > 0 && (
                    <Chip
                      label={`${params.row.rotate}° `}
                      color='primary'
                      sx={{fontSize: '17px'}}
                    />
                  )}
                  {params.row.recognized_type === null || params.row.recognized_type === 'null' ? (
                    <Chip label='기계식' color='error' sx={{fontSize: '17px'}} />
                  ) : (
                    <Chip label='디지털' color='warning' sx={{fontSize: '17px'}} />
                  )}
                </div>
                <img
                  alt={'device-image'}
                  className={'cursor-pointer'}
                  src={
                    params.row.file_path === null
                      ? noImage
                      : `${envImagePath}${params.row.file_path}`
                  }
                  width={320}
                  height={144}
                />
              </div>
            )}
          </>
        )
      },
    },
    {
      field: 'updateCheckValue',
      headerName: '당월지침',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            defaultValue={Number(params.row.check_value)}
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
            onChange={(e) => {
              const updateValue = [...checkMeterData]
              updateValue[params.row.id - 1].check_value = e.target.value
              setCheckMeterData(updateValue)
            }}
          />
        )
      },
    },
    {
      field: 'now_used',
      headerName: '당월사용량',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      // renderCell: (params) => {
      //   return (
      //     <TextField
      //       variant='outlined'
      //       defaultValue={Number(params.row.check_value)}
      //       onChange={(e) => {
      //         const updateValue = [...checkMeterData]
      //         updateValue[params.row.id - 1].check_value = e.target.value
      //         setCheckMeterData(updateValue)
      //       }}
      //     />
      //   )
      // },
    },
    {
      field: 'check_code',
      headerName: '당월검침코드',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className='w-100 text-center'>
            <FormControl fullWidth>
              <Select
                label=''
                id='check-code-select'
                value={params.row.now_check_code === null ? '00' : params.row.now_check_code}
                onChange={(e) => {
                  const updateValue = [...checkMeterData]
                  updateValue[params.row.id - 1].now_check_code = e.target.value
                  setCheckMeterData(updateValue)
                }}
              >
                <MenuItem value='00'>00 (정상)</MenuItem>
                <MenuItem value='01'>01 (회전)</MenuItem>
                <MenuItem value='02'>02 (교체)</MenuItem>
                <MenuItem value='03'>03 (인정)</MenuItem>
                <MenuItem value='04'>04 (부동)</MenuItem>
                <MenuItem value='05'>05 (누수)</MenuItem>
                <MenuItem value='06'>06 (중지)</MenuItem>
                <MenuItem value='07'>07 (미검침)</MenuItem>
                <MenuItem value='08'>08 (자가검침)</MenuItem>
                <MenuItem value='09'>09 (공업용수)</MenuItem>
              </Select>
            </FormControl>
          </div>
        )
      },
    },
    {
      field: 'check_user',
      headerName: '정보수정자',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'phoneNumber',
      headerName: '연락처',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
  ]

  //SAEOL1 칼럼 목록 수정 - 새올에서 받아온 값들중에서 특정 칼럼만 VISIBLE FALSE로
  const saeol1_columns: GridColDef[] = [
    {
      field: 'check_date',
      headerName: '검침년월',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'customer_number',
      headerName: '수용가번호',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row'}>
            <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
              {params.row.customer_number}
            </span>
            <span className={'text-danger text-center'}>
              {params.row.customer_status === null || params.row.customer_status === 'null'
                ? null
                : params.row.customer_status === 'as'
                ? 'A/S'
                : params.row.customer_status === 'move'
                ? '이전 철거'
                : '철거'}
            </span>
          </div>
        )
      },
    },
    {
      field: 'customerInfo',
      headerName: '주소',
      width: 500,
      align: 'left',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className={'row text-start'}>
            <span className={''}>{params.row.customer_name}</span>
            {params.row.address_road && (
              <span>
                <strong style={{color: '#104f98'}}>도로명 </strong> : {params.row.address_road}{' '}
              </span>
            )}
            {params.row.address_number && (
              <span>
                <strong style={{color: '#f37005'}}>지번 </strong> : {params.row.address_number}{' '}
              </span>
            )}
            {/* {params.row.addressDetail && (
              <span>
                <strong style={{color: 'rgba(86,31,13,0.7)'}}>상세주소 </strong> :{' '}
                {params.row.addressDetail}{' '}
              </span>
            )} */}
          </div>
        )
      },
    },
    {
      field: 'checkInfo',
      headerName: '검침일',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.check_value_default === '' ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.check_value_default
                )}
              </span>
              <span>
                {params.row.check_decimal_value === '' ||
                params.row.check_decimal_value === null ||
                params.row.check_decimal_value === 'null'
                  ? ''
                  : '.' + params.row.check_decimal_value?.split('.')[1]}
              </span>
            </div>
            <span>{dayjs(params.row.idx_date).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.device_type !== 'analog' ? (
              <img className={'cursor-pointer'} src={defaultImage} />
            ) : (
              <div className={'row'}>
                <div
                  className={'d-flex gap-1 justify-content-end'}
                  style={{position: 'relative', top: '15px'}}
                >
                  {params.row.rotate > 0 && (
                    <Chip
                      label={`${params.row.rotate}° `}
                      color='primary'
                      sx={{fontSize: '17px'}}
                    />
                  )}
                  {params.row.recognized_type === null || params.row.recognized_type === 'null' ? (
                    <Chip label='기계식' color='error' sx={{fontSize: '17px'}} />
                  ) : (
                    <Chip label='디지털' color='warning' sx={{fontSize: '17px'}} />
                  )}
                </div>
                <img
                  alt={'device-image'}
                  className={'cursor-pointer'}
                  src={
                    params.row.file_path === null
                      ? noImage
                      : `${envImagePath}${params.row.file_path}`
                  }
                  width={320}
                  height={144}
                />
              </div>
            )}
          </>
        )
      },
    },
    {
      field: 'updateCheckValue',
      headerName: '당월지침',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            defaultValue={Number(params.row.check_value)}
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
            onChange={(e) => {
              const updateValue = [...checkMeterData]
              updateValue[params.row.id - 1].check_value = e.target.value
              setCheckMeterData(updateValue)
            }}
          />
        )
      },
    },
    {
      field: 'check_code',
      headerName: '당월검침코드',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className='w-100 text-center'>
            <FormControl fullWidth>
              <Select
                label=''
                id='check-code-select'
                value={params.row.now_check_code === null ? '00' : params.row.now_check_code}
                onChange={(e) => {
                  const updateValue = [...checkMeterData]
                  updateValue[params.row.id - 1].now_check_code = e.target.value
                  setCheckMeterData(updateValue)
                }}
              >
                <MenuItem value='00'>00 (정상)</MenuItem>
                <MenuItem value='01'>01 (회전)</MenuItem>
                <MenuItem value='02'>02 (교체)</MenuItem>
                <MenuItem value='03'>03 (인정)</MenuItem>
                <MenuItem value='04'>04 (부동)</MenuItem>
                <MenuItem value='05'>05 (누수)</MenuItem>
                <MenuItem value='06'>06 (중지)</MenuItem>
                <MenuItem value='07'>07 (미검침)</MenuItem>
                <MenuItem value='08'>08 (자가검침)</MenuItem>
                <MenuItem value='09'>09 (공업용수)</MenuItem>
              </Select>
            </FormControl>
          </div>
        )
      },
    },
    {
      field: 'check_user',
      headerName: '정보수정자',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'phoneNumber',
      headerName: '연락처',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
  ]

  const crebiz_columns: GridColDef[] = [
    {
      field: 'check_date',
      headerName: '납기마감일',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'customer_number',
      headerName: '고객 번호',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row'}>
            <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
              {params.row.customer_number}
            </span>
            <span className={'text-danger text-center'}>
              {params.row.customer_status === null || params.row.customer_status === 'null'
                ? null
                : params.row.customer_status === 'as'
                ? 'A/S'
                : params.row.customer_status === 'move'
                ? '이전 철거'
                : '철거'}
            </span>
          </div>
        )
      },
    },
    {
      field: 'customer_name',
      headerName: '이름',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'address_Info',
      headerName: '주소',
      width: 400,
      align: 'left',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className={'row'}>
            {params.row.address_road && (
              <span>
                <strong style={{color: '#104f98'}}>도로명 </strong> : {params.row.address_road}{' '}
              </span>
            )}
            {params.row.address_number && (
              <span>
                <strong style={{color: '#f37005'}}>지번 </strong> : {params.row.address_number}{' '}
              </span>
            )}
          </div>
        )
      },
    },
    {
      field: 'bef_check_value',
      headerName: '전월 지침값',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            defaultValue={
              params?.row.bef_check_value === null || params?.row.bef_check_value === 'null'
                ? ''
                : Number(params?.row.bef_check_value)
            }
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
            onChange={(e) => {
              const updateValue = [...checkMeterData]
              updateValue[params.row.id - 1].bef_check_value = e.target.value
              updateValue[params.row.id - 1].filter3 = 'true'
              setCheckMeterData(updateValue)
            }}
          />
        )
      },
    },
    {
      field: 'bef_used',
      headerName: '전월 사용량',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            defaultValue={
              params?.row.bef_used === null || params?.row.bef_used === 'null'
                ? ''
                : Number(params?.row.bef_used)
            }
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
            onChange={(e) => {
              const updateValue = [...checkMeterData]
              updateValue[params.row.id - 1].bef_used = e.target.value
              updateValue[params.row.id - 1].filter3 = 'true'
              setCheckMeterData(updateValue)
            }}
          />
        )
      },
    },
    {
      field: 'now_check_date',
      headerName: '검침일',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div>
            <span>{dayjs(params.row.idx_date).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
    },

    {
      field: 'check_value',
      headerName: '검침정보',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.check_value_default === '' ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.check_value_default
                )}
              </span>
              <span>
                {params.row.check_decimal_value === '' ||
                params.row.check_decimal_value === null ||
                params.row.check_decimal_value === 'null'
                  ? ''
                  : '.' + params.row.check_decimal_value?.split('.')[1]}
              </span>
            </div>
          </div>
        )
      },
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.device_type !== 'analog' ? (
              <img className={'cursor-pointer'} src={defaultImage} />
            ) : (
              <div className={'row'}>
                <div
                  className={'d-flex gap-1 justify-content-end'}
                  style={{position: 'relative', top: '15px'}}
                >
                  {params.row.rotate > 0 && (
                    <Chip
                      label={`${params.row.rotate}° `}
                      color='primary'
                      sx={{fontSize: '17px'}}
                    />
                  )}
                  {params.row.recognized_type === null || params.row.recognized_type === 'null' ? (
                    <Chip label='기계식' color='error' sx={{fontSize: '17px'}} />
                  ) : (
                    <Chip label='디지털' color='warning' sx={{fontSize: '17px'}} />
                  )}
                </div>
                <img
                  alt={'device-image'}
                  className={'cursor-pointer'}
                  src={
                    params.row.file_path === null
                      ? noImage
                      : `${envImagePath}${params.row.file_path}`
                  }
                  width={320}
                  height={144}
                />
              </div>
            )}
          </>
        )
      },
    },

    {
      field: 'updateCheckValue',
      headerName: '당월 지침값',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            defaultValue={
              params?.row.check_value === null || params?.row.check_value === 'null'
                ? ''
                : Number(params?.row.check_value)
            }
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
            onChange={(e) => {
              const updateValue = [...checkMeterData]
              updateValue[params.row.id - 1].check_value = e.target.value
              updateValue[params.row.id - 1].filter3 = 'true'
              setCheckMeterData(updateValue)
            }}
          />
        )
      },
    },
    {
      field: 'now_used',
      headerName: '당월 사용량',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            defaultValue={
              params?.row.now_used === null || params?.row.now_used === 'null'
                ? ''
                : Number(params?.row.now_used)
            }
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
            onChange={(e) => {
              const updateValue = [...checkMeterData]
              updateValue[params.row.id - 1].now_used = e.target.value
              updateValue[params.row.id - 1].filter3 = 'true'
              setCheckMeterData(updateValue)
            }}
          />
        )
      },
    },
    {
      field: 'now_check_code',
      headerName: '검침코드',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className='w-75 text-center'>
            <FormControl fullWidth>
              <Select
                label=''
                id='check-code-select'
                value={params.row.now_check_code === null ? '001' : params.row.now_check_code}
                onChange={(e) => {
                  const updateValue = [...checkMeterData]
                  updateValue[params.row.id - 1].now_check_code = e.target.value
                  updateValue[params.row.id - 1].filter3 = 'true'
                  setCheckMeterData(updateValue)
                }}
              >
                <MenuItem value='001'>001 : 정상</MenuItem>
                <MenuItem value='002'>002 : 개조</MenuItem>
                <MenuItem value='003'>003 : 고장</MenuItem>
                <MenuItem value='004'>004 : 동파</MenuItem>
                <MenuItem value='005'>005 : 노후</MenuItem>
                <MenuItem value='006'>006 : 파손</MenuItem>
                <MenuItem value='007'>007 : 망실</MenuItem>
                <MenuItem value='008'>008 : 검증</MenuItem>
              </Select>
            </FormControl>
          </div>
        )
      },
    },
  ]

  const rows = _.map(checkMeterData, (el, i) => {
    return {
      id: i + 1,
      address_road: el.address_road,
      address_number: el.address_number,
      bef_check_value: el.bef_check_value,
      bef_used: el.bef_used,
      check_cycle: el.check_cycle,
      check_day: el.check_day,
      check_code: el.check_code,
      check_month: el.check_month,
      check_value: el.check_value,
      check_value_default: el.check_value_default,
      check_user: el.check_user,
      city_code: el.city_code,
      customer_name: el.customer_name,
      customer_number: el.customer_number,
      customer_status: el.customer_status,
      device_type: el.device_type,
      exceptstatus: el.exceptstatus,
      file_path: el.file_path,
      idx_date: el.idx_date,
      ip: el.ip,
      now_check_code: el.now_check_code,
      now_check_type: el.now_check_type,
      now_used: el.now_used,
      phone_number: el.phone_number,
      process_status: el.process_status,
      recognized_type: el.recognized_type,
      resource_code: el.resource_code,
      rotate: el.rotate,
      sub_number: el.sub_number,
      trsdat: el.trsdat,
      trstim: el.trstim,
      trsusr: el.trsusr,
      efos_connect_at: el.efos_connect_at,
      check_decimal_value: el.check_decimal_value,
      check_date: el?.check_date,
      filter3: el?.filter3,
    }
  })
  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: '이름',
      headerAlign: 'center',
      description: '수용가에 관한 정보입니다.',
      children: [{field: 'customerInfo'}],
    },
    {
      groupId: '당월검침정보',
      headerAlign: 'center',
      description: '당월 검침내역에 관한 정보입니다.',
      children: [{field: 'checkInfo'}],
    },
    {
      groupId: '전월검침정보',
      headerAlign: 'center',
      description: '전월 검침내역에 관한 정보입니다.',
      children: [
        {field: 'befCheckInfo'},
        {field: 'bef_check_code'},
        {field: 'bef_check_value'},
        {field: 'bef_used'},
      ],
    },
    {
      groupId: '변경일',
      headerAlign: 'center',
      description: '변경내역에 관한 정보입니다.',
      children: [{field: 'trsDateInfo'}],
    },
    {
      groupId: '변경자',
      headerAlign: 'center',
      description: '변경내역에 관한 정보입니다.',
      children: [{field: 'trsInfo'}],
    },
  ]

  const filterRows = () => {
    if (searchStatus === true) {
      return _.filter(
        rows,
        (row: any) =>
          row.customer_number?.includes(searchValue) ||
          row.customer_name?.includes(searchValue) ||
          row.address_road?.includes(searchValue) ||
          row.address_number?.includes(searchValue)
        // row.addressDetail?.includes(searchValue)
      )
    }
    return rows
  }

  useEffect(() => {
    setSelectStep({step1: true, step2: true})
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
      localStorage.setItem('reload', 'true')
    }

    const enableBeforeUnload = () => {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }

    const disableBeforeUnload = () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    enableBeforeUnload()

    if (localStorage.getItem('reload')) {
      getCheckMeterData()
      localStorage.removeItem('reload')
    }

    return () => {
      disableBeforeUnload()
    }
  }, [])

  return (
    <>
      <div className='card flex-grow-1' id='customer-register'>
        <div className='card-header '>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              <strong className={'text-primary'}>{officeInfo?.label}</strong>의 요금연계를
              진행합니다.
            </h3>
          </div>
          <div className='card-title m-0 me-4 mt-3'>
            {url?.program === 'SAEOL' || url?.program === 'SAEOL1' ? (
              <Button
                className='me-9'
                variant={'contained'}
                color={'error'}
                data-bs-toggle={'offcanvas'}
                data-bs-target='#ChargeSub'
                aria-controls='ChargeSub'
              >
                부관 조회
              </Button>
            ) : null}
            <Button
              variant={'contained'}
              onClick={async () => {
                if (
                  await customConfirm('처음으로 돌아시겠습니까?', 'warning', '처음으로 돌아가기')
                ) {
                  setSelectStep({step1: false, step2: false})
                  navigate('/charge/select')
                }
              }}
            >
              처음으로
            </Button>
          </div>
        </div>
        <div className='card-body p-3'>
          <div className={'container-fluid'}>
            <div className={'row row-cols mt-3'} id={'content'}>
              <div id={'table-section'} className={'col-lg '}>
                <div id={'table-header'} className={'d-flex justify-content-between'}>
                  <div id={'table-header-start'} className={'align-self-center'}>
                    <span className={'fs-3'}>
                      전체 : <strong>{rows?.length}</strong> 개
                    </span>
                  </div>
                  <div
                    className={'d-flex justify-content-between gap-3 pb-3'}
                    id={'table-header-end'}
                  >
                    <TextField
                      id='search-input'
                      placeholder='검색어를 입력하세요'
                      size='small'
                      className='me-5'
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      onKeyDown={(e) => keyDownHandler(e)}
                    />
                    <Button
                      variant={'contained'}
                      color={'warning'}
                      onClick={() => postCheckMeterData()}
                    >
                      저장
                    </Button>
                    <Button variant={'contained'} color={'success'} onClick={() => fileDownload()}>
                      {!loading ? (
                        '다운로드'
                      ) : (
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      )}
                    </Button>
                    {url?.program === 'WIMS' ? (
                      serverLocalName !== 'gyeryong' ? (
                        <Button variant={'contained'} color={'secondary'}>
                          전송
                        </Button>
                      ) : (
                        ''
                      )
                    ) : url?.program === 'SAEOL' || url?.program === 'SAEOL1' ? (
                      <Button
                        variant={'contained'}
                        color={'secondary'}
                        onClick={() => resultFileDownload()}
                      >
                        결과파일 다운로드
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <Box id={'table-content'} sx={{height: 850, width: '100%'}}>
                  <DataGridPro
                    rows={filterRows()}
                    columns={
                      url?.program === 'WIMS'
                        ? WIMS_columns
                        : url?.program === 'BLUEWATER'
                        ? blueWater_columns
                        : url?.program === 'SAEOL1'
                        ? saeol1_columns
                        : url?.program === 'CREBIZ'
                        ? crebiz_columns
                        : saeol_columns
                    }
                    rowHeight={160}
                    headerHeight={url?.program === 'BLUEWATER' ? 50 : 30}
                    loading={loading}
                    columnGroupingModel={columnGroupingModel}
                    experimentalFeatures={{columnGrouping: true}}
                    disableColumnMenu
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel: any) => setSortModel(newSortModel)}
                    getRowClassName={(params) =>
                      params.row.filter3 === null || params.row.filter3 === 'null'
                        ? ''
                        : 'highlight-row'
                    }
                    sx={{
                      '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                      },
                      '& .MuiDataGrid-columnHeader:focus': {
                        outline: 'none',
                      },
                      '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                      },
                      '& .highlight-row': {
                        backgroundColor: 'rgba(76, 175, 80, 0.2)',
                      },
                    }}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChargeSubOffcanvas />
    </>
  )
}

export default ChargeInfo
