import React, {useEffect} from 'react'
import {PageLink} from '../../_metronic/layout/core'
import {useAuth} from '../../_component/modules/auth'
import {Route, Routes, useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import toast, {ToastContainer} from 'react-toastify'
import MatchingSystem from './component/matchingSystem'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: '스마트 미터링 시스템',
    path: '/pages/match',
    isSeparator: false,
    isActive: false,
  },
]

const MatchingSystemPage = () => {
  const {logout} = useAuth()
  const navigate = useNavigate()
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  useEffect(() => {
    if (
      userInfo === null ||
      (userInfo.accountType !== 'sys_admin' &&
        userInfo.accountType !== 'sys_user' &&
        userInfo.accountType !== 'gov_admin' &&
        userInfo.accountType !== 'customer_client')
    ) {
      navigate('/auth')
      logout()
    }
  }, [])
  document.body.setAttribute('data-kt-aside-minimize', 'on')
  return (
    <Routes>
      <Route
        path={'/fota'}
        element={
          <>
            <MatchingSystem />
          </>
        }
      ></Route>
    </Routes>
  )
}

export default MatchingSystemPage
