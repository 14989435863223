import {AuthPage} from '_component/modules/auth'
import {ErrorsPage} from '_component/modules/errors/ErrorsPage'
import {MasterLayout} from '_metronic/layout/MasterLayout'
import CertificationPage from 'pages/certification'
import {MetervisionHome} from 'pages/dashboard'
import {FC} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {App} from '../App'
import AccountPage from '../pages/account'
import ChargePage from '../pages/charge'
import CustomerPage from '../pages/customer/index'
import DevicePage from '../pages/device'
import InstallPage from '../pages/install'
import MeterariumJoinPage from '../pages/join'
import MatchingSystemPage from '../pages/MatchingSystem'
import OfficeConfigPage from '../pages/officeConfig'
import SystemPage from '../pages/systemManagement'
import TaskPage from '../pages/task'

const AppRoutes: FC = () => {
  return (
    <BrowserRouter basename={'/'}>
      <Routes>
        <Route element={<App />}>
          <Route path='/join/*' element={<MeterariumJoinPage />} />
          <Route path='/password/*' element={<CertificationPage />} />
          <Route path='/' element={<AuthPage />} />
          <Route path='/auth' element={<AuthPage />} />
          <Route element={<MasterLayout />}>
            <Route path='/home' element={<MetervisionHome />} />
            <Route path='/customer/*' element={<CustomerPage />} />
            <Route path='/device/*' element={<DevicePage />} />
            <Route path='/task/*' element={<TaskPage />} />
            <Route path='/account/*' element={<AccountPage />} />
            <Route path='/system/*' element={<SystemPage />} />
            <Route path='/settings/*' element={<OfficeConfigPage />} />
            <Route path='/install/*' element={<InstallPage />} />
            <Route path='/charge/*' element={<ChargePage />} />
            <Route path='/match/*' element={<MatchingSystemPage />} />
          </Route>
          <Route path='*' element={<ErrorsPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
