import React, {useState} from 'react'
import {Box, Button, createTheme, TextField, ThemeProvider} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import {DataGridPro, GridColDef, koKR} from '@mui/x-data-grid-pro'
import _ from 'lodash'
import dayjs, {Dayjs} from 'dayjs'
import {DateRangePicker, LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import {DateRange} from '@mui/x-date-pickers-pro/DateRangePicker'
import DownloadOffcanvas from '../modules/downloadOffcanvas'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
const LogManagement = (props: any) => {
  const {toast} = props
  const [pageSize, setPageSize] = useState<number>(10)
  const [date, setDate] = React.useState<DateRange<Dayjs>>([null, null])

  const columns: GridColDef[] = [
    {field: 'id', headerName: 'No.', width: 60, align: 'center', headerAlign: 'center'},
    {
      field: 'date',
      headerName: '시간',
      width: 300,
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'userId',
      headerName: '사용자 ID',
      width: 300,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'content',
      headerName: '작업내용',
      width: 600,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'ip',
      headerName: 'IP',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
  ]

  const rows = _.map(_.range(0, 887), (el, i) => {
    return {
      id: i + 1,
      date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      userId: `young-it-${i + 1}`,
      content: `이것저것 하다감`,
      ip: `211.230.13.2${i}`,
    }
  })
  return (
    <>
      {!isMobileDevice() && (
        <div className='card mb-5 mb-xl-10' id='customer-allocation'>
          <div className='card-header '>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>미터리움 접속 로그를 확인할 수 있습니다.</h3>
            </div>
          </div>

          <div className='card-body p-9'>
            <div className={'container-fluid'}>
              <div className={'d-flex gap-3'} id={'control-section'}>
                <div className={'align-self-center'}>
                  <span className={'text-muted fs-4 '}>로그 기간</span>
                </div>
                <div id={'date-range'} className={'col-lg-3'}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{start: '시작날짜', end: '마지막 날짜'}}
                  >
                    <DateRangePicker
                      className={'justify-content-center '}
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue)
                      }}
                      renderInput={(startProps, endProps) => (
                        <React.Fragment>
                          <TextField {...startProps} />
                          <Box sx={{mx: 1}}> ~ </Box>
                          <TextField {...endProps} />
                        </React.Fragment>
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className={'row row-cols-2 mt-5'} id={'content'}>
                <div id={'table-section'} className={'col-lg mt-3'}>
                  <div id={'table-header'} className={'d-flex my-2 justify-content-between'}>
                    <div id={'table-header-start'}>
                      <span className={'fs-4 '}>
                        전체 : <strong>{rows.length}</strong> 개
                      </span>
                    </div>
                    <div id={'table-header-end'}>
                      <Button
                        variant={'contained'}
                        className={'mx-1'}
                        color={'success'}
                        startIcon={<DownloadIcon />}
                        data-bs-toggle={'offcanvas'}
                        data-bs-target='#downloadOffcanvas'
                        aria-controls='downloadOffcanvas'
                      >
                        다운로드
                      </Button>
                    </div>
                  </div>
                  <Box id={'table-content'} sx={{height: 630, width: '100%'}}>
                    <DataGridPro
                      rows={rows}
                      columns={columns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[10, 30, 50, 70, 100]}
                      disableColumnMenu
                      pagination
                      checkboxSelection
                      sx={{
                        fontSize: '16px',
                        '& .MuiDataGrid-cell:hover': {
                          color: 'primary.main',
                        },
                        cursor: 'pointer',
                      }}
                    />
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobileDevice() && (
        <div className='card mb-5 mb-xl-10' id='customer-allocation'>
          <div className='card-header '>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>미터리움 접속 로그를 확인할 수 있습니다.</h3>
            </div>
          </div>

          <div className='card-body p-9'>
            <div className={'container-fluid'}>
              <div className={'row gap-3'} id={'control-section'}>
                <span className={'fw-bolder fs-4 '}>
                  로그 기간 설정 (Today : {dayjs().format('YYYY-MM-DD')})
                </span>
                <div id={'date-range'}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{start: '시작날짜', end: '마지막 날짜'}}
                  >
                    <DateRangePicker
                      className={'justify-content-center '}
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue)
                      }}
                      renderInput={(startProps, endProps) => (
                        <React.Fragment>
                          <TextField {...startProps} fullWidth />
                          <Box sx={{mx: 1}}> ~ </Box>
                          <TextField {...endProps} fullWidth />
                        </React.Fragment>
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className={'row mt-5'} id={'content'}>
                <div id={'table-section'} className={'col-lg mt-3'}>
                  <div id={'table-header'} className={'d-flex my-2 justify-content-between'}>
                    <div id={'table-header-start'} className={'align-self-center'}>
                      <span className={'fs-4 '}>
                        전체 : <strong>{rows.length}</strong> 개
                      </span>
                    </div>
                    <div id={'table-header-end'}>
                      <Button
                        variant={'contained'}
                        className={'mx-1'}
                        color={'success'}
                        startIcon={<DownloadIcon />}
                        data-bs-toggle={'offcanvas'}
                        data-bs-target='#downloadOffcanvas'
                        aria-controls='downloadOffcanvas'
                      >
                        다운로드
                      </Button>
                    </div>
                  </div>
                  <Box id={'table-content'} sx={{height: 630, width: '100%'}}>
                    <DataGridPro
                      rows={rows}
                      columns={columns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[10, 30, 50, 70, 100]}
                      disableColumnMenu
                      pagination
                      checkboxSelection
                      sx={{
                        fontSize: '16px',
                        '& .MuiDataGrid-cell:hover': {
                          color: 'primary.main',
                        },
                        cursor: 'pointer',
                      }}
                    />
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <DownloadOffcanvas />
    </>
  )
}

export default LogManagement
