import {useEffect, useState} from 'react'
import {
  Backdrop,
  Card,
  CardContent,
  CardMedia,
  Chip,
  LinearProgress,
  Modal,
  Skeleton,
} from '@mui/material'
import Box from '@mui/material/Box'
import {getAsDetailImage} from 'utils/apiCalling/api'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import Typography from '@mui/material/Typography'
import {envBackHost} from 'GLOBAL/envVariables'
import dayjs from 'dayjs'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  overflowY: 'auto',
  borderRadius: '12px',
  boxShadow: 24,
  p: 4,
}
const AsDetailImageModal = (props: any) => {
  const {open, onClose, data, setDetailImageParams} = props
  const [detailImageList, setDetailImageList] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    if (data) {
      getAsDetailImage(data?.asIdx, {
        success: (res: any) => {
          setDetailImageList(res)
        },
        fail: (error: any) => {},
        loading: (loading: boolean) => {
          setLoading(loading)
        },
      })
    }
  }, [data])
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose()
          setDetailImageParams(null)
        }}
        aria-labelledby={`${window.location.pathname}-label`}
        aria-describedby={`${window.location.pathname}-description`}
        closeAfterTransition
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        id={'printTarget'}
        className={'printTarget'}
      >
        <Box sx={style}>
          <div className={'w-100 d-flex justify-content-between'}>
            <Chip
              label={`${data?.customerName} A/S 상세정보`}
              variant={'outlined'}
              sx={{fontSize: '20px', fontWeight: 'bold'}}
            />
            <CancelPresentationIcon
              sx={{fontSize: '40px', cursor: 'pointer'}}
              color={'error'}
              onClick={() => {
                onClose()
                setDetailImageParams(null)
              }}
            />
          </div>
          <div className={'container-fluid'} style={{marginTop: 50}}>
            {loading ? (
              <div className={'d-grid gap-1'}>
                <Box sx={{width: '100%'}}>
                  <LinearProgress />
                </Box>
                <Skeleton
                  variant='rectangular'
                  width={'100%'}
                  height={235}
                  className={'text-center fw-bold text-danger rounded-2'}
                />
              </div>
            ) : (
              <div className={'d-flex w-100 justify-content-center gap-5'}>
                <Card sx={{minWidth: 320}}>
                  <CardMedia
                    sx={{height: 144}}
                    image={`${envBackHost}${detailImageList?.beforeFilePath}`}
                    title='green iguana'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div' color={'#ff0000'}>
                      A/S 접수 시점
                    </Typography>
                    <span className={'fw-bold fs-6'}>
                      접수 시간 :{' '}
                      {dayjs(detailImageList?.beforeCreateAt).format('YYYY-MM-DD HH:mm:ss')}
                    </span>
                  </CardContent>
                </Card>
                {detailImageList?.afterFilePath ? (
                  <Card sx={{minWidth: 320}}>
                    <CardMedia
                      sx={{height: 144}}
                      image={`${envBackHost}${detailImageList?.afterFilePath}`}
                      title='green iguana'
                    />
                    <CardContent>
                      <Typography gutterBottom variant='h5' component='div' color={'#0092ff'}>
                        A/S 완료 시점
                      </Typography>
                      <span className={'fw-bold fs-6'}>
                        완료 시간 :{' '}
                        {dayjs(detailImageList?.afterCreateAt).format('YYYY-MM-DD HH:mm:ss')}
                      </span>
                    </CardContent>
                  </Card>
                ) : (
                  <div className={'d-grid gap-1'}>
                    <Skeleton
                      variant='rectangular'
                      width={320}
                      height={144}
                      className={'text-center fw-bold text-danger rounded-2'}
                    >
                      A/S 상태가 완료인지 확인해주세요.
                    </Skeleton>
                    <Skeleton
                      variant='rectangular'
                      width={320}
                      height={89}
                      className={'rounded-2'}
                    />
                  </div>
                )}
                <Card sx={{minWidth: 320}}>
                  <CardMedia
                    sx={{height: 144}}
                    image={`${envBackHost}${detailImageList?.currentFilePath}`}
                    title='green iguana'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      최근 검침 시점
                    </Typography>
                    <span className={'fw-bold fs-6'}>
                      최근 검침 시간 :{' '}
                      {dayjs(detailImageList?.currentCreateAt).format('YYYY-MM-DD HH:mm:ss')}
                    </span>
                  </CardContent>
                </Card>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default AsDetailImageModal
