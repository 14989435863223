import React, {useEffect, useState} from 'react'
import './../joinPage.css'
import Logo from '../images/meterarium_new_logo.gif'
import Paper from '@mui/material/Paper'
import {Autocomplete, Button, Step, StepLabel, Stepper, TextField} from '@mui/material'
import Box from '@mui/material/Box'
import {normalAlert, resultAlert, validationAlert} from 'utils/CustomAlert/Alert'
import {useNavigate} from 'react-router-dom'
import {isId, isPhone, isPw} from 'utils/validation/validation'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {envBackHost} from 'GLOBAL/envVariables'
import axios from 'axios'
import {hashPasswordParser} from 'utils/func/justUtils'

interface State {
  password: string
  checkPassword: string
  showPassword: boolean
}
const JoinRegitstration = () => {
  const navigate = useNavigate()
  const [completed, setCompleted] = useState(false)
  const steps = ['약관동의', '회원정보입력', '회원가입완료']
  const [idCheck, setIdCheck] = useState(false)
  const [submitData, setSubmitData] = useState({
    id: '',
    password: '',
    phoneNumber: '',
    customerNumber: '',
    officeId: '',
  })

  const [registration, setRegistration] = useState({
    customerNumber: '',
    phoneNumber: '',
    password: '',
    checkPassword: '',
  })
  const [pwForm, setPwForm] = useState(false)
  const [phoneNumberForm, setPhoneNumberForm] = useState(false)
  const [idForm, setIdForm] = useState(false)
  const [koreanRegions, setKoreanRegions] = useState<any>(null)
  const getKoreanRegions = async () => {
    axios.get(`${envBackHost}/city`).then((res) => {
      setKoreanRegions(res.data.res)
    })
  }

  const pwCheckForm = (prop: keyof State, e: any) => {
    const {value} = e.target
    if (isPw(value)) {
      setPwForm(true)
      setRegistration({...registration, [prop]: e.target.value})
    } else {
      setPwForm(false)
    }
  }
  const idValidation = (e: any) => {
    const {value} = e.target
    if (isId(value)) {
      setIdForm(true)
      setSubmitData({...submitData, id: value})
    } else {
      setIdForm(false)
    }
  }
  const phoneValidation = (e: any) => {
    const {value} = e.target
    if (isPhone(value)) {
      console.log('폰번호 형식이 맞습니다.')
      setPhoneNumberForm(true)
      setRegistration({...registration, phoneNumber: e.target.value})
    } else {
      setPhoneNumberForm(false)
      console.log('폰번호 형식이 아닙니다.')
    }
  }
  const postRegistration = async () => {
    if (registration.password !== registration.checkPassword) {
      await normalAlert('비밀번호가 일치하지 않습니다.')
      return
    }
    if (registration.password === '' || registration.checkPassword === '') {
      await normalAlert('비밀번호를 입력해주세요.')
      return
    }
    if (!pwForm) {
      await normalAlert('비밀번호 형식이 올바르지 않습니다.')
      return
    }
    if (registration.customerNumber === '') {
      await normalAlert('수용가 번호를 입력해주세요.')
      return
    }
    if (registration.phoneNumber === '') {
      await normalAlert('핸드폰 번호를 입력해주세요.')
      return
    }
    if (!phoneNumberForm) {
      await normalAlert('핸드폰 번호 형식이 올바르지 않습니다.')
      return
    }
    if (submitData.officeId === '') {
      await normalAlert('지역을 선택해주세요.')
      return
    }

    submitData.password = await hashPasswordParser(submitData.id, registration.password)
    axios
      .post(`${envBackHost}/client/account`, {
        id: submitData.id,
        password: submitData.password,
        phoneNumber: registration.phoneNumber,
        customerNumber: registration.customerNumber,
        officeId: submitData.officeId,
      })
      .then(async (res) => {
        console.log(res.data)
        if (res.data.resultCode === 11) {
          await validationAlert(res.data.message, 'error')
          return
        } else if (res.data.resultCode === 20) {
          await validationAlert(res.data.message, 'error')
        } else {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            setCompleted(true)
            navigate('/')
            return
          }
          return
        }
      })
      .catch(async (e) => {
        await resultAlert({resultCode: e.response.status})
      })
    // await validationAlert('준비 중 입니다.', 'warning')
  }
  useEffect(() => {
    getKoreanRegions()
  }, [])
  return (
    <>
      {!isMobileDevice() && (
        <div className={'container-fluid vh-100 join-body p-3 '}>
          <div className={'join-header text-center'}>
            <img src={Logo} alt={'logo'} width={'700'} style={{borderRadius: '28px'}} />
          </div>
          <div className={'join-content'}>
            <Paper elevation={3} className={'p-5'}>
              <Box sx={{width: '100%'}} className={'my-7 '}>
                <Stepper activeStep={completed ? 2 : 1} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <div className={'text-center'}>
                <h2 style={{fontSize: 35}}>회원정보입력</h2>
              </div>
              <div className={'row gap-4'} style={{padding: '0px 300px', margin: '40px 0px'}}>
                <div className={'d-flex gap-3 px-0 mx-0'}>
                  <Autocomplete
                    disablePortal
                    fullWidth
                    id='choose-office'
                    options={koreanRegions}
                    // value={submitData.officeId}
                    className={'px-0'}
                    disableClearable={true}
                    clearText={'초기화'}
                    noOptionsText={'검색 결과가 없습니다.'}
                    getOptionLabel={(option: any) => option.cityName}
                    onChange={(e, rowValue: any) => {
                      setSubmitData({
                        ...submitData,
                        officeId: rowValue?.officeId,
                      })
                    }}
                    sx={{fontSize: '1.5rem', color: '#fff'}}
                    renderInput={(params) => (
                      <div className={'d-flex gap-3'}>
                        <TextField
                          {...params}
                          fullWidth
                          label='지역선택'
                          focused
                          placeholder={'지역을 선택해주세요.'}
                        />
                      </div>
                    )}
                  />
                  <TextField
                    variant={'outlined'}
                    label={'수용가 번호'}
                    required
                    type={'number'}
                    autoComplete='new-password'
                    onChange={(e: any) =>
                      setRegistration({...registration, customerNumber: e.target.value})
                    }
                    fullWidth={true}
                  />
                </div>
                <div className={'d-flex gap-3 px-0 mx-0'}>
                  <TextField
                    variant={'outlined'}
                    label={'아이디'}
                    onChange={(e) => {
                      idValidation(e)
                    }}
                    disabled={idCheck}
                    required
                    fullWidth={true}
                  />
                </div>
                <div className={'d-flex gap-3 px-0'}>
                  <TextField
                    variant={'outlined'}
                    required
                    autoComplete='new-password'
                    label={'핸드폰 번호'}
                    helperText={
                      registration.phoneNumber === '' ? (
                        <span className={'fw-bolder fs-6 text-primary'}>
                          - 를 제외하고 입력하세요.
                        </span>
                      ) : phoneNumberForm ? (
                        <span className={'fw-bolder fs-6 text-success'}>
                          올바른 연락처 형식입니다.
                        </span>
                      ) : (
                        <span className={'fw-bolder fs-6 text-danger'}>
                          잘못된 연락처 형식입니다.{' '}
                        </span>
                      )
                    }
                    fullWidth={true}
                    onChange={(e: any) => phoneValidation(e)}
                  />
                </div>
                <div className={'px-0'}>
                  <div className={'d-flex gap-3 px-0'}>
                    <TextField
                      variant={'outlined'}
                      label={'비밀번호'}
                      required
                      autoComplete='new-password'
                      onChange={(e: any) => pwCheckForm('password', e)}
                      type={'password'}
                      fullWidth={true}
                    />
                    <TextField
                      variant={'outlined'}
                      label={'비밀번호 확인'}
                      required
                      onChange={(e: any) => pwCheckForm('checkPassword', e)}
                      autoComplete='new-password'
                      type={'password'}
                      fullWidth={true}
                    />
                  </div>
                  {registration.checkPassword === '' ? (
                    <div className={'mt-1 fw-bolder'}>
                      {registration.password === '' ? (
                        <div className={'row my-4'}>
                          <span className={'text-primary fw-bolder'}>
                            비밀번호는 6~12자리의 영문 대소문자, 숫자, 특수문자(!@#$%^&*)로 구성
                          </span>
                          <span className={'text-primary fw-bolder'}>
                            특수문자는 !@#$%^&* 만 허용
                          </span>
                        </div>
                      ) : pwForm ? (
                        <span className={'text-success'}> 올바른 비밀번호 형식입니다.</span>
                      ) : (
                        <span className={'text-danger'}> 잘못된 비밀번호 형식입니다.</span>
                      )}
                    </div>
                  ) : registration.checkPassword === registration.password ? (
                    <div className={'mt-1 fw-bolder'}>
                      <span className={'text-success'}> 비밀번호가 일치합니다.</span>
                    </div>
                  ) : (
                    <div className={'mt-1 fw-bolder'}>
                      <span className={'text-danger'}> 비밀번호가 일치하지 않습니다.</span>
                    </div>
                  )}
                </div>
              </div>
              <div className={'my-4 text-center d-flex gap-4 justify-content-center'}>
                <Button
                  variant='contained'
                  color='inherit'
                  size={'large'}
                  onClick={() => {
                    setCompleted(false)
                    navigate('/')
                  }}
                >
                  취소
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  size={'large'}
                  onClick={async () => {
                    await postRegistration()
                  }}
                >
                  확인
                </Button>
              </div>
            </Paper>
          </div>
        </div>
      )}
      {isMobileDevice() && (
        <div className={'container-fluid vh-100 join-body p-3 '}>
          <div className={'join-header text-center'}>
            <img src={Logo} alt={'logo'} width={'100%'} style={{borderRadius: '12px'}} />
          </div>
          <div className={'join-content-m'}>
            <Paper elevation={3} className={'p-5'}>
              <Box sx={{width: '100%'}} className={'my-7 '}>
                <Stepper activeStep={completed ? 2 : 1} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <div className={'text-center'}>
                <h2 style={{fontSize: 35}}>회원정보입력</h2>
              </div>
              <div className={'row gap-4'}>
                <div className={'d-flex gap-3 px-0 mx-0'}>
                  <Autocomplete
                    disablePortal
                    fullWidth
                    id='choose-office'
                    options={koreanRegions}
                    // value={submitData.officeId}
                    className={'px-0'}
                    disableClearable={true}
                    clearText={'초기화'}
                    noOptionsText={'검색 결과가 없습니다.'}
                    getOptionLabel={(option: any) => option.cityName}
                    onChange={(e, rowValue: any) => {
                      setSubmitData({
                        ...submitData,
                        officeId: rowValue?.officeId,
                      })
                    }}
                    sx={{fontSize: '1.5rem', color: '#fff'}}
                    renderInput={(params) => (
                      <div className={'d-flex gap-3'}>
                        <TextField
                          {...params}
                          fullWidth
                          label='지역선택'
                          focused
                          placeholder={'지역을 선택해주세요.'}
                        />
                      </div>
                    )}
                  />
                  <TextField
                    variant={'outlined'}
                    label={'수용가 번호'}
                    required
                    type={'number'}
                    autoComplete='new-password'
                    onChange={(e: any) =>
                      setRegistration({...registration, customerNumber: e.target.value})
                    }
                    fullWidth={true}
                  />
                </div>
                <div className={'d-flex gap-3 px-0 mx-0'}>
                  <TextField
                    variant={'outlined'}
                    label={'아이디'}
                    onChange={(e) => {
                      idValidation(e)
                    }}
                    disabled={idCheck}
                    required
                    fullWidth={true}
                  />
                </div>
                <div className={'d-flex gap-3 px-0'}>
                  <TextField
                    variant={'outlined'}
                    required
                    autoComplete='new-password'
                    label={'핸드폰 번호'}
                    helperText={
                      registration.phoneNumber === '' ? (
                        <span className={'fw-bolder fs-6 text-primary'}>
                          - 를 제외하고 입력하세요.
                        </span>
                      ) : phoneNumberForm ? (
                        <span className={'fw-bolder fs-6 text-success'}>
                          올바른 연락처 형식입니다.
                        </span>
                      ) : (
                        <span className={'fw-bolder fs-6 text-danger'}>
                          잘못된 연락처 형식입니다.{' '}
                        </span>
                      )
                    }
                    fullWidth={true}
                    onChange={(e: any) => phoneValidation(e)}
                  />
                </div>
                <div className={'px-0'}>
                  <div className={'d-flex gap-3 px-0'}>
                    <TextField
                      variant={'outlined'}
                      label={'비밀번호'}
                      required
                      autoComplete='new-password'
                      onChange={(e: any) => pwCheckForm('password', e)}
                      type={'password'}
                      fullWidth={true}
                    />
                    <TextField
                      variant={'outlined'}
                      label={'비밀번호 확인'}
                      required
                      onChange={(e: any) => pwCheckForm('checkPassword', e)}
                      autoComplete='new-password'
                      type={'password'}
                      fullWidth={true}
                    />
                  </div>
                  {registration.checkPassword === '' ? (
                    <div className={'mt-1 fw-bolder'}>
                      {registration.password === '' ? (
                        <span className={'text-primary fw-bolder'}>
                          영문 혹은 숫자 3 ~ 10 자리입니다.
                        </span>
                      ) : pwForm ? (
                        <span className={'text-success'}> 올바른 비밀번호 형식입니다.</span>
                      ) : (
                        <span className={'text-danger'}> 잘못된 비밀번호 형식입니다.</span>
                      )}
                    </div>
                  ) : registration.checkPassword === registration.password ? (
                    <div className={'mt-1 fw-bolder'}>
                      <span className={'text-success'}> 비밀번호가 일치합니다.</span>
                    </div>
                  ) : (
                    <div className={'mt-1 fw-bolder'}>
                      <span className={'text-danger'}> 비밀번호가 일치하지 않습니다.</span>
                    </div>
                  )}
                </div>
              </div>
              <div className={'my-4 text-center d-flex gap-4 justify-content-center'}>
                <Button
                  variant='contained'
                  color='inherit'
                  size={'large'}
                  onClick={() => {
                    setCompleted(false)
                    navigate('/')
                  }}
                >
                  취소
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  size={'large'}
                  onClick={async () => {
                    await postRegistration()
                  }}
                >
                  확인
                </Button>
              </div>
            </Paper>
          </div>
        </div>
      )}
    </>
  )
}

export default JoinRegitstration
