import React, {useEffect, useState} from 'react'
import {DataGridPro, GridRowId} from '@mui/x-data-grid-pro'
import {useSelector} from 'react-redux'
import {ToastContainer} from 'react-toastify'
import {recoveryInfoFilter} from 'pages/task/dataConfig/declearFilters'
import {asRecoveryColumns} from 'pages/task/dataConfig/declearColumns'
import {recoveryInfoRows} from 'pages/task/dataConfig/declearRows'
import {deleteDisuseDevice, getRecoveryInfoList, putRecycleDevice} from 'utils/apiCalling/api'
import {onRowsSelectionHandler} from 'utils/func/justUtils'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import {toolTipText} from 'utils/toolTips/toolTipText'

const RecoveryInfo = (props: any) => {
  const [pageSize, setPageSize] = useState<number>(30)
  const [selectData, setSelectData] = useState<any>([])
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const [recoveryList, setRecoveryList] = useState<any>(null)
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [filter, setFilter] = useState<any>(recoveryInfoFilter)
  const getRecoveryListCall = () => {
    getRecoveryInfoList(cooperInfo, filter, {
      success: (res: any) => {
        setRecoveryList(res)
      },
      fail: (error: any) => {
        console.log(error)
      },
      loading: (loading: boolean) => {
        setLoading(loading)
      },
    })
  }
  const recycleDeviceCall = async () => {
    putRecycleDevice(selectData, userInfo, {
      success: (res: any) => {
        console.log(res)
        if (res.resultCode === 0) {
          getRecoveryListCall()
          setSelectData([])
          setSelectionModel([])
          return
        }
      },
      fail: (error: any) => {
        console.log(error)
      },
    })
  }
  const deleteDisuseDeviceCall = async (imei?: any) => {
    deleteDisuseDevice(imei, userInfo, {
      success: (res: any) => {
        if (res.resultCode === 0) {
          getRecoveryListCall()
          return
        }
      },
      fail: (error: any) => {
        console.log(error)
      },
    })
  }
  const rows = recoveryInfoRows(recoveryList)
  const columns: any = asRecoveryColumns(deleteDisuseDeviceCall)

  useEffect(() => {
    getRecoveryListCall()
  }, [cooperInfo?.value])
  return (
    <>
      <MeterariumPageLayout
        headerDescription={toolTipText.recoveryInfo.description}
        tooltipText={toolTipText.recoveryInfo.text}
        filter={filter}
        buttonHandler={recycleDeviceCall}
        id={'as-recovery'}
        setFilter={setFilter}
        searchCallback={getRecoveryListCall}
        total={rows?.length}
        control={['']}
        actions={['recycle']}
        searchOptions={['serialNumber', 'imei', 'firmware']}
        checkData={selectData}
      >
        <DataGridPro
          rowHeight={150}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          autoHeight={true}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 30, 50, 70, 100]}
          disableColumnMenu
          loading={loading}
          pagination
          selectionModel={selectionModel}
          checkboxSelection
          onSelectionModelChange={(ids, selection) => {
            const selectedRowsData = ids.map((id) => rows.find((row: any) => row.id === id))
            onRowsSelectionHandler(ids, [
              [setSelectData, selectedRowsData],
              [setSelectionModel, ids],
            ])
          }}
        />
      </MeterariumPageLayout>
      <ToastContainer />
    </>
  )
}
export default RecoveryInfo
