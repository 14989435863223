import React, {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material'
import Logo from 'pages/join/images/meterarium_new_logo.gif'
import {isPw} from 'utils/validation/validation'
import {Visibility, VisibilityOff} from '@mui/icons-material'
import {hashPasswordParser} from 'utils/func/justUtils'
import {passwordChangeRequest} from 'utils/apiCalling/api'
import {resultAlert} from 'utils/CustomAlert/Alert'
import {useTimer} from 'react-timer-hook'
import Timer from 'pages/certification/modules/Timer'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {accessImg} from 'assets/imageDeclear'
const PasswordReset = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {data, info} = location?.state || {}

  const steps = ['인증', '비밀번호 초기화']
  const [passInfo, setPassInfo] = useState<any>({
    password: '',
    passwordCheck: '',
    showPassword: false,
  })
  const [error, setError] = useState<boolean>(false)
  const [abnormalAccess, setaAbnormalAccess] = useState<boolean>(false)
  const passwordValidation = (e: any, type: string) => {
    const {value} = e.target
    if (isPw(value)) {
      setPassInfo({...passInfo, [type]: value})
    }
  }
  const handleClickShowPassword = () => {
    setPassInfo({
      ...passInfo,
      showPassword: !passInfo.showPassword,
    })
  }
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  const changeRequest = async () => {
    const parsingPassword = await hashPasswordParser(info.id, passInfo.password)
    const postData = {
      id: info.id,
      password: parsingPassword,
      token: data.token,
      // token: 123,
    }
    passwordChangeRequest(postData, {
      success: async (res: any) => {
        await resultAlert(res)
        if (res.resultCode === 0) {
          navigate('/auth', {replace: true})
        } else {
          setError(true)
        }
      },
      fail: async (e: any) => {
        await resultAlert({resultCode: e.response.status})
        setError(true)
      },
    })
  }
  useEffect(() => {
    if (!data || !info) {
      setaAbnormalAccess(true)
    }
  }, [data, info])
  return (
    <>
      {!isMobileDevice() && (
        <div className={'container-fluid vh-100 join-body p-3'}>
          <div className={'join-header text-center'}>
            <img src={Logo} alt={'logo'} width={'700'} style={{borderRadius: '28px'}} />
          </div>
          {!abnormalAccess ? (
            <div className={'w-100 '} style={{display: 'grid', justifyItems: 'center'}}>
              <Paper elevation={3} className={'p-5 w-50'}>
                <Box sx={{width: '100%'}} className={'my-7 '}>
                  <Stepper activeStep={1} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
                <div className={'text-center mb-5'}>
                  <span className={'ts-5 fw-bold'}>
                    비밀번호 초기화는 <strong className={'text-danger'}>5분 이내 </strong>{' '}
                    진행되어야 합니다.
                  </span>
                </div>
                <div className={'w-100 d-grid justify-content-center'} style={{marginBottom: 25}}>
                  <Timer />
                </div>
                <div
                  className={'d-flex gap-5 justify-content-center'}
                  style={{padding: '0px 100px 0px 100px'}}
                >
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='standard-adornment-password'>비밀번호</InputLabel>
                    <OutlinedInput
                      id='outlined-adornment-password'
                      type={passInfo.showPassword ? 'text' : 'password'}
                      defaultValue={passInfo.password}
                      name={'password'}
                      onChange={(e: any) => passwordValidation(e, 'password')}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {passInfo.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label='비밀번호'
                      onKeyDown={(e: any) => {
                        if (passInfo.password === '' || passInfo.passwordCheck === '') return
                        if (e.key === 'Enter') {
                          changeRequest()
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='standard-adornment-checkPassword'>
                      비밀번호 확인
                    </InputLabel>
                    <OutlinedInput
                      id='outlined-adornment-checkPassword'
                      type={passInfo.showPassword ? 'text' : 'Password'}
                      defaultValue={passInfo.checkPassword}
                      name={'passwordCheck'}
                      onChange={(e: any) => passwordValidation(e, 'passwordCheck')}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {passInfo.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label='비밀번호 확인'
                      onKeyDown={(e: any) => {
                        if (passInfo.password === '' || passInfo.passwordCheck === '') return
                        if (e.key === 'Enter') {
                          changeRequest()
                        }
                      }}
                    />
                  </FormControl>
                </div>
                {passInfo.password !== passInfo.passwordCheck && (
                  <div style={{padding: '0px 100px 0px 100px', marginBottom: 30}}>
                    <span className={'text-danger fw-bolder'}>비밀번호가 일치하지 않습니다.</span>
                  </div>
                )}
                <div
                  className={'row my-4'}
                  style={{padding: '0px 100px 0px 100px', marginBottom: 30}}
                >
                  <span className={'text-primary fw-bolder'}>
                    비밀번호는 6~12자리의 영문 대소문자, 숫자, 특수문자(!@#$%^&*)로 구성
                  </span>
                  <span className={'text-primary fw-bolder'}>특수문자는 !@#$%^&* 만 허용</span>
                </div>
                <div
                  className={'d-flex gap-4 justify-content-end '}
                  style={{padding: '0px 70px 0px 0px', marginBottom: 30}}
                >
                  <Link to={'/auth'}>
                    <Button variant={'outlined'} color={'error'}>
                      로그인 페이지이동
                    </Button>
                  </Link>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => changeRequest()}
                    disabled={passInfo.password === '' || passInfo.passwordCheck === ''}
                  >
                    비밀번호 재설정
                  </Button>
                </div>
                {error && (
                  <div
                    className={'row my-4 border rounded-2 border-gray-400 p-3'}
                    style={{marginBottom: 30}}
                  >
                    <span className={'text-danger fw-bolder'}>
                      비밀번호 재설정에 실패하였습니다.
                    </span>
                    <span className={'text-danger fw-bolder'}>
                      비밀번호 재설정 시간이 초과되었거나, 잘못된 접근입니다.
                    </span>
                    <span className={'text-danger fw-bolder'}>
                      반복적으로 비밀번호 재설정에 실패할 경우, 영아이티로 문의부탁드립니다.
                    </span>
                  </div>
                )}
              </Paper>
            </div>
          ) : (
            <div className={'text-center'}>
              <h2 className={'text-danger fw-bolder text-center'}>비정상적인 접근입니다.</h2>
              <img src={accessImg} alt={'abnormal'} width={'40%'} style={{borderRadius: '28px'}} />
              <div className={'d-flex justify-content-center mt-5'}>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => navigate('/auth', {replace: true})}
                >
                  {' '}
                  미터리움 이동{' '}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      {isMobileDevice() && (
        <div className={'container-fluid vh-100 join-body p-3'}>
          <div className={'join-header text-center'}>
            <img src={Logo} alt={'logo'} width={'100%'} style={{borderRadius: '28px'}} />
          </div>
          {!abnormalAccess ? (
            <div className={'w-100 px-2'} style={{display: 'grid', justifyItems: 'center'}}>
              <Paper elevation={3} className={'p-5 w-100'}>
                <Box sx={{width: '100%'}} className={'my-7 '}>
                  <Stepper activeStep={1} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
                <div className={'text-center mb-5'}>
                  <span className={'ts-5 fw-bold'}>
                    비밀번호 초기화는 <strong className={'text-danger'}>5분 이내 </strong>{' '}
                    진행되어야 합니다.
                  </span>
                </div>
                <div className={'w-100 d-grid justify-content-center'} style={{marginBottom: 25}}>
                  <Timer />
                </div>
                <div className={'d-flex gap-5 justify-content-center'}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='standard-adornment-password'>비밀번호</InputLabel>
                    <OutlinedInput
                      id='outlined-adornment-password'
                      type={passInfo.showPassword ? 'text' : 'password'}
                      defaultValue={passInfo.password}
                      name={'password'}
                      onChange={(e: any) => passwordValidation(e, 'password')}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {passInfo.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label='비밀번호'
                      onKeyDown={(e: any) => {
                        if (passInfo.password === '' || passInfo.passwordCheck === '') return
                        if (e.key === 'Enter') {
                          changeRequest()
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='standard-adornment-checkPassword'>
                      비밀번호 확인
                    </InputLabel>
                    <OutlinedInput
                      id='outlined-adornment-checkPassword'
                      type={passInfo.showPassword ? 'text' : 'Password'}
                      defaultValue={passInfo.checkPassword}
                      name={'passwordCheck'}
                      onChange={(e: any) => passwordValidation(e, 'passwordCheck')}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {passInfo.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label='비밀번호 확인'
                      onKeyDown={(e: any) => {
                        if (passInfo.password === '' || passInfo.passwordCheck === '') return
                        if (e.key === 'Enter') {
                          changeRequest()
                        }
                      }}
                    />
                  </FormControl>
                </div>
                <div className={'row my-4'}>
                  <span className={'text-primary fw-bolder'}>
                    비밀번호는 6~12자리의 영문 대소문자, 숫자, 특수문자(!@#$%^&*)로 구성
                  </span>
                  <span className={'text-primary fw-bolder'}>특수문자는 !@#$%^&* 만 허용</span>
                </div>
                <div className={'d-flex gap-4 justify-content-end '}>
                  <Link to={'/auth'}>
                    <Button variant={'outlined'} color={'error'}>
                      로그인 페이지이동
                    </Button>
                  </Link>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => changeRequest()}
                    disabled={passInfo.password === '' || passInfo.passwordCheck === ''}
                  >
                    비밀번호 재설정
                  </Button>
                </div>
                {error && (
                  <div
                    className={'row my-4 border rounded-2 border-gray-400 p-3'}
                    style={{marginBottom: 30}}
                  >
                    <span className={'text-danger fw-bolder'}>
                      비밀번호 재설정에 실패하였습니다.
                    </span>
                    <span className={'text-danger fw-bolder'}>
                      비밀번호 재설정 시간이 초과되었거나, 잘못된 접근입니다.
                    </span>
                    <span className={'text-danger fw-bolder'}>
                      반복적으로 비밀번호 재설정에 실패할 경우, 영아이티로 문의부탁드립니다.
                    </span>
                  </div>
                )}
              </Paper>
            </div>
          ) : (
            <>
              <h5 className={'text-danger fw-bolder text-center'}>비정상적인 접근입니다.</h5>
              <img src={accessImg} alt={'abnormal'} width={'100%'} style={{borderRadius: '28px'}} />
              <div className={'d-flex justify-content-center mt-5'}>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => navigate('/auth', {replace: true})}
                >
                  {' '}
                  미터리움 이동{' '}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default PasswordReset
