export const submitData = {
  officeId: null,
  customerNumber: null,
  imei: null,
  idxDate: null,
  recognizedType: 'none',
  xstart: null,
  ystart: null,
  xend: null,
  yend: null,
  digit: null,
  rotate: 0,
  contrast: 255,
  brightness: 32,
  latitude: null,
  longitude: null,
  installId: null,
  createAt: null,
  deviceType: null,
  deviceTypeName: null,
  filePath: null,
  serialNumber: null,
  addressNumber: null,
  customerName: null,
  rssiStatus: null,
}

export const asSubmitData = {
  officeId: null,
  deviceType: null,
  serialNumber: null,
  idx: null,
  customerNumber: null,
  recognizedType: 'none',
  beforeImei: null,
  imei: null,
  resultIdx: null,
  resultIdxName: null,
  idxDate: null,
  xstart: null,
  ystart: null,
  createAt: null,
  xend: null,
  causeName: null,
  causeIdx: null,
  yend: null,
  digit: null,
  rotate: 0,
  contrast: 255,
  brightness: 32,
  latitude: null,
  longitude: null,
  updateId: null,
  afterComment: '',
  rssiStatus: null,
  changeFlag: false,
}
