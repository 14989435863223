import {GridColumnGroupingModel} from '@mui/x-data-grid-pro'

export const asManagementGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'A/S 설치정보',
    headerAlign: 'center',
    children: [
      {
        groupId: '원인명',
        children: [{field: 'solutionName'}],
        headerAlign: 'center',
      },
      {
        groupId: 'A/S 기사 ID',
        children: [{field: 'engineer'}],
        headerAlign: 'center',
      },
    ],
  },
  {
    groupId: '상세정보',
    headerAlign: 'center',
    children: [
      {
        groupId: '이름',
        children: [{field: 'customerInfo'}],
        headerAlign: 'center',
      },
    ],
  },
  {
    groupId: '사업소',
    headerAlign: 'center',
    children: [
      {
        groupId: '사업소 코드',
        children: [{field: 'officeName'}],
        headerAlign: 'center',
      },
    ],
  },
  {
    groupId: '설치자 정보',
    headerAlign: 'center',
    children: [
      {
        groupId: '설치자 ID',
        children: [{field: 'installInfo'}],
        headerAlign: 'center',
      },
    ],
  },
  {
    groupId: '일시',
    headerAlign: 'center',
    children: [
      {
        groupId: '생성일',
        children: [{field: 'date'}],
        headerAlign: 'center',
      },
    ],
  },
]
