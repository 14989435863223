import {
  Build as BuildIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Help as HelpIcon,
  HighlightOff as HighlightOffIcon,
  OfflinePin as OfflinePinIcon,
  Refresh as RefreshIcon,
  Search as SearchIcon,
  TaskAlt as TaskAltIcon,
  Unpublished as UnpublishedIcon,
} from '@mui/icons-material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ListAltIcon from '@mui/icons-material/ListAlt'
import MenuIcon from '@mui/icons-material/Menu'
import ReplayIcon from '@mui/icons-material/Replay'
import {
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import {DatePicker, DateRangePicker, LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import * as bootstrap from 'bootstrap'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, {Fragment} from 'react'
import {useSelector} from 'react-redux'
import {requestAs, requestAsCancel} from 'utils/apiCalling/api'
import {noDataAlert, normalAlert, validationAlert} from 'utils/CustomAlert/Alert'
import {CustomSwitch} from 'utils/designSytem/CustomSwitch'
import {CustomWidthTooltip} from 'utils/designSytem/CustomTooltip'

const MeterariumPageLayout = (props: any) => {
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const {
    errorCount,
    headerDescription,
    children,
    filter,
    id,
    total,
    checkData,
    setFilter,
    tooltipText,
    control,
    hideSearchOptions,
    actions,
    searchCallback,
    date,
    setDate,
    pannel,
    searchOptions,
    onDelete,
    accountTypeList,
    setTypeName,
    onCancel,
    onAssignCancel,
    onCreateUserModalOpen,
    buttonHandler,
    asValue = {},
    loading,
  } = props

  const {
    asReasonData,
    setAsReasonData,
    setCheckData,
    setSelectionModel,
    causeList,
    solutionList,
    setHiddenClick,
    hiddenClick,
  } = asValue
  const formControls: any = {
    assign: (
      <FormControl className={'col-lg-1'} key={'fC-assign'}>
        <InputLabel id='demo-simple-select-label'>할당상태</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='assignStatus'
          label='할당상태'
          defaultValue={filter?.assignStatus}
          onChange={(e) => setFilter({...filter, assignStatus: e.target.value})}
        >
          <MenuItem value={'all'}>전체</MenuItem>
          <MenuItem value={'assigned'}>할당</MenuItem>
          <MenuItem value={'unassigned'}>미할당</MenuItem>
        </Select>
      </FormControl>
    ),
    installable: (
      <FormControl className={'col-lg-1'} key={'fC-installable'}>
        <InputLabel id='demo-simple-select-label'>설치가능 여부</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='install-available'
          label='설치가능 여부'
          defaultValue={filter?.assignStatus}
          onChange={(e) => setFilter({...filter, customerStatus: e.target.value})}
        >
          <MenuItem value={'all'}>전체</MenuItem>
          <MenuItem value={'true'}>가능</MenuItem>
          <MenuItem value={'false'}>불가능</MenuItem>
        </Select>
      </FormControl>
    ),
    device: (
      <FormControl className={'col-lg-2'} key={'fC-device'}>
        <InputLabel id='demo-simple-select-label'>단말기 통신상태</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='customer-target'
          label='단말기 통신상태'
          onChange={(e) => {
            setFilter({...filter, deviceStatus: e.target.value})
          }}
          defaultValue={filter?.deviceStatus}
        >
          <MenuItem value={'all'}>전체</MenuItem>
          <MenuItem value={'true'}>정상</MenuItem>
          <MenuItem value={'false'}>점검</MenuItem>
        </Select>
      </FormControl>
    ),
    battery: (
      <FormControl className={'col-lg-2'} key={'fC-battery'}>
        <InputLabel id='demo-simple-select-label'>배터리 상태</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='customer-target'
          label='배터리 상태'
          defaultValue={filter?.batteryStatus}
          onChange={(e) => {
            setFilter({...filter, batteryStatus: e.target.value})
          }}
        >
          <MenuItem value={'all'}>전체</MenuItem>
          <MenuItem value={'true'}>정상</MenuItem>
          <MenuItem value={'false'}>점검</MenuItem>
        </Select>
      </FormControl>
    ),
    deviceType: (
      <FormControl className={'col-lg-2'} key={'fC-deviceType'}>
        <InputLabel id='demo-simple-select-label'>단말기 타입</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='customer-target'
          defaultValue={filter?.deviceType}
          label='단말기 타입'
          onChange={(e) => {
            setFilter({...filter, deviceType: e.target.value})
          }}
        >
          <MenuItem value={'all'}>전체</MenuItem>
          <MenuItem value={'analog'}>디지털 이미지</MenuItem>
          <MenuItem value={'digital'}>디지털 계량기</MenuItem>
        </Select>
      </FormControl>
    ),
    recognizedType: (
      <FormControl className={'col-lg-2'} key={'fC-recognizedType'}>
        <InputLabel id='demo-simple-select-label'>계량기 타입</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='customer-target'
          defaultValue={filter?.recognizedType}
          label='계량기 타입'
          onChange={(e) => {
            setFilter({...filter, recognizedType: e.target.value})
          }}
        >
          <MenuItem value={'all'}>전체</MenuItem>
          <MenuItem value={'analog'}>디지털 이미지</MenuItem>
          <MenuItem value={'digital'}>디지털 계량기</MenuItem>
        </Select>
      </FormControl>
    ),
    freezeWarning: (
      <FormControl className={'col-lg-2'} key={'fC-freezeWarning'}>
        <InputLabel id='demo-simple-select-label'>구분</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='customer-target'
          defaultValue={filter?.freezeWarningType}
          label='구분'
          onChange={(e) => {
            setFilter({...filter, freezeWarningType: e.target.value})
          }}
        >
          <MenuItem value={'all'}>전체</MenuItem>
          <MenuItem value={'normal'}>양호</MenuItem>
          <MenuItem value={'warning'}>주의</MenuItem>
          <MenuItem value={'dangerous'}>위험</MenuItem>
          <MenuItem value={'very-dangerous'}>매우 위험</MenuItem>
        </Select>
      </FormControl>
    ),

    efos: (
      <FormControl className={'col-lg-2'} key={'fC-efos'}>
        <InputLabel id='demo-simple-select-label'>계량기 통신상태</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='customer-target'
          defaultValue={filter?.efosStatus}
          label='계량기 통신상태'
          onChange={(e) => {
            setFilter({...filter, efosStatus: e.target.value})
          }}
        >
          <MenuItem value={'all'}>전체</MenuItem>
          <MenuItem value={'true'}>정상</MenuItem>
          <MenuItem value={'false'}>점검</MenuItem>
        </Select>
      </FormControl>
    ),
    selectDate: (
      <div id={'date-range'} className={'col-lg-2'} key={'fC-selectDate'}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label='날짜 선택'
            onChange={(newValue) =>
              setFilter({...filter, date: dayjs(newValue).format('YYYYMMDD')})
            }
            value={filter?.date}
            disableFuture={true}
            inputFormat={'YYYY년 MM월 DD일'}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>
    ),
    period: (
      <FormControl className={'col-lg-1'} key={'fC-period'}>
        <InputLabel id='demo-simple-select-label'>검색 기간 구분</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='customer-target'
          label='검색 기간 구분'
          defaultValue={filter?.flag}
          onChange={(e) => {
            if (e.target.value === 'day') {
              setFilter({
                ...filter,
                flag: e.target.value,
                start: dayjs(filter?.end).subtract(14, 'd').format('YYYYMMDD'),
                end: dayjs().format('YYYYMMDD'),
              })
            } else if (e.target.value === 'week') {
              setFilter({
                ...filter,
                flag: e.target.value,
                start: 4,
                end: dayjs().format('YYYYMMDD'),
              })
            } else if (e.target.value === 'month') {
              setFilter({
                ...filter,
                flag: e.target.value,
                start: 3,
                end: dayjs().format('YYYYMM'),
              })
            }
          }}
        >
          <MenuItem value={'day'}>일</MenuItem>
          <MenuItem value={'week'}>주</MenuItem>
          <MenuItem value={'month'}>월</MenuItem>
          {/*<MenuItem value={'year'}>년</MenuItem>*/}
        </Select>
      </FormControl>
    ),
    selectMonth: (
      <div id={'date-range'} className={'col-lg-2'} key={'fC-selectMonth'}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label='날짜 선택'
            onChange={(newValue) => setFilter({...filter, date: dayjs(newValue).format('YYYYMM')})}
            views={['year', 'month']}
            openTo={'month'}
            value={filter?.date}
            disableFuture={true}
            inputFormat={'YYYY년 MM월'}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>
    ),
    rangeDate: (
      <Fragment key={'fC-rangeDate'}>
        <FormControl className={'col-lg-1'}>
          <InputLabel id='demo-simple-select-label'>검색 기간 구분</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='range-date'
            label='검색 기간 구분'
            defaultValue={filter?.flag}
            onChange={(e) => {
              if (e?.target?.value === 'day') {
                setFilter({
                  ...filter,
                  flag: 'day',
                  start: 1,
                  end: dayjs().format('YYYYMMDD'),
                })
              } else if (e.target.value === 'week') {
                setFilter({
                  ...filter,
                  flag: e.target.value,
                  start: 4,
                  end: dayjs().format('YYYYMMDD'),
                })
              } else if (e.target.value === 'month') {
                setFilter({
                  ...filter,
                  flag: e.target.value,
                  start: 3,
                  end: dayjs().format('YYYYMM'),
                })
              }
            }}
          >
            <MenuItem value={'day'}>일</MenuItem>
            <MenuItem value={'week'}>주</MenuItem>
            <MenuItem value={'month'}>월</MenuItem>
            {/*<MenuItem value={'year'}>년</MenuItem>*/}
          </Select>
        </FormControl>
        <div id={'date-range'} className={'col-lg-3'}>
          {filter?.flag === 'day' && (
            <div className={'d-flex gap-4'}>
              {/*<TextField*/}
              {/*  variant={'outlined'}*/}
              {/*  className={'col'}*/}
              {/*  disabled*/}
              {/*  label={`검색 시작날짜`}*/}
              {/*  defaultValue={dayjs(filter?.start).format('YYYYMMDD')}*/}
              {/*/>*/}
              <span className={'w-200px fs-6 align-self-center text-end'}>
                기준 날짜로부터 일자별로 모든 데이터를 불러옵니다.
              </span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label='기준 날짜 선택'
                  value={date}
                  disableFuture={true}
                  onChange={(newValue: any) => {
                    setDate(newValue)
                    setFilter({
                      ...filter,

                      end: dayjs(newValue).format('YYYYMMDD'),
                    })
                  }}
                  className={'col'}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          )}
          {filter?.flag === 'week' && (
            <div className={'d-flex gap-3'}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>기간 선택</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='select-week'
                  defaultValue={4}
                  label='기간 선택 '
                  onChange={(e) => {
                    setFilter({...filter, start: e.target.value})
                  }}
                >
                  {_.map(_.range(1, 8), (el, i) => (
                    <MenuItem value={i + 1} key={i}>
                      {el}주차 전
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={['year', 'month', 'day']}
                  openTo='day'
                  mask={'____.__.__'}
                  inputFormat={'YYYY년 MM월 DD일'}
                  value={date}
                  disableFuture={true}
                  onChange={(newValue: any) => {
                    setDate(newValue)
                    setFilter({
                      ...filter,
                      end: dayjs(newValue).format('YYYYMMDD'),
                    })
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  minDate={new Date('2023-01-01')}
                  maxDate={new Date('2030-12-31')}
                />
              </LocalizationProvider>
            </div>
          )}
          {filter?.flag === 'month' && (
            <div className={'d-flex gap-3'}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>기간 선택</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='select-week'
                  defaultValue={3}
                  label='기간 선택 '
                  onChange={(e) => {
                    setFilter({...filter, start: e.target.value})
                  }}
                >
                  {_.map(_.range(1, 12), (el, i) => (
                    <MenuItem value={i + 1} key={i}>
                      {el}개월 전
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={['year', 'month']}
                  openTo='month'
                  mask={'____.__'}
                  disableFuture={true}
                  inputFormat={'YYYY년 MM월'}
                  value={date}
                  onChange={(newValue: any) => {
                    setDate(newValue)
                    setFilter({
                      ...filter,
                      end: dayjs(newValue).format('YYYYMM'),
                    })
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  minDate={new Date('2023-01-01')}
                  maxDate={new Date('2030-12-31')}
                />
              </LocalizationProvider>
            </div>
          )}
        </div>
      </Fragment>
    ),
    useSwitch: (
      <div
        className={'align-self-center text-center '}
        style={{width: '400px'}}
        key={'fC-useSwitch'}
      >
        <FormControl component='fieldset' variant='standard'>
          <FormLabel
            component='legend'
            className={'m-0 fw-bold text-dark'}
            style={{fontSize: '18px'}}
          >
            <u>사용량 존재여부</u>
          </FormLabel>
          <FormGroup>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography>전체</Typography>
              <CustomSwitch
                inputProps={{'aria-label': 'ant design'}}
                onChange={(e) => setFilter({...filter, flag: e.target.checked})}
                defaultValue={filter?.flag}
              />
              <Typography>사용량 존재</Typography>
            </Stack>
          </FormGroup>
        </FormControl>
      </div>
    ),
    timeRange: (
      <div id={'time-range'} className={'col-lg-2 d-flex gap-3'} key={'fC-timeRange'}>
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label'>시작시간</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={filter?.start}
            label='시작시간'
            onChange={async (e) => {
              if (e.target.value > filter?.end) {
                await validationAlert('시작시간은 마지막시간보다 작아야 합니다.', 'warning')
                return
              }
              setFilter({...filter, start: e.target.value})
            }}
            defaultValue={0}
          >
            {_.map(_.range(0, 24), (el, i) => {
              return (
                <MenuItem value={el} key={i}>
                  {el < 10 ? `0${el}` : el}시
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <span className={'mx-1 align-self-center'}>~</span>
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label'>마지막시간</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={filter?.end}
            label='마지막시간'
            onChange={(e: any) => setFilter({...filter, end: e.target.value})}
            defaultValue={6}
          >
            {_.map(_.range(filter?.start, 24), (el, i) => {
              return (
                <MenuItem value={el} key={i}>
                  {el < 10 ? `0${el}` : el}시
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    ),
    checkStatus: (
      <FormControl
        className={'col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-sm-5'}
        key={'fC-checkStatus'}
      >
        <InputLabel id='demo-simple-select-label'>검수상태</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='checkStatus'
          label='검수상태'
          defaultValue={filter.checkStatus}
          onChange={(e) => {
            setFilter({...filter, checkStatus: e.target.value})
          }}
        >
          <MenuItem value={'all'}>전체</MenuItem>
          <MenuItem value={'none'}>NONE</MenuItem>
          <MenuItem value={'pass'}>PASS</MenuItem>
          <MenuItem value={'fail'}>FAIL</MenuItem>
        </Select>
      </FormControl>
    ),
    betweenRange: (
      <Tooltip
        title={<span className={'fs-6 fw-bolder '}>시작날짜와 종료날짜를 모두 선택해주세요.</span>}
        leaveDelay={1000}
        arrow={true}
        placement={'top'}
        TransitionComponent={Fade}
        TransitionProps={{timeout: 600}}
        key={'betweenRange'}
      >
        <div id={'date-range'} className={'col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-5'}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{start: '검색시작', end: '검색종료'}}
          >
            <DateRangePicker
              className={'justify-content-center '}
              value={date}
              mask='____/__/__'
              inputFormat={'YYYY/MM/DD'}
              onChange={(newValue: any) => {
                setDate(newValue)
                setFilter({
                  ...filter,
                  startDate: newValue[0]?.format('YYYY-MM-DD'),
                  endDate: newValue[1]?.format('YYYY-MM-DD'),
                })
              }}
              renderInput={(startProps, endProps) => {
                return (
                  <React.Fragment>
                    <TextField {...startProps} autoComplete={'off'} />
                    <Box sx={{mx: 1}}> ~ </Box>
                    <TextField {...endProps} autoComplete={'off'} />
                  </React.Fragment>
                )
              }}
            />
          </LocalizationProvider>
        </div>
      </Tooltip>
    ),
    deviceDateRange: (
      <Fragment key={'fC-deviceDateRange'}>
        <div id={'date-range'} className={'col-lg-2'}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{start: '검색 시작기준', end: '검색 종료기준'}}
          >
            <DateRangePicker
              className={'justify-content-center '}
              value={date}
              onChange={(newValue: any) => {
                setDate(newValue)
                setFilter({
                  ...filter,
                  startDate: newValue[0]?.format('YYYYMMDD'),
                  endDate: newValue[1]?.format('YYYYMMDD'),
                })
              }}
              disableFuture
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{mx: 1}}> ~ </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </div>
        <Button
          variant={'outlined'}
          size={'large'}
          onClick={() => {
            if (window.location.href?.includes('task')) {
              setDate([dayjs().subtract(1, 'month'), dayjs()])
              setFilter({
                ...filter,
                startDate: dayjs().subtract(1, 'month').format('YYYYMMDD'),
                endDate: dayjs().format('YYYYMMDD'),
              })
            } else {
              setDate([dayjs(), dayjs()])
              setFilter({
                ...filter,
                startDate: dayjs().format('YYYYMMDD'),
                endDate: dayjs().format('YYYYMMDD'),
              })
            }
          }}
        >
          <RefreshIcon sx={{width: 24, height: 24}} />
        </Button>
      </Fragment>
    ),
    reason: (
      <FormControl className={'col-lg-1'} key={'fC-reason'}>
        <InputLabel id='demo-simple-select-label'>원인명</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='customer-target'
          label='원인명'
          defaultValue={filter.cause}
          onChange={(e) => {
            setFilter({
              ...filter,
              cause: e.target.value,
            })
          }}
        >
          <MenuItem value={'all'}>전체</MenuItem>
          {_.map(causeList, (el, i) => {
            return (
              <MenuItem value={el.codeIdx} key={i}>
                {el.codeName}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    ),
    solution: (
      <FormControl className={'col-lg-1'} key={'fC-solution'}>
        <InputLabel id='demo-simple-select-label'>해결명</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='customer-target'
          label='배터리 상태'
          defaultValue={filter.solution}
          onChange={(e) => {
            setFilter({
              ...filter,
              solution: e.target.value,
            })
          }}
        >
          <MenuItem value={'all'}>전체</MenuItem>
          {_.map(solutionList, (el, i) => {
            return (
              <MenuItem value={el.codeIdx} key={i}>
                {el.codeName}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    ),
    result: (
      <FormControl className={'col-lg-1'} key={'fC-result'}>
        <InputLabel id='demo-simple-select-label'>결과</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='customer-target'
          label='결과'
          defaultValue={filter?.result}
          onChange={(e) => {
            setFilter({
              ...filter,
              result: e.target.value,
            })
          }}
        >
          <MenuItem value={'all'}>전체</MenuItem>
          <MenuItem value={'true'}>해결</MenuItem>
          <MenuItem value={'false'}>미해결</MenuItem>
        </Select>
      </FormControl>
    ),
    role: (
      <FormControl className={'col-lg-1'} key={'fC-role'}>
        <InputLabel id='demo-simple-select-label'>권한 검색</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='customer-target'
          label='권한 검색'
          value={filter.accountType}
          onChange={(e) => setFilter({...filter, accountType: e.target.value})}
        >
          <MenuItem value={'all'}>전체</MenuItem>
          {accountTypeList?.map((el: any, i: number) => {
            return (
              <MenuItem key={i} value={el.accountType}>
                {el.accountTypeName}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    ),
    isActive: (
      <FormControl className={'col-lg-1'} key={'fC-isActive'}>
        <InputLabel id='demo-simple-select-label'>활성화 상태</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='customer-target'
          label='활성화 상태'
          value={filter.idActiveStatus}
          onChange={(e) => setFilter({...filter, idActiveStatus: e.target.value})}
        >
          <MenuItem value={'all'}>전체</MenuItem>
          <MenuItem value={'true'}>활성화</MenuItem>
          <MenuItem value={'false'}>비활성화</MenuItem>
        </Select>
      </FormControl>
    ),
  }
  const formActions: any = {
    delete: (
      <div className={'d-flex gap-3'} key={'fA-delete'}>
        <Button
          variant={'contained'}
          color={'error'}
          startIcon={<DeleteForeverIcon />}
          onClick={async () => {
            await onDelete()
          }}
        >
          삭제
        </Button>
      </div>
    ),
    assign: (
      <div className={'d-flex gap-3'} key={'fA-assign'}>
        <Button
          variant={'contained'}
          data-bs-toggle='modal'
          onClick={async () => {
            const myModal: any = new bootstrap.Modal(
              document.getElementById('customerAllocationModal') as HTMLElement
            )
            if (checkData?.length === 0) {
              await noDataAlert()
              return
            } else {
              myModal.show()
              setTypeName('assigned')
            }
          }}
        >
          할당
        </Button>
        <Button
          variant={'contained'}
          color={'error'}
          onClick={async () => {
            await onCancel()
          }}
        >
          할당 취소
        </Button>
      </div>
    ),
    deviceCheck: (
      <div id={'table-header-center'} className={'d-flex gap-3'} key={'fA-deviceCheck'}>
        <Button
          variant={'contained'}
          startIcon={<TaskAltIcon />}
          onClick={async () => {
            if (checkData === 0) {
              await noDataAlert()
              return
            }
            if (checkData.length >= 5000) {
              await normalAlert('5000개 이상의 단말기는 한번에 승인할 수 없습니다.')
              return
            }
            buttonHandler('pass')
          }}
        >
          PASS
        </Button>
        <Button
          variant={'contained'}
          color={'error'}
          startIcon={<HighlightOffIcon />}
          onClick={async () => {
            if (checkData === 0) {
              await noDataAlert()
              return
            }
            if (checkData.length >= 5000) {
              await normalAlert('5000개 이상의 단말기는 한번에 미승인 할 수 없습니다.')
              return
            }
            buttonHandler('fail')
          }}
        >
          FAIL
        </Button>
      </div>
    ),
    deviceAssign: (
      <div id={'table-header-end'} className={'d-flex gap-3'} key={'fA-deviceAssign'}>
        <Button
          className={'mx-1'}
          variant={'contained'}
          color={'primary'}
          startIcon={<OfflinePinIcon />}
          data-bs-toggle={'offcanvas'}
          data-bs-target='#allocationBatchRegister'
          aria-controls='allocationBatchRegister'
        >
          일괄등록
        </Button>
        <Button
          variant={'contained'}
          startIcon={<TaskAltIcon />}
          onClick={async () => {
            const myModal: any = new bootstrap.Modal(
              document.getElementById('deviceAllocationModal') as HTMLElement
            )
            if (checkData.length >= 5000) {
              await normalAlert('5000개 이상은 할당할 수 없습니다.')
              return
            }
            if (checkData.length === 0) {
              await noDataAlert()
              return
            } else {
              myModal.show()
              setTypeName('assigned')
            }
          }}
        >
          할당
        </Button>
        <Button
          variant={'contained'}
          color={'error'}
          startIcon={<HighlightOffIcon />}
          onClick={async () => {
            if (checkData.length >= 5000) {
              await normalAlert('5000개 이상은 한번에 할당 취소를 할 수 없습니다.')
              return
            }
            if (checkData.length === 0) {
              await noDataAlert()
              return
            } else {
              await onCancel()
            }
          }}
        >
          할당 취소
        </Button>
      </div>
    ),
    deviceDelete: (
      <Button
        key={'fA-deviceDelete'}
        variant={'contained'}
        color={'error'}
        startIcon={<DeleteIcon />}
        onClick={async () => {
          if (checkData === 0) {
            await noDataAlert()
            return
          }
          if (checkData.length >= 5000) {
            await normalAlert('5000개 이상의 단말기는 한번에 삭제할 수 없습니다.')
            return
          }
          buttonHandler('delete')
        }}
      >
        삭제
      </Button>
    ),

    download: (
      <div id={'table-header-end'} key={'fA-download'}>
        <Button
          variant={'contained'}
          className={'mx-1'}
          color={'success'}
          startIcon={<DownloadIcon />}
          data-bs-toggle={'modal'}
          data-bs-target='#downloadModal'
          aria-controls='downloadModal'
        >
          다운로드
        </Button>
      </div>
    ),
    as: (
      <div className={'d-flex col-lg-3 gap-3'} id={'table-header-end'} key={'fA-as'}>
        <FormControl className={'col-lg'} size={'small'}>
          <InputLabel id='reason-select-label'>원인을 골라주세요.</InputLabel>
          <Select
            labelId='reason'
            id='reason-select'
            label='원인을 골라주세요.'
            value={asReasonData}
            onChange={(e) => setAsReasonData(e.target.value)}
          >
            {_.map(causeList, (el, i) => {
              return (
                <MenuItem value={el?.codeIdx} key={i}>
                  {el?.codeName}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <Button
          variant={'outlined'}
          color={'error'}
          onClick={async () => {
            await requestAs(userInfo, checkData, asReasonData, {
              setCheckData,
              setSelectionModel,
              searchCallback,
              setAsReasonData,
            })
          }}
        >
          A/S
        </Button>
        <Button
          variant={'outlined'}
          color={'error'}
          onClick={async () => {
            await requestAsCancel(userInfo, checkData, {
              setCheckData,
              setSelectionModel,
              searchCallback,
            })
          }}
        >
          A/S 취소
        </Button>
      </div>
    ),
    asCancel: (
      <Button
        key={'fA-asCancle'}
        variant={'outlined'}
        className={'mx-1'}
        color={'error'}
        startIcon={<BuildIcon />}
        onClick={() => {
          onCancel()
        }}
      >
        A/S 취소
      </Button>
    ),
    asAssign: (
      <div id={'asAssign'} key={'fA-asAssign'}>
        <Button
          className={'mx-1'}
          variant={'contained'}
          color={'primary'}
          startIcon={<OfflinePinIcon />}
          data-bs-toggle={'offcanvas'}
          data-bs-target='#asBatchRegister'
          aria-controls='asBatchRegister'
        >
          일괄등록
        </Button>
        <Button
          variant={'contained'}
          className={'mx-1'}
          color={'primary'}
          startIcon={<OfflinePinIcon />}
          data-bs-toggle='modal'
          onClick={async () => {
            const myModal: any = new bootstrap.Modal(
              document.getElementById('asAllocationModal') as HTMLElement
            )
            if (checkData.length === 0) {
              await noDataAlert()
              return
            } else {
              if (
                _.some(checkData, (el) => el.resultIdx !== null && typeof el.resultIdx === 'string')
              ) {
                await normalAlert('AS 취소할 수 없는 AS가 포함되어 있습니다.')
                return
              }
              myModal.show()
            }
          }}
        >
          할당
        </Button>
        <Button
          variant={'contained'}
          className={'mx-1'}
          color={'error'}
          startIcon={<UnpublishedIcon />}
          onClick={() => {
            onAssignCancel()
          }}
        >
          할당취소
        </Button>
      </div>
    ),
    recycle: (
      <Button
        key={'fA-recycle'}
        variant={'contained'}
        className={'mx-1 fw-bold fs-4'}
        color={'secondary'}
        startIcon={<ReplayIcon />}
        onClick={() => buttonHandler()}
      >
        재사용
      </Button>
    ),
    create: (
      <div className={'d-flex gap-3'} key={'fA-create'}>
        <button
          id={'registerCollapseBtn'}
          className={'btn btn-primary align-self-center fw-bold'}
          onClick={() => onCreateUserModalOpen()}
        >
          계정등록
        </button>
        <button
          type='button'
          className='btn btn-info align-self-center fw-bold'
          data-bs-toggle='modal'
          data-bs-target='#addAccountType'
        >
          계정타입 설정
        </button>
      </div>
    ),

    leak: (
      <Fragment key={'fA-leak'}>
        <div className={'d-flex align-items-center gap-4'}>
          <div className={'d-flex flex-column align-items-center'}>
            {!props.loading ? (
              <>
                <span className={'fs-3'}>
                  전체 : <strong>{total}</strong>건 중 누수 의심 <strong>{errorCount}</strong> 건
                </span>
                {/* <span className={'fs-3'}>
                  전체 : <strong>{total}</strong> 건
                </span> */}
              </>
            ) : (
              <span className={'fs-3'}>
                <strong>검색중..</strong>
              </span>
            )}
          </div>
          {!props.loading && (
            <Button
              type='button'
              variant={'contained'}
              className={`align-self-center fw-bold`}
              onClick={() => {
                !props.loading && asValue?.setLeakModalOpen(true)
              }}
            >
              {!props.loading ? '확인이 필요한 수용가' : '검색중..'}
            </Button>
          )}
        </div>
        {!props.loading && (
          <div className={'d-flex gap-4 align-items-center'}>
            <div>
              <FormControl component='fieldset' variant='standard'>
                <FormLabel
                  component='legend'
                  className={'m-0 fw-bold text-dark'}
                  style={{fontSize: '18px', textAlign: 'center'}}
                >
                  <u>이미지 열 추가</u>
                </FormLabel>
                <FormGroup
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <Stack direction='row' spacing={1} alignItems={'center'}>
                    <Typography>X</Typography>
                    <CustomSwitch
                      inputProps={{'aria-label': 'ant design'}}
                      onChange={(e) => setFilter({...filter, flag: e.target.checked})}
                      defaultValue={filter?.flag}
                    />
                    <Typography>O</Typography>
                  </Stack>
                </FormGroup>
              </FormControl>
            </div>

            <Button
              variant={'outlined'}
              color={'success'}
              startIcon={<ListAltIcon sx={{width: 24, height: 24}} />}
              onClick={() => {
                asValue?.excelDownLoad(filter)
              }}
            >
              Download
            </Button>
          </div>
        )}

        {asValue?.officeName !== '전체' && asValue?.officeName !== '영아이티' ? (
          <div className={'d-flex gap-3'}>
            <span className={'fs-4 text-primary fw-bolder'}>
              <span>{asValue?.officeName}</span>의 누수 의심 설정값은
              <span className={'text-black'}> {asValue?.leakUseCycle} </span>
              <span className={'text-black'}>
                {' '}
                {asValue?.leakUseStandard}
                {asValue?.leakUseUnit}{' '}
              </span>
              입니다.
            </span>
          </div>
        ) : (
          <div className={'d-flex gap-3'}>
            <span className={'fs-4 text-primary fw-bolder'}>사업소를 선택해주세요.</span>
          </div>
        )}
      </Fragment>
    ),
    unused: (
      <Fragment key={'fA-unused'}>
        <div className={'d-flex align-items-center gap-4'}>
          <div className={'d-flex flex-column align-items-center'}>
            <span className={'fs-3'}>
              전체 : <strong>{total}</strong> 건
            </span>
          </div>
        </div>

        {asValue?.officeName !== '전체' && asValue?.officeName !== '영아이티' ? (
          <div className={'d-flex gap-3'}>
            <span className={'fs-4 text-primary fw-bolder'}>
              <span>{asValue?.officeName}</span>의 장기미사용 설정값은
              <span className={'text-black'}> {asValue?.longtermStandard}일</span>
              입니다.
            </span>
          </div>
        ) : (
          <div className={'d-flex gap-3'}>
            <span className={'fs-4 text-primary fw-bolder'}>사업소를 선택해주세요.</span>
          </div>
        )}
      </Fragment>
    ),

    asAssignAll: (
      <Button
        key={'fA-asAssignAll'}
        variant={'contained'}
        color={'primary'}
        data-bs-toggle={'offcanvas'}
        data-bs-target='#asBatchRegister'
        aria-controls='asBatchRegister'
      >
        일괄등록
      </Button>
    ),
    blank: (
      <Fragment key={'fA-blank'}>
        <div
          onClick={() => setHiddenClick(!hiddenClick)}
          className={'d-flex align-items-center gap-4'}
          style={{width: '300px', height: '36px'}}
        >
          &nbsp;
        </div>
      </Fragment>
    ),
  }

  const searchOptionsControl: any = {
    customerNumber: (
      <FormControlLabel
        key={'sO-customerNumber'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                searchOption: {
                  ...filter.searchOption,
                  customerNumber: e.target.checked,
                },
              })
            }}
          />
        }
        label='수용가번호'
      />
    ),
    customerName: (
      <FormControlLabel
        key={'sO-customerName'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                searchOption: {
                  ...filter.searchOption,
                  customerName: e.target.checked,
                },
              })
            }}
          />
        }
        label='이름'
      />
    ),
    address: (
      <FormControlLabel
        key={'sO-address'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                searchOption: {
                  ...filter.searchOption,
                  address: e.target.checked,
                },
              })
            }}
          />
        }
        label='주소'
      />
    ),
    installerId: (
      <FormControlLabel
        key={'sO-installerId'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                searchOption: {
                  ...filter.searchOption,
                  installId: e.target.checked,
                },
              })
            }}
          />
        }
        label='설치자 ID'
      />
    ),
    installerName: (
      <FormControlLabel
        key={'sO-installerName'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                searchOption: {
                  ...filter.searchOption,
                  installerName: e.target.checked,
                },
              })
            }}
          />
        }
        label='설치자 이름'
      />
    ),
    managerName: (
      <FormControlLabel
        key={'sO-managerName'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                searchOption: {
                  ...filter.searchOption,
                  managerName: e.target.checked,
                },
              })
            }}
          />
        }
        label='담당자'
      />
    ),
    serialNumber: (
      <FormControlLabel
        key={'sO-serialNumber'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                searchOption: {
                  ...filter.searchOption,
                  serialNumber: e.target.checked,
                },
              })
            }}
          />
        }
        label='단말기 SN'
      />
    ),
    imei: (
      <FormControlLabel
        key={'sO-imei'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                searchOption: {
                  ...filter.searchOption,
                  imei: e.target.checked,
                },
              })
            }}
          />
        }
        label='IMEI'
      />
    ),
    memo: (
      <FormControlLabel
        key={'sO-memo'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                searchOption: {
                  ...filter.searchOption,
                  memo: e.target.checked,
                },
              })
            }}
          />
        }
        label='메모'
      />
    ),
    meterDiameter: (
      <FormControlLabel
        key={'sO-meterDiameter'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                searchOption: {
                  ...filter.searchOption,
                  meterDiameter: e.target.checked,
                },
              })
            }}
          />
        }
        label='구경'
      />
    ),
    deviceVersion: (
      <FormControlLabel
        key={'sO-deviceVerision'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({...filter, deviceVersion: e.target.checked})
            }}
          />
        }
        label='단말기 펌웨어'
      />
    ),
    complete: (
      <FormControlLabel
        key={'sO-complete'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                imageSearchOption: {
                  ...filter.imageSearchOption,
                  complete: e.target.checked,
                },
              })
            }}
          />
        }
        label='정상처리'
      />
    ),
    fail: (
      <FormControlLabel
        key={'sO-fail'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                imageSearchOption: {
                  ...filter.imageSearchOption,
                  fail: e.target.checked,
                },
              })
            }}
          />
        }
        label='인식실패'
      />
    ),
    digit: (
      <FormControlLabel
        key={'sO-digit'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                imageSearchOption: {
                  ...filter.imageSearchOption,
                  digit: e.target.checked,
                },
              })
            }}
          />
        }
        label='자리수 인식 오류'
      />
    ),
    wait: (
      <FormControlLabel
        key={'sO-wait'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                imageSearchOption: {
                  ...filter.imageSearchOption,
                  wait: e.target.checked,
                },
              })
            }}
          />
        }
        label='인식 대기중'
      />
    ),
    imgerr: (
      <FormControlLabel
        key={'sO-imgerr'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                imageSearchOption: {
                  ...filter.imageSearchOption,
                  imgerr: e.target.checked,
                },
              })
            }}
          />
        }
        label='비정상 이미지'
      />
    ),
    fix: (
      <FormControlLabel
        key={'sO-fix'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                imageSearchOption: {
                  ...filter.imageSearchOption,
                  fix: e.target.checked,
                },
              })
            }}
          />
        }
        label='검침값이 수정된 이미지'
      />
    ),
    asSerialNumber: (
      <FormControlLabel
        key={'sO-asSerialNumber'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                searchOption: {
                  ...filter.searchOption,
                  asSerialNumber: e.target.checked,
                },
              })
            }}
          />
        }
        label='AS 단말기S/N'
      />
    ),
    asName: (
      <FormControlLabel
        key={'sO-asName'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                searchOption: {
                  ...filter.searchOption,
                  asName: e.target.checked,
                },
              })
            }}
          />
        }
        label='A/S기사 이름'
      />
    ),
    asId: (
      <FormControlLabel
        key={'sO-asId'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                searchOption: {
                  ...filter.searchOption,
                  asId: e.target.checked,
                },
              })
            }}
          />
        }
        label='A/S기사 ID'
      />
    ),
    firmware: (
      <FormControlLabel
        key={'sO-firmware'}
        control={
          <Checkbox
            onChange={(e) => {
              setFilter({
                ...filter,
                searchOption: {
                  ...filter.searchOption,
                  deviceVersion: e.target.checked,
                },
              })
            }}
          />
        }
        label='펌웨어'
      />
    ),
    userId: (
      <FormControlLabel
        key={'sO-userId'}
        control={
          <Checkbox
            onChange={(e) =>
              setFilter({
                ...filter,
                searchOption: {
                  ...filter.searchOption,
                  id: !filter.searchOption.id,
                },
              })
            }
          />
        }
        label='ID로 검색'
      />
    ),
    userName: (
      <FormControlLabel
        key={'sO-userName'}
        control={
          <Checkbox
            onChange={(e) =>
              setFilter({
                ...filter,
                searchOption: {
                  ...filter.searchOption,
                  name: !filter.searchOption.name,
                },
              })
            }
          />
        }
        label='이름으로 검색'
      />
    ),
  }
  return (
    <div className='card flex-grow-1' id={id}>
      <div className='card-header '>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>
            {headerDescription}
            <CustomWidthTooltip
              title={tooltipText}
              TransitionComponent={Fade}
              TransitionProps={{timeout: 600}}
              placement={'right-start'}
              arrow={true}
              sx={{fontSize: '10px', letterSpacing: '1px', cursor: 'pointer'}}
            >
              <HelpIcon
                color={'primary'}
                sx={{fontSize: '24px'}}
                className={'ms-4 mb-2 cursor-pointer'}
              />
            </CustomWidthTooltip>
          </h3>
        </div>
      </div>
      {pannel}
      <div className='card-body p-9'>
        <div className={'container-fluid'}>
          <div className={'d-flex gap-3'} id={id + '-control-section'}>
            {hideSearchOptions !== true && (
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{timeout: 600}}
                title={<span className={'fs-8'}>검색항목을 접거나 펼칠 수 있습니다.</span>}
              >
                <Button
                  variant={'outlined'}
                  size={'large'}
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target={`#filterSection-${id}`}
                  aria-expanded='false'
                  aria-controls={`filterSection-${id}`}
                  className={'pulse pulse-primary'}
                >
                  <MenuIcon sx={{width: 24, height: 24}} />
                  <span className={'pulse-ring  border-4'} />
                </Button>
              </Tooltip>
            )}
            <TextField
              variant={'outlined'}
              id={'search-field'}
              placeholder={'검색어를 입력하세요.'}
              autoComplete={'off'}
              value={filter.searchData}
              onChange={(e) => setFilter({...filter, searchData: e.target.value})}
              onKeyDown={async (e) => {
                if (e.key === 'Enter') {
                  await searchCallback()
                }
              }}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='resetBtn'
                      onClick={async () => {
                        await setFilter({...filter, searchData: ''})
                        setTimeout(() => {
                          document.getElementById('search-btn')?.click()
                        }, 100)
                      }}
                      edge='end'
                    >
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{timeout: 600}}
              title={
                <span className={'fs-8'}>
                  검색항목을 이용해 정확한 검색 결과를 얻을 수 있습니다.
                </span>
              }
            >
              <Button
                variant={'contained'}
                id={'search-btn'}
                size={'large'}
                type='button'
                onClick={async () => {
                  await searchCallback()
                }}
              >
                <SearchIcon sx={{width: 24, height: 24}} />
              </Button>
            </Tooltip>
            {control?.map((controlName: any) => formControls[controlName])}
          </div>
          <div className={'row row-cols-2 mt-5'} id={'content'}>
            {!hideSearchOptions && (
              <div
                id={`filterSection-${id}`}
                className='col-lg-2 accordion-collapse collapse show '
                aria-labelledby={`filterSection-${id}`}
                data-bs-parent={`#filterSection-${id}`}
              >
                <div className='accordion-body'>
                  <div className={' border border-secondary  rounded mt-3 ps-3 py-3'}>
                    <div
                      className={'card-header p-3 justify-content-center'}
                      style={{minHeight: 'auto'}}
                    >
                      <h3 className='fw-bolder m-0'>검색항목</h3>
                    </div>
                    <FormGroup className={'row gap-3 py-2'}>
                      {searchOptions?.map(
                        (searchOptionName: any) => searchOptionsControl[searchOptionName]
                      )}
                    </FormGroup>
                  </div>
                </div>
              </div>
            )}

            <div id={id + '-table-section'} className={'col-lg mt-3'}>
              <div
                id={'table-header'}
                className={'d-flex my-2 justify-content-between align-items-center'}
              >
                {actions[0] !== 'leak' && actions[0] !== 'unused' && (
                  <div>
                    <span className={'fs-3'}>
                      전체 : <strong>{total}</strong> 개
                    </span>
                  </div>
                )}
                {actions?.map((actionName: any) => formActions[actionName])}
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MeterariumPageLayout
