import React from 'react'
// @ts-ignore
import {Splide, SplideSlide} from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import {Button, Card, CardActions, CardContent, CardMedia} from '@mui/material'
import Typography from '@mui/material/Typography'
import {yitHome, officeHome, hycheckHome, yitBanner1, yitBanner5} from 'assets/imageDeclear'
import './../css/main.css'
const AdminDashboard = () => {
  const element = document.getElementById('dashboard-section')
  let width: number = 0

  if (element) {
    width = element.clientWidth / 3
  }
  return (
    <div className=' dashboard-bg-gradient w-100 h-100 d-flex align-items-center '>
      <Splide
        options={{rewind: true, autoplay: true, interval: 3000, width: '100%'}}
        aria-label='meterarium-dashboard'
      >
        <SplideSlide className={'px-8 d-flex gap-4 py-2 '}>
          <Card sx={{width: width}} elevation={4}>
            <CardMedia sx={{height: 300}} image={yitHome} title='yit-home' />
            <CardContent style={{height: 200}}>
              <Typography gutterBottom variant='h5' component='div'>
                영아이티 소개
              </Typography>
              <Typography variant='body1' color='text.secondary'>
                미터리움을 통해 스마트한 미터링과 검침의 미래를 경험해보세요. 신속하고 정확한 검침
                서비스로 더욱 효율적인 에너지 관리를 실현하세요.
              </Typography>
            </CardContent>
            <CardActions style={{position: 'absolute', bottom: 0}}>
              <Button
                size='large'
                onClick={() => {
                  window.open('https://young-it.co.kr', '_blank')
                }}
              >
                바로가기
              </Button>
            </CardActions>
          </Card>
          <Card sx={{width: width}} elevation={4}>
            <CardMedia sx={{height: 300}} image={officeHome} title='office-home' />
            <CardContent style={{height: 200}}>
              <Typography gutterBottom variant='h5' component='div'>
                오피스
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                영아이티 주식회사 업무포털
              </Typography>
            </CardContent>
            <CardActions style={{position: 'absolute', bottom: 0}}>
              <Button
                size='large'
                onClick={() => {
                  window.open('http://office.wicap.co.kr:9080/sign/login.do', '_blank')
                }}
              >
                바로가기
              </Button>
            </CardActions>
          </Card>
          <Card sx={{width: width}} elevation={4}>
            <CardMedia sx={{height: 300}} image={hycheckHome} title='hycheck-home' />
            <CardContent style={{height: 200}}>
              <Typography gutterBottom variant='h5' component='div'>
                HY•Check
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                MDS인텔리전스 하이체크 서비스
              </Typography>
            </CardContent>
            <CardActions style={{position: 'absolute', bottom: 0}}>
              <Button
                size='large'
                onClick={() => {
                  window.open('https://hycheck.neoidm.com/#/login', '_blank')
                }}
              >
                바로가기
              </Button>
            </CardActions>
          </Card>
        </SplideSlide>
        <SplideSlide className={'px-8 d-flex gap-4 py-2 '}>
          <img src={yitBanner1} alt='yit-banner1' width={'100%'} className={'rounded-3'} />
        </SplideSlide>
        <SplideSlide className={'px-8 d-flex py-2 '}>
          <img
            src={yitBanner5}
            alt='yit-banner2'
            width={'100%'}
            className={'rounded-3 border border-2 border-white'}
          />
        </SplideSlide>
      </Splide>
    </div>
  )
}

export default AdminDashboard
