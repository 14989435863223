import {Visibility, VisibilityOff} from '@mui/icons-material'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Popper,
  Select,
  TextField,
} from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {meterariumLogo, youngitLogo} from 'assets/imageDeclear'
import domToImage from 'dom-to-image'
import {envBackHost} from 'GLOBAL/envVariables'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import _ from 'lodash'
import React, {useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {normalAlert, resultAlert, validationAlert} from 'utils/CustomAlert/Alert'
import {hashPasswordParser} from 'utils/func/justUtils'
import {isEmail, isId, isPhone, isPw} from 'utils/validation/validation'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: 900,
  bgcolor: 'background.paper',
  overflowY: 'auto',
  borderRadius: '12px',
  boxShadow: 24,
  p: 4,
}
interface State {
  password: string
  checkPassword: string
  showPassword: boolean
}
const CreateUserModal = (props: any) => {
  const {open, close, accountTypeList, officeList, cooperList} = props
  const userInfo = useSelector((state: any) => state.userInfo.userInfo)
  const cardRef = useRef<any>(null)
  const [idCheck, setIdCheck] = useState<boolean>(false)
  const [pwForm, setPwForm] = useState<any>(false)
  const [pw, setPw] = useState<State>({
    password: '',
    checkPassword: '',
    showPassword: false,
  })
  const [submitData, setSubmitData] = useState<any>({
    id: '',
    password: pw.password,
    name: '',
    phoneNumber: '',
    email: '',
    accountType: '',
    idActiveStatus: true,
    officeId: '',
    cooperIdx: '',
    team: '영아이티설치',
    memo: '',
    imageUrl: 'https://young-it.co.kr/static/media/yit.c1235638b6cfc4f91884.jpg',
    createId: userInfo?.id,
  })
  const idValidation = (e: any) => {
    const {value} = e.target
    setSubmitData({...submitData, id: value})
  }
  const handleChange = (prop: keyof State, e: any) => {
    const {value} = e.target
    if (isPw(value)) {
      setPwForm(true)
      setPw({...pw, [prop]: e.target.value})
      setSubmitData({...submitData, password: pw.password})
    } else {
      setPwForm(false)
    }
  }
  const handleClickShowPassword = () => {
    setPw({
      ...pw,
      showPassword: !pw.showPassword,
    })
  }
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  const reset = () => {
    setSubmitData({
      id: '',
      password: '',
      name: '',
      phoneNumber: '',
      email: '',
      accountType: '',
      idActiveStatus: true,
      officeId: '',
      cooperIdx: '',
      team: '영아이티설치',
      memo: '',
      imageUrl: 'https://young-it.co.kr/static/media/yit.c1235638b6cfc4f91884.jpg',
      createId: userInfo?.id,
    })
    setPw({
      password: '',
      checkPassword: '',
      showPassword: false,
    })
    setIdCheck(false)
  }
  const duplicateInspectionBtn = async () => {
    try {
      await axiosWithAuth()
        .get(`${envBackHost}/idcheck/${submitData.id}`)
        .then(async (res) => {
          const result = res?.data?.res
          if (result === true) {
            await validationAlert('중복 아이디입니다.', 'error')
            setIdCheck(false)
            return
          } else {
            await validationAlert('사용 가능한 아이디 입니다.', 'success')
            setIdCheck(true)
            return
          }
        })
    } catch (e: any) {
      await resultAlert({resultCode: e.response.status})
    }
  }
  const CustomPopper = (props: any) => {
    return <Popper {...props} placement='top' />
  }

  const buttonHandler = async () => {
    if (submitData.officeId === 'all' || submitData.officeId === '') {
      await normalAlert('전체사업소로는 선택할 수 없습니다. 관리자의 경우 영아이티로 설정해주세요.')
      return
    }
    if (!isId(submitData.id)) {
      await normalAlert('아이디 형식이 맞지 않습니다.')
      return
    }
    if (!isPw(submitData.password)) {
      await normalAlert('비밀번호 형식이 맞지 않습니다.')
      return
    }
    if (!isEmail(submitData.email)) {
      await normalAlert('이메일 형식이 맞지 않습니다.')
      return
    }
    if (!isPhone(submitData.phoneNumber)) {
      await normalAlert('연락처 형식이 맞지 않습니다.')
      return
    }
    if (
      submitData.name === '' ||
      submitData.password === '' ||
      submitData.phoneNumber === '' ||
      submitData.email === '' ||
      submitData.accountType === ''
    ) {
      await normalAlert('필수입력값을 모두 채워주세요.')
      return
    }
    if (pw.checkPassword !== pw.password) {
      await normalAlert('비밀번호가 일치 하지않습니다.')
      return
    }
    if (pwForm === false) {
      await normalAlert('비밀번호 형식이 맞지 않습니다.')
      return
    }

    const pwParsing = await hashPasswordParser(submitData.id, submitData.password)
    const convertSubmitData = {
      ...submitData,
      password: pwParsing,
    }

    axiosWithAuth()
      .post(`${envBackHost}/account`, convertSubmitData)
      .then(async (res) => {
        await resultAlert(res.data)
        if (res.data.resultCode === 0) {
          await close()
          await reset()
          return
        }
      })
      .catch(async (e) => {
        await resultAlert({resultCode: e.response.status})
      })
  }

  const getAccountTypeName = (accountType: any, accountList: any) => {
    const accountItem = accountList?.find((item: any) => item.accountType === accountType)
    return accountItem ? accountItem.accountTypeName : null
  }
  const getOfficeName = (officeId: any, officeList: any) => {
    const officeItem = officeList?.find((item: any) => item.officeId === officeId)
    return officeItem ? officeItem.officeName : null
  }
  const getCooperName = (cooperIdx: any, cooperList: any) => {
    const cooperItem = cooperList?.find((item: any) => item.idx === cooperIdx)
    return cooperItem ? cooperItem.cooperName : null
  }
  const cardDownload = async () => {
    await normalAlert('계정카드를 다운로드 합니다.')
    const element = cardRef.current // 변환하려는 DOM 요소

    domToImage
      .toPng(element)
      .then(function (dataUrl: any) {
        const link = document.createElement('a')
        link.href = dataUrl
        link.download = `${submitData?.name}-계정카드.png`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        validationAlert('계정카드 다운로드가 완료되었습니다.', 'success')
      })
      .catch(function (error: any) {
        console.error('계정카드 이미지 생성에 실패했습니다: ', error)
      })
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          close()
          reset()
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style} id={'create-user-modal-box'}>
          <div
            className={'w-100 d-flex justify-content-between mb-5'}
            id={'create-user-modal-header'}
          >
            <Chip
              label={<span>미터리움 계정생성</span>}
              variant={'outlined'}
              sx={{fontSize: '20px', fontWeight: 'bold'}}
            />
            <CancelPresentationIcon
              sx={{fontSize: '40px', cursor: 'pointer'}}
              color={'error'}
              onClick={() => {
                close()
                reset()
              }}
            />
          </div>
          <div
            className={'container-fluid  align-items-center '}
            style={{height: '90%', display: 'grid'}}
          >
            <div className={'d-flex p-4'} style={{gap: 20}}>
              <div
                className={'px-3'}
                style={{width: '45%', height: 700, overflowY: 'scroll', overflowX: 'hidden'}}
                id={'create-user-textField'}
              >
                <div className={'row gap-4 py-4 '}>
                  <div className={'row gap-1'} style={{paddingRight: 0}}>
                    <div className={'d-flex gap-3'} style={{paddingRight: 0}}>
                      <TextField
                        className={'w-100'}
                        id='id-textField'
                        disabled={idCheck}
                        label='사용자 ID를 입력해주세요.'
                        variant='outlined'
                        required
                        value={submitData.id}
                        autoComplete={'off'}
                        name={'id-check-field'}
                        onChange={(e) => {
                          idValidation(e)
                        }}
                      />
                      <Button
                        variant={!idCheck ? 'outlined' : 'contained'}
                        color={!idCheck ? 'primary' : 'error'}
                        className={'col-lg-2 fs-4'}
                        size={'small'}
                        onClick={() => (!idCheck ? duplicateInspectionBtn() : reset())}
                      >
                        {idCheck ? '초기화' : '중복검사'}
                      </Button>
                    </div>
                    {submitData.id === '' ? (
                      <span className={'text-danger fw-bold'}>
                        ID 중복검사 후 다음을 진행할 수 있습니다.
                      </span>
                    ) : (
                      <span className={'text-primary'}>영문, 숫자 3~10자리</span>
                    )}
                  </div>
                  <div className={'d-flex gap-3'}>
                    <TextField
                      required
                      id='client-realName'
                      label='이름'
                      variant='outlined'
                      autoComplete={'off'}
                      fullWidth
                      disabled={!idCheck}
                      value={submitData.name}
                      onChange={(e) =>
                        setSubmitData({
                          ...submitData,
                          name: e.target.value,
                        })
                      }
                    />
                    <FormControl fullWidth className={'px-0'}>
                      <InputLabel id='demo-simple-select-label' required>
                        권한
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        className={'px-0'}
                        id='metervision-authority'
                        value={submitData.accountType}
                        disabled={!idCheck}
                        label='권한'
                        onChange={(e) => {
                          setSubmitData({...submitData, accountType: e.target.value})
                        }}
                      >
                        {userInfo.accountType !== 'sys_admin'
                          ? accountTypeList
                              ?.filter((el: any) => el.accountType !== 'sys_admin')
                              ?.map((el: any, i: any) => (
                                <MenuItem key={i} value={el.accountType}>
                                  {el.accountTypeName}
                                </MenuItem>
                              ))
                          : accountTypeList?.map((el: any, i: any) => (
                              <MenuItem key={i} value={el.accountType}>
                                {el.accountTypeName}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={'row gap-1'} style={{paddingRight: 0}}>
                    <div className={'d-flex gap-3'} style={{paddingRight: 0}}>
                      <FormControl variant='outlined' fullWidth>
                        <InputLabel htmlFor='standard-adornment-password'>비밀번호</InputLabel>
                        <OutlinedInput
                          id='outlined-adornment-password'
                          type={pw.showPassword ? 'text' : 'password'}
                          defaultValue={pw.password}
                          name={'password'}
                          disabled={!idCheck}
                          onChange={(e: any) => handleChange('password', e)}
                          error={pw.password !== '' && !pwForm}
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge='end'
                              >
                                {pw.showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label='비밀번호'
                        />
                      </FormControl>
                      <FormControl variant='outlined' fullWidth>
                        <InputLabel htmlFor='standard-adornment-checkPassword'>
                          비밀번호 확인
                        </InputLabel>
                        <OutlinedInput
                          id='outlined-adornment-checkPassword'
                          type={pw.showPassword ? 'text' : 'Password'}
                          defaultValue={pw.checkPassword}
                          disabled={!idCheck}
                          onChange={(e: any) => handleChange('checkPassword', e)}
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge='end'
                              >
                                {pw.showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label='비밀번호 확인'
                        />
                      </FormControl>
                    </div>
                    {pw.checkPassword === '' ? (
                      <div className={'mt-1 fw-bolder'}>
                        {pw.password === '' ? (
                          <span className={'text-danger fw-bolder'}>
                            아래의 비밀번호 규칙을 먼저 읽어주세요.
                          </span>
                        ) : pwForm ? (
                          <span className={'text-success'}> 올바른 비밀번호 형식입니다.</span>
                        ) : (
                          <span className={'text-danger'}> 잘못된 비밀번호 형식입니다.</span>
                        )}
                      </div>
                    ) : pw.checkPassword === pw.password ? (
                      <div className={'mt-1 fw-bolder'}>
                        <span className={'text-success'}> 비밀번호가 일치합니다.</span>
                      </div>
                    ) : (
                      <div className={'mt-1 fw-bolder'}>
                        <span className={'text-danger'}> 비밀번호가 일치하지 않습니다.</span>
                      </div>
                    )}
                    {pw.password === '' && (
                      <div className={'row my-4'}>
                        <span className={'text-primary fw-bolder'}>
                          영문 대소문자, 숫자, 특수문자(!@#$%^&*)로 구성
                        </span>
                        <span className={'text-primary fw-bolder'}>
                          특수문자는 !@#$%^&* 만 허용
                        </span>
                      </div>
                    )}
                  </div>
                  <div className={'d-flex gap-3'}>
                    <TextField
                      required
                      id='customer-address'
                      label='연락처'
                      placeholder={'Ex) 01012345678'}
                      variant='outlined'
                      disabled={!idCheck}
                      fullWidth
                      value={submitData.phoneNumber}
                      onChange={(e) => {
                        setSubmitData({
                          ...submitData,
                          phoneNumber: e.target.value,
                        })
                      }}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Email'
                      variant='outlined'
                      placeholder={'Ex) client@test.com'}
                      fullWidth
                      disabled={!idCheck}
                      value={submitData.email}
                      onChange={(e) => {
                        setSubmitData({
                          ...submitData,
                          email: e.target.value,
                        })
                      }}
                    />
                  </div>
                  <div className={'d-flex gap-3'}>
                    <Autocomplete
                      disablePortal
                      fullWidth
                      id='choose-office'
                      defaultValue={officeList[0]}
                      options={officeList}
                      disabled={!idCheck}
                      className={'px-0'}
                      disableClearable={true}
                      clearText={'초기화'}
                      noOptionsText={'검색 결과가 없습니다.'}
                      getOptionLabel={(option: any) =>
                        option.officeName + '사업소 ' + ' (' + option.officeId + ')'
                      }
                      onChange={(e, rowValue: any) => {
                        setSubmitData({
                          ...submitData,
                          officeId: rowValue?.officeId,
                        })
                      }}
                      PopperComponent={CustomPopper}
                      sx={{fontSize: '1.5rem', color: '#fff'}}
                      renderInput={(params) => (
                        <div className={'d-flex gap-3'}>
                          <TextField
                            {...params}
                            fullWidth
                            label='사업소 선택'
                            focused
                            placeholder={'검색하거나 선택할 수 있습니다.'}
                          />
                        </div>
                      )}
                    />
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>단말기 제조사</InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='customer-target'
                        label='단말기 제조사'
                        fullWidth
                        disabled={!idCheck}
                        value={submitData?.cooperIdx}
                        onChange={(e) => {
                          setSubmitData({...submitData, cooperIdx: e.target.value})
                        }}
                      >
                        {_.map(cooperList, (el, i) => {
                          return (
                            <MenuItem key={i} value={el?.idx}>
                              {el?.cooperName}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={'d-flex gap-3 align-items-center'}>
                    {submitData.imageUrl !== '' && (
                      <div style={{width: 180}} className={'border border-gray-600 rounded-2 p-2'}>
                        <img
                          src={submitData.imageUrl}
                          alt='profile'
                          className={'rounded-3'}
                          width={'100%'}
                        />
                      </div>
                    )}
                    <TextField
                      sx={{width: 400}}
                      label='이미지 URL'
                      focused
                      disabled={!idCheck}
                      placeholder={'Ex) https://young-it.co.kr/test.png'}
                      onChange={(e) => {
                        setSubmitData({...submitData, imageUrl: e.target.value})
                      }}
                      defaultValue={submitData.imageUrl}
                    />
                    <TextField label='팀' disabled focused value={submitData.team} />
                  </div>
                  <div className={'d-flex gap-3'}>
                    <TextField
                      id='outlined-basic'
                      label='메모'
                      variant='outlined'
                      disabled={!idCheck}
                      multiline
                      rows={2}
                      fullWidth
                      onChange={(e) => setSubmitData({...submitData, memo: e.target.value})}
                    />
                  </div>
                  <div
                    className={'d-flex gap-3 px-0'}
                    style={{
                      position: 'fixed',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      backgroundColor: 'white',
                    }}
                  >
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      fullWidth
                      onClick={() => buttonHandler()}
                      style={{height: 50, fontSize: 20}}
                    >
                      생성
                    </Button>
                  </div>
                </div>
              </div>
              <div
                style={{width: '55%', gap: 60}}
                className={'h-700px  '}
                id={'create-user-viewer'}
              >
                <div
                  className={'border border-gray-400 rounded-2 w-100 h-100 '}
                  style={{display: 'grid', placeItems: 'center', alignItems: 'center'}}
                >
                  <div className={'p-4'} ref={cardRef}>
                    <div
                      className={'border  border-2 border-gray-500 rounded-3 '}
                      style={{
                        width: 600,
                        height: 350,
                        backgroundColor: '#fff',
                        borderColor: '#43b0ec',
                      }}
                    >
                      <div
                        id={'create-user-card-header'}
                        className={'d-flex justify-content-between  p-3 '}
                        style={{
                          backgroundColor: '#43b0ec',
                          borderColor: '#43b0ec',
                          borderRadius: '6px 6px 0 0',
                        }}
                      >
                        <img
                          src={meterariumLogo}
                          alt='meterariumLogo'
                          className={'rounded-3'}
                          style={{width: 160}}
                        />
                        <Typography
                          variant={'h1'}
                          className={'text-white align-self-center'}
                          style={{fontWeight: 'bold', fontSize: 20}}
                        >
                          스마트 디지털 이미지 원격검침시스템
                        </Typography>
                      </div>
                      <div
                        id={'create-user-card-content'}
                        className={'border border-white'}
                        style={{
                          height: 220,
                          backgroundColor: '#fff',
                          display: 'grid',
                          alignItems: 'center',
                        }}
                      >
                        <div className={'d-flex'} style={{gap: 5, alignItems: 'center'}}>
                          <div style={{width: '15%', height: '100%'}} className={'text-center'}>
                            <AssignmentIndIcon
                              sx={{fontSize: '80px'}}
                              color={'primary'}
                              className={'p-2'}
                            />
                          </div>
                          <div style={{width: '85%'}} className={' p-5 align-self-center'}>
                            <div className={'row'}>
                              <div className={'d-flex'}>
                                <div style={{width: '50%'}}>
                                  <div className={'row fs-3 fw-bold gap-2'}>
                                    <div className={'d-flex gap-6 '}>
                                      <span> ID : </span>
                                      <span>{submitData.id}</span>
                                    </div>
                                    <div className={'d-flex gap-6 '}>
                                      <span> 이름 : </span>
                                      <span>{submitData.name}</span>
                                    </div>
                                    <div className={'d-flex gap-6 '}>
                                      <span> 권한 : </span>
                                      <span>
                                        {getAccountTypeName(
                                          submitData.accountType,
                                          accountTypeList
                                        )}
                                      </span>
                                    </div>
                                    <div className={'d-flex gap-6 '}>
                                      <span> 연락처 : </span>
                                      <span>
                                        {submitData.phoneNumber.replace(
                                          /(\d{3})(\d{4})(\d{4})/,
                                          '$1-$2-$3'
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div style={{width: '50%'}}>
                                  <div className={'row fs-3 fw-bold gap-2'}>
                                    <div className={'d-flex gap-6 '}>
                                      <span> 사업소 : </span>
                                      <span>{getOfficeName(submitData.officeId, officeList)}</span>
                                    </div>
                                    <div className={'d-flex gap-6 '}>
                                      <span> 단말기 제조사 : </span>
                                      <span>{getCooperName(submitData.cooperIdx, cooperList)}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={'d-flex gap-6 fs-3 fw-bold mt-1 '}>
                                <span> E-mail : </span>
                                <span>{submitData.email}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id={'create-user-card-footer'}
                        className={'d-flex justify-content-end border-top border-gray-200 h-50px'}
                      >
                        <img
                          src={youngitLogo}
                          alt='profile'
                          className={'rounded-3'}
                          style={{marginRight: 5, marginBottom: 5, marginTop: 10}}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <Button
                      variant={'contained'}
                      color={'success'}
                      fullWidth
                      // disabled={
                      //   submitData.id === '' ||
                      //   submitData.password === '' ||
                      //   submitData.name === '' ||
                      //   submitData.phoneNumber === '' ||
                      //   submitData.email === '' ||
                      //   submitData.accountType === '' ||
                      //   submitData.officeId === ''
                      // }
                      onClick={() => cardDownload()}
                    >
                      계정카드 다운로드
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default CreateUserModal
