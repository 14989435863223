import React, {useState} from 'react'
import './../joinPage.css'
import Logo from './../images/meterarium_new_logo.gif'
import Paper from '@mui/material/Paper'
import {Button, Checkbox, FormControlLabel, Step, StepLabel, Stepper} from '@mui/material'
import {normalAlert, validationAlert} from 'utils/CustomAlert/Alert'
import {useNavigate} from 'react-router-dom'
import Box from '@mui/material/Box'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
const AgreePage = () => {
  const [playChecked, setPlayChecked] = useState(false)
  const [agreeChecked, setAgreeChecked] = useState(false)
  const navigate = useNavigate()
  const steps = ['약관동의', '회원정보입력', '회원가입완료']
  return (
    <>
      {!isMobileDevice() && (
        <div className={'container-fluid vh-100 join-body p-3 '}>
          <div className={'join-header text-center'}>
            <img src={Logo} alt={'logo'} width={'700'} style={{borderRadius: '28px'}} />
          </div>
          <div className={'join-content'}>
            <Paper elevation={3} className={'p-5'}>
              <Box sx={{width: '100%'}} className={'my-7 '}>
                <Stepper activeStep={0} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <div className={'text-center'}>
                <h2 style={{fontSize: 35}}>약관동의</h2>
              </div>
              <div className={'my-5'}>
                <span className={'fw-bold fs-2 mx-3'}>이용약관</span>
                <div
                  style={{
                    height: '180px',
                    padding: '20px 20px 20px 20px',
                    overflowY: 'auto',
                    width: '98%',
                    boxSizing: 'border-box',
                    border: '1px solid #ccc',
                    margin: '0px 0px 0px 10px',
                    lineHeight: '20px',
                    fontSize: '15px',
                  }}
                  className={'row gap-6'}
                  tabIndex={0}
                >
                  <div className={'row gap-5'}>
                    <span className={'fw-bold fs-5'}>제1장 총칙 </span>
                    <div className={'row gap-3 ms-2'}>
                      <div className={'row'}>
                        <span>제1조 (목적)</span>
                        <span>
                          이 약관은 미터리움 원격검침시스템(이하 "서비스")의 이용조건 및 절차에 관한
                          기본적인 사항을 정함을 목적으로 합니다.
                        </span>
                      </div>
                      <div className={'row'}>
                        <span>
                          제2조 (용어의 정의)
                          <br />이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                        </span>
                        <span className={'ms-4'}>
                          1. “회사”란 미터리움 웹사이트를 운영하는 회사를 말합니다. <br />
                          2. “이용자”란 미터리움 웹사이트에 접속하여 이 약관에 따라 회사가 제공하는
                          서비스를 받는 회원 및 비회원을 말합니다.
                        </span>
                      </div>
                    </div>
                    <span className={'fw-bold fs-5'}>제2장 서비스 이용계약</span>
                    <div className={'row gap-3 ms-2'}>
                      <span>제3조 (이용계약의 성립)</span>
                      <span className={'ms-4'}>
                        1. 이용계약은 이용자의 이용신청 및 동의와 회사의 승낙의 방법으로 성립됩니다.{' '}
                        <br />
                        2. 회원은 이용약관에 동의함으로써 서비스를 이용할 수 있습니다.
                      </span>
                    </div>
                    <div className={'row gap-3 ms-2'}>
                      <span>제4조 (개인정보의 보호 및 사용)</span>
                      <span className={'ms-4'}>
                        회사는 관련법에 의거하여 이용자 등록 정보를 포함한 이용자의 개인정보를
                        보호하기 위해 노력합니다. 이용자의 개인정보 보호 및 사용에 대해서는 관련법
                        및 회사의 개인정보처리방침에 따릅니다.
                      </span>
                    </div>
                    <span className={'fw-bold fs-5'}>제3장 의무 및 권리</span>
                    <div className={'row gap-3 ms-2'}>
                      <span>제5조 (회사의 의무)</span>
                      <span className={'ms-4'}>
                        1. 회원의 개인정보는 「개인정보처리방침」에 따라 보호됩니다. <br />
                        2. 미터리움의 회원정보는 다음과 같이 사용,관리,보호 됩니다.
                        <div className={'ms-6'}>
                          <p>
                            1) 개인정보의 사용 : 미터리움은 서비스 제공과 관련해서 수집된 회원의
                            신상정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습 니다. 단,
                            전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우, 범죄에
                            대한 수사상의 목적이 있거나 정보통신윤리위원회의 요청이 있는 경우 또는
                            기타 관계법령에서 정한 절차에 따른 요청이 있는 경우, 이용자 스스로
                            개인정보를 공개한 경우에는 그러 하지 않습니다.
                          </p>
                          <p>
                            2) 개인정보의 관리 : 회원은 개인정보의 보호 및 관리를 위하여 서비스의
                            개인정보관리에서 수시로 회원의 개인정보를 수정/삭제할 수 있습니다.
                          </p>
                          <p>
                            3) 개인정보의 보호 : 회원의 개인정보는 오직 본인만이 열람/수정/삭제 할
                            수 있으며, 이는 전적으로 회원의 계정과 비밀번호에 의해 관리되고
                            있습니다. 따라서 타인에게 본인의 계정과 비밀번호를 알려주어서는 안되며,
                            작업 종료시에는 반드시 로그아웃해 주시기 바랍니다.
                          </p>
                        </div>
                      </span>
                    </div>
                    <div className={'row gap-3 ms-2'}>
                      <span>제6조 (이용자의 의무)</span>
                      <span className={'ms-4'}>
                        1. 이용자는 회사에서 정한 정보를 정확하게 제공하여야 합니다. <br />
                        2. 이용자는 서비스를 이용할 때 아래 각 호의 행위를 해서는 안됩니다.
                        <div className={'ms-6'}>
                          <p>- 법령에 위반되는 행위</p>
                          <p>- 타인의 권리를 침해하는 행위</p>
                          <p>- 회사의 영업을 방해하는 행위</p>
                        </div>
                      </span>
                    </div>
                    <span className={'fw-bold fs-5'}>제4장 기타</span>
                    <div className={'row gap-3 ms-2'}>
                      <span>제9조 (면책조항)</span>
                      <span className={'ms-4'}>
                        회사는 천재지변, 불가항력적 사건 등 비상사태로 인해 서비스를 제공할 수 없는
                        경우에는 서비스 제공에 관한 책임이 면제됩니다.
                      </span>
                    </div>
                    <div className={'row gap-3 ms-2'}>
                      <span>제10조 (분쟁해결)</span>
                      <span>
                        이 약관과 관련된 분쟁에 대해서는 대한민국의 법을 준거법으로 합니다.
                      </span>
                    </div>
                    <div className={'row gap-3 ms-2'}>
                      <span>제11조 (효력의 발생과 변경)</span>
                      <span className={'ms-4'}>
                        1. 이 약관은 이용자의 동의와 함께 효력을 발생합니다. <br />
                        2. 회사는 필요한 경우 이 약관을 변경할 수 있으며, 변경된 약관은 홈페이지에
                        공지함으로써 효력을 발생합니다.
                      </span>
                    </div>
                  </div>
                </div>
                <div className={'ps-4'}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={playChecked}
                        onChange={(e) => {
                          setPlayChecked(e.target.checked)
                        }}
                      />
                    }
                    label='위의 이용약관에 동의합니다.'
                  />
                </div>
              </div>
              <div style={{margin: '100px  0px 30px 0px'}}>
                <span className={'fw-bold fs-2 mx-3'}>영아이티주식회사 개인정보처리방침</span>
                <div
                  style={{
                    height: '180px',
                    padding: '20px 20px 20px 20px',
                    overflowY: 'auto',
                    width: '98%',
                    boxSizing: 'border-box',
                    border: '1px solid #ccc',
                    margin: '0px 0px 0px 10px',
                    lineHeight: '20px',
                    fontSize: '15px',
                  }}
                  className={'row gap-6'}
                  tabIndex={0}
                >
                  <div className={'row gap-8'}>
                    <span className={'fw-bold fs-4'}>
                      영아이티주식회사(이하 ‘회사’라고 함)는 개인정보 보호법 제30조에 따라
                      정보주체(이하 ‘이용자’라고 함) 의 개인정보를 소중하게 보호하고 이와 관련된
                      고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보
                      처리지침을 수립하여 공개합니다.{' '}
                    </span>
                    <span className={'fw-bold fs-4'}>
                      ‘회사’란 미터리움 웹사이트를 운영하는 영아이티주식회사를 말합니다.
                    </span>
                    <span className={'fw-bold fs-4'}>
                      ‘이용자’란 미터리움 웹사이트에 접속하여 본 개인정보처리방침에 따라 회사가
                      제공하는 서비스를 받는 회원 및 비회원의 정보주체를 말합니다.
                    </span>
                    <span className={'fw-bold fs-4 my-3 text-danger'}>
                      회사는 개인정보처리방침을 웹사이트 첫 화면에 공개함으로써 언제나 쉽게 확인할
                      수 있도록 하고 있습니다. 또한, 개인정보 처리방침을 개정하는 경우 웹사이트를
                      통하여 공지(또는 개별공지)할 것입니다.
                    </span>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}> 제1조 개인정보 처리 목적</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        회사는 다음의 목적을 위하여 개인정보를 처리하며, 처리하고 있는 개인정보는
                        다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는
                        개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할
                        예정입니다.
                      </span>
                      <span>
                        영아이티주식회사 미터리움 원격검침시스템(이하 ‘서비스’)의 이용조건 및 절차에
                        관한 기본적인 사항을 정함을 목적으로 하며, 회사는 서비스 제공을 위해 필요한
                        최소한의 범위 내에서 개인정보를 처리하고 있습니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>
                          1. 홈페이지 회원 가입 및 관리 회원 가입의사 확인, 회원 및 비회원 서비스
                          제공에 따른 본인 식별 · 인증, 회원 자격유지 · 관리, 제한적 본인확인제
                          시행에 따른 본인확인, 서비스 부정이용 방 지, 만 14세 미만 아동의 개인정보
                          처리시 법적 대리인의 동의여부 확인, 각종 고 지 · 통지 등을 목적으로
                          개인정보를 처리합니다.
                        </span>
                        <span>
                          2. 서비스 제공 회원가입, 문의사항 제출 등 서비스 이용과정에서 이용자가
                          직접 입력하는 방식 으로 수집됩니다.{' '}
                        </span>
                        <span>
                          3. 고충처리 민원인의 신원확인, 민원사항 확인, 사실조사를 위한 연락 · 통지,
                          처리결과 통보 등의 목적으로 개인정보를 처리합니다.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제2조 개인정보의 처리 및 보유기간</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        회사는 법령에 따른 개인정보 보유 · 이용기간 또는 이용자(정보주체) 로부터
                        개인정보를 수집시에 동의받은 개인벙보 보유 · 이용기간 내에서 개인정보를 처리
                        · 보유합니다
                      </span>
                      <span>개인정보 처리 및 보유 기간은 다음과 같습니다.</span>
                      <div className={'ms-4 row'}>
                        <span>
                          1. 홈페이지 회원가입 및 관리 : 사업자/개인/단체 홈페이지 탈퇴시까지 다만,
                          다음의 사유에 해당하는 경우에는 해당 사유 종료시까지
                        </span>
                        <span className={'ms-6'}>
                          <p>
                            1) 관계 볍령 위반에 따른 수사 · 조사 등이 진행중인 경우에는 해당 수사 ·
                            조사 종료시까지
                          </p>
                          <p>
                            2) 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 시행령 제29조에
                            따른 본인 확인 정보 보관
                          </p>
                          <p>3) 예외사유 시에는 보유기간 까지</p>
                        </span>
                      </div>
                      <div className={'ms-4 row'}>
                        <span>2. 서비스 제공</span>
                        <span className={'text-primary fw-bold'}>
                          「통신비밀보호법」 제41조에 따른 통신사실확인자료 보관
                        </span>
                        <span className={'ms-6'}>
                          <p>
                            1) 가입자 전기통신일시, 개시․종료시간, 상대방 가입자번호, 사용도수,
                            발신기 지국 위치 추적자료 : 1년
                          </p>
                          <p>2) 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월</p>
                        </span>
                      </div>
                      <span className={'text-danger fw-bold'}>
                        정보주체로부터 동의 받은 개인정보의 보유기간이 경과하거나 처리목적이
                        달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는
                        경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를
                        달리하여 보존합니다.
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제3조 개인정보의 제3자 제공</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        영아이티 주식회사는 원칙적으로 이용자의 개인정보를 외부에 제공하지 않습니다.
                        단, 이용자의 동의가 있거나 법률의 특별한 규정 등 개인정보 보호법 제17조 및
                        제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>1. 정보주체로부터 별도의 동의를 받은 경우</span>
                        <span>2. 다른 법률에 특정한 규정이 있는 경우</span>
                        <span>
                          3. 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나
                          주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는
                          제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
                        </span>
                        <span>
                          4. 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을
                          알아볼 수 없는 형태로 개인정보를 제공하는 경우
                        </span>
                        <span>
                          5. 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면
                          다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우로서 보호위원회의
                          심의·의결을 거친 경우
                        </span>
                        <span>
                          6. 조약, 그 밖의 국제협정의 이행을 위하여 또, 외국정부 또는 국제기구에
                          제공하기 위하여 필요한 경우
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>
                        제4조 정보주체와 법정대리인의 권리 · 의무 및 행사방법
                      </span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        이용자는 회사에 언제든지 개인정보 열람, 정정, 삭제, 처리정지 요구 등의
                        권리를 행사할 수 있습니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>1. 개인정보 열람 요구</span>
                        <span>2. 오류 등이 있을 경우 정정 요구</span>
                        <span>3. 삭제 요구</span>
                        <span>4. 처리정지 요구</span>
                      </div>
                      <span>
                        제4조에 대한 권리 행사는 이용자에 대해 개인정보호법 시행령 제41조제1항에
                        따라 서면, 전자우편, 오사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에
                        대해 지체없이 조치하겠습니다.
                      </span>
                      <span>
                        이용자(정보주체)가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
                        경우에는 회사의 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나
                        제공하지 않습니다. 또한 정보주체(이용자)의 법적대리인이나 위임을 받은 자 등
                        대리인을 통하여 하실 수 있으며, 이 경우 개인정보 보호법 시행규칙 별지 제11호
                        서식에 따른 위임장을 제출하셔야 합니다.
                      </span>
                      <span>
                        이용자(정보주체)는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고
                        있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제5조 처리하는 개인정보 항목</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        회사는 미터리움 원격검침시스템(이하 ‘서비스’)의 이용조건 및 절차에 관한
                        기본적인 사항을 정함을 목적으로 하며, 회사는 서비스 제공을 위해 필요한
                        최소한의 범위 내에서 개인정보를 처리하고 있습니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>
                          1. 수집하는 개인정보의(필수)항목 : 이름, 휴대폰번호, 수용가번호,
                          이메일주소, 주소
                        </span>
                        <span>2. 필수 항목 수집 목적</span>
                        <div className={'row ms-6 gap-2'}>
                          <span>1) 이름: 서비스 이용자 확인 및 문의사항 처리</span>
                          <span>
                            2) 휴대폰 번호: 본인확인, 비밀번호 찾기, 중복가입 방지, 서비스 이용 및
                            고객 문 의사항{' '}
                          </span>
                          <span>3) 수용가 번호: 정확한 검침 정보 확인 및 서비스 제공</span>
                          <span>4) 이메일 주소: 회원가입, 비밀번호 초기화, 서비스 관련 안내</span>
                          <span>5) 주소 : 설치 및 A/S 유지보수 서비스 처리</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제6조 개인정보의 안정성 확보조치</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        회사는 개인벙보보호법 제29조에 따라 개인정보의 안정성 확보를 위해 다음과
                        같이 조치를 취하고 있습니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>1. 관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등</span>
                        <span>
                          2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템
                          설치, 고유식별정보 등의 암호화, 보안프로그램 설치{' '}
                        </span>
                        <span>3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제7조 개인정보 파기</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게
                        되었을 때에는 지체없이 해당 개인정보를 파기합니다. 다만, 다른 법률에 따라
                        보존하여야하는 경우에는 그러하지 않습니다.
                      </span>
                      <span>개인정보 파기의 절차 및 방법은 다음과 같습니다.</span>
                      <div className={'ms-4 row gap-3'}>
                        <span>1. 파기절차</span>
                        <div className={'ms-6 '}>
                          <span>
                            1) 불필요한 개인정보(또는 개인정보파일)에 대해 파기계획을 수립하여
                            파기합니 다.
                          </span>
                          <span>
                            2) 파기사유가 발생한 개인정보(또는 개인정보파일)을 선정하고, 개인정보
                            보호책 임자의 승인을 받아 개인정보(또는 개인정보파일)을 파기합니다.
                          </span>
                        </div>
                        <span>1. 파기방법</span>
                        <div className={'ms-6 '}>
                          <span>
                            1) 전자적 파일 형태로 기록/저장된 개인정보는 기록을 재생할 수 없는
                            기술적 방법을 이용하여 파기합니다.
                          </span>
                          <span>
                            2) 종이문서에 기록/저장된 개인정보는 분쇄기로 분쇄하거나 소각하여
                            파기합니다.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제8조 개인정보 보호책임자</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한
                        정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보
                        보호책임자를 지정하고 있습니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>1. 개인정보 보호책임자</span>
                        <div className={'ms-6 row gap-3'}>
                          <span>- 성명 : 박지혜</span>
                          <span>- 직책 : 경영기획과장</span>
                          <span>
                            - 연락처 : T)042-932-0170, E)0170@young-it.co.kr, F)042-932-0173
                          </span>
                        </div>
                        <span>2. 개인정보 담당부서</span>
                        <div className={'ms-6 row gap-3 '}>
                          <span>- 부서명 : 개발부</span>
                          <span>- 담당자 : 박정일</span>
                          <span>
                            - 연락처 : T)042-932-0170, E)jipark@young-it.co.kr, F)042-932-0173
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제9조 이용자의 의무</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>1. 이용자는 회사에서 정한 정보를 정확하게 제공하여야 합니다</span>
                      <span>
                        2. 이용자는 서비스를 이용할 때 아래 각 호의 행위를 해서는 안됩니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>1) 법령에 위반뒤는 행위</span>
                        <span>2) 타인의 권리를 침해하는 행위</span>
                        <span>3) 회사의 영업을 방해하는 행위</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제10조 권익침해 구제방법</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        정보주체(이용자) 는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담
                        등을 문의하실 수 있습니다.
                      </span>
                      <span>
                        {`<아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리,
                        피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여
                        주시기 바랍니다>`}
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영) </span>
                        <div className={'ms-6 row gap-3'}>
                          <span>- 소관업무 : 개인정보 침해사실 신고, 상담 신청 </span>
                          <span>- 홈페이지 : privacy.kisa.or.kr </span>
                          <span>- 전화 : (국번없이) 118 </span>
                          <span>
                            - 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해
                            신고센터
                          </span>
                        </div>
                        <span>▶ 개인정보 분쟁조정위원회</span>
                        <div className={'ms-6 row gap-3 '}>
                          <span>
                            - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
                          </span>
                          <span>- 전화 : (국번없이) 1833-6972</span>
                          <span>
                            - 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
                          </span>
                        </div>
                        <span>
                          ▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (
                          <a href={'https://www.spo.go.kr'}>www.spo.go.kr</a>)
                        </span>
                        <span>
                          ▶ 경찰청 사이버안전국 : 182 (
                          <a href={'http://cyberbureau.police.go.kr'}>
                            www.cyberbureau.police.go.kr
                          </a>
                          )
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>
                        제11조 개인정보 자동수집 장치의 설치 · 운영 및 거부
                      </span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        회사는 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로
                        불러오는 ‘쿠키(cookie)’를 사용합니다.
                      </span>
                      <span>
                        쿠키는 웹사이트를 운영하는데 이용되는 서버(https)가 이용자의 컴퓨터
                        브라우저에게 보내는 소량의 정보이며 시용자들의 PC컴퓨터내의 하드디스크에
                        저장되기도 합니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>
                          1) 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹사이트들에 대한 방문
                          및 이용형태, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보를
                          제공하기 위해 사용됩니다.{' '}
                        </span>
                        <span>{`2) 쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구 > 인터넷옵션 > 개인정보 메뉴의 옵션 설정을 통해 쿠키저장을 거부할 수 있습니다.`}</span>
                        <span>
                          3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
                          있습니다.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제12조 영상정보처리기기 설치 · 운영</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        1. 영상정보처리기기 설치근거 및 목적 회사는 「개인정보 보호법」 제25조
                        제1항에 따라 시설물의 안전 및 화재예방, 고객 의 안전을 위한 범죄예방을
                        목적으로 영상정보처리기기를 설치·운영 합니다.
                      </span>
                      <span>
                        2. 설치 대수, 설치 위치, 촬영 범위 회사에서 운영하는 영상정보처리기기의
                        운영현황은 다음과 같습니다. - 사옥 내,외부 (4대) / 괸라책임자 및 접근권한자
                        : 경영기획팀 책임자
                      </span>
                      <span>3. 영상정보 촬영시간, 보관기간, 보관장소, 처리방법</span>
                      <div className={'ms-4 row gap-3'}>
                        <span>촬영시간 : 24시간</span>
                        <span>보관기간 : 촬영일로부터 30일</span>
                        <span>
                          보관장소 및 처리방법 : 중역실 영상정보처리기기 통제실에서 보관 및 처리
                        </span>
                      </div>
                      <span>
                        4. 영상정보 확인 방법 및 장소 확인 방법 : 소속기관 담당자에게 미리 연락하고
                        소속기관에 방문하시면 확인 가 능합니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>확인 장소 : 경영기획팀</span>
                      </div>
                      <span>
                        5. 정보주체의 영상정보 열람 등 요구에 대한 조치 : 개인영상정보 열람․존재확인
                        청구 서로 신청하여야 하며, 정보주체 자신이 촬영된 경우 또는 명백히
                        정보주체의 생명․신체․재산 이익을 위해 필요한 경우에 한해 열람을 허용함
                      </span>
                      <span>
                        6. 영상정보 보호를 위한 기술적․관리적․물리적 조치 : 내부관리계획 수립,
                        접근통제 및 접근권한 제한, 영상정보의 안전한 저장․전송기술 적용, 처리기록
                        보관 및 위․조 방지조치, 보관시설 마련 및 잠금장치 설치 등
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제13조 개인정보 처리방침의 변경</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        이 개인정보 처리방침은 2023년 8월 25일부터 적용됩니다. 회사는 필요한 경우
                        개인정보 처리방침을 변경할 수 있으며, 변경된 약관은 홈페이지에 공지함으로써
                        효력을 발생합니다.
                      </span>
                    </div>
                  </div>
                </div>
                <div className={'ps-4'}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agreeChecked}
                        onChange={(e) => {
                          setAgreeChecked(e.target.checked)
                        }}
                      />
                    }
                    label='위의 개인정보 수집 및 이용에 대한 안내에 동의합니다.'
                  />
                </div>
              </div>
              <div className={'all-agree'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreeChecked && playChecked}
                      onChange={(e) => {
                        if (agreeChecked && playChecked) {
                          setAgreeChecked(false)
                          setPlayChecked(false)
                        } else if (!agreeChecked && !playChecked) {
                          setAgreeChecked(e.target.checked)
                          setPlayChecked(e.target.checked)
                        } else if (!agreeChecked && playChecked) {
                          setAgreeChecked(e.target.checked)
                        } else if (agreeChecked && !playChecked) {
                          setPlayChecked(e.target.checked)
                        }
                      }}
                    />
                  }
                  label='위의 이용약관 및 개인정보 수집 및 이용에 대한 안내에 동의합니다.'
                />
              </div>
              <div className={'my-4 text-center d-flex gap-4 justify-content-center'}>
                <Button
                  variant='contained'
                  color='inherit'
                  size={'large'}
                  onClick={() => {
                    navigate('/')
                  }}
                >
                  취소
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  size={'large'}
                  onClick={async () => {
                    if (!agreeChecked && !playChecked) {
                      await normalAlert(
                        '이용약관 및 개인정보 수집 및 이용에 대해 동의하여 주십시오.'
                      )
                    } else if (!playChecked) {
                      await normalAlert('이용약관에 대해 동의하여 주십시오.')
                    } else if (!agreeChecked) {
                      await normalAlert('개인정보 수집 및 이용에 대해 동의하여 주십시오.')
                    } else {
                      await validationAlert('회원가입을 위해 회원정보를 입력해주세요.', 'success')
                      navigate('/join/registration')
                    }
                  }}
                >
                  확인
                </Button>
              </div>
            </Paper>
          </div>
        </div>
      )}
      {isMobileDevice() && (
        <div className={'container-fluid vh-100 join-body p-3 '}>
          <div className={'join-header text-center'}>
            <img src={Logo} alt={'logo'} width={'100%'} style={{borderRadius: '12px'}} />
          </div>
          <div className={'join-content-m'}>
            <Paper elevation={3} className={'p-5'}>
              <Box sx={{width: '100%'}} className={'my-7 '}>
                <Stepper activeStep={0} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <div className={'text-center'}>
                <h2 style={{fontSize: 35}}>약관동의</h2>
              </div>
              <div className={'my-5'}>
                <span className={'fw-bold fs-2 mx-3'}>이용약관</span>
                <div
                  style={{
                    height: '180px',
                    padding: '20px 20px 20px 20px',
                    overflowY: 'auto',
                    width: '98%',
                    boxSizing: 'border-box',
                    border: '1px solid #ccc',
                    margin: '0px 0px 0px 10px',
                    lineHeight: '20px',
                    fontSize: '15px',
                  }}
                  className={'row gap-6'}
                  tabIndex={0}
                >
                  <div className={'row gap-5'}>
                    <span className={'fw-bold fs-5'}>제1장 총칙 </span>
                    <div className={'row gap-3 ms-2'}>
                      <div className={'row'}>
                        <span>제1조 (목적)</span>
                        <span>
                          이 약관은 미터리움 원격검침시스템(이하 "서비스")의 이용조건 및 절차에 관한
                          기본적인 사항을 정함을 목적으로 합니다.
                        </span>
                      </div>
                      <div className={'row'}>
                        <span>
                          제2조 (용어의 정의)
                          <br />이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                        </span>
                        <span className={'ms-4'}>
                          1. “회사”란 미터리움 웹사이트를 운영하는 회사를 말합니다. <br />
                          2. “이용자”란 미터리움 웹사이트에 접속하여 이 약관에 따라 회사가 제공하는
                          서비스를 받는 회원 및 비회원을 말합니다.
                        </span>
                      </div>
                    </div>
                    <span className={'fw-bold fs-5'}>제2장 서비스 이용계약</span>
                    <div className={'row gap-3 ms-2'}>
                      <span>제3조 (이용계약의 성립)</span>
                      <span className={'ms-4'}>
                        1. 이용계약은 이용자의 이용신청 및 동의와 회사의 승낙의 방법으로 성립됩니다.{' '}
                        <br />
                        2. 회원은 이용약관에 동의함으로써 서비스를 이용할 수 있습니다.
                      </span>
                    </div>
                    <div className={'row gap-3 ms-2'}>
                      <span>제4조 (개인정보의 보호 및 사용)</span>
                      <span className={'ms-4'}>
                        회사는 관련법에 의거하여 이용자 등록 정보를 포함한 이용자의 개인정보를
                        보호하기 위해 노력합니다. 이용자의 개인정보 보호 및 사용에 대해서는 관련법
                        및 회사의 개인정보처리방침에 따릅니다.
                      </span>
                    </div>
                    <span className={'fw-bold fs-5'}>제3장 의무 및 권리</span>
                    <div className={'row gap-3 ms-2'}>
                      <span>제5조 (회사의 의무)</span>
                      <span className={'ms-4'}>
                        1. 회원의 개인정보는 「개인정보처리방침」에 따라 보호됩니다. <br />
                        2. 미터리움의 회원정보는 다음과 같이 사용,관리,보호 됩니다.
                        <div className={'ms-6'}>
                          <p>
                            1) 개인정보의 사용 : 미터리움은 서비스 제공과 관련해서 수집된 회원의
                            신상정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습 니다. 단,
                            전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우, 범죄에
                            대한 수사상의 목적이 있거나 정보통신윤리위원회의 요청이 있는 경우 또는
                            기타 관계법령에서 정한 절차에 따른 요청이 있는 경우, 이용자 스스로
                            개인정보를 공개한 경우에는 그러 하지 않습니다.
                          </p>
                          <p>
                            2) 개인정보의 관리 : 회원은 개인정보의 보호 및 관리를 위하여 서비스의
                            개인정보관리에서 수시로 회원의 개인정보를 수정/삭제할 수 있습니다.
                          </p>
                          <p>
                            3) 개인정보의 보호 : 회원의 개인정보는 오직 본인만이 열람/수정/삭제 할
                            수 있으며, 이는 전적으로 회원의 계정과 비밀번호에 의해 관리되고
                            있습니다. 따라서 타인에게 본인의 계정과 비밀번호를 알려주어서는 안되며,
                            작업 종료시에는 반드시 로그아웃해 주시기 바랍니다.
                          </p>
                        </div>
                      </span>
                    </div>
                    <div className={'row gap-3 ms-2'}>
                      <span>제6조 (이용자의 의무)</span>
                      <span className={'ms-4'}>
                        1. 이용자는 회사에서 정한 정보를 정확하게 제공하여야 합니다. <br />
                        2. 이용자는 서비스를 이용할 때 아래 각 호의 행위를 해서는 안됩니다.
                        <div className={'ms-6'}>
                          <p>- 법령에 위반되는 행위</p>
                          <p>- 타인의 권리를 침해하는 행위</p>
                          <p>- 회사의 영업을 방해하는 행위</p>
                        </div>
                      </span>
                    </div>
                    <span className={'fw-bold fs-5'}>제4장 기타</span>
                    <div className={'row gap-3 ms-2'}>
                      <span>제9조 (면책조항)</span>
                      <span className={'ms-4'}>
                        회사는 천재지변, 불가항력적 사건 등 비상사태로 인해 서비스를 제공할 수 없는
                        경우에는 서비스 제공에 관한 책임이 면제됩니다.
                      </span>
                    </div>
                    <div className={'row gap-3 ms-2'}>
                      <span>제10조 (분쟁해결)</span>
                      <span>
                        이 약관과 관련된 분쟁에 대해서는 대한민국의 법을 준거법으로 합니다.
                      </span>
                    </div>
                    <div className={'row gap-3 ms-2'}>
                      <span>제11조 (효력의 발생과 변경)</span>
                      <span className={'ms-4'}>
                        1. 이 약관은 이용자의 동의와 함께 효력을 발생합니다. <br />
                        2. 회사는 필요한 경우 이 약관을 변경할 수 있으며, 변경된 약관은 홈페이지에
                        공지함으로써 효력을 발생합니다.
                      </span>
                    </div>
                  </div>
                </div>
                <div className={'ps-4'}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={playChecked}
                        onChange={(e) => {
                          setPlayChecked(e.target.checked)
                        }}
                      />
                    }
                    label='위의 이용약관에 동의합니다.'
                  />
                </div>
              </div>
              <div style={{margin: '100px  0px 30px 0px'}}>
                <span className={'fw-bold fs-2 mx-3'}>영아이티주식회사 개인정보처리방침</span>
                <div
                  style={{
                    height: '180px',
                    padding: '20px 20px 20px 20px',
                    overflowY: 'auto',
                    width: '98%',
                    boxSizing: 'border-box',
                    border: '1px solid #ccc',
                    margin: '0px 0px 0px 10px',
                    lineHeight: '20px',
                    fontSize: '15px',
                  }}
                  className={'row gap-6'}
                  tabIndex={0}
                >
                  <div className={'row gap-8'}>
                    <span className={'fw-bold fs-4'}>
                      영아이티주식회사(이하 ‘회사’라고 함)는 개인정보 보호법 제30조에 따라
                      정보주체(이하 ‘이용자’라고 함) 의 개인정보를 소중하게 보호하고 이와 관련된
                      고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보
                      처리지침을 수립하여 공개합니다.{' '}
                    </span>
                    <span className={'fw-bold fs-4'}>
                      ‘회사’란 미터리움 웹사이트를 운영하는 영아이티주식회사를 말합니다.
                    </span>
                    <span className={'fw-bold fs-4'}>
                      ‘이용자’란 미터리움 웹사이트에 접속하여 본 개인정보처리방침에 따라 회사가
                      제공하는 서비스를 받는 회원 및 비회원의 정보주체를 말합니다.
                    </span>
                    <span className={'fw-bold fs-4 my-3 text-danger'}>
                      회사는 개인정보처리방침을 웹사이트 첫 화면에 공개함으로써 언제나 쉽게 확인할
                      수 있도록 하고 있습니다. 또한, 개인정보 처리방침을 개정하는 경우 웹사이트를
                      통하여 공지(또는 개별공지)할 것입니다.
                    </span>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}> 제1조 개인정보 처리 목적</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        회사는 다음의 목적을 위하여 개인정보를 처리하며, 처리하고 있는 개인정보는
                        다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는
                        개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할
                        예정입니다.
                      </span>
                      <span>
                        영아이티주식회사 미터리움 원격검침시스템(이하 ‘서비스’)의 이용조건 및 절차에
                        관한 기본적인 사항을 정함을 목적으로 하며, 회사는 서비스 제공을 위해 필요한
                        최소한의 범위 내에서 개인정보를 처리하고 있습니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>
                          1. 홈페이지 회원 가입 및 관리 회원 가입의사 확인, 회원 및 비회원 서비스
                          제공에 따른 본인 식별 · 인증, 회원 자격유지 · 관리, 제한적 본인확인제
                          시행에 따른 본인확인, 서비스 부정이용 방 지, 만 14세 미만 아동의 개인정보
                          처리시 법적 대리인의 동의여부 확인, 각종 고 지 · 통지 등을 목적으로
                          개인정보를 처리합니다.
                        </span>
                        <span>
                          2. 서비스 제공 회원가입, 문의사항 제출 등 서비스 이용과정에서 이용자가
                          직접 입력하는 방식 으로 수집됩니다.{' '}
                        </span>
                        <span>
                          3. 고충처리 민원인의 신원확인, 민원사항 확인, 사실조사를 위한 연락 · 통지,
                          처리결과 통보 등의 목적으로 개인정보를 처리합니다.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제2조 개인정보의 처리 및 보유기간</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        회사는 법령에 따른 개인정보 보유 · 이용기간 또는 이용자(정보주체) 로부터
                        개인정보를 수집시에 동의받은 개인벙보 보유 · 이용기간 내에서 개인정보를 처리
                        · 보유합니다
                      </span>
                      <span>개인정보 처리 및 보유 기간은 다음과 같습니다.</span>
                      <div className={'ms-4 row'}>
                        <span>
                          1. 홈페이지 회원가입 및 관리 : 사업자/개인/단체 홈페이지 탈퇴시까지 다만,
                          다음의 사유에 해당하는 경우에는 해당 사유 종료시까지
                        </span>
                        <span className={'ms-6'}>
                          <p>
                            1) 관계 볍령 위반에 따른 수사 · 조사 등이 진행중인 경우에는 해당 수사 ·
                            조사 종료시까지
                          </p>
                          <p>
                            2) 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 시행령 제29조에
                            따른 본인 확인 정보 보관
                          </p>
                          <p>3) 예외사유 시에는 보유기간 까지</p>
                        </span>
                      </div>
                      <div className={'ms-4 row'}>
                        <span>2. 서비스 제공</span>
                        <span className={'text-primary fw-bold'}>
                          「통신비밀보호법」 제41조에 따른 통신사실확인자료 보관
                        </span>
                        <span className={'ms-6'}>
                          <p>
                            1) 가입자 전기통신일시, 개시․종료시간, 상대방 가입자번호, 사용도수,
                            발신기 지국 위치 추적자료 : 1년
                          </p>
                          <p>2) 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월</p>
                        </span>
                      </div>
                      <span className={'text-danger fw-bold'}>
                        정보주체로부터 동의 받은 개인정보의 보유기간이 경과하거나 처리목적이
                        달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는
                        경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를
                        달리하여 보존합니다.
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제3조 개인정보의 제3자 제공</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        영아이티 주식회사는 원칙적으로 이용자의 개인정보를 외부에 제공하지 않습니다.
                        단, 이용자의 동의가 있거나 법률의 특별한 규정 등 개인정보 보호법 제17조 및
                        제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>1. 정보주체로부터 별도의 동의를 받은 경우</span>
                        <span>2. 다른 법률에 특정한 규정이 있는 경우</span>
                        <span>
                          3. 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나
                          주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는
                          제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
                        </span>
                        <span>
                          4. 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을
                          알아볼 수 없는 형태로 개인정보를 제공하는 경우
                        </span>
                        <span>
                          5. 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면
                          다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우로서 보호위원회의
                          심의·의결을 거친 경우
                        </span>
                        <span>
                          6. 조약, 그 밖의 국제협정의 이행을 위하여 또, 외국정부 또는 국제기구에
                          제공하기 위하여 필요한 경우
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>
                        제4조 정보주체와 법정대리인의 권리 · 의무 및 행사방법
                      </span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        이용자는 회사에 언제든지 개인정보 열람, 정정, 삭제, 처리정지 요구 등의
                        권리를 행사할 수 있습니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>1. 개인정보 열람 요구</span>
                        <span>2. 오류 등이 있을 경우 정정 요구</span>
                        <span>3. 삭제 요구</span>
                        <span>4. 처리정지 요구</span>
                      </div>
                      <span>
                        제4조에 대한 권리 행사는 이용자에 대해 개인정보호법 시행령 제41조제1항에
                        따라 서면, 전자우편, 오사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에
                        대해 지체없이 조치하겠습니다.
                      </span>
                      <span>
                        이용자(정보주체)가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
                        경우에는 회사의 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나
                        제공하지 않습니다. 또한 정보주체(이용자)의 법적대리인이나 위임을 받은 자 등
                        대리인을 통하여 하실 수 있으며, 이 경우 개인정보 보호법 시행규칙 별지 제11호
                        서식에 따른 위임장을 제출하셔야 합니다.
                      </span>
                      <span>
                        이용자(정보주체)는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고
                        있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제5조 처리하는 개인정보 항목</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        회사는 미터리움 원격검침시스템(이하 ‘서비스’)의 이용조건 및 절차에 관한
                        기본적인 사항을 정함을 목적으로 하며, 회사는 서비스 제공을 위해 필요한
                        최소한의 범위 내에서 개인정보를 처리하고 있습니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>
                          1. 수집하는 개인정보의(필수)항목 : 이름, 휴대폰번호, 수용가번호,
                          이메일주소, 주소
                        </span>
                        <span>2. 필수 항목 수집 목적</span>
                        <div className={'row ms-6 gap-2'}>
                          <span>1) 이름: 서비스 이용자 확인 및 문의사항 처리</span>
                          <span>
                            2) 휴대폰 번호: 본인확인, 비밀번호 찾기, 중복가입 방지, 서비스 이용 및
                            고객 문 의사항{' '}
                          </span>
                          <span>3) 수용가 번호: 정확한 검침 정보 확인 및 서비스 제공</span>
                          <span>4) 이메일 주소: 회원가입, 비밀번호 초기화, 서비스 관련 안내</span>
                          <span>5) 주소 : 설치 및 A/S 유지보수 서비스 처리</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제6조 개인정보의 안정성 확보조치</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        회사는 개인벙보보호법 제29조에 따라 개인정보의 안정성 확보를 위해 다음과
                        같이 조치를 취하고 있습니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>1. 관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등</span>
                        <span>
                          2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템
                          설치, 고유식별정보 등의 암호화, 보안프로그램 설치{' '}
                        </span>
                        <span>3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제7조 개인정보 파기</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게
                        되었을 때에는 지체없이 해당 개인정보를 파기합니다. 다만, 다른 법률에 따라
                        보존하여야하는 경우에는 그러하지 않습니다.
                      </span>
                      <span>개인정보 파기의 절차 및 방법은 다음과 같습니다.</span>
                      <div className={'ms-4 row gap-3'}>
                        <span>1. 파기절차</span>
                        <div className={'ms-6 '}>
                          <span>
                            1) 불필요한 개인정보(또는 개인정보파일)에 대해 파기계획을 수립하여
                            파기합니 다.
                          </span>
                          <span>
                            2) 파기사유가 발생한 개인정보(또는 개인정보파일)을 선정하고, 개인정보
                            보호책 임자의 승인을 받아 개인정보(또는 개인정보파일)을 파기합니다.
                          </span>
                        </div>
                        <span>1. 파기방법</span>
                        <div className={'ms-6 '}>
                          <span>
                            1) 전자적 파일 형태로 기록/저장된 개인정보는 기록을 재생할 수 없는
                            기술적 방법을 이용하여 파기합니다.
                          </span>
                          <span>
                            2) 종이문서에 기록/저장된 개인정보는 분쇄기로 분쇄하거나 소각하여
                            파기합니다.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제8조 개인정보 보호책임자</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한
                        정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보
                        보호책임자를 지정하고 있습니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>1. 개인정보 보호책임자</span>
                        <div className={'ms-6 row gap-3'}>
                          <span>- 성명 : 박지혜</span>
                          <span>- 직책 : 경영기획과장</span>
                          <span>
                            - 연락처 : T)042-932-0170, E)0170@young-it.co.kr, F)042-932-0173
                          </span>
                        </div>
                        <span>2. 개인정보 담당부서</span>
                        <div className={'ms-6 row gap-3 '}>
                          <span>- 부서명 : 개발부</span>
                          <span>- 담당자 : 박정일</span>
                          <span>
                            - 연락처 : T)042-932-0170, E)jipark@young-it.co.kr, F)042-932-0173
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제9조 이용자의 의무</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>1. 이용자는 회사에서 정한 정보를 정확하게 제공하여야 합니다</span>
                      <span>
                        2. 이용자는 서비스를 이용할 때 아래 각 호의 행위를 해서는 안됩니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>1) 법령에 위반뒤는 행위</span>
                        <span>2) 타인의 권리를 침해하는 행위</span>
                        <span>3) 회사의 영업을 방해하는 행위</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제10조 권익침해 구제방법</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        정보주체(이용자) 는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담
                        등을 문의하실 수 있습니다.
                      </span>
                      <span>
                        {`<아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리,
                        피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여
                        주시기 바랍니다>`}
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영) </span>
                        <div className={'ms-6 row gap-3'}>
                          <span>- 소관업무 : 개인정보 침해사실 신고, 상담 신청 </span>
                          <span>- 홈페이지 : privacy.kisa.or.kr </span>
                          <span>- 전화 : (국번없이) 118 </span>
                          <span>
                            - 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해
                            신고센터
                          </span>
                        </div>
                        <span>▶ 개인정보 분쟁조정위원회</span>
                        <div className={'ms-6 row gap-3 '}>
                          <span>
                            - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
                          </span>
                          <span>- 전화 : (국번없이) 1833-6972</span>
                          <span>
                            - 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
                          </span>
                        </div>
                        <span>
                          ▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (
                          <a href={'www.spo.go.kr'}>www.spo.go.kr</a>)
                        </span>
                        <span>
                          ▶ 경찰청 사이버안전국 : 182 (
                          <a href={'http://cyberbureau.police.go.kr'}>
                            http://cyberbureau.police.go.kr
                          </a>
                          )
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>
                        제11조 개인정보 자동수집 장치의 설치 · 운영 및 거부
                      </span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        회사는 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로
                        불러오는 ‘쿠키(cookie)’를 사용합니다.
                      </span>
                      <span>
                        쿠키는 웹사이트를 운영하는데 이용되는 서버(https)가 이용자의 컴퓨터
                        브라우저에게 보내는 소량의 정보이며 시용자들의 PC컴퓨터내의 하드디스크에
                        저장되기도 합니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>
                          1) 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹사이트들에 대한 방문
                          및 이용형태, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보를
                          제공하기 위해 사용됩니다.{' '}
                        </span>
                        <span>{`2) 쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구 > 인터넷옵션 > 개인정보 메뉴의 옵션 설정을 통해 쿠키저장을 거부할 수 있습니다.`}</span>
                        <span>
                          3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
                          있습니다.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제12조 영상정보처리기기 설치 · 운영</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        1. 영상정보처리기기 설치근거 및 목적 회사는 「개인정보 보호법」 제25조
                        제1항에 따라 시설물의 안전 및 화재예방, 고객 의 안전을 위한 범죄예방을
                        목적으로 영상정보처리기기를 설치·운영 합니다.
                      </span>
                      <span>
                        2. 설치 대수, 설치 위치, 촬영 범위 회사에서 운영하는 영상정보처리기기의
                        운영현황은 다음과 같습니다. - 사옥 내,외부 (4대) / 괸라책임자 및 접근권한자
                        : 경영기획팀 책임자
                      </span>
                      <span>3. 영상정보 촬영시간, 보관기간, 보관장소, 처리방법</span>
                      <div className={'ms-4 row gap-3'}>
                        <span>촬영시간 : 24시간</span>
                        <span>보관기간 : 촬영일로부터 30일</span>
                        <span>
                          보관장소 및 처리방법 : 중역실 영상정보처리기기 통제실에서 보관 및 처리
                        </span>
                      </div>
                      <span>
                        4. 영상정보 확인 방법 및 장소 확인 방법 : 소속기관 담당자에게 미리 연락하고
                        소속기관에 방문하시면 확인 가 능합니다.
                      </span>
                      <div className={'ms-4 row gap-3'}>
                        <span>확인 장소 : 경영기획팀</span>
                      </div>
                      <span>
                        5. 정보주체의 영상정보 열람 등 요구에 대한 조치 : 개인영상정보 열람․존재확인
                        청구 서로 신청하여야 하며, 정보주체 자신이 촬영된 경우 또는 명백히
                        정보주체의 생명․신체․재산 이익을 위해 필요한 경우에 한해 열람을 허용함
                      </span>
                      <span>
                        6. 영상정보 보호를 위한 기술적․관리적․물리적 조치 : 내부관리계획 수립,
                        접근통제 및 접근권한 제한, 영상정보의 안전한 저장․전송기술 적용, 처리기록
                        보관 및 위․조 방지조치, 보관시설 마련 및 잠금장치 설치 등
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className={'my-1'}>
                      <span className={'fw-bold fs-4'}>제13조 개인정보 처리방침의 변경</span>
                    </div>
                    <div className={'row gap-4 ms-2'}>
                      <span>
                        이 개인정보 처리방침은 2023년 8월 25일부터 적용됩니다. 회사는 필요한 경우
                        개인정보 처리방침을 변경할 수 있으며, 변경된 약관은 홈페이지에 공지함으로써
                        효력을 발생합니다.
                      </span>
                    </div>
                  </div>
                </div>
                <div className={'ps-4'}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agreeChecked}
                        onChange={(e) => {
                          setAgreeChecked(e.target.checked)
                        }}
                      />
                    }
                    label='위의 개인정보 수집 및 이용에 대한 안내에 동의합니다.'
                  />
                </div>
              </div>
              <div className={'all-agree'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreeChecked && playChecked}
                      onChange={(e) => {
                        if (agreeChecked && playChecked) {
                          setAgreeChecked(false)
                          setPlayChecked(false)
                        } else if (!agreeChecked && !playChecked) {
                          setAgreeChecked(e.target.checked)
                          setPlayChecked(e.target.checked)
                        } else if (!agreeChecked && playChecked) {
                          setAgreeChecked(e.target.checked)
                        } else if (agreeChecked && !playChecked) {
                          setPlayChecked(e.target.checked)
                        }
                      }}
                    />
                  }
                  label='위의 이용약관 및 개인정보 수집 및 이용에 대한 안내에 동의합니다.'
                />
              </div>
              <div className={'my-4 text-center d-flex gap-4 justify-content-center'}>
                <Button
                  variant='contained'
                  color='inherit'
                  size={'large'}
                  onClick={() => {
                    navigate('/')
                  }}
                >
                  취소
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  size={'large'}
                  onClick={async () => {
                    if (!agreeChecked && !playChecked) {
                      await normalAlert(
                        '이용약관 및 개인정보 수집 및 이용에 대해 동의하여 주십시오.'
                      )
                    } else if (!playChecked) {
                      await normalAlert('이용약관에 대해 동의하여 주십시오.')
                    } else if (!agreeChecked) {
                      await normalAlert('개인정보 수집 및 이용에 대해 동의하여 주십시오.')
                    } else {
                      await validationAlert('회원가입을 위해 회원정보를 입력해주세요.', 'success')
                      navigate('/join/registration')
                    }
                  }}
                >
                  확인
                </Button>
              </div>
            </Paper>
          </div>
        </div>
      )}
    </>
  )
}

export default AgreePage
