import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import {Button, FormControl, InputLabel, MenuItem, Select, Slide, TextField} from '@mui/material'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import dayjs from 'dayjs'
import {serverLocalName} from 'GLOBAL/envVariables'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {playConfirm} from 'utils/CustomAlert/Alert'
import {validationAlert} from './../../../utils/CustomAlert/Alert'
import {saeolDataPost} from './../../../utils/func/justUtils'

const ChargeSelect = (props: any) => {
  const {setSelectStep, selectStep} = props
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const officeInfo = useSelector((state: any) => state?.officeInfo)
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const [selectInfo, setSelectInfo] = useState<any>({
    selectProgram: '',
    selectDate: '',
  })
  const [file, setFile] = useState<File>()
  const [fileName, setFileName] = useState<string>('')

  const fileUploadHandler = async (e: any) => {
    const target = e.currentTarget
    const file = (target.files as FileList)[0]
    setFile(file)
    setFileName(file.name)
  }

  const postFileData = async () => {
    const formData = new FormData()
    formData.append('file', file as Blob)
    formData.append('id', userInfo?.userName)
    formData.append('officeId', userInfo?.officeId)
    try {
      const res = await saeolDataPost(formData, officeInfo)
      if (res) {
        if (fileName === '') {
          validationAlert('txt파일 업로드 없이 진행합니다.', 'success')
        } else {
          validationAlert(`'${fileName} 으로 요금연계를 진행합니다.'`, 'success')
        }
        setLoading(false)
      } else {
        setLoading(false)
        await validationAlert('실패했습니다', 'warning')
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (selectInfo.selectDate === '' && selectInfo.selectProgram === '') {
      setSelectStep({step1: false, step2: false})
    }
  }, [])

  const selectedProgram = (items: any) => {
    switch (items) {
      case 'WIMS':
        return 'WIMS 프로그램으로 요금연계를 진행합니다.'
      case 'SAEOL':
        return ' 새올 프로그램으로 요금연계를 진행합니다.'
      case 'BLUEWATER':
        return '푸른물 프로그램으로 요금연계를 진행합니다.'
      case 'CREBIZ':
        return '크레비즈 프로그램으로 요금연계를 진행합니다.'
      default:
        return '요금프로그램을 선택해주세요.'
    }
  }

  return (
    <>
      {!selectStep.step1 && !selectStep.step2 && (
        <div className='card flex-grow-1' id='customer-register'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>
                <strong className={'text-primary'}>{officeInfo?.label}</strong> 요금연계프로그램
                선택
              </h3>
            </div>
          </div>
          <div className={'card-body align-self-center w-100 d-flex justify-content-center'}>
            <div className={' w-25'} style={{top: '30%', position: 'fixed'}}>
              <FormControl fullWidth className={'mb-4'}>
                <InputLabel id='demo-simple-select-label'>요금 프로그램</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='charge-program-select'
                  label='요금 프로그램'
                  value={selectInfo.selectProgram}
                  onChange={(e) => {
                    setSelectInfo({...selectInfo, selectProgram: e.target.value})
                  }}
                >
                  <MenuItem value={'WIMS'}>유니버스 ( WIMS )</MenuItem>
                  <MenuItem value={'SAEOL'}>시군구 ( 새올 )</MenuItem>
                  <MenuItem value={'BLUEWATER'}>한일 ( 푸른물 )</MenuItem>
                  <MenuItem value={'CREBIZ'}>크레비즈</MenuItem>
                </Select>
              </FormControl>
              <div className={'d-flex justify-content-between'}>
                {selectInfo.selectProgram === '' ? (
                  <span className={'text-danger fs-4 fw-bold'}>요금프로그램을 선택해주세요.</span>
                ) : (
                  <span className={'text-primary fs-4 fw-bold'}>
                    {selectedProgram(selectInfo.selectProgram)}
                  </span>
                )}
                <Button
                  variant={'contained'}
                  color={'primary'}
                  sx={{fontSize: '20px'}}
                  onClick={() => {
                    setSelectStep({...selectStep, step1: true})
                  }}
                  disabled={selectInfo.selectProgram === null}
                >
                  다음
                </Button>
              </div>
            </div>
          </div>
          <footer>
            {/* <div className='d-flex justify-content-center align-self-center'>
              <a className='fw-bold fs-5 m-4'>원격지원시스템</a>
            </div> */}
          </footer>
        </div>
      )}
      {selectStep.step1 === true && !selectStep.step2 && (
        <Slide
          direction='left'
          in={selectStep.step1 === true && !selectStep.step2}
          mountOnEnter
          unmountOnExit
          timeout={700}
        >
          <div className='card flex-grow-1' id='customer-register'>
            <div className='card-header'>
              <div className='card-title m-0'>
                <ArrowBackIosNewIcon
                  className={'me-3 cursor-pointer'}
                  onClick={() => {
                    setSelectStep({...selectStep, step1: false})
                    setSelectInfo({
                      ...selectInfo,
                      selectDate: '',
                    })
                  }}
                />
                <h3 className='fw-bolder m-0'>
                  <strong className={'text-primary'}>{officeInfo?.label}</strong> 납기일자 선택
                </h3>
              </div>
            </div>
            <div className={'card-body w-100 d-flex justify-content-center'}>
              <div className={' w-25'} style={{top: '30%', position: 'fixed'}}>
                {selectInfo.selectProgram === 'SAEOL' ? (
                  <div className='row mb-xl-15'>
                    <div className='d-flex'>
                      <TextField
                        variant='outlined'
                        sx={{caretColor: 'transparent', width: '80%'}}
                        value={fileName === '' ? '파일을 선택해주세요.' : fileName}
                      />
                      <div className='d-flex w-25'>
                        <Button
                          className='w-100 fs-2'
                          variant='contained'
                          color='success'
                          component='label'
                        >
                          파일 선택
                          <input
                            type='file'
                            id='fileItems'
                            accept='.txt'
                            onChange={(e) => fileUploadHandler(e)}
                            hidden
                          />
                        </Button>
                      </div>
                    </div>
                    {fileName === '' ? (
                      <span className='text-danger fs-4 fw-bold mt-3'> 파일을 선택해주세요.</span>
                    ) : (
                      <span className='text-primary fs-4 fw-bold mt-3'>
                        {fileName} 파일로 요금연계를 진행합니다.
                      </span>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <div className={'mb-4'}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={serverLocalName === 'gyeryong' ? '검침일자' : '납기일자'}
                      minDate={dayjs().subtract(3, 'month')}
                      views={
                        serverLocalName === 'gyeryong'
                          ? ['year', 'month', 'day']
                          : ['year', 'month']
                      } //feature/sc 23.12.19
                      openTo='month'
                      shouldDisableDate={(date) => {
                        const day = dayjs(date).date()
                        if (serverLocalName === 'gyeryong') {
                          return day !== 1 && day !== 15
                        } else {
                          return false
                        }
                      }}
                      onChange={(e) => {
                        if (selectInfo.selectProgram === 'WIMS') {
                          let select
                          if (serverLocalName === 'gyeryong') {
                            select = dayjs(e).format('YYYYMMDD')
                          } else {
                            select = dayjs(e).endOf('month').format('YYYYMMDD')
                          }

                          setSelectInfo({
                            ...selectInfo,
                            selectDate: select,
                          })
                        } else {
                          setSelectInfo({
                            ...selectInfo,
                            selectDate: dayjs(e).endOf('month').format('YYYYMM'),
                          })
                        }
                      }}
                      value={selectInfo.selectDate}
                      inputFormat={
                        selectInfo.selectProgram === 'WIMS' ? 'YYYY년 MM월 DD일' : 'YYYY년 MM월'
                      }
                      renderInput={(params: any) => (
                        <TextField fullWidth size='large' {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className={'d-flex justify-content-between'}>
                  {selectInfo.selectDate === '' ? (
                    serverLocalName === 'gyeryong' ? (
                      <span className={'text-danger fs-4 fw-bold'}> 검침일자를 선택해주세요.</span>
                    ) : (
                      <span className={'text-danger fs-4 fw-bold'}> 납기일자를 선택해주세요.</span>
                    )
                  ) : selectInfo.selectProgram === 'WIMS' ? (
                    <span className={'text-primary fs-4 fw-bold'}>
                      {dayjs(selectInfo.selectDate).format('YYYY년 MM월 DD일')} 납기일로 요금연계를
                      진행합니다.
                    </span>
                  ) : (
                    <span className={'text-primary fs-4 fw-bold'}>
                      {dayjs(selectInfo.selectDate).format('YYYY년 MM월')} 납기일로 요금연계를
                      진행합니다.
                    </span>
                  )}

                  <Button
                    variant={'contained'}
                    color={'primary'}
                    sx={{fontSize: '20px'}}
                    onClick={async () => {
                      if (selectInfo.selectProgram === 'WIMS') {
                        if (
                          await playConfirm(
                            `${userInfo?.officeName}의 요금연계`,
                            `요금프로그램 : ${selectInfo.selectProgram} / 납기일 : ${dayjs(
                              selectInfo.selectDate
                            ).format('YYYY년 MM월 DD일')}`,
                            'info',
                            '요금연계 진행'
                          )
                        ) {
                          setSelectStep({...selectStep, step2: true})
                          navigate(
                            `/charge/list?program=${selectInfo.selectProgram}&date=${selectInfo.selectDate}`
                          )
                        }
                      } else if (selectInfo.selectProgram === 'SAEOL') {
                        if (
                          await playConfirm(
                            `${userInfo?.officeName}의 요금연계`,
                            `요금프로그램 : ${
                              selectInfo.selectProgram === 'BLUEWATER' ? '푸른물' : '새올'
                            } / 납기일 : ${dayjs(selectInfo.selectDate).format('YYYY년 MM월')}`,
                            'info',
                            '요금연계 진행'
                          )
                        ) {
                          setSelectStep({...selectStep, step2: true})
                          setSelectInfo({selectProgram: '', selectDate: ''})
                          postFileData()
                          if (fileName === '') {
                            await navigate(
                              `/charge/list?program=${selectInfo.selectProgram}1&date=${selectInfo.selectDate}`
                            )
                          } else {
                            await navigate(
                              `/charge/list?program=${selectInfo.selectProgram}&date=${selectInfo.selectDate}`
                            )
                          }
                        }
                      } else if (selectInfo.selectProgram === 'CREBIZ') {
                        if (
                          await playConfirm(
                            `${userInfo?.officeName}의 요금연계`,
                            `요금프로그램 : 크레비즈 / 납기일 : ${dayjs(
                              selectInfo.selectDate
                            ).format('YYYY년 MM월')}`,
                            'info',
                            '요금연계 진행'
                          )
                        ) {
                          setSelectStep({...selectStep, step2: true})
                          setSelectInfo({selectProgram: '', selectDate: ''})
                          await navigate(
                            `/charge/list?program=${selectInfo.selectProgram}&date=${selectInfo.selectDate}`
                          )
                        }
                      } else {
                        if (
                          await playConfirm(
                            `${userInfo?.officeName}의 요금연계`,
                            `요금프로그램 : ${
                              selectInfo.selectProgram === 'BLUEWATER' ? '푸른물' : '새올'
                            } / 납기일 : ${dayjs(selectInfo.selectDate).format('YYYY년 MM월')}`,
                            'info',
                            '요금연계 진행'
                          )
                        ) {
                          setSelectStep({...selectStep, step2: true})
                          setSelectInfo({selectProgram: '', selectDate: ''})
                          navigate(
                            `/charge/list?program=${selectInfo.selectProgram}&date=${selectInfo.selectDate}`
                          )
                        }
                      }
                    }}
                    disabled={selectInfo.selectDate === ''}
                  >
                    다음
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Slide>
      )}
    </>
  )
}

export default ChargeSelect
