import React, {useEffect} from 'react'
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import SystemHeader from './layout/SystemHeader'
import CodeManagement from './component/codeManagement'
import LogManagement from './component/logManagement'
import {useAuth} from '../../_component/modules/auth'
import {useSelector} from 'react-redux'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: '시스템',
    path: '/pages/system',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const SystemPage = (props: any) => {
  const {toast} = props
  const {logout} = useAuth()
  const navigate = useNavigate()
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  useEffect(() => {
    if (
      userInfo === null ||
      (userInfo.accountType !== 'sys_admin' && userInfo.accountType !== 'sys_user')
    ) {
      navigate('/auth')
      logout()
    }
  }, [])
  return (
    <Routes>
      <Route
        element={
          <>
            <SystemHeader toast={toast} />
            <Outlet />
          </>
        }
      >
        <Route
          path={'code'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>코드관리</PageTitle>
              <CodeManagement toast={toast} />
            </>
          }
        />
        <Route
          path={'log'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>로그관리</PageTitle>
              <LogManagement toast={toast} />
            </>
          }
        />
      </Route>
    </Routes>
  )
}
export default SystemPage
