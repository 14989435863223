import React, {useEffect, useState} from 'react'
import {DataGridPro} from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import {useSelector} from 'react-redux'
import {scrollTop} from 'utils/func/justUtils'
import CustomPagination from 'utils/designSytem/CustomPagination'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import {deviceImagesFilter} from 'pages/device/dataConfig/declearFilters'
import {deviceImagesColumns} from 'pages/device/dataConfig/declearColumns'
import {deviceImagesRows} from 'pages/device/dataConfig/declearRows'
import {getDeviceImages} from 'utils/apiCalling/api'
import {toolTipText} from 'utils/toolTips/toolTipText'

const DeviceImages = (props: any) => {
  const [date, setDate] = React.useState<any>([dayjs(), dayjs()])
  const [loading, setLoading] = useState<boolean>(false)
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const [deviceImageList, setDeviceImageList] = useState<any>(null)
  const [filter, setFilter] = useState<any>(deviceImagesFilter)
  const [totalCount, setTotalCount] = useState<any>(null)
  const getDeviceImageCall = () => {
    getDeviceImages(officeInfo, cooperInfo, filter, {
      success: (res: any) => {
        setDeviceImageList(res)
      },
      count: (count: any) => {
        setTotalCount(count)
      },
      fail: (error: any) => {
        console.log(error)
      },
      loading: (loading: boolean) => {
        setLoading(loading)
      },
    })
  }

  const columns: any = deviceImagesColumns

  const rows = deviceImagesRows(deviceImageList)
  useEffect(() => {
    scrollTop()
    getDeviceImageCall()
  }, [
    filter.startDate,
    filter.endDate,
    officeInfo,
    cooperInfo,
    filter.imageSearchOption,
    filter.rowPerPage,
    filter.page,
  ])
  return (
    <>
      <MeterariumPageLayout
        headerDescription={toolTipText.deviceImages.description}
        tooltipText={toolTipText.deviceImages.text}
        filter={filter}
        id={'device-image'}
        setFilter={setFilter}
        searchCallback={getDeviceImageCall}
        total={totalCount}
        control={['deviceDateRange']}
        actions={['']}
        onDelete={null}
        setDate={setDate}
        date={date}
        searchOptions={['complete', 'fail', 'digit', 'wait', 'imgerr', 'fix']}
        checkData={null}
        onCancel={null}
      >
        <DataGridPro
          rowHeight={150}
          rows={deviceImageList === null ? [] : rows}
          columns={columns}
          disableColumnMenu
          autoHeight={true}
          loading={loading}
          checkboxSelection={false}
          paginationMode={'server'}
          hideFooterPagination={true}
          pagination={true}
          hideFooter={true}
          sx={{height: 685}}
        />
        <CustomPagination filter={filter} setFilter={setFilter} totalCount={totalCount} />
      </MeterariumPageLayout>
    </>
  )
}

export default DeviceImages
