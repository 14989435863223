export const isDev = process.env.NODE_ENV === 'development'
export const envBackHost = process.env.REACT_APP_BACK_HOST
export const wssBackHost = process.env.REACT_APP_WS_HOST
export const chargeBackHost = process.env.REACT_APP_CHARGE_BACK_HOST
export const envImagePath = process.env.REACT_APP_IMAGE_PATH
export const serverLocalName = process.env.REACT_APP_LOCAL_NAME
/** env console */

let cssRule =
  'color:#0883f3;' +
  'font-size: 17px;' +
  'font-weight: bold;' +
  'letter-space:-1px;' +
  'font-family:Tahoma,Arial,sans-serif'
let cssLogo1 =
  'color:#05eb06;' +
  'font-size: 20px;' +
  'font-weight: bold;' +
  'letter-space:-1px;' +
  'font-family:Tahoma,Arial,sans-serif' +
  'text-align:center'
if (isDev) {
  setTimeout(console.log.bind(console, '%c====개발자 전용모드====', cssRule), 0)
  if (window.console != undefined) {
    setTimeout(
      console.log.bind(console, '%cMETERARIUM \n 스마트 디지털 이미지 원격검침시스템', cssLogo1),
      0
    )
  }
} else {
  setTimeout(
    console.log.bind(
      console,
      '%c===============Thank you for visiting Meterarium.==============',
      cssRule
    ),
    0
  )
  if (window.console != undefined) {
    setTimeout(
      console.log.bind(console, '%cMETERARIUM \n 스마트 디지털 이미지 원격검침시스템', cssLogo1),
      0
    )
  }
}

if (isDev) {
  // console.log('API host : ', envBackHost)
} else {
  console.log('API host check')
  console.log('DB conn check')
  console.warn('모든 저작권은 영아이티 주식회사에게 있습니다.')
  console.log('자세한 문의는 https://young-it.co.kr 를 참고해주세요. ')
  console.log('대표전화 : 042-932-0170 ')
}
