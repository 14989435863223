import {cooperActionTypes} from '../actionTypes'

interface CooperInfoState {
  label: string | null
  value: number | null
  isLoading: boolean
  error: string | null
}

const initialState: CooperInfoState = {
  label: '전체',
  value: 0,
  isLoading: false,
  error: null,
}

const cooperInfoReducer = (state = initialState, action: any): CooperInfoState => {
  switch (action.type) {
    case cooperActionTypes.COOPER_INFO_REQUEST:
      return {
        ...action.payload,
        isLoading: true,
        error: null,
      }

    case cooperActionTypes.COOPER_INFO_DELETE:
      return {
        label: '전체',
        value: 0,
        isLoading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default cooperInfoReducer
