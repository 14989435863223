import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {useSelector} from 'react-redux'

const OfficeConfigHeader = (props: any) => {
  const location = useLocation()
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  return (
    <>
      {!isMobileDevice() && (
        <div className={'row row-cols-2 gap-5 px-0 mx-0'}>
          <div className='card mb-5 col-lg'>
            <div className='card-body d-flex pt-4 pb-0 justify-content-between'>
              <div className='d-flex overflow-auto h-55px '>
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder flex-nowrap'
                  style={{fontSize: '22px'}}
                >
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/settings/setup' && 'active')
                      }
                      to='/settings/setup'
                    >
                      기초정보
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {!isMobileDevice() && (
            <div className='card col-lg-4 mb-5 justify-content-center'>
              <div className={'d-flex  gap-6 text-center fw-bold fs-4'}>
                {userInfo.accountType.includes('sys') ? (
                  <div className={'d-flex  gap-6 text-center fw-bold fs-4'}>
                    <span>OFFICE에 사업소를 먼저 등록하고 진행해주세요.</span>
                    <a
                      href={
                        'http://office.wicap.co.kr:9080/portal/code/area/areaCode.do?menuId=M_009002'
                      }
                      target={'_blank'}
                    >
                      바로가기
                    </a>
                  </div>
                ) : (
                  <span>사업소의 기본설정을 진행합니다.</span>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {isMobileDevice() && (
        <div className={'w-100'}>
          <div className='card mb-5 mb-xl-10 col-lg mt-5'>
            <div className='card-body d-flex pt-4 pb-0 '>
              <div className='d-flex overflow-auto h-auto '>
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-bolder justify-content-evenly'
                  style={{fontSize: '20px'}}
                >
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/settings/setup' && 'active')
                      }
                      to='/settings/setup'
                    >
                      기초정보
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default OfficeConfigHeader
