import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import RotateLeftTwoToneIcon from '@mui/icons-material/RotateLeftTwoTone'
import SearchIcon from '@mui/icons-material/Search'
import {Backdrop, Button, Chip, Modal, TextField} from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import {DataGridPro, GridColDef} from '@mui/x-data-grid-pro'
import {DateRangePicker, LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import {DateRange} from '@mui/x-date-pickers-pro/DateRangePicker'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {yMarker} from 'assets/imageDeclear'
import dayjs, {Dayjs} from 'dayjs'
import {envBackHost, envImagePath} from 'GLOBAL/envVariables'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import fileDownload from 'js-file-download'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {globalFormatWithHyphens} from 'utils/const/globalConst'
import {resultAlert} from 'utils/CustomAlert/Alert'
import {CustomerDetailChart} from './customerDetailChart'
import './print.css'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: '90%',
  // height: !isMobileDevice() ? '90%' : '90%',
  bgcolor: 'background.paper',
  overflowY: 'auto',
  // border: '2px solid #000',
  borderRadius: '12px',
  boxShadow: 24,
  p: !isMobileDevice() ? 4 : 2,
}
const DashboardDetailModal = (props: any) => {
  const {data, open, onClose} = props
  const [date, setDate] = React.useState<DateRange<Dayjs>>([null, null])
  const [pageSize, setPageSize] = useState<number>(60)
  const [imageList, setImageList] = useState<any>(null)
  const officeInfo = useSelector((state: any) => state?.officeInfo)
  const userInfo = useSelector((state: any) => state?.userInfo?.userInfo)
  const [filter, setFilter] = useState<any>({
    startDate: !undefined
      ? dayjs().subtract(7, 'day').format('YYYYMMDD')
      : date[0]?.format('YYYYMMDD'),
    endDate: !undefined ? dayjs().format('YYYYMMDD') : date[1]?.format('YYYYMMDD'),
  })
  const getImageList = () => {
    axiosWithAuth()
      .get(`${envBackHost}/${data?.officeId}/customer/${data?.customerNumber}/checkmeter`, {
        params: filter,
      })
      .then((res) => {
        setImageList(res.data.res)
      })
  }
  const getDownloadData = async () => {
    axiosWithAuth()
      .get(
        `${envBackHost}/${data?.officeId}/customer/${data?.customerNumber}/checkmeter/excel?startDate=${filter.startDate}&endDate=${filter?.endDate}`,
        {
          responseType: 'blob',
        }
      )
      .then(async (res) => {
        await resultAlert({resultCode: 0})
        fileDownload(
          res.data,
          `${data?.customerNumber}수용가_${filter.startDate}~${filter.endDate}_검침상세정보.xlsx`
        )
        await document.getElementById('downloadModalClose')?.click()
      })
      .catch(async (e) => {
        await resultAlert({resultCode: e.response.status})
      })
  }

  const columns: GridColDef[] = [
    {
      field: 'createAt',
      headerName: '검침일시',
      width: 260,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return <div>{dayjs(params?.row?.createAt).format('YYYY-MM-DD HH시 mm분 ss초')}</div>
      },
    },
    {
      field: 'filePath',
      headerName: '검침 이미지',
      width: 220,
      align: 'center',
      headerAlign: 'center',
      hide: data?.deviceType === 'digital',
      renderCell: (params: any) => {
        return (
          <div>
            <img src={envImagePath + params?.row?.filePath} alt='' width={150} />
          </div>
        )
      },
    },

    {
      field: 'processStatus',
      headerName: '이미지 처리상태',
      width: 170,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <span>
            {params.row.processStatus === 'digital' ? '디지털 계량기' : params.row.processStatus}
          </span>
        )
      },
    },
    {
      field: 'checkValue',
      headerName: '검침 값',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.checkValue === '' || params.row.checkValue === null ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.checkValue
                )}
              </span>
              <span>
                {params.row.checkDecimalValue === '' || params.row.checkDecimalValue === null
                  ? ''
                  : '.' + params.row.checkDecimalValue?.split('.')[1]}
              </span>
            </div>
          </>
        )
      },
    },
    {
      field: 'valueUpdate',
      headerName: '검침값 수정',
      width: 280,
      align: 'center',
      headerAlign: 'center',
      hide: data?.deviceType === 'digital',
      renderCell: (params: any) => {
        return (
          <>
            <div className={'d-flex justify-content-center gap-3'}>
              <TextField
                sx={{textAlignLast: 'center'}}
                variant={'outlined'}
                size={'small'}
                value={params.row.checkValue}
              />
              <Button
                variant={'contained'}
                color={params.row.processStatus === 'COMPLETE' ? 'success' : 'primary'}
                size={'small'}
              >
                수정
              </Button>
            </div>
          </>
        )
      },
    },
  ]
  const rows = _.map(imageList, (el, i) => {
    return {
      id: i + 1,
      addressDeatil: el?.addressDeatil,
      addressRoad: el?.addressRoad,
      checkValue: el?.checkValue,
      custsomerName: el?.custsomerName,
      customerNumber: el?.customerNumber,
      customerTypeName: el?.customerTypeName,
      customerWorkTypeName: el?.customerWorkTypeName,
      deviceType: el?.deviceType,
      deviceTypeName: el?.deviceTypeName,
      deviceVersion: el?.deviceVersion,
      meterDiameter: el?.meterDiameter,
      filePath: el?.filePath,
    }
  })
  useEffect(() => {
    getImageList()
  }, [data])

  useEffect(() => {
    const today = dayjs()
    const oneWeekAgo = today.subtract(7, 'day')
    setDate([oneWeekAgo, today])
  }, [])
  useEffect(() => {
    if (data && data.latitude && data.longitude) {
      setTimeout(() => {
        let container = document?.getElementById('dashboard-detail-map')
        if (container) {
          const map = new window.kakao.maps.Map(container, {
            center: new window.kakao.maps.LatLng(data.latitude, data.longitude),
            mapTypeId: window.kakao.maps.MapTypeId.HYBRID,
            level: 1,
            draggable: false,
            scrollwheel: false,
            zoomable: false,
          })
          // const mapTypeControl = new window.kakao.maps.MapTypeControl()
          // map.addControl(mapTypeControl, window.kakao.maps.ControlPosition.TOPRIGHT)
          var markerPosition = new window.kakao.maps.LatLng(data.latitude, data.longitude)

          var marker = new window.kakao.maps.Marker({
            position: markerPosition,
            image: new window.kakao.maps.MarkerImage(yMarker, new window.kakao.maps.Size(40, 59)),
          })

          marker.setMap(map)
        }
      }, 300)
    }
  }, [data])
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby={`${window.location.pathname}-label`}
      aria-describedby={`${window.location.pathname}-description`}
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      id={'printTarget'}
      className={'printTarget'}
    >
      <Box sx={style}>
        <>
          {!isMobileDevice() && (
            <div>
              <div>
                <div className=''>
                  <div className={'w-100 d-flex justify-content-between'}>
                    <Chip
                      label={`${data?.customerName} 상세정보`}
                      variant={'outlined'}
                      sx={{fontSize: '20px', fontWeight: 'bold'}}
                    />
                    {/*<Button variant={'contained'} onClick={onClose}>*/}
                    <CancelPresentationIcon
                      // className={'point-cursor'}
                      sx={{fontSize: '40px', cursor: 'pointer'}}
                      color={'error'}
                      onClick={onClose}
                    />
                    {/*</Button>*/}
                  </div>

                  <div className=''>
                    {data && (
                      <>
                        <div className={'d-flex gap-6 col'}>
                          <div className={'col'}>
                            <div className={'row mx-0 gap-2'}>
                              <div className={' border border-1 border-gray-400 shadow-sm px-0'}>
                                <Paper
                                  className={'p-4 border-bottom border-1 border-gray-400 '}
                                  square
                                >
                                  <h2>수용가 정보</h2>
                                </Paper>
                                <Paper className={'p-4 '} square>
                                  <div className={'row gap-2'}>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 수용가번호
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={globalFormatWithHyphens(
                                          data?.officeId,
                                          data?.customerNumber
                                        )}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 수용가 이름
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.customerName}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 수용가 주소
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={
                                          data?.addressRoad === null
                                            ? data?.addressNumber
                                            : data?.addressRoad
                                        }
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>

                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 소속 사업소
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.officeName}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 계량기 구경
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.meterDiameter}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 수용가 종류
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.customerTypeName}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 수용가 업종
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.customerWorkTypeName}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Paper>
                              </div>
                            </div>
                          </div>
                          <div className={'col '}>
                            <div className={'row mx-0 gap-2 align-self-stretch'}>
                              <div
                                className={
                                  ' border border-1 border-gray-400 shadow-sm px-0 align-self-stretch'
                                }
                              >
                                <Paper
                                  className={
                                    'p-4 border-bottom border-1 border-gray-400 align-self-stretch '
                                  }
                                  square
                                >
                                  <h2>{dayjs().format('YYYY년 M월 DD일') + ' 검침 정보'}</h2>
                                </Paper>
                                <Paper className={'p-4 '} square>
                                  <div className={'row gap-2'}>
                                    <div className={'d-flex w-100 justify-content-center my-2'}>
                                      <img
                                        src={envImagePath + data?.filePath}
                                        style={{
                                          transform: `rotate(${data?.rotate}deg)`,
                                          width: 320,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 단말기 방식
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.deviceTypeName}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 단말기 버전
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.deviceVersion}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 검침값
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.checkValue}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Paper>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={'col'}>
                          <div
                            id='dashboard-detail-map'
                            style={{width: '100%', height: 350, cursor: 'pointer'}}
                            onClick={() => {
                              window.open(
                                `https://m.map.kakao.com/scheme/search?q=${data.addressRoad}&center=me`,
                                '_blank'
                              )
                            }}
                            className={'rounded-2 border-gray-400 border border-1'}
                          />
                        </div>
                        <div className={'text-end'}>
                          <span className={'text-danger fw-bold text-end'}>
                            지도를 클릭하면 카카오맵으로 이동합니다.
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isMobileDevice() && (
            <div className='w-100'>
              <div className={'w-100 d-flex justify-content-between'}>
                <Chip
                  label={`${data?.imei} 검침 상세정보`}
                  variant={'outlined'}
                  sx={{fontSize: '14px', fontWeight: 'bold'}}
                />
                {/*<Button variant={'contained'} onClick={onClose}>*/}
                <CancelPresentationIcon
                  // className={'point-cursor'}
                  sx={{fontSize: '40px', cursor: 'pointer'}}
                  color={'error'}
                  onClick={onClose}
                />
                {/*</Button>*/}
              </div>

              <div className=''>
                {data && (
                  <div className={'row gap-6 col'}>
                    <div className={'col'}>
                      <div className={'row mx-0 gap-2'}>
                        <div className={' border border-1 border-gray-400 shadow-sm px-0'}>
                          <Paper className={'p-4 border-bottom border-1 border-gray-400 '} square>
                            <h2>수용가 정보</h2>
                          </Paper>
                          <Paper className={'p-4 '} square>
                            <div className={'row gap-2'}>
                              <div
                                className={'row w-100 justify-content-between align-items-center'}
                              >
                                <h2 className={'d-flex align-items-center gap-2'}>
                                  <DoubleArrowIcon /> 수용가번호
                                </h2>
                                <TextField
                                  variant={'outlined'}
                                  className={'mx-2 bg-gray-300'}
                                  value={data?.customerNumber}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div
                                className={'row w-100 justify-content-between align-items-center'}
                              >
                                <h2 className={'d-flex align-items-center gap-2'}>
                                  <DoubleArrowIcon /> 수용가 이름
                                </h2>
                                <TextField
                                  variant={'outlined'}
                                  className={'mx-2 bg-gray-300'}
                                  value={data?.customerName}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div
                                className={'row w-100 justify-content-between align-items-center'}
                              >
                                <h2 className={'d-flex align-items-center gap-2'}>
                                  <DoubleArrowIcon /> 수용가 주소
                                </h2>
                                <TextField
                                  variant={'outlined'}
                                  className={'mx-2 bg-gray-300'}
                                  value={data?.addressRoad}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div
                                className={'row w-100 justify-content-between align-items-center'}
                              >
                                <h2 className={'d-flex align-items-center gap-2'}>
                                  <DoubleArrowIcon /> 계량기 번호
                                </h2>
                                <TextField
                                  variant={'outlined'}
                                  className={'mx-2 bg-gray-300'}
                                  value={data?.meterNumber}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div
                                className={'row w-100 justify-content-between align-items-center'}
                              >
                                <h2 className={'d-flex align-items-center gap-2'}>
                                  <DoubleArrowIcon /> 계량기 구경
                                </h2>
                                <TextField
                                  variant={'outlined'}
                                  className={'mx-2 bg-gray-300'}
                                  value={data?.meterDiameter + 'mm'}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div
                                className={'row w-100 justify-content-between align-items-center'}
                              >
                                <h2 className={'d-flex align-items-center gap-2'}>
                                  <DoubleArrowIcon /> 단말기 IMEI
                                </h2>
                                <TextField
                                  variant={'outlined'}
                                  className={'mx-2 bg-gray-300'}
                                  value={data?.imei}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div
                                className={'row w-100 justify-content-between align-items-center'}
                              >
                                <h2 className={'d-flex align-items-center gap-2'}>
                                  <DoubleArrowIcon /> 단말기 SN
                                </h2>
                                <TextField
                                  variant={'outlined'}
                                  className={'mx-2 bg-gray-300'}
                                  value={data?.serialNumber}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div
                                className={'row w-100 justify-content-between align-items-center'}
                              >
                                <h2 className={'d-flex align-items-center gap-2'}>
                                  <DoubleArrowIcon /> 단말기 타입
                                </h2>
                                <TextField
                                  variant={'outlined'}
                                  className={'mx-2 bg-gray-300'}
                                  value={data?.deviceType === 'analog' ? '디지털 이미지' : '디지털'}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                            </div>
                          </Paper>
                        </div>
                        <div
                          id='map'
                          style={{width: '100%', height: 330}}
                          className={'rounded-2 border-gray-400 border border-1'}
                        />
                      </div>
                    </div>
                    <div className={'col'}>
                      <div className={'d-flex gap-2 w-100'}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          localeText={{start: '시작날짜', end: '마지막 날짜'}}
                        >
                          <DateRangePicker
                            PopperProps={{
                              style: {
                                zIndex: 9999,
                              },
                            }}
                            value={date}
                            calendars={3}
                            mask='____/__/__'
                            inputFormat={'YYYY/MM/DD'}
                            onChange={(newValue) => {
                              setDate(newValue)
                              setFilter({
                                ...filter,
                                startDate: newValue[0]?.format('YYYYMMDD'),
                                endDate: newValue[1]?.format('YYYYMMDD'),
                              })
                            }}
                            renderInput={(startProps, endProps) => {
                              return (
                                <div className={'d-flex gap-3'}>
                                  <TextField {...startProps} autoComplete={'off'} />
                                  <ArrowForwardIcon className={'align-self-center'} />
                                  <TextField {...endProps} autoComplete={'off'} />
                                </div>
                              )
                            }}
                          />
                        </LocalizationProvider>
                        <div className={'d-flex gap-3'}>
                          <Button
                            variant={'contained'}
                            color={'error'}
                            onClick={async () => {
                              const today = dayjs()
                              const oneWeekAgo = today.subtract(7, 'day')
                              setFilter({
                                ...filter,
                                startDate: dayjs().subtract(7, 'day').format('YYYYMMDD'),
                                endDate: dayjs().format('YYYYMMDD'),
                              })
                              setDate([oneWeekAgo, today])
                            }}
                          >
                            <RotateLeftTwoToneIcon sx={{width: 24, height: 24}} />
                          </Button>
                          <Button variant={'contained'} onClick={() => getImageList()}>
                            <SearchIcon sx={{width: 24, height: 24}} />
                          </Button>
                        </div>
                      </div>
                      <div className={'ps-1'} style={{height: '330px'}}>
                        {imageList && (
                          <CustomerDetailChart
                            className={'w-100 px-3'}
                            data={data}
                            imageList={imageList}
                            filter={filter}
                          />
                        )}
                      </div>
                      <div className={'pe-3'}>
                        <Box id={'table-content'} sx={{height: 600, width: '100%'}}>
                          <DataGridPro
                            rows={rows}
                            columns={columns}
                            rowHeight={90}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 60, 100]}
                            disableColumnMenu
                            pagination
                            loading={imageList === null}
                          />
                        </Box>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      </Box>
    </Modal>
  )
}
export default DashboardDetailModal
