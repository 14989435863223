export const installModalStyle = {
  position: 'absolute',
  top: '50%', // Start off-screen
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '98%',
  height: window.innerHeight * 0.9,
  bgcolor: 'background.paper',
  borderRadius: '12px',
  zIndex: 900,
  boxShadow: 24,
  p: 0,
  transition: 'top 0.3s ease-out',
}
