import React, {useEffect, useState} from 'react'
import {Box, Button, IconButton, InputAdornment, TextField} from '@mui/material'
import {envBackHost} from 'GLOBAL/envVariables'
import _ from 'lodash'
import {DataGridPro, GridColDef, GridRowId} from '@mui/x-data-grid-pro'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import {useSelector} from 'react-redux'
import {ToastContainer} from 'react-toastify'
import {resultAlert} from 'utils/CustomAlert/Alert'
import CancelIcon from '@mui/icons-material/Cancel'

const DeviceAssignModal = (props: any) => {
  const {data, typeName, resetHandler} = props
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const [installerList, setInstallerList] = useState<any>()
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([])
  const [installerSelect, setInstallerSelect] = useState<any>()
  const [pageSize, setPageSize] = useState<number>(30)
  const [config, setConfig] = useState<any>(null)
  const [installFilterList, setInstallFilterList] = useState<any>()
  const [searchData, setSearchData] = useState<any>('')
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const getInstallerList = async () => {
    axiosWithAuth()
      .post(`${envBackHost}/account/list`, config)
      .then((res) => {
        const _res = res.data.res
        setInstallerList(_res)
      })
  }
  const columns: GridColDef[] = [
    // {field: 'id', headerName: 'No.', width: 90, align: 'center', headerAlign: 'center'},
    {
      field: 'installerName',
      headerName: '설치자 이름',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'assignAccountId',
      headerName: '설치자 ID',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
  ]
  const rows = _.map(searchData === '' ? installerList : installFilterList, (el, i) => {
    return {
      id: i + 1,
      installerName: el.name,
      assignAccountId: el.id,
    }
  })
  const buttonHandler = async () => {
    console.log(data)
    axiosWithAuth()
      .post(`${envBackHost}/device/assign/assigned`, {
        device: data,
        fromId: userInfo?.userId,
        toId: installerSelect.assignAccountId,
      })
      .then(async (res) => {
        await resultAlert(res.data)
        if (res.data.resultCode === 0) {
          await resetHandler()
          setSelectionModel([])
          document.getElementById('deviceAllocationModalClose')?.click()
        }
      })
      .catch(async (e) => {
        await resultAlert({resultCode: e.response.status})
      })
  }
  useEffect(() => {
    if (typeName) {
      getInstallerList()
    }
  }, [typeName])
  useEffect(() => {
    setConfig({
      officeId: officeInfo?.value,
      accountType: 'installer',
      idActiveStatus: true,
    })
  }, [])
  useEffect(() => {
    if (selectionModel.length === 0) {
      setInstallerSelect({})
    }
  }, [selectionModel])
  useEffect(() => {
    if (searchData !== '') {
      setInstallFilterList(
        _.filter(installerList, (el) => {
          return el.name.includes(searchData) || el.id.includes(searchData)
        })
      )
    }
    setSelectionModel([])
  }, [searchData])
  return (
    <div
      className='modal fade'
      id='deviceAllocationModal'
      tabIndex={-1}
      aria-labelledby='deviceAllocationModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered p-3'>
        <div className='modal-content  rounded-2'>
          <div className='modal-header'>
            <h5 className='modal-title' id='deviceAllocationModalLabel'>
              단말기 할당 - 설치자 선택
            </h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              id={'deviceAllocationModalClose'}
            ></button>
          </div>
          <div className='modal-body'>
            <div className={'d-flex gap-3 px-0 mx-0 mb-3'}>
              <TextField
                variant={'outlined'}
                id={'search-field'}
                className={'w-100'}
                placeholder={'설치자 검색'}
                fullWidth
                value={searchData}
                onChange={(e) => {
                  setSearchData(e.target.value)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='resetBtn'
                        edge='end'
                        onClick={() => {
                          setSearchData('')
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Box id={'table-content'} sx={{height: 635, width: '100%'}}>
              <DataGridPro
                headerHeight={30}
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[30, 50, 70, 100, 200]}
                disableColumnMenu
                selectionModel={selectionModel}
                pagination
                hideFooterRowCount
                hideFooterSelectedRowCount
                checkboxSelection
                onSelectionModelChange={(selection) => {
                  if (selection.length > 1) {
                    const selectionSet = new Set(selectionModel)
                    const result = selection.filter((s) => !selectionSet.has(s))

                    setSelectionModel(result)
                  } else {
                    setSelectionModel(selection)
                  }
                }}
                onCellClick={(params) => {
                  setInstallerSelect(params.row)
                }}
                sx={{
                  fontSize: '13px',
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                  },
                  cursor: 'pointer',
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
            <div className={'my-3 text-center'}>
              {data?.length < 1 ? (
                <span>할당할 수 있는 데이터가 없습니다.</span>
              ) : (
                <span className={'text-muted fs-4 '}>
                  <strong className={'text-primary'}>{data?.length}개</strong>의 데이터를
                  <strong className={'text-primary'}>
                    {installerSelect?.installerName
                      ? `${installerSelect?.installerName}(${installerSelect?.assignAccountId})`
                      : '선택'}
                  </strong>
                  님께 할당합니다.
                </span>
              )}
            </div>
          </div>
          <div className='modal-footer gap-5'>
            <Button
              variant={'contained'}
              className={'p-5'}
              type={'button'}
              disabled={data?.length < 1 || !installerSelect?.assignAccountId}
              onClick={() => buttonHandler()}
            >
              할당
            </Button>
            <Button
              id={'assignedModalClose'}
              variant={'contained'}
              className={'p-5'}
              color={'error'}
              type={'button'}
              data-bs-dismiss='modal'
              onClick={() => {
                setSelectionModel([])
                setInstallerSelect({})
              }}
            >
              취소
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default DeviceAssignModal
