import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {Line} from 'react-chartjs-2'
import dayjs from 'dayjs'
import _ from 'lodash'
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const ActivityMonitoringChart = (props: any) => {
  const {monitoringList} = props
  const options = {
    responsive: true,
    aspectRatio: 0,
    scale: {
      ticks: {
        // maxTicksLimit: 10,
        font: {
          size: window.innerWidth > 1920 ? 20 : window.innerWidth < 1200 ? 15 : 18,
        },
      },
    },
    plugins: {
      legend: {
        enabled: false,
        display: false,
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context: any) {
            return context.raw + ' t'
          },
          title: function (context: any) {
            return context[0].label
          },
        },
        displayColors: false,
        bodyFont: {
          size: window.innerWidth > 1920 ? 20 : window.innerWidth < 1200 ? 15 : 18,
        },
        bodyAlign: 'center',
        titleFont: {
          size: window.innerWidth > 1920 ? 20 : window.innerWidth < 1200 ? 15 : 18,
        },
        titleAlign: 'left',
        titleMarginBottom: 10,
        backgroundColor: '#252525',
      },
      datalabels: {
        display: true,
        color: 'red',
        align: function (context: any) {
          const value = context.dataset.data[context.dataIndex]
          const minValue = Math.min(...context.dataset.data)

          return value === minValue ? 'top' : 'bottom'
        },
        anchor: 'center',
        font: {
          size: window.innerWidth > 1920 ? 20 : window.innerWidth < 1200 ? 15 : 18,
          weight: 'bold',
        },
        formatter: function (value: any, context: any) {
          return value + 't'
        },
      },
      yaxis: {
        labels: {
          formatter: function (value: any, context: any) {
            return value + 't'
          },
        },
      },
    },
  }
  const labels = _.map(monitoringList, (el, i) => {
    return dayjs(el.createAt).format('HH:mm')
  })

  const data = {
    labels,
    datasets: [
      {
        label: '사용량',
        data: _.map(monitoringList, (el, i: any) => {
          const value = Number(el.checkValue) + Number(el.checkDecimalValue)
          return value
        }),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        pointRadius: 6,
        pointHoverRadius: 10,
        pointHoverBorderWidth: 2,
        pointHoverBorderColor: 'rgba(255,0,0)',
        pointHoverBackgroundColor: 'rgba(255,0,0,0.5)',
      },
    ],
  }
  return (
    <>
      <div style={{height: '100%', width: '100%'}} className={''}>
        <Line options={options as any} data={data} />
      </div>
    </>
  )
}
export default ActivityMonitoringChart
