import React, {useEffect, useState} from 'react'
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material'
import dayjs from 'dayjs'
import axios from 'axios'
import {envBackHost} from '../../../GLOBAL/envVariables'
import fileDownload from 'js-file-download'
import _ from 'lodash'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {axiosWithAuth} from '../../../helper/axiosWithAuth'
import {useSelector} from 'react-redux'
import {toast, ToastContainer} from 'react-toastify'
import {resultAlert} from '../../../utils/CustomAlert/Alert'

const BatchRegisterOffcanvas = (props: any) => {
  const [file, setFile] = useState<File>()
  const [fileName, setFileName] = useState<string>('')
  const [processing, setProcessing] = useState<boolean>(false)
  const [failList, setFailList] = useState<any[]>([])
  const [deviceTypeList, setDeviceTypeList] = useState<any[]>([])
  const [cooperList, setCooperList] = useState<any[]>([])
  const [batchInfo, setBatchInfo] = useState<any>({
    deviceType: '',
    userId: '',
    cooperIdx: '',
  })
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const exampleExcelDownload = async () => {
    const fileName = `Device_register_batch_example_${dayjs().format('YYYY-MM-DD HH:mm')}.xlsx`
    axiosWithAuth()
      .get(`${envBackHost}/device/excel`, {
        responseType: 'blob',
      })
      .then(async (res) => {
        console.log(res)
        await resultAlert({resultCode: res.status})
        if (res.status === 200) {
          fileDownload(res.data, fileName)
        }
      })
  }
  const getDeviceType = () => {
    axiosWithAuth()
      .get(`${envBackHost}/devicetype`)
      .then((res) => {
        setDeviceTypeList(res.data.res)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const getCooperList = () => {
    axiosWithAuth()
      .get(`${envBackHost}/cooperation/device`)
      .then((res) => {
        setCooperList(res.data.res)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const fileValidationUploadHandler = async (e: any) => {
    const target = e.currentTarget
    const file = (target.files as FileList)[0]

    setFile(file)
    setFileName(file.name)
  }
  const fileUploadHandler = async () => {
    const formData = new FormData()
    formData.append('file', file as Blob)
    formData.append('id', userInfo?.userId)
    formData.append('deviceType', batchInfo?.deviceType)
    formData.append('cooperIdx', batchInfo?.cooperIdx)
    setProcessing(true)
    await toast.promise(
      axiosWithAuth()
        .post(`${envBackHost}/device/excel`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(async (res) => {
          setProcessing(false)
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            document.getElementById('closeBtn')?.click()
            return
          } else {
            setFailList(res.data.res)
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
          setProcessing(false)
        }),
      {
        pending: '단말기 일괄 등록 중 입니다.',
      },
      {
        position: 'bottom-left',
        closeButton: false,
        className: 'fs-3  fw-bold',
        style: {width: '400px'},
      }
    )
  }
  useEffect(() => {
    setFailList([])
  }, [fileName])
  useEffect(() => {
    getDeviceType()
    getCooperList()
    setBatchInfo({
      ...batchInfo,
      userId: userInfo?.userId,
    })
  }, [])
  return (
    <>
      <div
        className='offcanvas offcanvas-end'
        tabIndex={-1}
        id='batchRegister'
        aria-labelledby='batchRegisterLabel'
        style={{width: isMobileDevice() ? '100%' : '40%'}}
      >
        <div className='offcanvas-header'>
          <h2 className='offcanvas-title' id='batchRegisterLabel'>
            단말기 일괄등록
          </h2>
          <button
            type='button'
            className='btn-close text-reset'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          ></button>
        </div>
        <div className='offcanvas-body mb-6'>
          <div className={'row row-cols mb-8 '}>
            <div className={'col-lg'}>
              <TextField
                variant={'outlined'}
                label={'파일 이름 　　'}
                value={fileName}
                disabled={true}
                fullWidth
                helperText={
                  fileName === '' ? (
                    <strong className={'fs-5'}>파일을 선택해주세요.</strong>
                  ) : failList?.length > 0 ? (
                    <strong className={'text-primary fs-5'}>
                      잘못 업로드한 경우 다시 찾아보기를 클릭해주세요.
                    </strong>
                  ) : (
                    <strong className={'text-primary fs-5'}>일괄등록을 눌러주세요 !</strong>
                  )
                }
                inputProps={{
                  style: {
                    fontSize: 20,
                    color: 'black',
                    fontWeight: 'bold',
                  },
                }}
                InputLabelProps={{style: {fontSize: 20, color: 'black'}}}
              />
            </div>
            <div className={'col-lg d-flex gap-4'}>
              <Button variant='contained' component='label'>
                {fileName !== '' || failList?.length > 0 ? '다시 찾아보기' : '찾아보기'}
                <input
                  type={'file'}
                  id={'fileItem'}
                  accept={'.xlsx'}
                  onChange={(e) => fileValidationUploadHandler(e)}
                  hidden
                />
              </Button>
              <Button
                variant={'contained'}
                color={'success'}
                onClick={() => exampleExcelDownload()}
              >
                샘플양식 다운로드
              </Button>
            </div>
          </div>
          <div className={'d-flex gap-4'}>
            <FormControl className={'col-lg'}>
              <InputLabel id='demo-simple-select-label'>단말기 타입 선택</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='customer-target'
                label='단말기 타입 선택'
                value={batchInfo?.deviceType}
                onChange={(e) => {
                  setBatchInfo({...batchInfo, deviceType: e.target.value})
                }}
              >
                {_.map(deviceTypeList, (el, i) => {
                  return (
                    <MenuItem key={i} value={el?.deviceType}>
                      {el?.deviceTypeName}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl className={'col-lg'}>
              <InputLabel id='demo-simple-select-label'>제조사 선택</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='customer-target'
                label='제조사 선택'
                value={batchInfo?.cooperIdx}
                onChange={(e) => {
                  setBatchInfo({...batchInfo, cooperIdx: e.target.value})
                }}
              >
                {_.map(cooperList, (el, i) => {
                  return (
                    <MenuItem key={i} value={el?.idx}>
                      {el?.cooperName}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
          {failList?.length > 0 && (
            <>
              <div className={'my-4 d-flex px-2'}>
                <h3 className={'text-danger fs-2'}> {fileName} 업로드 실패 목록</h3>
                <span className={'text-muted fw-bolder mx-2 fs-5'}>( {failList?.length}건 )</span>
              </div>
              <table
                className='table table-hover table-rounded  border  gy-3 gs-3 text-center'
                style={{overflow: 'scroll', maxHeight: '1024px'}}
              >
                <thead style={{position: 'sticky', top: 0}} className={'bg-gray-600 '}>
                  <tr className='fw-bolder fs-5  border-bottom-2 border-gray-200 text-white'>
                    <th>No.</th>
                    <th>단말기 S/N</th>
                    <th>IMEI (단말기 ID)</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(failList, (el, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{el?.serialNumber}</td>
                        <td>{el?.imei}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
        <div className={'offcanvas-footer'}>
          <div
            className={'d-flex gap-7 justify-content-end '}
            style={{marginBottom: '50px', marginRight: '30px'}}
          >
            <Button
              variant={'contained'}
              size={'large'}
              color={'info'}
              disabled={processing || batchInfo?.deviceType === '' || batchInfo?.cooperIdx === ''}
              // data-bs-dismiss='offcanvas'
              onClick={() => fileUploadHandler()}
            >
              일괄등록
            </Button>
            <Button
              variant={'contained'}
              size={'large'}
              color={'error'}
              id={'closeBtn'}
              data-bs-dismiss='offcanvas'
              disabled={processing}
            >
              나가기
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}
export default BatchRegisterOffcanvas
