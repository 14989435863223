import React, {useEffect, useState} from 'react'
import {TextField} from '@mui/material'
import {envBackHost} from '../../../GLOBAL/envVariables'
import {ToastContainer} from 'react-toastify'
import {axiosWithAuth} from '../../../helper/axiosWithAuth'
import {confirmAlert, normalAlert, resultAlert} from '../../../utils/CustomAlert/Alert'
const WorkTypeUpdateModal = (props: any) => {
  const {setDetailInfoFlag, detailInfoFlag, selectedWorkType, setSelectedWorkType} = props
  const [updateWorkTypeInfo, setUpdateWorkTypeInfo] = useState<any>(null)
  const myModal: any = document.getElementById('workTypeUpdateModal')
  myModal?.addEventListener('hidden.bs.modal', function () {
    setSelectedWorkType(null)
  })
  /** API **/
  const updateHandler = async () => {
    if (updateWorkTypeInfo?.customerWorkTypeName === '') {
      await normalAlert('수용가 업종 이름을 입력해주세요.')
      return
    }
    if (updateWorkTypeInfo?.customerWorkTypeName === selectedWorkType?.customerWorkTypeName) {
      await normalAlert('수용가 업종 이름이 변경되지 않았습니다.')
      return
    }
    axiosWithAuth()
      .put(
        `${envBackHost}/office/${selectedWorkType.officeId}/customerworktype`,
        updateWorkTypeInfo
      )
      .then(async (res) => {
        await resultAlert(res.data)
        if (res.data.resultCode === 0) {
          setDetailInfoFlag(!detailInfoFlag)
          setUpdateWorkTypeInfo(null)
          document.getElementById('workTypeCloseButton')?.click()
        }
      })
      .catch(async (e) => {
        await resultAlert({resultCode: e.response.status})
      })
  }

  const deleteHandler = async () => {
    if (await confirmAlert('삭제', 'info')) {
      axiosWithAuth()
        .delete(
          `${envBackHost}/office/${selectedWorkType.officeId}/customerworktype/${selectedWorkType.idx}`
        )
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            setDetailInfoFlag(!detailInfoFlag)
            setUpdateWorkTypeInfo(null)
            document.getElementById('workTypeCloseButton')?.click()
            return
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    } else {
      return
    }
  }

  useEffect(() => {
    setUpdateWorkTypeInfo({
      idx: selectedWorkType?.idx,
      customerWorkTypeName: selectedWorkType?.customerWorkTypeName,
    })
  }, [selectedWorkType])
  return (
    <>
      <div
        className='modal fade'
        id='workTypeUpdateModal'
        tabIndex={-1}
        aria-labelledby='workTypeUpdateModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='workTypeUpdateModalLabel'>
                수용가 업종 수정
              </h5>
              <button
                id={'workTypeCloseButton'}
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              {selectedWorkType?.customerWorkTypeName && (
                <TextField
                  variant={'outlined'}
                  id={'textArea'}
                  label={'수용가 업종 명'}
                  defaultValue={selectedWorkType?.customerWorkTypeName}
                  placeholder={'수용가 업종 명을 입력해주세요.'}
                  onChange={(e) => {
                    setUpdateWorkTypeInfo({
                      ...updateWorkTypeInfo,
                      customerWorkTypeName: e.target.value,
                    })
                  }}
                  fullWidth
                />
              )}
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-danger' onClick={() => deleteHandler()}>
                삭제
              </button>
              <button type='button' className='btn btn-primary' onClick={() => updateHandler()}>
                수정
              </button>
              <button
                type='button'
                className='btn btn-secondary'
                data-bs-dismiss='modal'
                // onClick={() => }
              >
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default WorkTypeUpdateModal
