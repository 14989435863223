import React, {useEffect, useState} from 'react'
import {DataGridPro} from '@mui/x-data-grid-pro'
import AddAccountTypeModal from '../modules/addAccountTypeModal'
import ChangeAccountTypeModal from '../modules/changeAccountTypeModal'
import {useSelector} from 'react-redux'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import CreateUserModal from 'pages/account/modules/createUserModal'
import {accountRows} from 'pages/account/dataConfig/declearRows'
import {accountFilter} from 'pages/account/dataConfig/declearFilters'
import {accountColumns} from 'pages/account/dataConfig/declearColumns'
import {
  getAccountList,
  getAccountTypeList,
  getAllOfficeList,
  getCooperList,
  getOfficeList,
} from 'utils/apiCalling/api'
import UpdateUserModal from 'pages/account/modules/updateUserModal'
import {scrollTop} from 'utils/func/justUtils'
import {toolTipText} from 'utils/toolTips/toolTipText'

const Account = (props: any) => {
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const [pageSize, setPageSize] = useState<number>(30)
  const [createUserOpen, setCreateUserOpen] = useState(false)
  const [updateUserOpen, setUpdateUserOpen] = useState(false)
  const createUserModalOpen = () => setCreateUserOpen(true)
  const createUserModalClose = () => setCreateUserOpen(false)
  const updateUserModalOpen = () => setUpdateUserOpen(true)
  const updateUserModalClose = () => setUpdateUserOpen(false)
  const [userDetailData, setUserDetailData] = useState<any>(null)
  const [accountList, setAccountList] = useState<any>(null)
  const [accountTypeList, setAccountTypeList] = useState<any>(null)
  const [officeList, setOfficeList] = useState<any>([])
  const [cooperList, setCooperList] = useState<any>(null)
  const [singleAccountType, setSingleAccountType] = useState<any>({
    accountType: '',
    accountTypeName: '',
  })
  //
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const [filter, setFilter] = useState<any>(accountFilter)

  const getOfficeListCall = async () => {
    getAllOfficeList(userInfo, {
      success: (res: any) => {
        setOfficeList(res)
      },
      fail: (error: any) => {
        console.log(error)
      },
    })
  }
  const getAccountListCall = async () => {
    getAccountList(officeInfo, filter, {
      success: (res: any) => {
        setAccountList(res)
      },
      fail: (error: any) => {
        console.log(error)
      },
    })
  }
  const getCooperListCall = () => {
    getCooperList({
      success: (res: any) => {
        setCooperList(res)
      },
      fail: (error: any) => {
        console.log(error)
      },
    })
  }
  const getAccountTypeListCall = async () => {
    getAccountTypeList({
      success: (res: any) => {
        setAccountTypeList(res)
      },
      fail: (error: any) => {
        console.log(error)
      },
    })
  }

  const columns: any = accountColumns
  const rows = accountRows(accountList)

  useEffect(() => {
    getAccountListCall()
    getAccountTypeListCall()
    getOfficeListCall()
    getCooperListCall()
  }, [filter.accountType, filter.idActiveStatus, officeInfo])
  //
  return (
    <>
      <MeterariumPageLayout
        headerDescription={toolTipText.account.description}
        tooltipText={toolTipText.account.text}
        filter={filter}
        id={'account'}
        setFilter={setFilter}
        searchCallback={getAccountListCall}
        accountTypeList={accountTypeList}
        total={rows?.length}
        control={['role', 'isActive']}
        actions={['create']}
        onCreateUserModalOpen={createUserModalOpen}
        searchOptions={['userId', 'userName']}
      >
        <DataGridPro
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowHeight={60}
          autoHeight={true}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 30, 50, 70, 100]}
          disableColumnMenu
          pagination
          onCellClick={(params) => {
            setUserDetailData(params.row)
            // const myModal = new bootstrap.Modal(
            //   document.getElementById('userDetailModal') as HTMLElement,
            //   {
            //     keyboard: false,
            //     backdrop: true,
            //   }
            // )
            // myModal.show()
            updateUserModalOpen()
          }}
          loading={accountList?.length < 0}
          onPageChange={() => {
            scrollTop()
          }}
        />
      </MeterariumPageLayout>

      {/*<UserDetailModal*/}
      {/*  data={userDetailData}*/}
      {/*  setUserDetailData={setUserDetailData}*/}
      {/*  officeList={officeList}*/}
      {/*  cooperList={cooperList}*/}
      {/*  getAccountList={getAccountListCall}*/}
      {/*/>*/}
      <AddAccountTypeModal
        accountTypeList={accountTypeList}
        singleAccountType={singleAccountType}
        setSingleAccountType={setSingleAccountType}
      />
      <ChangeAccountTypeModal
        accountTypeList={accountTypeList}
        singleAccountType={singleAccountType}
        setSingleAccountType={setSingleAccountType}
        setAccountTypeList={setAccountTypeList}
      />
      <CreateUserModal
        open={createUserOpen}
        close={() => createUserModalClose()}
        accountTypeList={accountTypeList}
        officeList={officeList}
        cooperList={cooperList}
      />
      <UpdateUserModal
        open={updateUserOpen}
        accountTypeList={accountTypeList}
        close={() => updateUserModalClose()}
        data={userDetailData}
        officeList={officeList}
        cooperList={cooperList}
        getAccountList={getAccountListCall}
      />
    </>
  )
}
export default Account
