import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import ListAltIcon from '@mui/icons-material/ListAlt'
import SearchIcon from '@mui/icons-material/Search'
import {Backdrop, Box, Button, Chip, Modal, TextField} from '@mui/material'
import {DataGridPro} from '@mui/x-data-grid-pro'
import {DateRangePicker, LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import dayjs from 'dayjs'
import {envBackHost, envImagePath} from 'GLOBAL/envVariables'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import fileDownload from 'js-file-download'
import _ from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {getLeakListUncertainAll, getLeakListUncertainUser} from 'utils/apiCalling/api'
import {confirmAlert, resultAlert, validationAlert} from 'utils/CustomAlert/Alert'
import {scrollTop} from 'utils/func/justUtils'
import {LeakViewChartBongHwaTemp} from '../charts/LeakViewChartBongHwaTemp'
import {customerLeakageDetailUncertainListColumns} from '../dataConfig/declearColumns'
import {customerLeakageFilter} from '../dataConfig/declearFilters'
import {
  customerLeakageDetailUncertainListRows,
  customerLeakageDetailUncertainUserRows,
} from '../dataConfig/declearRows'

export const LeakDetailModal = (props: any) => {
  const {open, setOpen, filter, leakOption} = props
  /**************************** REDUX ***************************/
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const userInfo = useSelector((state: any) => state?.userInfo?.userInfo)
  /**************************** STATE ***************************/
  const [detailFilter, setDetailFilter] = useState<any>(customerLeakageFilter)
  const [rangedDate, setRangedDate] = useState<any>(['', ''])
  const [officeNum, setOfficeNum] = useState<any>('')
  const [selectedRow, setSelectedRow] = useState<any>(null)
  const [uncertainAllList, setUncertainAllList] = useState<any>(null)
  const [uncertainUserLeakList, setUncertainUserLeakList] = useState<any>(null)
  const [imageList, setImageList] = useState<any>(null)
  const [allLoading, setAllLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [allPageSize, setAllPageSize] = useState<number>(30)
  const [pageSize, setPageSize] = useState<number>(30)
  const [fixValue, setFixValue] = useState<any[]>([])
  const [page, setPage] = useState(1)
  const gridRef = useRef<HTMLDivElement>(null)
  const [updateCheckValue, setUpdateCheckValue] = useState<any>('')

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: selectedRow !== null ? '90%' : '860px',
    height: !isMobileDevice() ? '90%' : '90%',
    bgcolor: 'background.paper',
    overflowY: 'auto',
    borderRadius: '12px',
    boxShadow: 24,
    p: 2,
  }
  /**************************** API ***************************/
  const getUncertainListCall = () => {
    getLeakListUncertainAll(officeInfo, cooperInfo, filter, {
      success: (res: any) => {
        setUncertainAllList(res)
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {
        setAllLoading(loading)
      },
      count: (count: number) => {
        setTotalCount(count)
      },
    })
  }

  const calEndDate = (startDate: any) => {
    let endDate
    if (leakOption?.leakUseCycle == '월') {
      endDate = dayjs(startDate).add(60, 'day').format('YYYYMMDD')
    } else if (leakOption?.leakUseCycle == '주') {
      endDate = dayjs(startDate).add(14, 'day').format('YYYYMMDD')
    } else {
      endDate = dayjs(startDate).add(7, 'day').format('YYYYMMDD')
    }
    return endDate
  }
  const getUncertainCustomerCall = () => {
    getLeakListUncertainUser(officeInfo, cooperInfo, detailFilter, {
      success: (res: any) => {
        if (res !== null) {
          setUncertainUserLeakList(res[0])
          if (res[0]?.calStartDate !== null && res[0]?.calEndDate !== null) {
            updateImageListRangeDate(res[0]?.calStartDate, res[0]?.calEndDate)
          } else {
            updateImageListRangeDate(res[0]?.calStartDate, calEndDate(res[0]?.calStartDate))
          }
        } else {
          setUncertainUserLeakList(null)
          updateImageListRangeDate(
            dayjs(filter.date).add(-7, 'day').format('YYYYMMDD'),
            filter.date
          )
        }
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {
        setLoading(loading)
      },
    })
  }

  const getImageList = () => {
    axiosWithAuth()
      .get(
        `${envBackHost}/${selectedRow.officeId}/customer/${selectedRow.customerNumber}/checkmeter`,
        {
          params: {startDate: rangedDate[0], endDate: rangedDate[1]},
        }
      )
      .then((res) => {
        let checkValue: any[] = []
        _.map(res.data.res, (el, idx: number) => {
          return (checkValue[idx] = el.checkValue)
        })
        setFixValue(checkValue)
        setImageList(res.data.res)
      })
  }

  const updateCheckValuePost = async (idxData: any, imei: any, updateId: any, checkValue: any) => {
    if (updateCheckValue === '' || updateCheckValue === checkValue) {
      await validationAlert('이전값과 동일합니다. 수정값을 입력해주세요.', 'warning')
      return
    }
    if (await confirmAlert('수정', 'info')) {
      await axiosWithAuth()
        .post(`${envBackHost}/checkmeter/update`, {
          idxDate: idxData,
          value: updateCheckValue,
          imei: imei,
          updateId: updateId,
        })
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            getImageList()
            setUpdateCheckValue('')
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    } else {
      return
    }
  }

  const getDownloadExcel = async () => {
    await toast
      .promise(
        axiosWithAuth()
          .get(
            `${envBackHost}/${officeInfo?.value}/${cooperInfo?.value}/customer/leak/uncertain/excel`,
            {
              params: filter,
              responseType: 'blob',
            }
          )
          .then(async (res) => {
            await resultAlert({resultCode: 0})
            fileDownload(res.data, `${officeInfo?.label}_${filter.date}_확인_필요_수용가.xlsx`)
            document.getElementById('downloadModalClose')?.click()
          })
          .catch(async (e) => {
            await resultAlert({resultCode: 400})
          }),
        {
          pending: '다운로드 중입니다.',
        },
        {
          position: 'top-center',
          autoClose: 1000,
          className: 'fs-3  fw-bold',
          style: {width: '400px'},
        }
      )
      .catch(async (e) => {
        await resultAlert({resultCode: 400})
      })
  }

  const updateImageListRangeDate = (startDate: any, endDate: any) => {
    setRangedDate([startDate, endDate])
  }
  /**************************** TABLE ***************************/
  const buttonColor = (data: string) => {
    switch (data) {
      case 'COMPLETE':
        return 'success'
      case 'DIGIT':
        return 'warning'
      case 'FAIL':
        return 'error'
      case 'FIX':
        return 'info'
      case 'IMGERR':
        return 'error'
    }
  }
  const allRows = customerLeakageDetailUncertainListRows(uncertainAllList)
  const allColumns: any = customerLeakageDetailUncertainListColumns(filter)
  const userRows = customerLeakageDetailUncertainUserRows(imageList)
  const userColumns: any = [
    {
      field: 'createAt',
      headerName: '검침일시',
      width: 260,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return <div>{dayjs(params?.row?.createAt).format('YYYY-MM-DD HH시 mm분 ss초')}</div>
      },
    },
    {
      field: 'filePath',
      headerName: '검침 이미지',
      width: 250,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      // hide: data?.deviceType === 'digital',
      renderCell: (params: any) => {
        return (
          <div>
            <img
              src={envImagePath + params?.row?.filePath}
              alt='check'
              width={220}
              style={{
                transform: params?.row?.filePath
                  ? `rotate(${params?.row?.rotate}deg)`
                  : `rotate(0deg)`,
              }}
            />
          </div>
        )
      },
    },

    {
      field: 'processStatus',
      headerName: '이미지 처리상태',
      width: 170,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <span>
            {params.row.processStatus === 'digital' ? '디지털 계량기' : params.row.processStatus}
          </span>
        )
      },
    },
    {
      field: 'checkValue',
      headerName: '검침 값',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.checkValue === '' || params.row.checkValue === null ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.checkValue
                )}
              </span>
              <span>
                {params.row.checkDecimalValue === '' || params.row.checkDecimalValue === null
                  ? ''
                  : '.' + params.row.checkDecimalValue?.split('.')[1]}
              </span>
            </div>
          </>
        )
      },
    },
    {
      field: 'valueUpdate',
      headerName: '검침값 수정',
      width: 280,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      // hide: data?.deviceType === 'digital',
      renderCell: (params: any) => {
        return (
          <>
            <div
              className={'d-flex justify-content-center gap-3'}
              onClick={(e) => handleCellClick(e)}
            >
              <TextField
                sx={{textAlignLast: 'center'}}
                variant={'outlined'}
                size={'small'}
                value={fixValue[params.id - 1]}
                onChange={(e) => {
                  let idx = params.id - 1
                  setFixValue({...fixValue, [idx]: e.target.value})
                  setUpdateCheckValue(e.target.value)
                }}
                disabled={params.row.processStatus === 'IMGERR'}
              />
              <Button
                variant={'contained'}
                color={buttonColor(params.row.processStatus)}
                size={'small'}
                onClick={() => {
                  updateCheckValuePost(
                    params.row.idxDate,
                    params.row.imei,
                    userInfo.userId,
                    params.row.checkValue
                  )
                }}
                disabled={params.row.processStatus === 'IMGERR'}
              >
                수정
              </Button>
            </div>
          </>
        )
      },
    },
  ]

  /**************************** Util ***************************/
  const handleCellClick = (event: any) => {
    event.stopPropagation()
  }
  const onClose = () => {
    setOpen(false)
  }

  const handlePageChange = (newPage: any) => {
    setPage(newPage)
  }
  const resetPage = () => {
    setPage(0)
    if (gridRef.current) {
      const scrollableElement = gridRef.current.querySelector(
        '.MuiDataGrid-virtualScroller'
      ) as HTMLDivElement
      if (scrollableElement) {
        scrollableElement.scrollTop = 0
      }
    }
  }
  /**************************** USE-EFFECT ***************************/
  useEffect(() => {
    if (open === true) {
      scrollTop()
      getUncertainListCall()
    }
  }, [open])

  useEffect(() => {
    if (selectedRow?.customerNumber !== undefined) {
      getUncertainCustomerCall()
      getImageList()
    }
  }, [selectedRow])

  useEffect(() => {
    if (selectedRow?.customerNumber !== undefined) {
      getImageList()
    }
  }, [rangedDate])

  /**************************** HTML ***************************/
  return (
    <Modal
      open={open}
      onClose={() => {
        setSelectedRow(null)
        onClose()
      }}
      aria-labelledby={`${window.location.pathname}-label`}
      aria-describedby={`${window.location.pathname}-description`}
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      id={'printTarget'}
      className={'printTarget'}
    >
      <Box sx={style}>
        <div className={`py-3`}>
          <div className='modal-header pb-3'>
            <div>
              <Chip
                label={`${dayjs(filter.date).format(
                  'MM월 DD일'
                )} 기준 확인이 필요한 수용가 ${totalCount} 건`}
                variant={'outlined'}
                sx={{fontSize: '16px', fontWeight: 'bold', marginRight: '8px'}}
              />
              <Button
                variant={'outlined'}
                color={'success'}
                startIcon={<ListAltIcon sx={{width: 24, height: 24}} />}
                onClick={() => getDownloadExcel()}
              >
                Download
              </Button>
            </div>

            <CancelPresentationIcon
              className={'point-cursor'}
              sx={{fontSize: '40px', cursor: 'pointer'}}
              color={'error'}
              onClick={() => {
                setSelectedRow(null)
                onClose()
              }}
            />
          </div>
          <div className='d-flex gap-4'>
            <div>
              <Box id={'leak-checklist'} sx={{height: 1070, width: 830}}>
                <DataGridPro
                  rows={allRows}
                  loading={allLoading}
                  columns={allColumns}
                  rowHeight={100}
                  pageSize={allPageSize}
                  onPageSizeChange={(newPageSize) => setAllPageSize(newPageSize)}
                  disableColumnMenu
                  rowsPerPageOptions={[10, 30, 50, 70, 100]}
                  pagination
                  disableMultipleSelection
                  hideFooterSelectedRowCount
                  onRowClick={(params) => {
                    resetPage()
                    setDetailFilter({
                      ...detailFilter,
                      date: filter.date,
                      searchData: params.row.customerNumber,
                      searchOption: {
                        ...filter.searchOption,
                        customerNumber: true,
                      },
                    })
                    setSelectedRow(params.row)
                  }}
                  sx={{
                    '& .MuiDataGrid-cell:focus': {
                      outline: 'none',
                    },
                  }}
                />
              </Box>
            </div>
            {selectedRow !== null && (
              <div className={'d-flex row w-100 gap-4]'}>
                <div className={'d-flex gap-4 w-100 justify-content-start align-items-center'}>
                  <span className={'fs-2 fw-bolder '}>
                    {selectedRow?.customerName}님의 검침 정보
                  </span>
                  {uncertainUserLeakList !== null ? (
                    <span className={'fs-4 fw-bolder text-muted'}>
                      (조회 가능 기간{' '}
                      {dayjs(uncertainUserLeakList?.calStartDate).format('MM월 DD일')} ~{' '}
                      {dayjs(uncertainUserLeakList?.calEndDate).format('MM월 DD일')})
                    </span>
                  ) : (
                    <span className={'fs-4 fw-bolder text-muted'}>
                      (조회 가능한 기간이 없습니다.)
                    </span>
                  )}
                </div>

                <div className={' w-100 '} style={{height: 300}}>
                  <LeakViewChartBongHwaTemp
                    className={'w-100'}
                    filter={detailFilter}
                    checkList={
                      uncertainUserLeakList !== null ? uncertainUserLeakList?.checkList : null
                    }
                    avg={uncertainUserLeakList !== null ? uncertainUserLeakList?.avg : null}
                  />
                </div>

                <div className={'d-flex w-100 gap-4 my-7'}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{start: '시작 날짜', end: '마지막 날짜'}}
                  >
                    <DateRangePicker
                      className={'justify-content-start'}
                      value={rangedDate}
                      mask='____/__/__'
                      inputFormat={'YYYY/MM/DD'}
                      onChange={(newValue: any) => {
                        updateImageListRangeDate(
                          newValue[0]?.format('YYYYMMDD'),
                          newValue[1]?.format('YYYYMMDD')
                        )
                      }}
                      renderInput={(startProps, endProps) => {
                        return (
                          <React.Fragment>
                            <TextField {...startProps} autoComplete={'off'} />
                            <Box sx={{mx: 1}}> ~ </Box>
                            <TextField {...endProps} autoComplete={'off'} />
                          </React.Fragment>
                        )
                      }}
                    />
                  </LocalizationProvider>
                  <Button variant={'contained'} onClick={() => getImageList()}>
                    <SearchIcon sx={{width: 24, height: 24}} />
                  </Button>
                </div>

                <Box id={'one'} sx={{height: 630}}>
                  <DataGridPro
                    ref={gridRef}
                    page={page}
                    onPageChange={handlePageChange}
                    loading={loading}
                    rows={loading ? [] : userRows}
                    columns={userColumns}
                    rowHeight={90}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    disableColumnMenu
                    rowsPerPageOptions={[10, 30, 50, 70, 100]}
                    pagination
                    disableMultipleSelection
                    hideFooterSelectedRowCount
                    sx={{
                      '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                      },
                    }}
                  />
                </Box>
              </div>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  )
}
