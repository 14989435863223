import _ from 'lodash'

export const customerAllocationRows = (list: any) => {
  return _.map(list, (el: any, i: any) => {
    return {
      officeId: el.officeId,
      officeName: el.officeName,
      id: i + 1,
      customerNumber: el?.customerNumber,
      customerName: el.customerName,
      customerStatus: el.customerStatus,
      addressDetail: el.addressDetail,
      addressNumber: el.addressNumber,
      addressRoad: el.addressRoad,
      assignStatus: el.assignStatus,
      assignFromId: el.assignFromId,
      assignFromName: el.assignFromName,
      assignToId: el.assignToId,
      assignToName: el.assignToName,
    }
  })
}
export const customerInfoRows = (list: any) => {
  return _.map(list, (el: any, i: any) => {
    return {
      id: i + 1,
      addressDetail: el.addressDetail === null ? '' : el.addressDetail,
      addressNumber: el.addressNumber,
      addressRoad: el.addressRoad,
      batteryLevel: el.batteryLevel,
      brightness: el.brightness,
      checkDay: el.checkDay,
      checkCreateAt: el.checkCreateAt,
      checkDecimalValue: el.checkDecimalValue,
      checkMonth: el.checkMonth,
      contractNumber: el.contractNumber,
      customerStatus: el.customerStatus,
      contrast: el.contrast,
      installAt: el.installAt,
      asIdx: el.asIdx,
      installerName: el.installerName,
      customerTypeId: el.customerTypeId,
      customerTypeName: el.customerTypeName,
      customerWorkTypeName: el.customerWorkTypeName,
      customerWorkTypeId: el.customerWorkTypeId,
      deviceCommFailCount: el.deviceCommFailCount,
      deviceConnectAt: el.deviceConnectAt,
      deviceType: el.deviceType,
      deviceTypeName: el.deviceTypeName,
      deviceVersion: el.deviceVersion,
      processStatus: el.processStatus,
      phoneNumber: el.phoneNumber,
      digit: el.digit,
      deviceStatus: el.deviceStatus,
      meterReadCommFailCount: el.meterReadCommFailCount,
      efosConnectAt: el.efosConnectAt,
      efosStatus: el.efosStatus,
      latitude: el.latitude,
      longitude: el.longitude,
      memo: el.memo,
      managerName: el.managerName,
      meterReadInstallDate: el.meterReadInstallDate,
      meterReadSerialNumber: el.meterReadSerialNumber,
      meterReadVersion: el.meterReadVersion,
      remainDurability: el.remainDurability,
      rotate: el.rotate,
      serverCycle: el.serverCycle,
      signalStrength: el.signalStrength,
      checkValue: el.checkValue,
      cooperIdx: el.cooperIdx,
      cooperName: el.cooperName,
      customerName: el.customerName,
      customerNumber: el.customerNumber,
      deviceApplyStatus: el.deviceApplyStatus,
      filePath: el.filePath,
      imageIdxDate: el.imageIdxDate,
      imei: el.imei,
      meterDiameter: el.meterDiameter,
      meterNumber: el.meterNumber,
      officeId: el.officeId,
      officeName: el.officeName,
      serialNumber: el.serialNumber,
      temperature: el.temperature,
      updateCheckValue: el.updateCheckValue,
      updateCheckdecimalValue: el.updateCheckdecimalValue,
      xfrom: el.xfrom,
      yfrom: el.yfrom,
      xto: el.xto,
      yto: el.yto,
      recognizedType: el.recognizedType,
    }
  })
}
export const customerInfoBoardVersionRows = (list: any) => {
  return _.map(list, (el: any, i: any) => {
    return {
      id: i + 1,
      addressDetail: el.addressDetail === null ? '' : el.addressDetail,
      addressNumber: el.addressNumber,
      addressRoad: el.addressRoad,
      batteryLevel: el.batteryLevel,
      brightness: el.brightness,
      boardVersion: el.boardVersion,
      checkDay: el.checkDay,
      checkCreateAt: el.checkCreateAt,
      checkDecimalValue: el.checkDecimalValue,
      checkMonth: el.checkMonth,
      contractNumber: el.contractNumber,
      customerStatus: el.customerStatus,
      contrast: el.contrast,
      installAt: el.installAt,
      asIdx: el.asIdx,
      installerName: el.installerName,
      customerTypeId: el.customerTypeId,
      customerTypeName: el.customerTypeName,
      customerWorkTypeName: el.customerWorkTypeName,
      customerWorkTypeId: el.customerWorkTypeId,
      deviceCommFailCount: el.deviceCommFailCount,
      deviceConnectAt: el.deviceConnectAt,
      deviceType: el.deviceType,
      deviceTypeName: el.deviceTypeName,
      deviceVersion: el.deviceVersion,
      processStatus: el.processStatus,
      phoneNumber: el.phoneNumber,
      digit: el.digit,
      deviceStatus: el.deviceStatus,
      meterReadCommFailCount: el.meterReadCommFailCount,
      efosConnectAt: el.efosConnectAt,
      efosStatus: el.efosStatus,
      latitude: el.latitude,
      longitude: el.longitude,
      memo: el.memo,
      managerName: el.managerName,
      meterReadInstallDate: el.meterReadInstallDate,
      meterReadSerialNumber: el.meterReadSerialNumber,
      meterReadVersion: el.meterReadVersion,
      remainDurability: el.remainDurability,
      rotate: el.rotate,
      serverCycle: el.serverCycle,
      signalStrength: el.signalStrength,
      checkValue: el.checkValue,
      cooperIdx: el.cooperIdx,
      cooperName: el.cooperName,
      customerName: el.customerName,
      customerNumber: el.customerNumber,
      deviceApplyStatus: el.deviceApplyStatus,
      filePath: el.filePath,
      imageIdxDate: el.imageIdxDate,
      imei: el.imei,
      meterDiameter: el.meterDiameter,
      meterNumber: el.meterNumber,
      officeId: el.officeId,
      officeName: el.officeName,
      serialNumber: el.serialNumber,
      temperature: el.temperature,
      updateCheckValue: el.updateCheckValue,
      updateCheckdecimalValue: el.updateCheckdecimalValue,
      xfrom: el.xfrom,
      yfrom: el.yfrom,
      xto: el.xto,
      yto: el.yto,
      recognizedType: el.recognizedType,
    }
  })
}
export const customerActivityRows = (list: any) => {
  return _.map(list, (el: any, i: any) => {
    return {
      id: i,
      address: el?.address,
      checkCreateAt: el?.checkCreateAt,
      checkDecimalValue: el?.checkDecimalValue,
      checkModelList: el?.checkModelList,
      checkValue: el?.checkValue,
      customerName: el?.customerName,
      customerNumber: el?.customerNumber,
      customerTypeId: el?.customerTypeId,
      customerTypeName: el?.customerTypeName,
      deviceStatus: el?.deviceStatus,
      deviceType: el?.deviceType,
      efosStatus: el?.efosStatus,
      filePath: el?.filePath,
      imageIdxDate: el?.imageIdxDate,
      latitude: el?.latitude,
      longitude: el?.longitude,
      officeId: el?.officeId,
      officeName: el?.officeName,
    }
  })
}

export const customerCheckListRows = (list: any) => {
  return _.map(list, (el: any, i: any) => {
    return {
      id: i + 1,
      officeId: el?.officeId,
      customerNumber: el?.customerNumber,
      customerName: el?.customerName,
      checkMeterList: el?.checkMeterList,
      processStatus: el?.processStatus,
      filePath: el?.filePath,
      idxDate: el?.idxDate,
      imageSendAt: el?.imageSendAt,
      address: el?.address,
    }
  })
}

export const customerCheckReportRows = (list: any) => {
  return _.map(list, (el: any, i: any) => {
    return {
      id: i + 1,
      customerNumber: el.customerNumber,
      checkDay: el.checkDay,
      customerName: el.customerName,
      address: el.address,
      endCheckValue: el.endCheckValue,
      endFilePath: el.endFilePath,
      endIdxDate: el.endIdxDate,
      meterDiameter: el.meterDiameter,
      officeId: el.officeId,
      deviceType: el.deviceType,
      startCheckValue: el.startCheckValue,
      startFilePath: el.startFilePath,
      startIdxDate: el.startIdxDate,
      startImei: el.startImei,
      startRotate: el.startRotate,
      endRotate: el.endRotate,
      endImei: el.endImei,
    }
  })
}

export const customerMinimumFlowRows = (list: any) => {
  return _.map(list, (el: any, i: any) => {
    return {
      id: i + 1,
      officeId: el?.officeId,
      customerNumber: el.customerNumber,
      customerName: el?.customerName,
      address: el?.address,
      checkMeterList: el?.checkMeterList,
      totalUsage: _.sumBy(el?.checkMeterList, (el: any) => {
        return Number(el.use)
      }),
    }
  })
}

export const customerLeakageRows = (list: any) => {
  return _.map(list, (el: any, i: any) => {
    return {
      id: i + 1,
      officeId: el.officeId,
      checkList: el.checkList,
      customerNumber: el.customerNumber,
      leakCheckValue: el.leakCheckValue,
      leakFilePath: el.leakFilePath,
      leakIdxDate: el.leakIdxDate,
      leakStatus: el.leakStatus,
      customerName: el.customerName,
      address: el.address,
      leakUseUse: el.leakUseUse,
      todayUse: el.todayUse,
      todayCheckValue: el.todayCheckValue,
      todayFilePath: el.todayFilePath,
      todayIdxDate: el.todayIdxDate,
      avg: el.avg,
      calEndDate: el.calEndDate,
      calStartDate: el.calStartDate,
      leakUseCycle: el.leakUseCycle,
      usedAmountUntilRecent: el.usedAmountUntilRecent,
      usedAmountUntilToday: el.usedAmountUntilToday,
    }
  })
}

export const customerLeakageDetailUncertainListRows = (list: any) => {
  const result = _.chain(list)
    .map((el, i) => ({
      id: i + 1,
      customerName: el?.customerName,
      customerNumber: el?.customerNumber,
      address: el?.address,
      filePath: el?.filePath,
      lastIdxDate: el?.lastIdxDate,
      officeId: el?.officeId,
    }))
    .filter((item) => item.customerNumber !== null || item.customerName !== null)
    .value()
  return result
}

export const customerLeakageDetailUncertainUserRows = (list: any) => {
  return _.map(list, (el, i) => {
    return {
      id: i + 1,
      checkDecimalValue: el?.checkDecimalValue,
      checkValue: el?.checkValue,
      createAt: el?.createAt,
      fileName: el?.fileName,
      filePath: el?.filePath,
      rotate: el?.rotate,
      idxDate: el?.idxDate,
      imei: el?.imei,
      processStatus: el?.processStatus,
      updateAt: el?.updateAt,
      updateCheckDecimalValue: el?.updateCheckDecimalValue,
      updateCheckValue: el?.updateCheckValue,
    }
  })
}

export const customerLongTermUnusedUserRows = (list: any) => {
  return _.map(list, (el, i) => {
    return {
      id: i + 1,
      customerName: el?.customerName,
      customerNumber: el?.customerNumber,
      address: el?.address,
      officeId: el?.officeId,
      firstFilePath: el?.checkList[0]?.filePath,
      firstIdxDate: el?.checkList[0]?.idxDate,
      firstCheckValue: el?.checkList[0]?.checkValue,
      secondFilePath: el?.checkList[1]?.filePath,
      secondIdxDate: el?.checkList[1]?.idxDate,
      secondCheckValue: el?.checkList[1]?.checkValue,
    }
  })
}

export const customerFreezeWarningRows = (list: any) => {
  return _.map(list, (el: any, i: any) => {
    return {
      id: i + 1,
      addressDetail: el.addressDetail === null ? '' : el.addressDetail,
      addressNumber: el.addressNumber,
      addressRoad: el.addressRoad,
      batteryLevel: el.batteryLevel,
      brightness: el.brightness,
      checkDay: el.checkDay,
      checkCreateAt: el.checkCreateAt,
      checkDecimalValue: el.checkDecimalValue,
      checkMonth: el.checkMonth,
      contractNumber: el.contractNumber,
      customerStatus: el.customerStatus,
      contrast: el.contrast,
      installAt: el.installAt,
      asIdx: el.asIdx,
      installerName: el.installerName,
      customerTypeId: el.customerTypeId,
      customerTypeName: el.customerTypeName,
      customerWorkTypeName: el.customerWorkTypeName,
      customerWorkTypeId: el.customerWorkTypeId,
      deviceCommFailCount: el.deviceCommFailCount,
      deviceConnectAt: el.deviceConnectAt,
      deviceType: el.deviceType,
      deviceTypeName: el.deviceTypeName,
      deviceVersion: el.deviceVersion,
      freezeWarningType: el.freezeWarningType,
      processStatus: el.processStatus,
      phoneNumber: el.phoneNumber,
      digit: el.digit,
      deviceStatus: el.deviceStatus,
      meterReadCommFailCount: el.meterReadCommFailCount,
      efosConnectAt: el.efosConnectAt,
      efosStatus: el.efosStatus,
      latitude: el.latitude,
      longitude: el.longitude,
      memo: el.memo,
      managerName: el.managerName,
      meterReadInstallDate: el.meterReadInstallDate,
      meterReadSerialNumber: el.meterReadSerialNumber,
      meterReadVersion: el.meterReadVersion,
      remainDurability: el.remainDurability,
      rotate: el.rotate,
      serverCycle: el.serverCycle,
      signalStrength: el.signalStrength,
      checkValue: el.checkValue,
      cooperIdx: el.cooperIdx,
      cooperName: el.cooperName,
      customerName: el.customerName,
      customerNumber: el.customerNumber,
      deviceApplyStatus: el.deviceApplyStatus,
      filePath: el.filePath,
      imageIdxDate: el.imageIdxDate,
      imei: el.imei,
      meterDiameter: el.meterDiameter,
      meterNumber: el.meterNumber,
      officeId: el.officeId,
      officeName: el.officeName,
      serialNumber: el.serialNumber,
      temperature: el.temperature,
      updateCheckValue: el.updateCheckValue,
      updateCheckdecimalValue: el.updateCheckdecimalValue,
      xfrom: el.xfrom,
      yfrom: el.yfrom,
      xto: el.xto,
      yto: el.yto,
      recognizedType: el.recognizedType,
    }
  })
}
