import {Button, MenuItem, Pagination, Select} from '@mui/material'
import Box from '@mui/material/Box'
import {DataGridPro, GridColDef, GridRowId} from '@mui/x-data-grid-pro'
import axios from 'axios'
import dayjs from 'dayjs'
import {envBackHost} from 'GLOBAL/envVariables'
import _ from 'lodash'
import {useEffect, useLayoutEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {customConfirm, validationAlert} from 'utils/CustomAlert/Alert'

const FotaListTable = (props: any) => {
  const location = useLocation()
  const pageState = location?.state
  const {batchInfo} = props
  const [filter, setFilter] = useState<any>({
    searchData: '',
    deviceStatus: pageState?.deviceStatus === undefined ? 'all' : pageState?.deviceStatus,
    efosStatus: 'all',
    deviceType: pageState?.deviceType === undefined ? 'all' : pageState?.deviceType,
    batteryStatus: pageState?.batteryStatus === undefined ? 'all' : pageState?.batteryStatus,
    rowPerPage: 30,
    page: 1,
  })

  const [totalCount, setTotalCount] = useState<any>(null)

  //여기 까지 sc

  const [pageSize, setPageSize] = useState<number>(50)
  const [list, setList] = useState<any>([])

  //feature/sc
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([])
  const [selectedData, setSelectedData] = useState<any[]>([]) // 추가된 상태

  const getSelectedRowData = (selectedIds: GridRowId[]) => {
    const data = rows.filter((row) => selectedIds.includes(row.id))
    setSelectedData(data)
  }

  const handleCellClick = (event: any) => {
    event.stopPropagation()
  }

  useEffect(() => {
    getList()
  }, [batchInfo?.officeId])

  const getList = () => {
    axios
      .post(
        `${envBackHost}/fota/device/${batchInfo?.officeId === 'yit' ? 'all' : batchInfo?.officeId}`,
        {
          searchQuery: null,
        }
      )
      .then((res) => {
        setList(res.data.res)
        setTotalCount(res.data.res)
      })
  }

  const matchingHandler = async (target: any, data: any) => {
    if (target === '등록필요') {
      if (await customConfirm('작업중인 내용이 사라질 수 있습니다.', 'warning', '확인')) {
        window.location.replace('/customer/register')
      } else return
    }
    //feature/sc
    await axios
      .post(`${envBackHost}/fota/device/mapping`, {
        res: [
          {
            officeId: data.officeId,
            imei: data.imei,
            customerNumber: data.customerNumber,
          },
        ],
      })
      .then((res) => {
        if (res.data.message === '이미 설치된 수용가입니다.') {
          validationAlert(res.data.message, 'warning')
        } else if (res.data.message === 'NULL값이 있습니다.') {
          validationAlert(res.data.message, 'warning')
        } else if (res.data.message === '잘못된 파일입니다.') {
          validationAlert(res.data.message, 'warning')
        } else {
          validationAlert(res.data.message, 'success')
        }
        getList()
      })
  }
  //feature/sc
  const matchingData = _.map(selectedData, (el, i) => {
    return {
      id: i + 1,
      officeId: el.officeId,
      customerName: el.customerName,
      customerNumber: el.customerNumber,
      imei: el.imei,
      officeName: el.officeName,
      status: el.status,
      updateAt: el.updateAt,
      createAt: el.createAt,
      customer_name: el.customer_name,
    }
  })
  //feature/sc
  const allMatchingHandler = async (data: any) => {
    const requestBody = {
      res: data,
    }

    await axios
      .post(`${envBackHost}/fota/device/mapping`, requestBody)
      .then((res) => {
        getList()
        if (res.data.resultCode === 0) {
          validationAlert(res.data.message, 'success')
        } else if (res.data.resultCode === 30) {
          validationAlert(res.data.message, 'warning')
        } else {
          validationAlert('실패하였습니다.', 'warning')
        }
      })
      .catch((err) => {
        console.log(err)
        return
      })
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'No.',
      width: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'imei',
      headerName: '단말기 IMEI',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'officeName',
      headerName: '사업소 명',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'customerNumber',
      headerName: '수용가 번호',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div
            className={'d-flex gap-1 justify-content-center '}
            style={{
              color: params.row.customerNumber === '미등록' ? 'red' : 'black',
            }}
          >
            <div>{params.row.customerNumber}</div>
          </div>
        )
      },
    },
    {
      field: 'customerName',
      headerName: '수용가 명',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'location',
      headerName: '위치',
      width: 300,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'d-flex gap-1 justify-content-center '}>
            <div>위도 : {params.row.location.latitude?.toString().substring(0, 9)}</div>
            <div className={'mx-2'}>/</div>
            <div>경도 : {params.row.location.longitude?.toString().substring(0, 9)}</div>
          </div>
        )
      },
    },
    {
      field: 'createAt',
      headerName: '생성일시',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'updateAt',
      headerName: '수정일시',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'status',
      headerName: '상태',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'action',
      headerName: '',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div
            className={'d-flex gap-3 justify-content-center'}
            onClick={(e) => handleCellClick(e)}
          >
            <Button
              variant={'contained'}
              color={params.row.customerName === '등록필요' ? 'inherit' : 'primary'}
              onClick={() => {
                matchingHandler(params.row.customerName, params.row)
              }}
            >
              {params.row.customerName === '등록필요' ? '등록' : '매칭'}
            </Button>
          </div>
        )
      },
    },
  ]
  const rows = _.map(list, (el, i) => {
    return {
      id: i + 1,
      imei: el.imei,
      officeName: el.officeName,
      officeId: el.officeId,
      customerNumber:
        el.customerNumber === 'NULL' || el.customerNumber === 'Empty'
          ? '미등록'
          : el.customerNumber,
      customerName: el.customerName,
      location: {latitude: el.latitude, longitude: el.longitude},
      createAt: dayjs(el.createAt).format('YY-MM-DD HH시 mm분'),
      updateAt: dayjs(el.updateAt).format('YY-MM-DD HH시 mm분'),
      status: el.status,
    }
  })

  useLayoutEffect(() => {
    getList()
  }, [])
  return (
    <div>
      <div className={'text-end mb-2'}>
        <div>
          <span className={''} style={{fontSize: '20px', fontWeight: 'bold'}}>
            {' '}
            선택 단말기 : {selectedRows.length}건
          </span>
        </div>
        <Button
          variant={'contained'}
          color={'success'}
          onClick={() => {
            allMatchingHandler(matchingData)
            // console.log(pageSize)
          }}
        >
          일괄매칭
        </Button>
      </div>
      <Box id={'table-content'} sx={{height: '60vh', width: '100%'}}>
        <DataGridPro
          scrollbarSize={60}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 30, 50, 70, 100]}
          disableColumnMenu
          paginationMode={'server'}
          hideFooterPagination={true}
          pagination={true}
          hideFooter={true}
          // checkboxSelection={true}
          checkboxSelection //feature/sc 23.12.19
          checkboxSelectionVisibleOnly={true}
          onSelectionModelChange={(newSelection) => {
            setSelectedRows(newSelection)
            getSelectedRowData(newSelection)
          }}
          getRowClassName={(params) => {
            if (params.row.customerName === '등록필요') {
              return 'bg-warning text-dark'
            }
            return ''
          }}
        />
      </Box>
      <div className={'mt-5 d-flex gap-3 justify-content-center align-items-center'}>
        <Pagination
          // count={Number(Number(totalCount / filter?.rowPerPage)?.toFixed(0))}
          count={Math.ceil(totalCount / (filter?.rowPerPage || 30))}
          color='primary'
          size={'large'}
          sx={{
            '& .MuiPaginationItem-root': {
              fontSize: '24px',
              fontWeight: 'bold',
            },
            '& .MuiPagination-ul': {
              justifyContent: 'center',
            },
          }}
          variant='outlined'
          shape='rounded'
          siblingCount={4}
          page={filter?.page}
          onChange={(e, page) => {
            setFilter({...filter, page: page})
            // console.log('22')
          }}
        />
        <Select
          labelId='demo-simple-select-label'
          id='customer-target'
          label='페이지 당 행'
          size={'small'}
          value={filter?.rowPerPage}
          onChange={(e) => {
            // console.log('d')
            setFilter({...filter, page: 1, rowPerPage: e.target.value})
          }}
        >
          {_.map([30, 50, 100], (el, i) => {
            return (
              <MenuItem key={i} value={el}>
                {el} / 페이지 당
              </MenuItem>
            )
          })}
        </Select>
      </div>
    </div>
  )
}

export default FotaListTable
